import properties from "components/properties/ApplicationProps";

export function getCompleteUploadResourceJson({ versionFlag, clientId, promiseArr }) {
	const requestcontext = {
		action: properties.actions.INSERT,
		updateType: properties.updateType.RESOURCEUPDATE,
		partETagCombo: promiseArr,
		clientId,
		versionFlag,
	};

	return requestcontext;
}

export function getNewCompleteUploadResourceJson({ versionFlag, clientId, promiseArr }) {
	const requestcontext = {
		action: properties.actions.INSERT,
		updateType: properties.updateType.RESOURCEUPDATE,
		partETagCombo: promiseArr,
		clientId,
		versionFlag,
	};

	return requestcontext;
}

export function getKeepOrReplaceCompleteUploadJson({ file, etagsArray, requestId }) {
	const requestcontext = {
		header: {
			clientId: `${file.fileId}-1`,
		},
		action: properties.actions.INSERT,
		updateType: properties.updateType.RESOURCEUPDATE,
		partETagCombo: etagsArray,
		versionFlag: file.uploadFlag,
		dfReqId: requestId,
	};

	return requestcontext;
}
