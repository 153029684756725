import cutIcon from "assets/proto_1/cut.png";
import pasteIcon from "assets/proto_1/paste.png";
import shareIcon from "assets/proto_1/share.png";
import infoIcon from "assets/proto_1/details.png";
import versionsIcons from "assets/proto_1/versions.png";
import renameIcon from "assets/proto_1/rename.png";
import deleteIcon from "assets/proto_1/delete.png";
import downloadIcon from "assets/proto_1/download.png";
import addNewFolderIcon from "assets/proto_1/new_folder.png";
import cloneIcon from "assets/proto_1/clone.png";
import parentIcon from "assets/proto_1/gotolocations.png";
import unshareIcon from "assets/proto_1/unshare.png";

const contextMenuOptionsList = [
	{
		name: "New Folder",
		type: "item",
		icon: (
			<img
				src={addNewFolderIcon}
				alt="addfolder_icon"
				className="context-icon"
			></img>
		),
		action: "createFolder",
		href: "#",
	},
	{
		name: "Rename",
		type: "item",
		icon: (
			<img
				src={renameIcon}
				alt="edit_icon"
				className="context-icon"
			></img>
		),
		action: "rename",
		href: "#",
	},
	{
		name: "Clone",
		type: "item",
		icon: (
			<img
				src={cloneIcon}
				alt="cut_icon"
				className="context-icon"
			></img>
		),
		action: "clone",
		href: "#",
	},
	{
		name: "Cut",
		type: "item",
		icon: (
			<img
				src={cutIcon}
				alt="cut_icon"
				className="context-icon"
			></img>
		),
		action: "cut",
		href: "#",
	},
	{
		name: "Paste",
		type: "item",
		icon: (
			<img
				src={pasteIcon}
				alt="paste_icon"
				className="context-icon"
			></img>
		),
		action: "paste",
	},
	{
		name: "Share",
		type: "item",
		icon: (
			<img
				src={shareIcon}
				alt="share_icon"
				className="context-icon"
			></img>
		),
		action: "share",
	},
	{
		name: "Unshare",
		type: "item",
		icon: (
			<img
				src={unshareIcon}
				alt="share_icon"
				className="context-icon"
			></img>
		),
		action: "unshare",
	},
	{
		name: "Download",
		type: "item",
		icon: (
			<img
				src={downloadIcon}
				alt="download_icon"
				className="context-icon"
			></img>
		),
		action: "download",
	},
	{
		name: "Delete",
		type: "item",
		icon: (
			<img
				src={deleteIcon}
				alt="delete_icon"
				className="context-icon"
			></img>
		),
		action: "delete",
	},
	{
		name: "Show Versions",
		type: "item",
		icon: (
			<img
				src={versionsIcons}
				alt="version_icon"
				className="context-icon"
			></img>
		),
		action: "showVersions",
	},
	{
		name: "Go To Location",
		type: "item",
		icon: (
			<img
				src={parentIcon}
				alt="parent_icon"
				className="context-icon"
			></img>
		),
		action: "goToParent",
	},
	{
		name: "Details",
		type: "item",
		icon: (
			<img
				src={infoIcon}
				alt="delete_icon"
				className="context-icon"
			></img>
		),
		action: "details",
	},
];

export default contextMenuOptionsList;
