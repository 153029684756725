import properties from "components/properties/ApplicationProps";

function getGenerateNotificationJSON(notificationDetails) {
	console.log("Generating notification", notificationDetails);
	const baseJson = {
		action: notificationDetails.action,
		event: properties.events.NOTIFY,
		lastUpdateUser: notificationDetails.lastupdateuser || sessionStorage.getItem("email"),
		systemRole: properties.userRoles.member,
		userAccounts: [
			{
				email: notificationDetails.email,
				userId: notificationDetails.userid,
				role: properties.userRoles.member,
			},
		],
		notificationid: notificationDetails.notificationid || "",
	};

	return baseJson;
}

function getAcceptNotificationJSON(notificationDetails) {
	console.log("Accepting notification", notificationDetails);
	const baseJson = {
		action: notificationDetails.action,
		event: properties.events.NOTIFY,
		lastUpdateUser: notificationDetails.lastupdateuser || sessionStorage.getItem("email"),
		systemRole: properties.userRoles.member,
		userAccounts: [
			{
				email: sessionStorage.getItem("email"),
				userId: notificationDetails.userid,
				role: properties.userRoles.member,
			},
		],
		notificationId: notificationDetails.notificationid || "",
	};

	return baseJson;
}

function getRejectNotificationJSON(notificationDetails) {
	console.log("Rejecting notification", notificationDetails);
	const baseJson = {
		action: properties.actions.REJECT_NOTIFICATION,
		event: properties.events.NOTIFY,
		lastUpdateUser: notificationDetails.lastupdateuser || sessionStorage.getItem("email"),
		systemRole: properties.userRoles.member,
		userAccounts: [
			{
				email: sessionStorage.getItem("email"),
				userId: notificationDetails.userid,
				role: properties.userRoles.member,
			},
		],
		notificationId: notificationDetails.notificationid || "",
		// Fields specific to rejection
		rejectionReason: notificationDetails.rejectionReason || "No reason provided",
		rejectionTime: new Date().toISOString(),
	};

	return baseJson;
}

export { getGenerateNotificationJSON, getAcceptNotificationJSON, getRejectNotificationJSON };
