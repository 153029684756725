import React, { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { neutral, textsize } from "assets/css/MainCss";
import { ContextRequestUpdateResource } from "components/requestcontext/UpdateResource";
import { dashboardApi } from "components/misc/DashboardApi";
import eventEmitter from "components/misc/eventEmitter";
import Notification from "./notification/Notification";
import { useContext } from "react";
import { FontContext } from "../UI/Theme";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { responseMessage } from "Constants";
import checkIfResponseIsSuccOrFail from "utils/helpers/checkIfResponseIsSuccOrFail";

const RenameDialog = ({ renameDialogOpen, handleCloseRenameDialog }) => {
	const resourceItem = useSelector(({ resourceGrid }) => resourceGrid.resourceToRename[0]);
	const defaultFileName = resourceItem?.filename ?? "";
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { fontFamily, largeTextSize } = useContext(FontContext);

	const { handleSubmit, control, setValue } = useForm({});

	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	const handleResourceRename = useCallback(
		async ({ newFileName }) => {
			const resource = resourceItem;
			try {
				let updateRequestJson = ContextRequestUpdateResource.getUpdateResourceInfoJson(resource);
				console.log("resource info", resource.resourceid);
				const fileName = newFileName;
				updateRequestJson.fileName = fileName;
				updateRequestJson.parentFolderId = resource.parentfolderid;
				console.log("updateinfo Request json body:", updateRequestJson);
				const updateNameResponse = await dashboardApi.updateResourceInfo(updateRequestJson);

				if (checkIfResponseIsSuccOrFail(updateNameResponse) === responseMessage.common.success) {
					showSnackbarWithMessage("Rename file sucessfully");
				} else {
					showSnackbarWithMessage(`Failed to rename ${resource.filename} file`);
				}

				eventEmitter.emit("RefreshDashboardEvent");
				handleCloseRenameDialog();
			} catch (error) {
				console.log(error);
			}
		},
		[resourceItem, handleCloseRenameDialog],
	);

	useEffect(() => {
		setValue("newFileName", defaultFileName);
	}, [defaultFileName, setValue]);

	return (
		<div>
			<Dialog
				open={renameDialogOpen}
				onClose={handleCloseRenameDialog}
				fullWidth
				maxWidth="xs"
			>
				<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>Rename as</DialogTitle>
				<DialogContent>
					<form
						style={{ margin: 0 }}
						onSubmit={handleSubmit((formData) => handleResourceRename(formData))}
					>
						<Controller
							control={control}
							name="newFileName"
							render={({ field }) => {
								return (
									<TextField
										autoFocus
										fullWidth
										margin="dense"
										id="resourcename"
										label="New Resource Name"
										size="small"
										variant="outlined"
										style={{ fontFamily: fontFamily }}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
										{...field}
									/>
								);
							}}
						/>

						<DialogActions
							style={{
								marginRight: "-8px",
								paddingBottom: "10px",
								marginTop: "0.5rem",
							}}
						>
							<Button
								onClick={handleCloseRenameDialog}
								variant="outlined"
								color="primary"
								style={{
									color: neutral.buttoncolor,
									borderColor: neutral.buttoncolor,
									fontSize: textsize.buttontextsize,
									fontFamily: fontFamily,
								}}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="contained"
								style={{
									backgroundColor: neutral.buttoncolor,
									color: neutral.buttontextcolor,
									fontSize: textsize.buttontextsize,
									fontFamily: fontFamily,
								}}
								data-button-name="Rename"
							>
								Submit
							</Button>
						</DialogActions>
					</form>
				</DialogContent>
			</Dialog>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
};

export default React.memo(RenameDialog);
