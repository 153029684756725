import { checkFilePath } from "./checkFilePath";

export function getTopLevelFolder(file) {
	let folderLocations = checkFilePath(file);

	if (!folderLocations) {
		return null;
	}

	folderLocations = folderLocations
		.split("/")
		.filter((x) => x) // filter will remove any falsy value from array
		.slice(0, folderLocations.length - 1);
	return folderLocations[0];
}
