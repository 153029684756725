import React, { useState, useEffect, memo, useContext } from "react";
import { List } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";
//icons
import { Menu as MenuIcon } from "@material-ui/icons";

// components
import SidebarLink from "components/dashboard/sidenav/SidebarLink/SidebarLink";
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "routes/LayoutContext";
import EventEmitter from "components/misc/eventEmitter";
import { structure } from "./SideNavlink";
import { neutral } from "assets/css/MainCss";
import { useDispatch, useSelector } from "react-redux";
import { RESET_DROPPED_LOCATION_ID } from "store/droppedLocationIdSlice";
import { GlobalRole } from "Constants";
import { FontContext } from "components/UI/Theme";
import {
	RESET_DASHBOARD_UP_LOCATION_ID_SLICE,
	RESET_SHARED_WITH_ME_UP_LOCATION_ID_SLICE,
} from "store/gridNavigationSlice";
import { HIDE_VERSIONS_COLUMN } from "store/resourceGridSlice";
import { SHOW_NEW_FOLDER_DIALOG } from "store/uiSlice";
import eventEmitter from "components/misc/eventEmitter";

const Sidebar = () => {
	const classes = useStyles();
	const theme = useTheme();
	const { isSidebarOpened } = useLayoutState();
	const layoutDispatch = useLayoutDispatch();
	const dispatch = useDispatch();
	const currentDashboardParentId = useSelector(({ dashboard }) => dashboard.data.parentFolderId);
	const { fontFamily, largeTextSize } = useContext(FontContext);

	const [isPermanent, setPermanent] = useState(true);

	useEffect(function () {
		console.log(currentDashboardParentId);
		window.addEventListener("resize", handleWindowWidthChange);
		handleWindowWidthChange();
		return function cleanup() {
			window.removeEventListener("resize", handleWindowWidthChange);
		};
	});

	const handleOpenCreateFolderDialog = () => {
		if (GlobalRole.userrole !== "user") {
			dispatch(SHOW_NEW_FOLDER_DIALOG());
		}
	};

	const uploadFolder = () => {
		EventEmitter.emit("selectUploadFolder");
	};

	const uploadFiles = () => {
		EventEmitter.emit("selecttuploadfile");
	};

	const handleAction = (action) => (event) => {
		switch (action) {
			case "NewFolder":
				handleOpenCreateFolderDialog();
				break;
			case "UploadFolder":
				uploadFolder();
				break;
			case "UploadFile":
				uploadFiles();
				break;
			case "resetDashboard":
				dispatch(RESET_DROPPED_LOCATION_ID());
				dispatch(RESET_DASHBOARD_UP_LOCATION_ID_SLICE());
				dispatch(HIDE_VERSIONS_COLUMN());
				eventEmitter.emit("resetGridFilters");
				EventEmitter.emit("resetGridSelectionService");
				break;
			case "resetSharedWithMe":
				dispatch(RESET_SHARED_WITH_ME_UP_LOCATION_ID_SLICE());
				dispatch(HIDE_VERSIONS_COLUMN());
				eventEmitter.emit("resetGridFilters");
				EventEmitter.emit("resetGridSelectionService");
				break;
			default:
				break;
		}
	};

	const updatedStructure = structure.map((item) => ({
		...item,
		label: (
			<span
				className={classes.breadcrumblinkSidenav}
				style={{ fontFamily: fontFamily, fontSize: largeTextSize }}
			>
				{item.label}
			</span>
		),
		icon: (
			<img
				src={item.icon}
				alt={`${item.label}_icon`}
				className={classes.iconStyle}
				style={{ marginRight: "50%", marginLeft: "6px", fontFamily: fontFamily }}
			/>
		),
	}));

	const handleWindowWidthChange = () => {
		const windowWidth = window.innerWidth;
		const breakpointWidth = theme.breakpoints.values.md;
		const isSmallScreen = windowWidth < breakpointWidth;

		if (isSmallScreen && isPermanent) {
			setPermanent(false);
		} else if (!isSmallScreen && !isPermanent) {
			setPermanent(true);
		}
	};

	return (
		<>
			<Drawer
				variant={isPermanent ? "permanent" : "temporary"}
				className={classNames(classes.drawer, {
					[classes.drawerOpen]: isSidebarOpened,
					[classes.drawerClose]: !isSidebarOpened,
				})}
				classes={{
					paper: classNames({
						[classes.drawerOpen]: isSidebarOpened,
						[classes.drawerClose]: !isSidebarOpened,
						[classes.drawerWithoutBorder]: true,
					}),
				}}
				open={isSidebarOpened}
				PaperProps={{
					sx: { border: "none" },
				}}
			>
				<div className={classes.toolbar} />
				<div className={classes.mobileBackButton}>
					<MenuIcon
						onClick={() => toggleSidebar(layoutDispatch)}
						style={{ color: neutral.textcolor }}
						classes={{
							root: classNames(classes.headerIcon, classes.headerIconCollapse),
						}}
					/>
				</div>
				<List>
					{updatedStructure.map((link) => (
						<div
							key={link.id}
							style={{ marginBottom: "5px", padding: "0px" }}
							onClick={handleAction(link.action)}
						>
							<SidebarLink
								key={link.id}
								isSidebarOpened={isSidebarOpened}
								{...link}
								className={updatedStructure.disabled ? "disabled" : ""}
								nestedRoutes={link?.nestedRoutes}
								icon={link.icon}
							/>
						</div>
					))}
				</List>
			</Drawer>
		</>
	);
};

export default memo(withRouter(Sidebar));
