import { GlobalRole, setPermissions } from "Constants";

export const roles = {
	// redirect user to support page
	unknown: [],

	//enable all routes
	billing: [
		...setPermissions.disable_permissions,
		...setPermissions.setting_permissions,
		...setPermissions.payment_permissions,
		...setPermissions.group_permissions,
	],

	//enable all routes
	support: [
		...setPermissions.disable_permissions,
		...setPermissions.ownership_permissions,
		...setPermissions.payment_permissions,
		...setPermissions.setting_permissions,
		...setPermissions.group_permissions,
		...setPermissions.datamaintenance_permissions,
		...setPermissions.attribute_permissions,
		...setPermissions.org_permissions,
		...setPermissions.subscription_permissions,
		...setPermissions.dns_management,
	],

	// user can only read route
	user: [
		...setPermissions.context_permissions,
		...setPermissions.setting_permissions,
		...setPermissions.notification_permissions,
	],

	//enable all routes
	systemadmin: [
		...setPermissions.disable_permissions,
		...setPermissions.ownership_permissions,
		...setPermissions.payment_permissions,
		...setPermissions.setting_permissions,
		...setPermissions.group_permissions,
		...setPermissions.datamaintenance_permissions,
		...setPermissions.attribute_permissions,
		...setPermissions.dns_management,
	],

	//disable contact support,change ownership ,link account and payment route
	member: [
		...setPermissions.disable_permissions,
		...setPermissions.setting_permissions,
		...setPermissions.group_permissions,
	],
};

export const userRoles = [GlobalRole.userrole];
