import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _sortBy from "lodash/sortBy";
import { dashboardApi } from "../../misc/DashboardApi";
import Breadcrumb from "../breadcrumb/GroupBreadcrumb";
import AgContextHeader from "./AgContextHeader";
import { AgGridReact } from "ag-grid-react";
import AgGridFooter from "components/ResourceTableComponent/AgGridFooter";
import AgContextMenu from "./AgContextMenu";
import DeleteDialogGrp from "../DeleteDialogGrp";
// import RenameDialog from "./RenameDialog";
import { SAVE_RESOURCES_TO_DELETE, SAVE_RESOURCES_TO_RENAME, SAVE_GROUP_TO_UPDATE } from "store/resourceGridSlice";
import columnDefinations from "components/groupComponent/columnDefinations";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "assets/css/grid.css";
import "../../ResourceTableComponent/ResourceTableComponent.styles.css";
import { FontContext } from "components/UI/Theme";
import UpdateGroupInfoDialog from "../UpdateGroupDialog";
import EventEmitter from "components/misc/eventEmitter";
import DeleteDialog from "../DeleteDialog";
import UpdateGroupDialog from "./UpdateGroup";
import { getFetchGroupJson } from "components/requestcontext/FetchGroup";

function FetchGroups() {
	const location = useLocation();
	const dispatch = useDispatch();
	const gridRef = useRef();
	const gridContainerRef = useRef();
	const resourceContainerRef = useRef();
	const gridProgressbarRef = useRef();
	const { fontFamily } = useContext(FontContext);

	const [fileItems, setFileItems] = useState([]);
	const [selected, setSelected] = useState([]);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showRenameDialog, setShowRenameDialog] = useState(false);
	const [showUpdateGroupDialog, setShowUpdateGroupDialog] = useState(false);

	const [colDefs, setColDefs] = useState([]);
	const [showContextMenu, setShowContextMenu] = useState(null);
	const { selectedResources } = useSelector(({ resourceGrid }) => resourceGrid);
	const getSelectedRowsData = useCallback(() => gridRef.current?.api.selectionService.getSelectedRows(), []);
	const currentPageRoute = useLocation().pathname;

	const fetchGroups = useCallback(async () => {
		try {
			const fetchGroupJson = getFetchGroupJson();
			const response = await dashboardApi.fetchGroups(fetchGroupJson);
			const resourcesData = response.data.resourcePropertiesList || [];
			const sortedResources = _sortBy(resourcesData, (resource) => !resource.isfolder);
			setFileItems(sortedResources);
			setColDefs(columnDefinations);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		fetchGroups();

		EventEmitter.on("GroupDashboardEvent", () => {
			fetchGroups();
		});
	}, [fetchGroups]);

	const handleDeleteAction = useCallback(() => {
		if (!selected.length) return;
		dispatch(SAVE_RESOURCES_TO_DELETE(selected));
		setShowDeleteDialog(true);
	}, [dispatch, selected]);

	const handleUpdateGroupAction = useCallback(() => {
		if (selected.length !== 1) return;
		console.log("selected inside handleupdategroup action: ", selected);
		dispatch(SAVE_GROUP_TO_UPDATE(selected));
		setShowUpdateGroupDialog(true);
	}, [dispatch, selected]);

	const handleRenameAction = useCallback(() => {
		if (selected.length !== 1) return;
		dispatch(SAVE_RESOURCES_TO_RENAME(selected));
		setShowRenameDialog(true);
	}, [dispatch, selected]);

	const handleCloseUpdateGroupAction = useCallback(() => {
		setShowUpdateGroupDialog(false);
	}, []);
	const handleCloseDeleteDialog = useCallback(() => {
		setShowDeleteDialog(false);
	}, []);

	const handleCloseRenameDialog = useCallback(() => {
		setShowRenameDialog(false);
	}, []);

	const handleCloseContextMenu = useCallback(() => {
		setShowContextMenu(null);
	}, []);

	const handleRemoveAllFilters = useCallback(() => {
		gridRef.current?.api.setFilterModel(null);
	}, []);

	const handleRefreshAction = useCallback(() => {
		if (currentPageRoute.includes("mygroups")) {
			console.dir(fetchGroups());
		}
	}, [fetchGroups]);

	const contextActionsList = useMemo(
		() => ({
			edit: selected.length === 1 ? handleUpdateGroupAction : null,
			rename: selected.length === 1 ? handleRenameAction : null,
			delete: selected.length > 0 ? handleDeleteAction : null,
			removeAllFilters: handleRemoveAllFilters,
			refresh: handleRefreshAction,
		}),
		[
			selected.length,
			handleUpdateGroupAction,
			handleRenameAction,
			handleDeleteAction,
			handleRemoveAllFilters,
			handleRefreshAction,
		],
	);

	const onSelectionChanged = useCallback(() => {
		if (gridRef.current && gridRef.current.api) {
			const selectedNodes = gridRef.current.api.getSelectedNodes();
			const selectedData = selectedNodes.map((node) => node.data);
			setSelected(selectedData);
		}
	}, []);

	const handleUpdateGroups = useCallback(() => {
		fetchGroups(); // Call fetchGroups action to fetch groups again
	}, [dispatch]);

	return (
		<div
			ref={gridContainerRef}
			className="grid-container"
			style={{ fontFamily }}
		>
			<header>
				<Breadcrumb style={{ fontFamily: "inherit", fontSize: "inherit" }} />
				<AgContextHeader
					contextActions={contextActionsList}
					gridApi={gridRef}
					selected={selected}
				/>
			</header>

			<main
				ref={resourceContainerRef}
				id="resource-container"
				data-cy="dndzone"
				className="ag-theme-quartz"
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				onContextMenu={(event) => {
					event.preventDefault();
					setShowContextMenu({ mouseX: event.clientX, mouseY: event.clientY });
				}}
			>
				<div
					ref={gridProgressbarRef}
					id="grid-progressbar"
					style={{ display: "none", position: "absolute", zIndex: 1, width: "100%", top: "33px" }}
				></div>

				<AgGridReact
					sortingOrder={["asc", "desc"]}
					rowMultiSelectWithClick
					deltaRowDataMode
					suppressContextMenu
					suppressDragLeaveHidesColumns
					suppressCellFocus
					suppressScrollOnNewData
					preventDefaultOnContextMenu
					ref={gridRef}
					columnDefs={colDefs}
					rowData={fileItems}
					rowSelection="multiple"
					overlayNoRowsTemplate="<span id='norow-overlay'>No Resources Found!</span>"
					onModelUpdated={(event) => {
						event.api.getDisplayedRowCount() === 0
							? event.api.showNoRowsOverlay()
							: event.api.hideOverlay();
					}}
					onSelectionChanged={onSelectionChanged}
					gridOptions={{
						headerHeight: 35,
						rowHeight: 35,
						onModelUpdated: (event) => {
							event.api.getDisplayedRowCount() === 0
								? event.api.showNoRowsOverlay()
								: event.api.hideOverlay();
						},

						onCellContextMenu: (gridEvent) => {
							// Set current row state as `selected`
							gridEvent.node.setSelected(true);
							setShowContextMenu(() => ({
								mouseX: gridEvent.event.clientX,
								mouseY: gridEvent.event.clientY,
							}));
						},

						postSortRows: (params) => {
							const allRowNodes = params.nodes;
							let folderPosition = 0;

							// here we will keep folders always on top
							allRowNodes.forEach((rowNode, index) => {
								const isItFolder = rowNode.data ? rowNode.data.isfolder : undefined;

								if (isItFolder === true) {
									allRowNodes.splice(folderPosition, 0, allRowNodes.splice(index, 1)[0]);
									folderPosition++;
								}
							});
						},
					}}
				/>
			</main>

			<AgGridFooter
				rowData={fileItems}
				selectedResources={selected}
			/>
			<AgContextMenu
				contextMenu={showContextMenu}
				handleContextMenuClose={handleCloseContextMenu}
				contextActions={contextActionsList}
				selected={selected}
			/>
			<DeleteDialog
				deleteDialogOpen={showDeleteDialog}
				handleClose={handleCloseDeleteDialog}
				selected={selected}
			/>

			<UpdateGroupInfoDialog
				renameDialogOpen={showRenameDialog}
				handleClose={handleCloseRenameDialog}
				selected={selected}
				handleUpdateGroups={handleUpdateGroups}
			/>

			<UpdateGroupDialog
				updateGroupDialogOpen={showUpdateGroupDialog}
				handleCloseUpdateGroupAction={handleCloseUpdateGroupAction}
				selected={selected}
			/>
		</div>
	);
}

export default FetchGroups;
