import React, { useContext } from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { neutral } from "assets/css/MainCss";
import { FontContext } from "../../UI/Theme";
import { RESET_GRID_MESSAGE } from "store/uiSlice";

const useStyles = makeStyles((theme) => ({
	snackbar: {
		backgroundColor: neutral.buttoncolor,
		width: "300px",
		padding: "0px 10px 0px 10px",
		margin: 0,
	},
}));

const Notification = (props) => {
	const { showSnackbar, snackbarMessage, setShowSnackbar, messageLength = 40 } = props;
	const classes = useStyles();
	const { fontFamily } = useContext(FontContext);
	const dispatch = useDispatch();

	return (
		<Snackbar
			open={showSnackbar && !!snackbarMessage}
			autoHideDuration={1000 * 3}
			onClose={() => {
				setShowSnackbar(false);
				dispatch(RESET_GRID_MESSAGE());
			}}
			message={snackbarMessage}
			title={snackbarMessage}
		>
			<SnackbarContent
				className={classes.snackbar}
				style={{ fontFamily: fontFamily }}
				message={snackbarMessage}
			/>
		</Snackbar>
	);
};

export default React.memo(Notification);
