import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardApi } from "components/misc/DashboardApi";
import properties from "components/properties/ApplicationProps";
import { SET_GRID_MESSAGE } from "./uiSlice";

const initialState = {
	loading: false,
	error: null,
	data: {
		header: {},
		footer: {},
		resourceProperties: null,
		parentFolderId: null,
		resourcePropertiesList: [],
	},
	folders: null,
	files: null,
	// TODO: replace hard coded with properties
	dashboardParentFolderId: -1,
};

export const fetchDashboardResourcesThunk = createAsyncThunk(
	"dashboard/FETCH_DASHBOARD_RESOURCES",
	async (parentFolderId, { fulfillWithValue, rejectWithValue, dispatch }) => {
		try {
			const response = await dashboardApi.fetchDashboardResources(parentFolderId);

			if (response.data.footer.code === 0) {
				dispatch(FILTEROUT_FOLDERS(response.data));
				dispatch(FILTEROUT_FILES(response.data));
				return fulfillWithValue(response.data);
			}

			dispatch(SET_GRID_MESSAGE("Failed To get Resources!"));
			dispatch(FILTEROUT_FOLDERS(initialState.data));
			dispatch(FILTEROUT_FILES(initialState.data));

			return fulfillWithValue(initialState.data);
		} catch (error) {
			dispatch(SET_GRID_MESSAGE("Failed To get Resources!"));
			return rejectWithValue(error);
		}
	},
	{
		dispatchConditionRejection: true,
	},
);

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		RESET_DASHBOARD_SLICE: (state, _) => {
			state.loading = initialState.loading;
			state.error = initialState.error;
			state.data = initialState.data;
			state.folders = initialState.folders;
			state.files = initialState.files;

			properties.parentFolderId = -1;
		},

		UPDATE_CURRENT_DASHBOARD_RESOURCES: (state, { payload }) => {
			state.data.resourcePropertiesList = payload;
		},

		FILTEROUT_FOLDERS: (state, { payload }) => {
			const currentDashboardResources = payload.resourcePropertiesList.map(({ fields }) => fields);
			const foldersFromCurrentDashboard = currentDashboardResources
				.map(({ isfolder, filename }) => {
					if (!isfolder) {
						return null;
					}

					return filename;
				})
				.filter((x) => x);

			state.folders = foldersFromCurrentDashboard;
		},

		// SAVE_CURRENT_DASHBOARD_DETAILS: (state, payload) => {
		// 	state.currentParentFolderId = payload;
		// },

		SAVE_DASHBOARD_PARENT_FOLDER_ID: (state, { payload }) => {
			state.dashboardParentFolderId = payload;
		},

		FILTEROUT_FILES: (state, { payload }) => {
			const currentDashboardResources = payload.resourcePropertiesList.map(({ fields }) => fields);
			const filesFromCurrentDashboard = currentDashboardResources
				.map(({ isfolder, filename }) => {
					if (isfolder) {
						return null;
					}

					return filename;
				})
				.filter((x) => x);

			state.files = filesFromCurrentDashboard;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDashboardResourcesThunk.pending, (state, { payload }) => {
			state.loading = true;
		});

		builder.addCase(fetchDashboardResourcesThunk.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.error = false;
			state.data = payload;
			state.dashboardParentFolderId = payload.parentFolderId;

			properties.parentFolderId = payload.parentFolderId;
		});

		builder.addCase(fetchDashboardResourcesThunk.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
			state.data = initialState.data;
			state.dashboardParentFolderId = initialState.dashboardParentFolderId;
		});
	},
});

export const {
	RESET_DASHBOARD_SLICE,
	FILTEROUT_FOLDERS,
	FILTEROUT_FILES,
	SAVE_DASHBOARD_PARENT_FOLDER_ID,
	UPDATE_CURRENT_DASHBOARD_RESOURCES,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
