// viewmodels/JoinOrgViewModel.js
import { useState } from "react";

import { createFormDataObject } from "utils/FormDataTranslator";
import properties from "components/properties/ApplicationProps";
import { joinOrganization } from "model/joinOrganization";

export function useJoinOrgViewModel(history) {
	const [ownerEmail, setOwnerEmail] = useState("");
	const [buttonLoading, setButtonLoading] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const handleJoinOrg = async () => {
		setButtonLoading(true);
		try {
			// Use the utility function to create form data
			const formData = createFormDataObject(ownerEmail, properties.actions.GENERATE_NOTIFICATION);
			const response = await joinOrganization(formData.email, formData.action);
			const footer = response.data.footer;

			if (footer.code === 0) {
				setSnackbarMessage("Request Sent Successfully!");
				setShowSnackbar(true);

				if (footer.msg === "[Onboarding in progress.]") {
					history.push("./setupinprogress");
				}
			} else {
				setSnackbarMessage(footer.msg);
				setShowSnackbar(true);
			}
		} catch (error) {
			setSnackbarMessage("Error occurred while joining the organization.");
			setShowSnackbar(true);
		} finally {
			setButtonLoading(false);
		}
	};

	return {
		ownerEmail,
		setOwnerEmail,
		buttonLoading,
		showSnackbar,
		snackbarMessage,
		setShowSnackbar,
		handleJoinOrg,
	};
}
