import properties from "components/properties/ApplicationProps";

export function getupdateUsersInGroupJson(groupid, usersToAdd) {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		action: properties.actions.MODIFY,
		groupId: groupid,
		usersInGroup: usersToAdd,
	};

	return stringifyFetchResource;
}
