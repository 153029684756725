import properties from "components/properties/ApplicationProps";

export default function getBucketCreationJSON(resource) {
	console.log("resource", resource);
	return {
		action: properties.actions.SAVE,
		event: resource.event,
		accountId: resource.accountid,
		bucketName: resource.bucketname,
		displayName: resource.displayname,
		versioned: resource.versioned, //NR
		shareOutsideOrg: properties.orgProperties.shareOutSideOrg, //NR
		bucketType: resource.buckettype,
		region: resource.region,
		autoOnBoardingEnabled: resource.autoonboard,
		gstNo: resource.gstno,
		subscriptionKey: resource.subscriptionkey,
		contactEmail: resource.contactemail,
		contactNumber: resource.contactnumber,
		status: resource.status,
		tranid: resource.tranid,
		email: resource.email,
		lastupdatetime: resource.lastupdatetime,
		lastupdateuser: sessionStorage.getItem("email"),
		orgShortName: resource.orgshortname,
		endpointExpirationTime: resource.endpointexptime,
		// encrypted,
	};
}
