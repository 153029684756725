import properties from "components/properties/ApplicationProps";

export default function getFetchNotificationsJSON(userInfo) {
	return {
		header: {
			email: sessionStorage.getItem("username"),
			user: sessionStorage.getItem("firstName"),
			org: sessionStorage.getItem("user_org"),
		},

		action: properties.actions.SEARCH,
	};
}
