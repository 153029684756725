import React, { useEffect } from "react";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid, IconButton, Checkbox } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import { MenuItem, Select, InputAdornment } from "@mui/material";
import { useState, useRef } from "react";
import { dashboardApi } from "components/misc/DashboardApi";
import Notification from "components/dashboard/notification/Notification";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import FormContainer from "layout/FormContainer";
import { getSubscriptionDetails } from "components/requestcontext/CreateSubscriptionPlan";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { getSearchSubscriptionJson } from "components/requestcontext/SearchSubscriptionPlan";
import { includes } from "lodash";
import properties from "components/properties/ApplicationProps";
import Switch from "@mui/material/Switch";

function NewSubscriptionPlan() {
	const classes = useStyles();
	const history = useHistory();
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);

	const [subscriptionName, setSubscriptionName] = useState("");
	const [subscriptionCharge, setSubscriptionCharge] = useState("");
	const [validity, setSubscriptionValidity] = useState("");
	const [licenses, setSubscriptionLicenses] = useState("");
	const [description, setSubscriptionDescription] = useState("");
	const [discount, setDiscount] = useState("");
	const [subscriptionKey, setSubscriptionKey] = useState("");
	const [subscriptionChargeType, setSubscriptionChargeType] = useState("USD");
	const [discountType, setDiscountType] = useState("normal");
	const [switchValue, setSwitchValue] = useState("subscription");
	const [selectedDate, setSelectedDate] = useState(null);
	const [subscriptionCounter, setSubscriptionCounter] = useState("");
	const [buttonName, setButtonName] = useState("Add");

	const { fontFamily, themeColor, filter } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);

	const [isActive, setSubscriptionIsActive] = useState(false);
	const [isExternal, setSubscriptionIsExternal] = useState(false);

	const subscriptionChargeRef = useRef(null);
	const subscriptionValidityRef = useRef(null);
	const DiscountRef = useRef(null);
	const subscriptionNameRef = useRef(null);
	const subscriptionAddonsRef = useRef(null);
	const subscriptionDescriptionRef = useRef(null);
	const DiscountTypeRef = useRef(null);
	const subscriptionKeyRef = useRef(null);
	const subscriptionCounterRef = useRef(null);
	const currencySelectorRef = useRef(null);
	const label = { inputProps: { "aria-label": "Size switch demo" } };

	useEffect(() => {
		if (!validity) {
			handleDateChange();
		}
	}, [validity]);

	async function CreateSubscription() {
		// handleDateChange();
		setButtonLoading(() => true);
		if (
			!subscriptionKey ||
			!subscriptionName
			// !description
			// !subscriptionCharge ||
			// !licenses ||
			// !discount ||
			// !validity ||
			// !subscriptionChargeType ||
			// !discountType
		) {
			showSnackbarWithMessage("Please fill in all required fields.");
			setButtonLoading(false);
			return;
		}

		if (!validity) {
			handleDateChange();
		}

		try {
			const subscriptionDetails = {
				subscriptionKey,
				subscriptionName: subscriptionName.trim().replace(/\s{2,}/g, " "),
				description,
				subscriptionCharge,
				validity,
				licenses,
				discount,
				subscriptionCounter,
				subscriptionChargeType,
				discountType,
				isExternal,
				isActive,
			};

			console.log("Subscription Details before processing:", subscriptionDetails);

			let subscriptionDetailsJson = getSubscriptionDetails(subscriptionDetails);
			let subscriptionDetailsResponse = await dashboardApi.createSubscriptionPlan(subscriptionDetailsJson);
			console.log(subscriptionDetailsResponse);
			if (subscriptionDetailsResponse.data.footer.msg === "SUCCESS") {
				showSnackbarWithMessage("Subscription plan created successfully");
				setButtonLoading(() => false);
				setSubscriptionValidity("");
				setSelectedDate(null);
			} else if (subscriptionDetailsResponse.data.footer.msg === "FAILURE") {
				showSnackbarWithMessage("Failure while adding subscription!");
				setButtonLoading(() => false);
				setSubscriptionValidity("");
				setSelectedDate(null);
			}

			setSubscriptionKey("");
			setSubscriptionName("");
			setSubscriptionDescription("");
			setSubscriptionCharge("");
			setSubscriptionValidity("");
			setSelectedDate(null);
			setDiscount("");
			setSubscriptionLicenses("");
			setSubscriptionIsActive(false);
			setSubscriptionIsExternal(false);
			setButtonLoading(() => false);
		} catch (error) {
			showSnackbarWithMessage("Failure while subscription creation");
			setButtonLoading(() => false);
			setSubscriptionValidity("");
			setSelectedDate(null);
			setSubscriptionIsActive(false);
			setSubscriptionIsExternal(false);
		}
	}

	//snackbar
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};
	const handleClose = () => {
		history.push("/settings");
	};

	const handleKeyDown = (e, ref) => {
		if (e.key === "Enter") {
			e.preventDefault();

			if (ref.current === subscriptionKeyRef.current) {
				subscriptionNameRef.current.focus();
			} else if (ref.current === subscriptionNameRef.current) {
				subscriptionDescriptionRef.current.focus();
			} else if (ref.current === subscriptionDescriptionRef.current) {
				subscriptionChargeRef.current.focus();
			} else if (ref.current === subscriptionChargeRef.current) {
				subscriptionValidityRef.current.focus();
			} else if (ref.current === subscriptionValidityRef.current) {
				subscriptionAddonsRef.current.focus();
			} else if (ref.current === subscriptionAddonsRef.current) {
				DiscountTypeRef.current.focus();
			} else if (ref.current === DiscountTypeRef.current) {
				subscriptionCounterRef.current.focus();
			}
		}
	};

	const handleDateChange = (date) => {
		console.log("inside handle date change function");
		let selectedDateLong;

		if (!date) {
			// If no date is selected, set the date to 5 years from now
			const currentDate = dayjs().add(5, "year");
			selectedDateLong = currentDate.valueOf();
			setSelectedDate(currentDate);
		} else {
			selectedDateLong = date.valueOf();
			setSelectedDate(date); // Update selectedDate state
		}

		setSubscriptionValidity(selectedDateLong);
	};

	const handleCurrencyChange = (event) => {
		setSubscriptionChargeType(event.target.value);
	};

	const handleDiscountTypeChange = (event) => {
		setDiscountType(event.target.value);
	};

	const handleSwitchTypeChange = (event) => {
		if (event.target.checked) {
			// Subscription discount
			setSwitchValue("discount");
			setDiscountType("notional");
		} else {
			// Discount percentage
			setDiscountType("normal");
			setSwitchValue("subscription");
		}
	};

	return (
		<div
			style={{
				position: "relative",
				filter: filter,
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			{" "}
			<CssBaseline />
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						// Comment out maxWidth to restore previous looks for contextHeader
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Enroll New Subscription
								</h1>

								<hr />
								<TextField
									id={"subscriptionkey"}
									autoFocus
									required
									className="input-field"
									label="Key"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={subscriptionKey}
									onChange={(e) => setSubscriptionKey(e.target.value)}
									style={{ marginTop: "3px" }}
									onKeyDown={(e) => handleKeyDown(e, subscriptionKeyRef)}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
									inputRef={subscriptionKeyRef}
								/>

								<TextField
									id={"subscriptionname"}
									required
									className="input-field"
									label="Display Name"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={subscriptionName}
									onChange={(e) => setSubscriptionName(e.target.value)}
									// onChange={(e) => handleSubscriptionNameChange(e.target.value)}
									style={{ marginTop: "3px" }}
									onKeyDown={(e) => handleKeyDown(e, subscriptionNameRef)}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
									inputRef={subscriptionNameRef}
								/>

								<TextField
									id={"description"}
									className="input-field"
									label="Description"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={description}
									onChange={(e) => setSubscriptionDescription(e.target.value)}
									style={{ marginTop: "3px", marginBottom: "15px" }}
									onKeyDown={(e) => handleKeyDown(e, subscriptionDescriptionRef)}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
									inputRef={subscriptionDescriptionRef}
								/>
								<div
									style={{
										marginTop: "-3%",
										fontFamily: fontFamily,
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										marginLeft: "auto",
									}}
								>
									<span>Subscription</span>
									<div style={{ display: "flex", alignItems: "center" }}>
										{/* Adjust margin for spacing */}
										<Switch
											id="subscriptionswitch"
											style={{
												color: "#12a5c6",
												width: "fit-content",
											}}
											{...label}
											checked={switchValue === "discount"}
											onChange={handleSwitchTypeChange}
										/>
										<span style={{ marginRight: "3px" }}>Discount</span>
									</div>
								</div>

								{switchValue === "discount" ? (
									<>
										<TextField
											id={"discount"}
											required
											className="input-field"
											label="Type"
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											value={discount}
											onChange={(e) => {
												const value = e.target.value;
												const sanitizedValue = value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except .
												const decimalCount = sanitizedValue.split(".").length - 1;

												if (decimalCount <= 1) {
													setDiscount(sanitizedValue);
												}
											}}
											style={{ marginTop: "3px" }}
											onKeyDown={(e) => handleKeyDown(e, DiscountTypeRef)}
											InputLabelProps={{
												style: { fontFamily: fontFamily },
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Select
															id={discountType}
															value={discountType}
															onChange={handleDiscountTypeChange}
															variant="standard"
														>
															{Object.entries(properties.discountType).map(
																([discountCode, discountLabel]) => (
																	<MenuItem
																		id={discountCode}
																		key={discountCode}
																		value={discountCode}
																	>
																		{discountLabel}
																	</MenuItem>
																),
															)}
														</Select>
													</InputAdornment>
												),
												style: { fontFamily: fontFamily },
											}}
											inputRef={DiscountTypeRef}
										/>
										<DatePicker
											id="dateformat"
											className="datepicker"
											placeholder={selectedDate ? selectedDate : "select date"}
											slotProps={{
												textField: { size: "small" },
												field: { clearable: true },
											}}
											sx={{
												fontFamily: "inherit",
												width: "100%",
												marginBottom: "15px",
											}}
											label="Validity"
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													className="input-field"
													size="small"
													fullWidth
													margin="normal"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton>
																	<CalendarTodayIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
													style={{ fontFamily: fontFamily }}
												/>
											)}
											inputFormat="dd/MM/yyyy"
											disablePast
											openTo="year"
											views={["year", "month", "day"]}
											clearable
											mask="__/__/____"
											onChange={handleDateChange}
											value={selectedDate}
										/>
									</>
								) : (
									<>
										<TextField
											id="subscriptioncharge"
											className="input-field"
											label="Charge"
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											value={subscriptionCharge}
											onChange={(e) => {
												const value = e.target.value;
												const sanitizedValue = value.replace(/[^\d.]/g, "");
												const decimalCount = sanitizedValue.split(".").length - 1;

												if (decimalCount <= 1) {
													setSubscriptionCharge(sanitizedValue);
												}
											}}
											style={{ marginTop: "3px" }}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Select
															id="subscriptionchargetype"
															value={subscriptionChargeType}
															onChange={handleCurrencyChange}
															variant="standard"
															onKeyDown={(e) => handleKeyDown(e, currencySelectorRef)}
															inputRef={currencySelectorRef}
														>
															{Object.entries(properties.currencies).map(
																([currencyCode, currencyLabel]) => (
																	<MenuItem
																		id={currencyCode}
																		key={currencyCode}
																		value={currencyCode}
																	>
																		{currencyLabel}
																	</MenuItem>
																),
															)}
														</Select>
													</InputAdornment>
												),
												style: { fontFamily: fontFamily },
											}}
											inputRef={subscriptionChargeRef}
										/>
										<DatePicker
											id="dateformat"
											className="datepicker"
											placeholder={selectedDate ? selectedDate : "select date"}
											slotProps={{
												textField: { size: "small" },
												field: { clearable: true },
											}}
											sx={{
												fontFamily: "inherit",
												width: "100%",
												marginBottom: "5px",
												marginTop: "5px",
											}}
											label="Validity"
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													className="input-field"
													size="small"
													fullWidth
													margin="normal"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton>
																	<CalendarTodayIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
													style={{ fontFamily: fontFamily }}
												/>
											)}
											inputFormat="dd/MM/yyyy"
											disablePast
											openTo="year"
											views={["year", "month", "day"]}
											clearable
											mask="__/__/____"
											onChange={handleDateChange}
											value={selectedDate}
										/>
										<TextField
											id={"subscriptionaddons"}
											required
											className="input-field"
											label="Licenses"
											variant="outlined"
											size="small"
											fullWidth
											type="number"
											margin="normal"
											value={licenses}
											onChange={(e) => {
												const value = e.target.value.replace(/\D/g, "");
												setSubscriptionLicenses(value);
											}}
											style={{ marginTop: "7px" }}
											onKeyDown={(e) => handleKeyDown(e, subscriptionAddonsRef)}
											InputLabelProps={{
												style: { fontFamily: fontFamily },
											}}
											inputProps={{
												min: 1, // Minimum value allowed
												max: 100, // Maximum value allowed
												step: 1, // Step increment
												style: { fontFamily: fontFamily },
											}}
											inputRef={subscriptionAddonsRef}
										/>
									</>
								)}
								<div style={{ display: "flex", alignItems: "center" }}>
									<Checkbox
										id={"subscriptionisactive"}
										checked={isActive}
										onChange={(e) => setSubscriptionIsActive(e.target.checked)}
										color="primary"
									/>
									<span style={{ marginLeft: -1 }}>Active</span>

									<div style={{ marginLeft: 16, display: "flex", alignItems: "center" }}>
										<Checkbox
											id={"subscriptionisexternal"}
											checked={isExternal}
											onChange={(e) => setSubscriptionIsExternal(e.target.checked)}
											color="primary"
										/>
										<span style={{ marginLeft: -1 }}>External</span>
									</div>
								</div>

								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										data-button-name="cancel-manage-account"
									>
										Cancel
									</Button>

									{/* {accountcreation === true && ( */}
									<Button
										id="createButton"
										onClick={CreateSubscription}
										variant="outlined"
										buttonType="main"
										isLoading={buttonLoading}
										data-button-name="create-subscription"
									>
										{buttonName}
									</Button>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
				messageLength={50}
			/>
		</div>
	);
}

export default NewSubscriptionPlan;
