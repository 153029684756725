import properties from "components/properties/ApplicationProps";

export function getDeleteResourceJson({ isfolder, resourceid, version }) {
	let stringifyFetchResource = {
		header: {},
		action: properties.actions.DELETE,
		resourceId: resourceid,
		isItFolder: isfolder,
		version: isfolder ? 0 : version,
	};

	return stringifyFetchResource;
}
