import properties from "components/properties/ApplicationProps";

export function getFetchDNSdetailsJson() {
	let stringifyFetchDNSdetails = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		action: properties.actions.SEARCH,
		orgName: sessionStorage.getItem("user_org"),
	};

	return stringifyFetchDNSdetails;
}
