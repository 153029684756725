import React from "react";
import Select from "react-select";
const CustomSelect = ({ id, options, value, onChange, isLoading, placeholder, styles, ...props }) => {
	return (
		<Select
			id={id}
			options={options}
			value={value}
			onChange={onChange}
			isLoading={isLoading}
			placeholder={placeholder}
			styles={styles}
			{...props}
		/>
	);
};
export default CustomSelect;
