import { getInvoiceOrderJson } from "components/requestcontext/GetInvoiceOrder";
import IPaymentAdapter from "./IPaymentAdapter";
import { load } from "@cashfreepayments/cashfree-js";
import { dashboardApi } from "components/misc/DashboardApi";
import { getInvoiceDetailsJson } from "components/requestcontext/InvoiceDetails";
import { getValidateCashfreePaymentJson } from "components/requestcontext/CashfreePaymentJson";
import EventEmitter from "components/misc/eventEmitter";

export default class CashfreeAdapter extends IPaymentAdapter {
	constructor(cashfreeOptions) {
		super();
		this.cashfree = null;
		this.onSuccess = cashfreeOptions.onSuccess;
		this.onCancel = cashfreeOptions.onCancel;
		this.onFailure = cashfreeOptions.onFailure;
		this.initializeSDK(cashfreeOptions);
		this.service = "cashfree";
	}

	async initializeSDK(cashfreeOptions) {
		try {
			this.cashfree = await load(cashfreeOptions);
			console.log("Cashfree SDK initialized successfully", cashfreeOptions);
		} catch (error) {
			console.error("Failed to initialize Cashfree SDK:", error);
		}
	}

	async makePayment(amount, options) {
		try {
			// fetch invoice details
			let fetchInvoiceDetailsJson = getInvoiceDetailsJson();
			const invoiceDetailsResponse = await dashboardApi.fetchInvoice(fetchInvoiceDetailsJson);
			console.log("RESOURCE RESPONSE::", invoiceDetailsResponse.data.resourcePropertiesList);

			let totalamount = amount;
			let invoiceid = options.invoiceId;

			// Get payment order
			let fetchPaymentOrderJson = getInvoiceOrderJson(totalamount, this.service, invoiceid);
			const response = await dashboardApi.getPaymentOrder(fetchPaymentOrderJson);

			const checkoutOptions = {
				paymentSessionId: response.data.sessionId,
				redirectTarget: "_modal",
				onPaymentCompletion: (result) => {
					if (result.status === "SUCCESS") {
						this.onPaymentSuccess(result);
					} else {
						this.onPaymentFailure(result);
					}
				},
			};
			console.log(checkoutOptions);

			await this.cashfree.checkout(checkoutOptions);

			// validate Payment
			let fetchPaymentOrderJson1 = getValidateCashfreePaymentJson(
				this.service,
				response.data.orderId,
				response.data.paymentSeqId,
				totalamount,
				invoiceid,
			);
			const responseValidatePayment = await dashboardApi.validatePayment(fetchPaymentOrderJson1);
			console.log("RESOURCE RESPONSE::", responseValidatePayment);

			if (responseValidatePayment.data.orderStatus === "PAID") {
				this.onPaymentSuccess(responseValidatePayment.data);
			} else {
				this.onPaymentFailure(responseValidatePayment.data);
			}
		} catch (error) {
			console.error("Failed to make payment with Cashfree:", error);
		}
	}

	onPaymentSuccess(result) {
		console.log("Payment Successful:", result);
		if (this.onSuccess) {
			this.onSuccess(result);
		}
	}

	onPaymentCancel(result) {
		console.log("Payment Canceled:", result);
		if (this.onCancel) {
			this.onCancel(result);
		}
	}

	onPaymentFailure(result) {
		console.log("Payment Failure:", result);
		if (this.onFailure) {
			this.onFailure(result);
		}
	}
}
