export const ONE_BYTE = 1;
export const ONE_KILOBYTE = 1000 * ONE_BYTE;
export const ONE_MEGABYTE = 1000 * ONE_KILOBYTE;
export const ONE_GIGABYTE = 1000 * ONE_MEGABYTE;

const uploadFileLimit = sessionStorage.getItem("user_org") === "DATAFRUGAL" ? 5 * ONE_MEGABYTE : ONE_BYTE;

export const p1 = {
	uploadFileLimit,
};
