import { useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Stack from "@mui/material/Stack";
import { neutral, textsize } from "assets/css/MainCss";
import { useDispatch } from "react-redux";
import {
	HANDLE_USER_RESPONSE_FOR_CONFLICT,
	uploadFilesThunk,
	HIDE_UPDATE_DIALOGUE,
	CANCEL_CURRENT_UPLOAD,
} from "store/fileUploadSlice";
import properties from "components/properties/ApplicationProps";

export default function UpdateDialogue({ open1, disableRadio }) {
	console.log("Is UpdateDialogue open", open1);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(HANDLE_USER_RESPONSE_FOR_CONFLICT(properties.versionFlag.replace_existing_files));
	}, [dispatch]);

	return (
		<div>
			<Dialog open={open1}>
				<DialogTitle>{"File(s) already exists,do you want to"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<FormControl>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								defaultValue="replace"
								name="radio-buttons-group"
							>
								<FormControlLabel
									value="replace"
									control={
										<Radio
											onChange={() => {
												// following fn called when radio button is changed not
												// when user clicks 'Upload' button
												dispatch(
													HANDLE_USER_RESPONSE_FOR_CONFLICT(
														properties.versionFlag.replace_existing_files,
													),
												);
											}}
											color="primary"
											disabled={disableRadio}
										/>
									}
									label="Replace existing file"
									data-test-id="radio-replace-file"
								/>
								<FormControlLabel
									value="update"
									control={
										<Radio
											onChange={() => {
												// following fn called when radio button is changed not
												// when user clicks 'Upload' button
												dispatch(
													HANDLE_USER_RESPONSE_FOR_CONFLICT(
														properties.versionFlag.keep_existing_files,
													),
												);
											}}
											color="primary"
											disabled={disableRadio}
										/>
									}
									label="Keep both files"
									data-test-id="radio-keep-file"
									// disabled = "true"
								/>
							</RadioGroup>
						</FormControl>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Stack
						spacing={2}
						direction="row"
						position="right center"
					>
						<Button
							variant="text"
							onClick={() => {
								dispatch(CANCEL_CURRENT_UPLOAD());
								dispatch(HIDE_UPDATE_DIALOGUE());
							}}
							color="primary"
							style={{
								color: neutral.buttoncolor,
								borderColor: neutral.buttoncolor,
								fontSize: textsize.buttontextsize,
							}}
							data-test-id="update-button-cancel"
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							onClick={() => {
								dispatch(HIDE_UPDATE_DIALOGUE());
								dispatch(uploadFilesThunk());
							}}
							// onClick={handleToClose}
							// color="primary"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
								fontSize: textsize.buttontextsize,
							}}
							data-test-id="update-button-upload"
						>
							Upload
						</Button>
					</Stack>
				</DialogActions>
			</Dialog>
		</div>
	);
}
