import { testController } from "../../Constants";
import { ContextRequestAddResource } from "components/requestcontext/AddResource";
import { dashboardApi } from "components/misc/DashboardApi";
import { checkFilePath } from "./checkFilePath";

export async function createFolderHierarchy({ file, dispatch }) {
	const rawFolderLocations = checkFilePath(file);
	let folderLocations = rawFolderLocations.split("/").filter((x) => x);
	folderLocations = folderLocations.slice(0, folderLocations.length - 1);
	let uploadLocationId = "";

	for (const folderName of folderLocations) {
		const folderDetails = await checkIfResourceExists(folderName, file.uploadLocationId);

		// create folder if not exist
		if (!folderDetails) {
			const requestBody = ContextRequestAddResource.generateUploadFolderResourceJson(
				folderName,
				uploadLocationId || file.uploadLocationId,
			);
			const response = await dashboardApi.insertOrUpdateResource(
				requestBody,
				testController.abortController.signal,
			);
			if (response.data.resourceProperties.fields.locationid) {
				uploadLocationId = response.data.resourceProperties.fields.locationid;
				Object.defineProperty(file, "uploadLocationId", { value: uploadLocationId });
			}
		} else {
			uploadLocationId = folderDetails.locationid;
			Object.defineProperty(file, "uploadLocationId", { value: uploadLocationId });
		}
	}

	return uploadLocationId;
}

async function checkIfResourceExists(itemName, location) {
	try {
		const response = await dashboardApi.fetchDashboardResources(location);

		if (!response?.data.resourcePropertiesList.length) {
			return false;
		}

		const { resourcePropertiesList } = response?.data;
		const resources = resourcePropertiesList.map(({ fields }) => fields);
		const existingResource = resources.filter(({ filename }) => filename === itemName);

		if (!existingResource.length) {
			return false;
		}

		return existingResource[0];
	} catch (error) {
		console.error(error);
		throw Error(`Unable to locate ${itemName} at ${location}`);
	}
}
