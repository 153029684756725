import { responseMessage } from "Constants";

export default function checkIfResponseIsSuccOrFail(response) {
	const data = response?.data;

	if (!data) throw new Error("response data is invalid");

	if (data.footer.code !== 0) {
		console.log(responseMessage.common.failure);
		return responseMessage.common.failure;
	}

	console.log(responseMessage.common.failure);
	return responseMessage.common.success;
}
