import properties from "components/properties/ApplicationProps";

export function getUpdateSubscriptionDetailsJson(
	accountid,
	updatedSubscriptionName,
	subscriptionCharge,
	updatedSubscriptionValidity,
	discount,
	subscriptionAddons,
	subscriptionIsActive,
	subscriptionId,
	subscriptionDescription,
	discountType,
	subscriptionChargeType,
	subscriptionIsExternal,
	subscriptionKey
) {
	console.log(subscriptionKey)
	let stringifyFetchResource = {
		action: properties.actions.MODIFY,
		accountId: accountid,
		subscriptionName: updatedSubscriptionName,
		description: subscriptionDescription,
		subscriptionId: subscriptionId,
		subscriptionCharge: subscriptionCharge,
		subscriptionChargeType: subscriptionChargeType,
		validity: updatedSubscriptionValidity,
		licenses: subscriptionAddons,
		discount: discount,
		discountType: discountType,
		isActive: subscriptionIsActive,
		isExternal: subscriptionIsExternal,
		subscriptionKey:subscriptionKey
	};

	return stringifyFetchResource;
}
