import React, { useCallback, useMemo, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import delete_icon from "assets/proto_1/delete.png";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import dayjs from "dayjs";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const SubscriptionNumberOfTimesRenderer = ({ params, handleNumberChange }) => {
	const [subQuantity, setSubQuantity] = useState(params.data.subQty ? Number(params.data.subQty) : 0);

	useEffect(() => {
		if (subQuantity !== params.data.subQty) {
			handleNumberChange(params.data.subscriptionId, subQuantity);
		}
	}, [subQuantity, params.data.subscriptionId, handleNumberChange]);

	const handleSubQuantityChange = (e) => {
		const newQuantity = Number(e.target.value);
		if (newQuantity < 1 || isNaN(newQuantity)) {
			setSubQuantity(1);
		} else {
			setSubQuantity(newQuantity);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	return (
		<TextField
			className="subsQty"
			sx={{
				"& fieldset": { border: "none" },
				marginTop: "-0.5rem",
			}}
			type="number"
			value={subQuantity}
			onChange={handleSubQuantityChange}
			onKeyDown={handleKeyDown}
		/>
	);
};

const ValidityCellRenderer = ({ params, handleDateChange }) => {
	const [validUntilTime, setValidUntilTime] = useState(
		params.data.validUntilTime ? dayjs(params.data.validUntilTime) : null,
	);

	const handleValidityDateChange = useCallback(
		(date) => {
			if (date && date.isValid() && date.year() >= 1900) {
				const ValidUntilDate = date.valueOf();
				setValidUntilTime(date);
				console.log("ValidUntilDate", ValidUntilDate);
				console.log("date", date, "validUntilTime", date.valueOf());
				handleDateChange(params.data.subscriptionId, date.toISOString());
			}
		},
		[params.data.subscriptionId, handleDateChange],
	);

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	return (
		<div
			className="datediv"
			style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-0.5rem" }}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					sx={{
						"& fieldset": { border: "none" },
					}}
					className="subsdatepicker"
					value={validUntilTime}
					onChange={handleValidityDateChange}
					format="DD/MM/YYYY"
					openTo="day"
					views={["day", "month", "year"]}
					mask="__/__/____"
					minDate={dayjs("1900-01-01")} // Minimum date constraint
					PopoverProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							className="input-field"
							size="small"
							fullWidth
							margin="normal"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton>
											<CalendarTodayIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
							onKeyDown={handleKeyDown}
						/>
					)}
				/>
			</LocalizationProvider>
		</div>
	);
};

const AssociatedSubscriptions = ({ rowData, colDefs, handleDelete, handleDateChange, handleNumberChange }) => {
	const DeleteCellRenderer = useCallback(
		(params) => {
			const handleClick = (e) => {
				e.stopPropagation();
				handleDelete(params.data.subscriptionId, e);
			};

			return (
				<button
					onClick={handleClick}
					style={{ background: "none", border: "none", cursor: "pointer" }}
				>
					<img
						src={delete_icon}
						alt="Delete"
						style={{ width: "20px", height: "20px" }}
						className="deleteSubscription"
					/>
				</button>
			);
		},
		[handleDelete],
	);

	const subscriptionNumberOfTimesRenderer = useCallback(
		(params) => {
			return (
				<SubscriptionNumberOfTimesRenderer
					params={params}
					handleNumberChange={handleNumberChange}
				/>
			);
		},
		[handleNumberChange],
	);

	const validityCellRenderer = useCallback(
		(params) => {
			return (
				<ValidityCellRenderer
					params={params}
					handleDateChange={handleDateChange}
				/>
			);
		},
		[handleDateChange],
	);

	const columnsWithDelete = useMemo(() => {
		return colDefs.some((col) => col.field === "actions")
			? colDefs
			: [
					...colDefs,
					{
						headerName: "Validity",
						field: "validUntilTime",
						cellRenderer: validityCellRenderer,
						colId: "validUntilTime",
						maxWidth: 200,
					},
					{
						field: "subQty",
						headerName: "Number Of Times",
						width: 150,
						cellRenderer: subscriptionNumberOfTimesRenderer,
						sort: "asc",
						filter: "agTextColumnFilter",
						filterParams: {
							buttons: ["reset", "apply"],
							closeOnApply: true,
						},
					},
					{
						headerName: "Actions",
						field: "actions",
						cellRenderer: DeleteCellRenderer,
						colId: "actions",
						maxWidth: 100,
					},
			  ];
	}, [colDefs, validityCellRenderer, subscriptionNumberOfTimesRenderer, DeleteCellRenderer]);

	const defaultRowData = useMemo(() => {
		return rowData.map((item) => ({
			...item,
			subscriptionkey: item.subscriptionKey || "N/A",
			subscriptionname: item.subscriptionName || "N/A",
			discounttype: item.discountType || "N/A",
			validUntilTime: item.validUntilTime ? dayjs(item.validUntilTime).format("YYYY-MM-DD") : null,
		}));
	}, [rowData]);

	return (
		<div
			className="ag-theme-alpine"
			style={{ height: "100%" }}
		>
			<AgGridReact
				rowData={defaultRowData}
				columnDefs={columnsWithDelete}
				domLayout="autoHeight"
				suppressCellFocus
				onCellKeyDown={(event) => {
					if (event.event.key === "Enter") {
						event.event.preventDefault();
						event.event.stopPropagation();
					}
				}}
			/>
		</div>
	);
};

export default AssociatedSubscriptions;