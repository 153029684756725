import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
// import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_DROPPED_LOCATION_ID } from "store/droppedLocationIdSlice";
import { RESET_DASHBOARD_SLICE,	UPDATE_CURRENT_DASHBOARD_RESOURCES,	fetchDashboardResourcesThunk } from "store/dashboardSlice"; //prettier-ignore
import { unwrapResult } from "@reduxjs/toolkit";
import { HIDE_BACKGROUND_PROGRESS_BAR, HIDE_RESOURCE_VERSION_NO, SET_GRID_MESSAGE, SHOW_BACKGROUND_PROGRESS_BAR, SHOW_NEW_FOLDER_DIALOG } from "store/uiSlice"; // prettier-ignore
import AgContextMenu from "./AgContextMenu";
import AgContextHeader from "./AgContextHeader";
import { SAVE_CUT_RESOURCES, RESET_CUT_RESOURCES, SAVE_SELECTED_RESOURCES, SAVE_SHARE_RESOURCES, SAVE_RESOURCES_TO_DELETE, REFRESH_CURRENT_DASHBOARD_GRID, SAVE_RESOURCES_TO_RENAME, SAVE_RESOURCE_VERSIONS_DETAILS, RESET_RESOURCE_VERSIONS_DETAILS, SHOW_VERSIONS_COLUMN, STOP_REFRESHING_CURRENT_DASHBOARD_GRID } from "store/resourceGridSlice"; //prettier-ignore
import { getMoveResourceJson } from "components/requestcontext/MoveResource";
import { dashboardApi } from "components/misc/DashboardApi";
import ShareDialog from "components/dashboard/ShareDialog";
import "assets/css/Dropzone.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "assets/css/grid.css";
import "./ResourceTableComponent.styles.css";
import DeleteDialogGrp from "components/dashboard/DeleteDialogGrp";
import { getFetchResourceJson } from "components/requestcontext/FetchResource";
import RenameDialog from "components/dashboard/RenameDialog";
import NewFolderDialog from "components/dashboard/NewFolderDialog";
import DetailsDialogue from "components/UI/DetailsDialogue";
import { getFetchResourceVersionJson } from "components/requestcontext/FetchResourceVersion";
import downloadFile from "utils/helpers/downloadFile";
import JSZip from "jszip";
import _sortBy from "lodash/sortBy";
import columnDefinations from "./columnDefinations";
import Dropzone, { useDropzone } from "react-dropzone";
import { END_UPLOAD, PUT_FILES_IN_QUEUE, START_UPLOAD } from "store/fileUploadSlice";
import properties from "components/properties/ApplicationProps";
import { getUnShareResource } from "components/requestcontext/UnShareResourceJson";
import { useLocation } from "react-router-dom";
import { RESET_SHARED_WITH_ME_SLICE, SAVE_CURRENT_SHARED_WITH_ME_RESOURCE_LOCATION_ID, UPDATE_CURRENT_SHARED_WITH_ME_RESOURCES, fetchSharedWithMeResourcesThunk } from "store/sharedWithMeSlice"; //prettier-ignore
import { getFetchCloneJson } from "components/requestcontext/getfetchCloneResourceJson";
import { REFRESH_DASHBOARD_GRID, REFRESH_SEARCH_GRID, SAVE_DASHBOARD_UP_LOCATION_ID, SAVE_GO_TO_LOCATION_BREADCRUMB_CHIP, SAVE_SEARCH_UP_LOCATION_ID,  SAVE_SHARED_WITH_ME_UP_LOCATION_ID, STOP_REFRESHING_USING_BREADCRUMB, UPDATE_DEFAULT_DASHBOARD_UP_LOCATION_ID } from "store/gridNavigationSlice"; //prettier-ignore
import AgGridFooter from "./AgGridFooter";
import BreadCrumb from "./BreadCrumb";
import { fetchSearchFolderResourcesThunk, fetchSearchResources } from "store/searchSlice";
import FileSaver from "file-saver";
import { FontContext } from "components/UI/Theme";
import LinearProgress from "@mui/material/LinearProgress";
import BackupIcon from "@mui/icons-material/Backup";
import eventEmitter from "components/misc/eventEmitter";
import { isEmpty } from "lodash";
import { gridSelectAllEventsList, HALF_SECOND, ONE_MINUTE } from "Constants";
import checkIfUserClickOnCheckBox from "utils/helpers/checkIfUserClickOnCheckBox";
import { requestManager } from "Service/RequestManager";
import waitFor from "utils/helpers/waitFor";
import { END_DOWNLOAD } from "store/fileDownloadSlice";

const ResourceTableComponent = ({ resourcesData, showGridProgressbar }) => {
	const gridRef = useRef();
	const mapOriginalRowNodeToRowIdRef = useRef(null);
	const dashboardDropContainerRef = useRef();
	const dashboardDropRef = useRef();
	const resourceContainerRef = useRef();
	const gridContainerRef = useRef();
	const gridProgressbarRef = useRef();
	const currentlyActiveDropOverRowRef = useRef([]);

	const currentPageRoute = useLocation().pathname;
	const dispatch = useDispatch();
	const { fontFamily, smallTextSize } = useContext(FontContext);

	const rowData = useMemo(() => {
		const resources = resourcesData.map(({ fields }) => fields);

		return _sortBy(resources, (resource) => !resource.isfolder);
	}, [resourcesData]);

	const [colDefs, setColDefs] = useState(columnDefinations);
	const [showContextMenu, setShowContextMenu] = useState(null);
	const [showDetailsDialog, setShowDetailsDialog] = useState(false);
	const [showShareDialog, setShowShareDialog] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showRenameDialog, setShowRenameDialog] = useState(false);
	const [isGridReady, setIsGridReady] = useState(false);

	const { resourcesForCutPaste, selectedResources, refreshDashboardGrid, showVersionsColumn } = useSelector(
		({ resourceGrid }) => resourceGrid,
	);
	const userRole = useSelector(({ authDetails }) => authDetails.data.role.toLowerCase());
	const droppedLocationId = useSelector(({ droppedLocationId }) => droppedLocationId.locationId);
	const { dashboardParentFolderId } = useSelector(({ dashboard }) => dashboard);
	const dashboardUpLocation = useSelector(({ gridNavigation }) => gridNavigation.dashboardUpLocationId.slice(-1)[0]);
	const searchUpLocation = useSelector(({ gridNavigation }) => gridNavigation.searchUpLocationId.slice(-1)[0]);
	const sharedWithMeUpLocationId = useSelector(
		({ gridNavigation }) => gridNavigation.sharedWithMeUpLocationId.slice(-1)[0],
	);
	const { refreshDashboardUsingBreadCrumb, refreshSharedWithMeUsingBreadCrumb, refreshSearchUsingBreadCrumb } =
		useSelector(({ gridNavigation }) => gridNavigation);
	const { searchTerm } = useSelector(({ search }) => search);

	const usersWhoDontHaveUploadPermission = [properties.userRoles.user, properties.userRoles.unknown];
	const isUserHasPersmissionToUpload = !usersWhoDontHaveUploadPermission.includes(userRole);
	const isDragNDropAllowed = currentPageRoute.includes("dashboard");

	const resourceContainerEl = document.querySelector("#resource-container");
	const gridContainerEl = document.querySelector(".grid-container");
	const gridProgressbarEl = document.querySelector("#grid-progressbar");
	const noRowOverlayEl = document.querySelector("#norow-overlay");
	const dashboardDropContainerEl = document.querySelector(".dashboard-drop-container");
	// const dashboardDropEl = document.querySelector(".dashboard-drop");

	const getSelectedRowsNode = useCallback(() => gridRef.current?.api?.selectionService.getSelectedNodes(), []);
	const getSelectedRowsData = useCallback(() => gridRef.current?.api?.selectionService.getSelectedRows(), []);
	const getRenderedNodes = useCallback(() => gridRef.current?.api?.getRenderedNodes(), []);
	const getAllRowsNode = useCallback(() => gridRef.current?.api?.rowModel.rowsToDisplay, []);
	const updateGridOptions = useCallback((options) => gridRef.current?.api.updateGridOptions({ ...options }), []);
	const getGridOption = useCallback((option) => gridRef.current.api.getGridOption(option), []);
	const deselectAllRows = useCallback(() => gridRef.current?.api?.deselectAll(), []);

	const fetchDashboardResourceThunkPromise = useRef();
	const fetchSearchResourcesThunkPromise = useRef();
	const fetchSharedWithMeResourcesThunkPromise = useRef();

	// states to handle beforeunload event
	const isUploading = useSelector((state) => state.fileUpload.isUploading);
	const isDownloading = useSelector((state) => state.fileDownload.isDownloading);
	const isPaymentInProgress = useSelector((state) => state.payment.isPaymentInProgress);

	const refreshDashboardResources = useCallback(
		async (locationId) => {
			try {
				const fetchResourceForThisLocation = locationId === 0 ? -1 : locationId || dashboardParentFolderId;

				fetchDashboardResourceThunkPromise.current = dispatch(
					fetchDashboardResourcesThunk(fetchResourceForThisLocation),
				);

				return await fetchDashboardResourceThunkPromise.current
					.then(unwrapResult)
					.then(({ parentFolderId }) => {
						dispatch(CHANGE_DROPPED_LOCATION_ID(parentFolderId));
						dispatch(HIDE_RESOURCE_VERSION_NO());

						/**
						 * This will update default Dashboard up locationId to
						 * server sent parentFolderId. By default UI sent -1 to server and use 0
						 * for grid navigation purposes. So to upload resource at correct
						 * location we need to set corrent locationId one time only
						 */
						if (locationId === 0) {
							dispatch(UPDATE_DEFAULT_DASHBOARD_UP_LOCATION_ID(parentFolderId));
						}

						return Promise.resolve({
							status: 200,
							message: "success",
						});
					})
					.catch(() => {
						return Promise.reject({
							status: 400,
							message: "failure",
						});
					});
			} catch (error) {
				console.log(error);
			}
		},
		[dashboardParentFolderId, dispatch],
	);

	const refreshSearchResources = useCallback(
		async (locationId) => {
			try {
				const fetchResourceForThisLocation = locationId === 0 ? -1 : locationId || searchUpLocation?.locationId;

				if (searchTerm && !fetchResourceForThisLocation) {
					fetchSearchResourcesThunkPromise.current = dispatch(fetchSearchResources());

					return await fetchSearchResourcesThunkPromise.current
						.then(unwrapResult)
						.then(() =>
							Promise.resolve({
								status: 200,
								message: "success",
							}),
						)
						.catch(() =>
							Promise.reject({
								status: 400,
								message: "failure",
							}),
						);
				}

				fetchSearchResourcesThunkPromise.current = dispatch(
					fetchSearchFolderResourcesThunk(fetchResourceForThisLocation),
				);

				return await fetchSearchResourcesThunkPromise.current
					.then(unwrapResult)
					.then(() =>
						Promise.resolve({
							status: 200,
							message: "success",
						}),
					)
					.catch(() =>
						Promise.reject({
							status: 400,
							message: "failure",
						}),
					);
			} catch (error) {
				console.log(error);
			}
		},
		[searchTerm, searchUpLocation, dispatch],
	);

	const refreshSharedWithMeResources = useCallback(
		async (locationId, resourceId) => {
			try {
				fetchSharedWithMeResourcesThunkPromise.current = dispatch(
					fetchSharedWithMeResourcesThunk({
						locationId: locationId ?? sharedWithMeUpLocationId.locationId,
						resourceId: resourceId ?? sharedWithMeUpLocationId.resourceId,
					}),
				);

				return await fetchSharedWithMeResourcesThunkPromise.current
					.then(() =>
						Promise.resolve({
							status: 200,
							message: "success",
						}),
					)
					.catch(() =>
						Promise.reject({
							status: 400,
							message: "failure",
						}),
					);
			} catch (error) {
				console.log(error);
			}
		},
		[sharedWithMeUpLocationId, dispatch],
	);

	const handleRefreshAction = useCallback(() => {
		if (currentPageRoute.includes("dashboard")) {
			refreshDashboardResources();
		}

		if (currentPageRoute.includes("search")) {
			refreshSearchResources();
		}

		if (currentPageRoute.includes("shared-with-me")) {
			refreshSharedWithMeResources();
		}
	}, [currentPageRoute, refreshDashboardResources, refreshSearchResources, refreshSharedWithMeResources]);

	const handleCutAction = useCallback(() => {
		const selectedFiles = [].concat(getSelectedRowsData());

		if (!selectedFiles.length) return;

		dispatch(SAVE_CUT_RESOURCES(selectedFiles));
	}, [dispatch, getSelectedRowsData]);

	const handlePasteAction = useCallback(async () => {
		if (!resourcesForCutPaste.length) return;

		try {
			const pasteActionRes = await Promise.allSettled(
				resourcesForCutPaste.map(async (resource) => {
					try {
						const moveResourceJson = getMoveResourceJson();
						moveResourceJson.resourceId = resource.resourceid;
						// moveResourceJson.newParentId = dashboardParentFolderId;
						moveResourceJson.destLocationId = dashboardParentFolderId;
						const moveResourceRes = await dashboardApi.moveResource(moveResourceJson);
						const isResourceMovedSuccessfully = moveResourceRes?.data?.footer.code === 0;

						if (!isResourceMovedSuccessfully) {
							return Promise.reject({
								status: "failed",
								filename: resource.filename,
								resourceId: resource.resourceid,
							});
						}

						return Promise.resolve({
							status: "success",
							filename: resource.filename,
							resourceId: resource.resourceid,
						});
					} catch (error) {
						throw new Error(error);
					}
				}),
			);

			const success = pasteActionRes
				.filter((res) => {
					if (res.status === "fulfilled") return res.value;
					return null;
				})
				.filter((x) => x);

			if (success.length === resourcesForCutPaste.length) {
				dispatch(SET_GRID_MESSAGE("Files moved successfully"));
				return;
			}

			if (success.length < resourcesForCutPaste.length && success.length !== 0) {
				dispatch(SET_GRID_MESSAGE("Some files failed to move"));
				return;
			}

			dispatch(SET_GRID_MESSAGE("Failed to move files!"));
		} catch (error) {
			console.log(error);
		} finally {
			dispatch(RESET_CUT_RESOURCES());
			dispatch(REFRESH_CURRENT_DASHBOARD_GRID());
		}
	}, [resourcesForCutPaste, dashboardParentFolderId, dispatch]);

	const handleShareAction = useCallback(async () => {
		const selectedFiles = [].concat(getSelectedRowsData());
		const areAllSelectedFilesFolder =
			selectedFiles.filter(({ isfolder }) => isfolder).length === selectedFiles.length;

		if (!selectedFiles.length) return;

		if (selectedFiles.length > 10) {
			dispatch(
				SET_GRID_MESSAGE(
					`Only 10 folders can be shared at a time! Unselect any ${selectedFiles.length - 10} folders`,
				),
			);
			return;
		}

		if (areAllSelectedFilesFolder === false) {
			dispatch(SET_GRID_MESSAGE(`Only folder sharing is allowed! Please uncheck any selected files.`));
			return;
		}

		dispatch(SAVE_SHARE_RESOURCES(selectedFiles));
		setShowShareDialog(() => true);
	}, [dispatch, getSelectedRowsData]);

	const handleUnShareAction = useCallback(async () => {
		const selectedFiles = [].concat(getSelectedRowsData());

		// unshare only those files which are previously shared.
		const onlySharedFiles = selectedFiles.filter(({ shared }) => shared === true);

		if (!onlySharedFiles.length) return;

		try {
			const unshareActionRes = await Promise.allSettled(
				onlySharedFiles.map(async (resource) => {
					try {
						const unShareResourceJSON = getUnShareResource(resource.resourceid);
						const unsharedResponse = await dashboardApi.unsharedresource(unShareResourceJSON);
						const isResourceUnsharedSuccessfully = unsharedResponse?.data?.footer.code === 0;

						if (!isResourceUnsharedSuccessfully) {
							return Promise.reject({
								status: "failed",
								filename: resource.filename,
								resourceId: resource.resourceid,
							});
						}

						return Promise.resolve({
							status: "success",
							filename: resource.filename,
							resourceId: resource.resourceid,
						});
					} catch (error) {
						throw new Error(error);
					}
				}),
			);

			const success = unshareActionRes
				.filter((res) => {
					if (res.status === "fulfilled") return res.value;
					return null;
				})
				.filter((x) => x);

			dispatch(REFRESH_DASHBOARD_GRID());

			if (success.length === onlySharedFiles.length) {
				dispatch(SET_GRID_MESSAGE("Files were unshared successfully"));
				return;
			}

			if (success.length < onlySharedFiles.length && success.length !== 0) {
				dispatch(SET_GRID_MESSAGE("Some files failed to Unshare"));
				return;
			}

			dispatch(SET_GRID_MESSAGE("Failed to Unshare files!"));
		} catch (error) {
			console.log(error);
		}
	}, [dispatch, getSelectedRowsData]);

	const handleDeleteAction = useCallback(() => {
		const selectedFiles = [].concat(getSelectedRowsData());
		if (!selectedFiles.length) return;
		dispatch(SAVE_RESOURCES_TO_DELETE(selectedFiles));
		setShowDeleteDialog(() => true);
	}, [dispatch, getSelectedRowsData]);

	//get file details inside folder
	const getfile = useCallback(
		async (item) => {
			return downloadFile(item, dispatch);
		},
		[dispatch],
	);

	//call download folder
	const handleDownloadFolder = useCallback(
		async (zip, item) => {
			const folderID = item.locationid;
			const resourceId = item.resourceid;

			let listOfresource;
			let fileItems;
			let folder;

			if (currentPageRoute.includes("shared-with-me")) {
				listOfresource = await dashboardApi.fetchSharedDashBoardResource(folderID, resourceId);
				fileItems = listOfresource.data.resourcePropertiesList.map(({ fields }) => fields);
				folder = zip.folder(item.filename);
			} else {
				// for `Dashboard` and `Shared Resource` page
				listOfresource = await dashboardApi.fetchDashboardResources(folderID);
				fileItems = listOfresource.data.resourcePropertiesList.map(({ fields }) => fields);
				folder = zip.folder(item.filename);
			}

			for (let index = 0; index < fileItems.length; index++) {
				if (fileItems[index].isfolder) {
					await handleDownloadFolder(folder, fileItems[index]);
				} else {
					dispatch(SET_GRID_MESSAGE(`Downloading '${fileItems[index].filename}'`));
					const blob = await getfile(fileItems[index]);
					folder.file(fileItems[index]["filename"], blob);
				}
			}
		},
		[getfile, currentPageRoute, dispatch],
	);

	const handleDownloadAction = useCallback(async () => {
		if (isUploading || isPaymentInProgress) {
			dispatch(END_UPLOAD());
			// dispatch(END_UPLOAD_BATCH());
		}

		const selectedFiles = [].concat(getSelectedRowsData());
		if (!selectedFiles.length) return;

		const filesToDownload = selectedFiles.filter(({ isfolder }) => !isfolder);
		const foldersToDownload = selectedFiles.filter(({ isfolder }) => isfolder);

		// create a container to contain all download links for this specific action
		const downloadActionId = crypto.randomUUID();
		const downloadContainer = document.createElement("div");
		downloadContainer.id = `id_${downloadActionId}`;
		document.querySelector("body").appendChild(downloadContainer);

		// Files Download
		for (let i = 0; i < filesToDownload.length; i++) {
			try {
				const fetchResourceJson = getFetchResourceJson(filesToDownload[i]);
				const featchedResourceDetails = await requestManager.fetchResource(fetchResourceJson);
				const endpointUrl = featchedResourceDetails.data?.endpoint.endpoint;

				if (!endpointUrl) {
					dispatch(SET_GRID_MESSAGE(`Failed to get download link for '${filesToDownload[i].filename}'`));
					continue;
				}

				/**
				 *	Create an iframe which will be associated with endpointUrl(download) link
				 *	and append it to download container of this download action
				 */
				const downloadLinkIframe = document.createElement("iframe");
				downloadLinkIframe.name = `resource-${filesToDownload[i].resourceid}`;
				downloadLinkIframe.style.display = "none";
				document.querySelector(`#${downloadContainer.id}`).appendChild(downloadLinkIframe);

				// create downloadable link
				const downloadLink = document.createElement("a");
				downloadLink.download = filesToDownload[i].filename;
				downloadLink.href = endpointUrl;
				downloadLink.target = downloadLinkIframe.name;
				downloadLinkIframe.srcdoc = document.createElement("body").appendChild(downloadLink);
				downloadLink.click();

				dispatch(
					SHOW_BACKGROUND_PROGRESS_BAR({
						message: "Downloading Files",
						isDeterminate: true,
						showBackgroundProgressBar: true,
						progressValue: ((i + 1) / filesToDownload.length) * 100,
					}),
				);

				if (i + 1 === filesToDownload.length) {
					setTimeout(() => dispatch(HIDE_BACKGROUND_PROGRESS_BAR()), HALF_SECOND);
					setTimeout(() => downloadContainer.remove(), ONE_MINUTE);
				}

				await waitFor(HALF_SECOND);
			} catch (error) {
				console.log(error);
				dispatch(SET_GRID_MESSAGE(`Failed to Download '${filesToDownload[i].filename}'`));
				continue;
			}
		}

		for (const folder of foldersToDownload) {
			try {
				const zip = new JSZip();
				await handleDownloadFolder(zip, folder);
				const zipBlob = await zip.generateAsync({ type: "blob" }, ({ percent }) => {});
				FileSaver.saveAs(zipBlob, folder.filename);
			} catch (err) {
				console.log(err);
			}
		}

		dispatch(END_DOWNLOAD());

		if (isUploading) {
			dispatch(START_UPLOAD());
		}
		// Folder Download
	}, [dispatch, getSelectedRowsData, handleDownloadFolder, isUploading, isPaymentInProgress]);

	const handleCloneAction = useCallback(async () => {
		const selectedFiles = [].concat(getSelectedRowsData());
		if (!selectedFiles.length) return;

		const filesToClone = selectedFiles.filter(({ isfolder }) => !isfolder);

		const cloneActionRes = await Promise.allSettled(
			filesToClone.map(async (resource) => {
				try {
					const fetchCloneJson = getFetchCloneJson(resource.resourceid);
					const featchedCloneResponse = await dashboardApi.cloneresource(fetchCloneJson);
					const isResourceClonedSuccessfully = featchedCloneResponse?.data?.footer.code === 0;

					if (!isResourceClonedSuccessfully) {
						return Promise.reject({
							status: "failed",
							filename: resource.filename,
							resourceId: resource.resourceid,
						});
					}

					return Promise.resolve({
						status: "success",
						filename: resource.filename,
						resourceId: resource.resourceid,
					});
				} catch (error) {
					throw new Error(error);
				}
			}),
		);

		const success = cloneActionRes
			.filter((res) => {
				if (res.status === "fulfilled") return res.value;
				return null;
			})
			.filter((x) => x);

		if (success.length === filesToClone.length) {
			dispatch(SET_GRID_MESSAGE("Files were Cloned successfully"));
			return;
		}

		if (success.length < filesToClone.length && success.length !== 0) {
			dispatch(SET_GRID_MESSAGE("Some files failed to Clone"));
			return;
		}

		dispatch(SET_GRID_MESSAGE("Failed to Clone files!"));
	}, [dispatch, getSelectedRowsData]);

	const handleRenameAction = useCallback(() => {
		const selectedFiles = [].concat(getSelectedRowsData());

		if (!selectedFiles.length || selectedFiles.length > 1) return;

		dispatch(SAVE_RESOURCES_TO_RENAME(selectedFiles));
		setShowRenameDialog(() => true);
	}, [dispatch, getSelectedRowsData]);

	const handleShowVerionsAction = useCallback(async () => {
		const selectedFiles = [].concat(getSelectedRowsData());

		if (!selectedFiles.length || selectedFiles.length > 1) return;

		const resource = selectedFiles[0];

		try {
			const fetchResourceVersionsJson = getFetchResourceVersionJson(resource.resourceid);
			const resourcesVersions = await dashboardApi
				.fetchResourceVersions(fetchResourceVersionsJson)
				.then(({ data }) => data.resourcePropertiesList)
				.catch((error) => error);
			dispatch(SAVE_RESOURCE_VERSIONS_DETAILS(resourcesVersions.map(({ fields }) => fields)));

			if (currentPageRoute.includes("dashboard")) {
				dispatch(UPDATE_CURRENT_DASHBOARD_RESOURCES(resourcesVersions));
			}

			if (currentPageRoute.includes("shared-with-me")) {
				dispatch(UPDATE_CURRENT_SHARED_WITH_ME_RESOURCES(resourcesVersions));
			}

			const isShowVersionEnable = colDefs.every((col) => {
				return col.field !== "version";
			});

			if (isShowVersionEnable) {
				dispatch(SHOW_VERSIONS_COLUMN());
				setColDefs(() =>
					colDefs.concat({
						field: "version",
						headerName: "Version",
						width: 100,
					}),
				);
			}
		} catch (error) {
			console.log(error);
		}
	}, [colDefs, currentPageRoute, dispatch, getSelectedRowsData]);

	const handleGoToParentAction = useCallback(() => {
		const selectedFiles = [].concat(getSelectedRowsData());

		if (!selectedFiles.length || selectedFiles.length > 1) return;

		console.log("Go To Location: ", selectedFiles);

		deselectAllRows();
		dispatch(SAVE_GO_TO_LOCATION_BREADCRUMB_CHIP(selectedFiles[0]));
	}, [getSelectedRowsData, deselectAllRows, dispatch]);

	const handleSearchAction = useCallback(() => {}, []);

	const handleRemoveAllFilters = useCallback(() => {
		gridRef.current?.api.setFilterModel(null);
		gridRef.current?.api.setGridOption("quickFilterText", "");
	}, []);

	const handleOpenFolderAction = useCallback(() => {}, []);

	const handleCloseShareDialog = useCallback(() => {
		setShowShareDialog(() => false);
	}, []);

	const handleCloseDeleteDialog = useCallback(() => {
		setShowDeleteDialog(() => false);
	}, []);

	const handleCloseRenameDialog = useCallback(() => {
		setShowRenameDialog(() => false);
	}, []);

	const handleNewFolderAction = useCallback(() => {
		dispatch(SHOW_NEW_FOLDER_DIALOG());
	}, [dispatch]);

	const handleDetailsAction = useCallback(() => {
		setShowDetailsDialog(() => true);
	}, []);

	const handleCloseContextMenu = () => {
		setShowContextMenu(() => null);
	};

	const handleCloseDetailsDialog = () => {
		setShowDetailsDialog(() => false);
	};

	const contextActionsList = useMemo(
		() => ({
			openFolder: handleOpenFolderAction,
			createFolder: handleNewFolderAction,
			rename: handleRenameAction,
			search: handleSearchAction,
			cut: handleCutAction,
			paste: handlePasteAction,
			removeAllFilters: handleRemoveAllFilters,
			refresh: handleRefreshAction,
			share: handleShareAction,
			unshare: handleUnShareAction,
			download: handleDownloadAction,
			delete: handleDeleteAction,
			clone: handleCloneAction,
			showVersions: handleShowVerionsAction,
			goToParent: handleGoToParentAction,
			details: handleDetailsAction,
		}),

		[
			handleOpenFolderAction,
			handleNewFolderAction,
			handleSearchAction,
			handleCutAction,
			handlePasteAction,
			handleRemoveAllFilters,
			handleRefreshAction,
			handleShareAction,
			handleUnShareAction,
			handleDeleteAction,
			handleDownloadAction,
			handleCloneAction,
			handleGoToParentAction,
			handleRenameAction,
			handleDetailsAction,
			handleShowVerionsAction,
		],
	);

	const mapAllRowsToTheirId = useCallback((rowsNode) => {
		const mappedObject = {};

		for (const rowNode of rowsNode) {
			Object.defineProperty(mappedObject, `${rowNode.id}`, { value: rowNode });
		}

		return mappedObject;
	}, []);

	const handleRowDoubleClick = useCallback(
		async (resource) => {
			try {
				if (currentPageRoute.includes("dashboard")) {
					fetchDashboardResourceThunkPromise.current?.abort();
					const response = await refreshDashboardResources(
						resource.locationid || resource.lid,
						resource.filename,
					);

					if (response.status !== 200) return;

					dispatch(SAVE_DASHBOARD_UP_LOCATION_ID(resource));
					handleRemoveAllFilters();
					return;
				}

				if (currentPageRoute.includes("search")) {
					fetchSearchResourcesThunkPromise.current?.abort();
					const response = await refreshSearchResources(
						resource.locationid || resource.lid,
						resource.filename,
					);

					if (response.status !== 200) return;

					dispatch(SAVE_SEARCH_UP_LOCATION_ID(resource));
					handleRemoveAllFilters();
					return;
				}

				if (currentPageRoute.includes("shared-with-me")) {
					fetchSharedWithMeResourcesThunkPromise.current?.abort();
					const response = await refreshSharedWithMeResources(
						resource.locationid || resource.lid,
						resource.resourceid,
						resource.filename,
					);

					if (response.status !== 200) return;

					dispatch(SAVE_CURRENT_SHARED_WITH_ME_RESOURCE_LOCATION_ID(resource));
					dispatch(SAVE_SHARED_WITH_ME_UP_LOCATION_ID(resource));
					handleRemoveAllFilters();
					return;
				}
			} catch (error) {
				console.error(error);
			}
		},
		[
			currentPageRoute,
			handleRemoveAllFilters,
			fetchDashboardResourceThunkPromise,
			fetchSearchResourcesThunkPromise,
			fetchSharedWithMeResourcesThunkPromise,
			refreshDashboardResources,
			refreshSearchResources,
			refreshSharedWithMeResources,
			dispatch,
		],
	);

	const handleRowsSelection = useCallback(
		(event) => {
			const nativeEvent = event.event || event;
			const isUserClickedOnCheckbox = checkIfUserClickOnCheckBox(nativeEvent);
			const rowId = nativeEvent.target.getAttribute("row-id");
			const rowNode = mapOriginalRowNodeToRowIdRef.current && mapOriginalRowNodeToRowIdRef.current[rowId];
			const isThisRowSelected = rowNode?.selected;
			const areThereAnyOtherPrevSelectedRows = !isEmpty(getSelectedRowsNode());

			// enable multiselection if user click's on checkbox
			if (isUserClickedOnCheckbox) {
				updateGridOptions({ rowMultiSelectWithClick: true });
			}

			// disable multiselection if user deselects all rows
			if (
				event.type === "rowClicked" &&
				isEmpty(getSelectedRowsNode()) &&
				getGridOption("rowMultiSelectWithClick")
			) {
				updateGridOptions({ rowMultiSelectWithClick: false });
			}

			// handle selection by mouse's right click
			if (nativeEvent.type === "contextmenu") {
				if (isThisRowSelected === false && areThereAnyOtherPrevSelectedRows) {
					/* -------------- 🔴 Warning: Don't re-arrage following code 🔴 ------------- */
					deselectAllRows();
					updateGridOptions({ rowMultiSelectWithClick: false });
					rowNode.setSelected(true);
				} else if (isThisRowSelected === false && areThereAnyOtherPrevSelectedRows === false) {
					rowNode.setSelected(true);
				}
			}
		},
		[getSelectedRowsNode, updateGridOptions, getGridOption, deselectAllRows],
	);

	const onGridDrop = useCallback(
		async (acceptedFiles) => {
			if (isUserHasPersmissionToUpload) {
				const droppedFiles = [].concat([...acceptedFiles]);
				dispatch(PUT_FILES_IN_QUEUE({ droppedFiles, droppedLocationId }));
				resourceContainerEl?.classList.remove("resource-container-dragging");
			}
		},
		[isUserHasPersmissionToUpload, droppedLocationId, resourceContainerEl, dispatch],
	);

	const onGridDragEnter = useCallback(() => {
		resourceContainerEl?.classList.add("resource-container-dragging");
	}, [resourceContainerEl]);

	const onGridDragLeave = useCallback(() => {
		if (!currentlyActiveDropOverRowRef.current.length) {
			resourceContainerEl?.classList.remove("resource-container-dragging");
		}
	}, [resourceContainerEl]);

	const onGridDragOver = useCallback(() => {
		const isDragginEffectPresent = resourceContainerEl?.classList.contains("resource-container-dragging");

		if (isDragginEffectPresent) {
			return;
		}

		resourceContainerEl?.classList.add("resource-container-dragging");
	}, [resourceContainerEl]);

	const { getRootProps } = useDropzone({
		noClick: true,
		noDrag: !isDragNDropAllowed,
		onDrop: onGridDrop,
		onDragEnter: onGridDragEnter,
		onDragLeave: onGridDragLeave,
		onDragOver: onGridDragOver,
	});

	const onRowDrop = useCallback(
		(event) => {
			const renderedRows = getRenderedNodes();
			const nativeElIndex = parseInt(event.target.getAttribute("row-index"));
			const rowData = renderedRows.filter(({ rowIndex }) => rowIndex === nativeElIndex)[0].data;

			if (rowData?.isfolder) {
				console.log(`Changing droplocation Id to: ${rowData.filename}`);
				dispatch(CHANGE_DROPPED_LOCATION_ID(rowData.locationid));
				dispatch(SET_GRID_MESSAGE(`Uploading file/s to ${rowData.filename}`));
			}
		},
		[dispatch, getRenderedNodes],
	);

	const onRowDragEnter = useCallback((event) => {
		event.preventDefault();

		const resourceContainerEl = document.querySelector("#resource-container");
		const isElTypeFolder = event.target.dataset.resourceType === "folder";
		const rowIndex = event.target.getAttribute("row-index");
		currentlyActiveDropOverRowRef.current.push(rowIndex);

		if (isElTypeFolder) {
			event.target.style.backgroundColor = "#c8e6f0";
		} else {
			resourceContainerEl.classList.add("resource-container-dragging");
		}
	}, []);

	const onRowDragLeave = useCallback((event) => {
		event.preventDefault();
		const rowIndex = event.target.getAttribute("row-index");
		currentlyActiveDropOverRowRef.current = currentlyActiveDropOverRowRef.current.filter(
			(prevRowIndex) => prevRowIndex !== rowIndex,
		);
		event.target.style.backgroundColor = "unset";
	}, []);

	const onRowDragOver = useCallback(
		(event) => {
			event.preventDefault();

			if (event.clientX > window.innerWidth - 500 && currentPageRoute.includes("dashboard")) {
				dashboardDropContainerRef.current.style.display = "block";
			}
		},
		[currentPageRoute],
	);

	const onParentDrop = useCallback(
		(acceptedFiles, _, event) => {
			event.preventDefault();
			event.stopPropagation();

			if (isUserHasPersmissionToUpload) {
				const droppedFiles = [].concat([...acceptedFiles]);
				dispatch(PUT_FILES_IN_QUEUE({ droppedFiles, droppedLocationId: dashboardUpLocation.locationId }));
				resourceContainerEl?.classList.remove("resource-container-dragging");
				dashboardDropContainerRef.current.style.display = "none";
			}
		},
		[dashboardUpLocation, resourceContainerEl, isUserHasPersmissionToUpload, dispatch],
	);

	const onParentDragEnter = useCallback((event) => {
		event.preventDefault();
		event.stopPropagation();

		const dashboardDropEl = document.querySelector(".dashboard-drop");
		dashboardDropEl.classList.add("dashboard-drop-animation");
		resourceContainerRef.current?.classList.remove("resource-container-dragging");
	}, []);

	const onParentDragLeave = useCallback((event) => {
		event.preventDefault();
		event.stopPropagation();

		// console.log("Drag Leave Dashboard Drop");
		const dashboardDropEl = document.querySelector(".dashboard-drop");
		dashboardDropEl.classList.remove("dashboard-drop-animation");
		resourceContainerRef.current?.classList.add("resource-container-dragging");
	}, []);

	const onParentDragOver = useCallback((event) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const addRequiredEventToRows = useCallback(() => {
		try {
			const renderedRowNodes = getRenderedNodes();

			renderedRowNodes.forEach((node) => {
				const rowNativeEl = document.querySelector(`div[row-index='${node.rowIndex}']`);
				rowNativeEl.dataset.rowType = "resource";

				rowNativeEl.removeEventListener("click", () => {});
				rowNativeEl.addEventListener("click", handleRowsSelection);

				if (
					node.data.isfolder &&
					(currentPageRoute.includes("dashboard") || currentPageRoute.includes("search"))
				) {
					rowNativeEl.dataset.resourceType = "folder";

					// Removes existing events if present
					rowNativeEl?.removeEventListener("drop", () => {});
					rowNativeEl?.removeEventListener("dragenter", () => {});
					rowNativeEl?.removeEventListener("dragleave", () => {});
					rowNativeEl?.removeEventListener("dragover", () => {});

					// Adds new drop,dragenter and dragleave events
					rowNativeEl?.addEventListener("drop", onRowDrop);
					rowNativeEl?.addEventListener("dragenter", onRowDragEnter);
					rowNativeEl?.addEventListener("dragleave", onRowDragLeave);
					rowNativeEl?.addEventListener("dragover", onRowDragOver);
				} else {
					rowNativeEl.dataset.resourceType = "file";
				}
			});
		} catch (error) {
			console.error("Unableto set required row event", error);
		}
	}, [
		currentPageRoute,
		getRenderedNodes,
		handleRowsSelection,
		onRowDrop,
		onRowDragEnter,
		onRowDragLeave,
		onRowDragOver,
	]);

	useEffect(() => {
		return () => {
			dispatch(RESET_DASHBOARD_SLICE());
			dispatch(RESET_SHARED_WITH_ME_SLICE());
		};
	}, [dispatch]);

	useEffect(() => {
		if (refreshDashboardGrid) {
			refreshDashboardResources();
			dispatch(STOP_REFRESHING_CURRENT_DASHBOARD_GRID());
		}
	}, [refreshDashboardGrid, dashboardParentFolderId, dispatch, refreshDashboardResources]);

	useEffect(() => {
		dispatch(RESET_RESOURCE_VERSIONS_DETAILS());
	}, [currentPageRoute, dispatch]);

	useEffect(() => {
		if (refreshDashboardUsingBreadCrumb) {
			refreshDashboardResources(dashboardUpLocation.locationId);
			dispatch(STOP_REFRESHING_USING_BREADCRUMB());
			return;
		}
	}, [refreshDashboardUsingBreadCrumb, dashboardUpLocation, refreshDashboardResources, dispatch]);

	useEffect(() => {
		if (refreshSearchUsingBreadCrumb) {
			if (searchUpLocation.locationId === 0 && searchUpLocation.isFolder) {
				dispatch(fetchSearchResources());
				dispatch(STOP_REFRESHING_USING_BREADCRUMB());
				return;
			}

			refreshSearchResources(searchUpLocation.locationId || searchUpLocation.parentFolderId);
			dispatch(STOP_REFRESHING_USING_BREADCRUMB());
			return;
		}
	}, [refreshSearchUsingBreadCrumb, searchUpLocation, refreshSearchResources, dispatch]);

	useEffect(() => {
		if (refreshSharedWithMeUsingBreadCrumb) {
			refreshSharedWithMeResources(sharedWithMeUpLocationId.locationId);
			dispatch(STOP_REFRESHING_USING_BREADCRUMB());
			return;
		}
	}, [refreshSharedWithMeUsingBreadCrumb, sharedWithMeUpLocationId, dispatch, refreshSharedWithMeResources]);

	useEffect(() => {
		if (!gridContainerEl) return;

		if (smallTextSize === 12) {
			gridContainerEl.style.fontSize = "unset";
		} else {
			gridContainerEl.style.fontSize = "medium";
		}
	}, [smallTextSize, gridContainerEl]);

	/**
	 *	Don't merge with noRowOverlayEl useEffect
	 */
	useEffect(() => {
		if (!gridProgressbarEl) return;

		if (showGridProgressbar) {
			gridProgressbarEl.style.display = "block";
		} else {
			gridProgressbarEl.style.display = "none";
		}
	}, [showGridProgressbar, gridProgressbarEl, noRowOverlayEl]);

	/**
	 *	Don't merge with gridProgressbarEl useEffect
	 */
	useEffect(() => {
		if (!noRowOverlayEl) return;

		if (showGridProgressbar) {
			noRowOverlayEl.textContent = "Loading...";
			noRowOverlayEl.classList.add("animate__animated", "animate__flash", "animate__infinite", "animate__slow");
		} else {
			noRowOverlayEl.textContent = "No Resources Found!";
			noRowOverlayEl.classList.remove( "animate__animated", "animate__flash", "animate__infinite", "animate__slow"); // prettier-ignore
		}
	}, [showGridProgressbar, noRowOverlayEl]);

	useEffect(() => {
		if (!showVersionsColumn) {
			setColDefs(() => columnDefinations);
		}
	}, [showVersionsColumn]);

	useEffect(() => {
		document.addEventListener("keydown", (event) => {
			if (event.key === "Escape" && dashboardDropContainerEl) {
				console.log("Escape is pressed!");
				dashboardDropContainerEl.style.display = "none";
			}
		});

		return () => document.removeEventListener("keydown", () => {});
	}, [dashboardDropContainerEl]);

	useEffect(() => {
		return () => {
			fetchDashboardResourceThunkPromise.current?.abort();
			fetchSearchResourcesThunkPromise.current?.abort();
			fetchSharedWithMeResourcesThunkPromise.current?.abort();
		};
	}, [fetchDashboardResourceThunkPromise, fetchSearchResourcesThunkPromise, fetchSharedWithMeResourcesThunkPromise]);

	useEffect(() => {
		eventEmitter.on("resetGridFilters", () => {
			gridRef.current?.api.setFilterModel(null);
			gridRef.current?.api.setGridOption("quickFilterText", "");
		});

		eventEmitter.on("resetGridSelectionService", () => {
			updateGridOptions({ rowMultiSelectWithClick: false });
		});

		eventEmitter.on("refreshSearchGrid", () => {
			dispatch(REFRESH_SEARCH_GRID());
		});
	}, [updateGridOptions, dispatch]);

	return (
		<div
			ref={gridContainerRef}
			className="grid-container"
			style={{ fontFamily }}
		>
			<header>
				<BreadCrumb style={{ fontFamily: "inherit", fontSize: "inherit" }} />
				<AgContextHeader
					contextActions={contextActionsList}
					gridApi={gridRef}
				/>
			</header>

			<main
				ref={resourceContainerRef}
				id="resource-container"
				data-cy="dndzone"
				className="ag-theme-quartz"
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				onContextMenu={(event) => {
					handleRowsSelection(event);
					setShowContextMenu(() => ({ mouseX: event.clientX, mouseY: event.clientY }));
				}}
				{...getRootProps()}
			>
				<div
					ref={gridProgressbarRef}
					id="grid-progressbar"
					style={{
						display: "none",
						position: "absolute",
						zIndex: 1,
						width: "100%",
						top: "33px",
					}}
				>
					<LinearProgress />
				</div>

				<AgGridReact
					sortingOrder={["asc", "desc"]}
					deltaRowDataMode
					suppressContextMenu
					suppressDragLeaveHidesColumns
					suppressCellFocus
					suppressScrollOnNewData
					preventDefaultOnContextMenu
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					ref={gridRef}
					columnDefs={colDefs}
					rowData={rowData}
					rowSelection="multiple"
					overlayNoRowsTemplate="<span id='norow-overlay'>No Resources Found!</span>"
					onGridReady={() => setIsGridReady(() => true)}
					gridOptions={{
						headerHeight: 35,
						rowHeight: 35,
						onModelUpdated: (gridEvent) => {
							gridEvent.api.getDisplayedRowCount() === 0
								? gridEvent.api.showNoRowsOverlay()
								: gridEvent.api.hideOverlay();

							addRequiredEventToRows();
							mapOriginalRowNodeToRowIdRef.current = mapAllRowsToTheirId(getAllRowsNode());
						},
						onRowClicked: handleRowsSelection,
						onRowDoubleClicked: (gridEvent) => {
							const resourceDetails = gridEvent.data;
							if (resourceDetails.isfolder) {
								handleRowDoubleClick(resourceDetails);
								updateGridOptions({ rowMultiSelectWithClick: false });
							}
						},
						onSelectionChanged: (gridEvent) => {
							const selectedRows = getSelectedRowsData();
							dispatch(SAVE_SELECTED_RESOURCES(selectedRows));

							const isUserDeselectsAllRows =
								gridSelectAllEventsList.includes(gridEvent.source) && !getSelectedRowsNode().length;

							if (isUserDeselectsAllRows) {
								updateGridOptions({ rowMultiSelectWithClick: false });
							}
						},
						postSortRows: (params) => {
							const allRowNodes = params.nodes;
							let folderPosition = 0;

							// here we will keep folders always on top
							allRowNodes.forEach((rowNode, index) => {
								const isItFolder = rowNode.data ? rowNode.data.isfolder : undefined;

								if (isItFolder === true) {
									allRowNodes.splice(folderPosition, 0, allRowNodes.splice(index, 1)[0]);
									folderPosition++;
								}
							});
						},
						onRowDataUpdated: addRequiredEventToRows,
						onBodyScroll: addRequiredEventToRows,
						// onSortChanged: addRequiredEventToRows,
						// onFilterChanged: addRequiredEventToRows,
					}}
				/>
			</main>

			<AgGridFooter
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				rowData={rowData}
				selectedResources={selectedResources}
			/>

			<AgContextMenu
				contextMenu={showContextMenu}
				handleContextMenuClose={handleCloseContextMenu}
				contextActions={contextActionsList}
			/>

			<Dropzone
				onDrop={(acceptedFiles, fileRejection, event) => onParentDrop(acceptedFiles, fileRejection, event)}
			>
				{({ getRootProps }) => (
					<div
						className="dashboard-drop-container"
						ref={dashboardDropContainerRef}
						onDragOver={(event) => {
							if (event.clientX < window.innerWidth - 500 && currentPageRoute.includes("dashboard")) {
								dashboardDropContainerRef.current.style.display = "none";
							}
						}}
					>
						<div
							ref={dashboardDropRef}
							className="dashboard-drop animate__animated animate__fadeInRight animate__faster"
							{...getRootProps({
								onDragEnter: onParentDragEnter,
								onDragLeave: onParentDragLeave,
								onDragOver: onParentDragOver,
							})}
						>
							<div style={{ pointerEvents: "none" }}>
								<p className="animate__animated animate__bounce animate__infinite">
									<BackupIcon style={{ fontSize: "6rem" }} />
								</p>
							</div>

							<div
								style={{
									backgroundColor: "#c8e6f0",
									padding: "1rem",
									borderRadius: "15px",
									pointerEvents: "none",
								}}
							>
								<p style={{ margin: 0 }}>Drop your file/s here to upload to</p>
								<p style={{ fontSize: "1.5rem" }}>{dashboardUpLocation.name}</p>
							</div>

							<p style={{ marginTop: "1rem", pointerEvents: "none" }}>
								**Press <strong>'Escape'</strong> to cancel.
							</p>
						</div>
					</div>
				)}
			</Dropzone>

			{showShareDialog ? (
				<ShareDialog
					openShareDialog={showShareDialog}
					handleCloseDialog={handleCloseShareDialog}
				/>
			) : null}

			<DeleteDialogGrp
				deleteDialogOpen={showDeleteDialog}
				handleRefresh={handleRefreshAction}
				handleClose={handleCloseDeleteDialog}
			/>

			<RenameDialog
				renameDialogOpen={showRenameDialog}
				handleCloseRenameDialog={handleCloseRenameDialog}
			/>

			<NewFolderDialog />

			{showDetailsDialog ? (
				<DetailsDialogue
					resource={selectedResources}
					handleCloseState={handleCloseDetailsDialog}
				/>
			) : null}
		</div>
	);
};

export default memo(ResourceTableComponent);
