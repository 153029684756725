import { memo } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";

const AgGridFooter = ({ rowData, selectedResources }) => {
	const { isDeterminate, showBackgroundProgressBar, message, progressValue } = useSelector(
		({ ui }) => ui.backgroundProgressBar,
	);

	return (
		<footer
			id="ag-grid-footer"
			style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 8 }}
		>
			<p style={{ margin: 0 }}>
				<span>{rowData.length}</span> Items
				<span style={{ marginLeft: 20 }}>{selectedResources.length}</span> items selected
			</p>

			{showBackgroundProgressBar ? (
				<div style={{ display: "flex", alignItems: "center" }}>
					<span>{message}:</span>
					<span style={{ width: 200, marginLeft: 10 }}>
						<LinearProgress
							style={{ height: 6 }}
							variant={isDeterminate ? "determinate" : "indeterminate"}
							value={progressValue}
						/>
					</span>
				</div>
			) : null}
		</footer>
	);
};

export default memo(AgGridFooter);
