import { alpha } from "@mui/material/styles";
const withAlphas = (color) => {
	return {
		...color,
		alpha4: alpha(color.main, 0.04),
		alpha8: alpha(color.main, 0.08),
		alpha12: alpha(color.main, 0.12),
		alpha30: alpha(color.main, 0.3),
		alpha50: alpha(color.main, 0.5),
	};
};

export const neutral = {
	maincolor: "#4db8ff",
	textcolor: "#000000",
	textcolor1: "#ffffff",
	hovereffect: "#f3f5ff",
	buttoncolor: "#12a5c6",
	buttondisable: "#00000042",
	buttontextcolor: "#ffffff",
	buttonDanger: "#ff5f5f",
};

export const textsize = {
	headerlogotext: 16,
	navigationtext: 14,
	filedetailstext: 12,
	buttontextsize: 12,
};

export const icon = {
	width: "20px",
	height: "20px",
};
export const bgcolor = {
	themecolor: "#ffffff",
	iconcolor: "invert(0)",
};

export const font = {
	fontFamily: "sans-serif",
};

export const indigo = withAlphas({
	lightest: "#F5F7FF",
	light: "#EBEEFE",
	main: "#6366F1",
	dark: "#4338CA",
	darkest: "#312E81",
	contrastText: "#FFFFFF",
});

// export const success = withAlphas({
// 	lightest: "#F0FDF9",
// 	light: "#3FC79A",
// 	main: "#10B981",
// 	dark: "#0B815A",
// 	darkest: "#134E48",
// 	contrastText: "#FFFFFF",
// });

export const info = withAlphas({
	lightest: "#ECFDFF",
	light: "#CFF9FE",
	main: "#06AED4",
	dark: "#0E7090",
	darkest: "#164C63",
	contrastText: "#FFFFFF",
});

export const warning = withAlphas({
	lightest: "#FFFAEB",
	light: "#FEF0C7",
	main: "#F79009",
	dark: "#B54708",
	darkest: "#7A2E0E",
	contrastText: "#FFFFFF",
});

export const error = withAlphas({
	lightest: "#FEF3F2",
	light: "#FEE4E2",
	main: "#F04438",
	dark: "#B42318",
	darkest: "#7A271A",
	contrastText: "#FFFFFF",
});

// export const rowhover = withAlphas({
//     contrastText:rgba(108, 173, 209, 0.5),
// })
