import React from "react";
import useStyles from "routes/TableStyles";
import { CssBaseline } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";

function CheckStatus() {
	const classes = useStyles();
	return (
		<div>
			<React.Fragment>
				<CssBaseline />
				<div className={classes.outercontainer}>
					<div className={classes.innercontainer}>
						<TableContainer className={classes.tablecontainer}>
							<h1>Access denied</h1>
						</TableContainer>
					</div>
				</div>
			</React.Fragment>
		</div>
	);
}

export default CheckStatus;
