import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { neutral } from "assets/css/MainCss";
import { Typography } from "@material-ui/core";
import { Modal } from "@mui/material";
import clickSettingIcon from "assets/proto_1/settingClick.png";
import rightArrow from "assets/proto_1/nextArrow.png";
import checked from "assets/proto_1/fileContentTypes/checked.png";
import createAccount from "assets/proto_1/createAccount.png";


const WelcomeModal = () => {
	const [open, setOpen] = useState(true);
	const history = useHistory();
	const modalStyle = {
		position: "absolute",
		transform: "translate(-50%, -50%)",
		marginLeft: "50%",
		maxWidth: "300px",
		minWidth: "200px",
		marginTop: "25%",
		width: "100%",
		bgcolor: "background.paper",
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
		textAlign: "center",
	};

	const Iconstyle = {
		maxWidth: "30px",
		height: "30px",
		margin: "0 5px",
		marginTop: "10px",
		marginBottom: "10px",
	};

	const buttonStyle = {
		backgroundColor: neutral.buttoncolor,
		color: neutral.textcolor1,
		padding: "5px 5px",
		textAlign: "center",
		textDecoration: "none",
		display: "inline-block",
		fontSize: "14px",
		cursor: "pointer",
		borderRadius: "5px",
		marginTop: "9px",
		marginLeft: "30%",
		marginBottom: "10px",
		width: "45%",
	};

	const iconContainerStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: "20px",
	};

	const typographyStyle = {
		backgroundColor: neutral.buttoncolor,
		color: neutral.textcolor1,
		padding: "10px 10px",
		textAlign: "left",
		textDecoration: "none",
		display: "inline-block",
		fontSize: "16px",
	};

	function handleClose() {
		setOpen(false);
		history.push("/settings");
	}

	return (
		<Modal open={open}>
			<div style={modalStyle}>
				<div className="modal-content">
					<Typography style={typographyStyle}>Complete Sign up</Typography>

					<div className={iconContainerStyle}>
						<img
							src={clickSettingIcon}
							alt="Setting Icon"
							style={Iconstyle}
						/>
						<img
							src={rightArrow}
							alt="Right Arrow"
							style={Iconstyle}
						/>
						<img
							src={createAccount}
							alt="create account icon"
							style={Iconstyle}
						/>
						<img
							src={rightArrow}
							alt="Right Arrow"
							style={Iconstyle}
						/>
						<img
							src={checked}
							alt="Checked"
							style={Iconstyle}
						/>
					</div>
					<button
						onClick={handleClose}
						data-button-name="welcome-submit"
						style={buttonStyle}
					>
						OK
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default WelcomeModal;
