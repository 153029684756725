export function checkFilePath(file) {
	let filepath;

	if (file.webkitRelativePath || file?.path) {
		filepath = file?.path || file.webkitRelativePath;
	} else if (file?.path?.startsWith("/") || file.webkitRelativePath?.startsWith("/")) {
		filepath = null;
	}

	return filepath;
}
