import ResourceLabel from "components/ResourceLabels";
import FileIcon from "components/fileService/FileIcons";
import numeral from "numeral";

const columnDefinations = [
	{
		field: "filename",
		flex: 1,
		checkboxSelection: true,
		headerCheckboxSelection: true,
		headerCheckboxSelectionFilteredOnly: true,
		editable: false,
		minWidth: 200,
		headerName: "Filename",
		sort: "asc",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		// tooltipValueGetter: (param) => {
		// 	console.log(param);
		// 	return param.value;
		// },
		cellRenderer: fileNameRendere,
		comparator: (valueA, valueB) =>
			valueA.toLowerCase().localeCompare(valueB.toLowerCase(), "en", { numeric: true }),
	},

	{
		field: "contenttype",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Content Type",
		width: 150,
	},
	{
		field: "lastupdatetime",
		headerName: "Last Modified",
		width: 200,
		filter: "agDateColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			defaultOption: "inRange",
			// filterOptions: ["inRange"],
			suppressAndOrCondition: true,
			closeOnApply: true,
			comparator: (filterLocalDateAtMidnight, cellValue) => {
				/**
				 * we store datae a long value, so we need to convert it string
				 * as dd/mm/yyyy format
				 */
				const dateAsString = new Date(cellValue).toLocaleDateString("en-GB");

				if (dateAsString == null) {
					return 0;
				}

				const dateParts = dateAsString.split("/");
				const year = Number(dateParts[2]);
				const month = Number(dateParts[1]) - 1;
				const day = Number(dateParts[0]);
				const cellDate = new Date(year, month, day);

				// Now that both parameters are Date objects, we can compare
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				} else if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				return 0;
			},
		},
		valueFormatter: (param) => {
			return new Date(param.value || new Date()).toLocaleString([], { hour12: true });
		},
	},
	{
		field: "shared",
		filter: "agTextColumnFilter",
		headerName: "Shared",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		width: 100,
		valueFormatter: (param) => {
			if (param.value === null) {
				return false;
			}

			return param.value;
		},
	},
	{
		headerName: "Labels",
		field: "labels",
		width: 300, // original : 370
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			textMatcher: (param) => {
				const filterText = param.filterText.toLowerCase();
				const labels = JSON.parse(param.data?.labels?.value);
				const isFilterTextPresent = labels
					.filter((x) => x) // remove null
					.filter((label) => label.toLowerCase().includes(filterText)).length;

				return isFilterTextPresent;
			},
		},
		cellRenderer: labelsRender,
		comparator: (valueA, valueB) =>
			valueA.value.toLowerCase().localeCompare(valueB.value.toLowerCase(), "en", { numeric: true }),
	},
	{
		field: "size",
		headerName: "Size",
		width: 100,
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			// allowedCharPattern: '([1-9]\d{0,2}|0)(\.\d+)?s*(B|KB|MB|GB|TB|PB)', // prettier-ignore
			closeOnApply: true,
			numberParser: (text) => {
				console.log(text);
			},
			numberFormatter: (value) => {
				console.log(value);
			},
		},
		valueFormatter: (param) => {
			if (!param.value) return "-";

			return numeral(param.value).format("0.00b");
		},
	},
];

export default columnDefinations;

function fileNameRendere(param) {
	const resource = param.data;

	// Set data-cy attribute to help cypress to capture element
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${resource.filename}`;

	return (
		<>
			<FileIcon contentType={resource.contenttype} />
			<span
				style={{ marginLeft: "2rem" }}
				data-cy={`resource-name-${resource.filename}`}
			>
				{resource.filename}
			</span>
		</>
	);
}

function labelsRender(param) {
	const resource = param.data;

	return resource.labels ? (
		<ResourceLabel
			resourceLabels={resource.labels}
			resourceid={resource.resourceid}
			resourceName={resource.filename}
		/>
	) : (
		"-"
	);
}
