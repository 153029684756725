import Breadcrumb from "@mui/material/Breadcrumbs";
import { Link } from "@material-ui/core";
import BreadcrumbJson from "components/misc/FolderJSON";
import useStyles from "../sidenav/styles";
import { useContext } from "react";
import { FontContext } from "../../UI/Theme";

export default function BreadCrumb(props) {
	// const { handleBreadCrumbClick } = props;
	const classes = useStyles();
	const { fontFamily, largeTextSize } = useContext(FontContext);

	return (
		<Breadcrumb
			maxItems={3}
			separator={<span className={classes.separator}>{">"}</span>}
			style={{ fontFamily: fontFamily }}
		>
			{BreadcrumbJson.Invoice.map((item) => (
				<Link
					key={item}
					title={item.name}
					id="text"
					className={classes.breadcrumblink}
					style={{ fontFamily: fontFamily, fontSize: largeTextSize }}
				>
					{item.name.length > 20 ? `${item.name.slice(0, 20)}...` : item.name}
				</Link>
			))}
		</Breadcrumb>
	);
}
