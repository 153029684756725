import { memo } from "react";

import userProfileIcon from "assets/proto_1/user.png";
import { SHOW_USER_DETAILED_VIEW } from "store/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import { neutral, textsize } from "assets/css/MainCss";
import { Button } from "@mui/material";

const USER_ROLES = {
	user: "user",
	member: "member",
	billing: "billing",
	systemadmin: "system-admin",
};

const Summery = memo(({ label, info }) => {
	return (
		<div
			className="summery"
			onClick={() => navigator.clipboard.writeText(info)}
		>
			<p>{label}</p>
			<p>{info || "N/A"}</p>
		</div>
	);
});

const SimpleView = ({ userSummery, userAuthDetails }) => {
	const dispatch = useDispatch();
	const { isDarkThemeOn } = useSelector(({ ui }) => ui);
	const isUserOnboarded =
		userAuthDetails.db_onboarding === "completed" && userAuthDetails.kc_onboarding === "completed";

	return (
		<>
			<div
				className="user-summery"
				style={{
					justifyContent: "center",
				}}
			>
				<div className="user-summery-img-container">
					<img
						src={userAuthDetails?.picture || userProfileIcon}
						alt="User's Profile"
						title="User's Profile Picture"
						style={{
							filter: isDarkThemeOn && "invert(1)",
							background: isDarkThemeOn && "#fff",
						}}
					/>
				</div>

				<p className="user-summery-username">{`${userAuthDetails.name}`}</p>

				<p className="user-summery-role">{USER_ROLES[userAuthDetails.role]}</p>

				<p
					className="user-summery-acc-status"
					data-acc-status={userSummery?.accountstatus}
				>
					{userSummery?.accountstatus}
				</p>
			</div>

			<div className="user-summery">
				<div className="user-summery-personal">
					<h3>User Details</h3>

					<Summery
						label="Name"
						info={userAuthDetails.name}
					/>

					<Summery
						label="Email"
						info={userSummery.email}
					/>

					<Summery
						label="Mobile No."
						info={userSummery.mobile || "N/A"}
					/>

					<Summery
						label="Is User Onboarded ?"
						info={isUserOnboarded ? "Yes" : "No"}
					/>

					<Summery
						label="Is E-mail Verified ?"
						info={userAuthDetails.email_verified ? "Yes" : "No"}
					/>
				</div>

				<div className="user-summery-org">
					<h3>Organization Details</h3>

					<Summery
						label="Account ID"
						info={userSummery?.accountid}
					/>

					<Summery
						label="Account Name"
						info={userSummery?.accountname}
					/>

					<Summery
						label="Org ID"
						info={userSummery?.orgid}
					/>

					<Summery
						label="Org Name"
						info={userSummery?.orgname}
					/>

					<Summery
						label="Account Type"
						info={userSummery?.accounttype}
					/>

					<Summery
						label="G.S.T. No."
						info={userSummery?.gstno}
					/>

					<Summery
						label="Plan"
						info={userSummery?.plantype}
					/>
				</div>

				<Button
					style={{
						alignSelf: "flex-start",
						backgroundColor: neutral.buttoncolor,
						color: "#fff",
						borderColor: neutral.buttoncolor,
						fontSize: textsize.buttontextsize,
						marginTop: "1rem",
					}}
					className="switch-button"
					onClick={() => {
						dispatch(SHOW_USER_DETAILED_VIEW());
					}}
				>
					Show Detailed View
				</Button>
			</div>
		</>
	);
};

export default memo(SimpleView);
