import React from "react";
import { useEffect } from "react";
import eventEmitter from "components/misc/eventEmitter";
import "../../assets/css/Table.css";
import { useDispatch, useSelector } from "react-redux";
import { RESET_DASHBOARD_SLICE, fetchDashboardResourcesThunk } from "store/dashboardSlice";
import "assets/css/Dropzone.css";
import { HIDE_RESOURCE_VERSION_NO } from "store/uiSlice";
import ResourceTableComponent from "components/ResourceTableComponent";
import { RESET_DASHBOARD_UP_LOCATION_ID_SLICE } from "store/gridNavigationSlice";

const Dashboard = () => {
	const dispatch = useDispatch();

	const currentDashboardResources = useSelector(({ dashboard }) => dashboard.data.resourcePropertiesList);
	const areDashboardResourcesLoading = useSelector(({ dashboard }) => dashboard.loading);

	useEffect(() => {
		eventEmitter.on("RefreshDashboardEvent", () => {
			dispatch(fetchDashboardResourcesThunk());
			dispatch(HIDE_RESOURCE_VERSION_NO());
		});
	}, [dispatch]);

	useEffect(() => {
		return () => {
			dispatch(RESET_DASHBOARD_SLICE());
			dispatch(HIDE_RESOURCE_VERSION_NO());
			dispatch(RESET_DASHBOARD_UP_LOCATION_ID_SLICE());
		};
	}, [dispatch]);

	return (
		<div
			className="resources-page"
			id="dashboard-page"
		>
			<ResourceTableComponent
				resourcesData={currentDashboardResources}
				showGridProgressbar={areDashboardResourcesLoading}
			/>
		</div>
	);
};

export default React.memo(Dashboard);
