import properties from "components/properties/ApplicationProps";

export function getValidatePaymentJson(amount, planId, res, paymentSequenceId, invoiceId, timestamp) {
	console.log(paymentSequenceId);
	let stringifyFetchResource = {
		header: {
			user: sessionStorage.getItem("username"),
			email: sessionStorage.getItem("email"),
			org: sessionStorage.getItem("user_org"),
		},
		action: properties.actions.SEARCH,
		subscriptionKey: planId,
		amount: amount,
		currency: "INR",
		paymentSequenceId: paymentSequenceId,
		orderid: res.razorpay_order_id,
		paymentid: res.razorpay_payment_id,
		signature: res.razorpay_signature,
		status: "SUCCESS",
		invoiceId: invoiceId,
		lastPaymentDate: timestamp,
	};

	return stringifyFetchResource;
}
