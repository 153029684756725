import properties from "components/properties/ApplicationProps";


//request body for fetchOrgDetails
export function getValidateCashfreePaymentJson(paymentservice,orderid,paymentseqid,totalamount,invoiceid) {
	let stringifyFetchOrgDetailsJson = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
        service:paymentservice,
        orderId : orderid,
		invoiceId:invoiceid,
        paymentSeqId : paymentseqid,
        amount: totalamount,
        currency: properties.currency,
        status : properties.status.success

	};

	return stringifyFetchOrgDetailsJson;
}
