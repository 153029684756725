import React, { useEffect } from "react";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid, IconButton, InputAdornment } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { dashboardApi } from "components/misc/DashboardApi";
import Notification from "components/dashboard/notification/Notification";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import FormContainer from "layout/FormContainer";
import { getAllSubscriptionsJson } from "components/requestcontext/FetchAllSubscriptions";
import Select from "react-select";
import { getSearchSubscriptionJson } from "components/requestcontext/SearchSubscriptionPlan";

import { getSubscriptionAccountDetailsJson } from "components/requestcontext/AssignSubscriptionPlan";
import { getAllAccountDetailsJson } from "components/requestcontext/FetchAllAccounts";
import { getUnassignCompositionDetailsJson } from "components/requestcontext/UnAssignSubscription";
import { getAssociatedSubscriptionsJson } from "components/requestcontext/GetAllAssociatedSubscriptionsWithAccount";

function UnassignSubscriptionPlan() {
	const classes = useStyles();
	const history = useHistory();
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [subscriptionName, setSubscriptionName] = useState("");
	const [validity, setSubscriptionValidity] = useState("");
	const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
	const [subscriptions, setSubscriptions] = useState([]);
	const [accountId, setAccountId] = useState("");
	const { fontFamily, themeColor, filter } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState("");
	const [owner, setOwner] = useState("");
	const [orgname, setOrgname] = useState("");
	const [plantype, setPlantype] = useState("");
	const [subscriptionKey, setSubscriptionKey] = useState("");

	//snackbar
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};
	const handleClose = () => {
		history.push("/settings");
	};

	useEffect(() => {
		if (!validity) {
			handleDateChange();
		}
	}, [validity]);
	const handleDateChange = (date) => {
		console.log("inside handle date change fnction");
		let selectedDateLong;
		if (!date) {
			// If no date is selected, set the date to 5 years from now
			var date = new Date(); // Now
			selectedDateLong = date.setFullYear(date.getFullYear() + 5);

			console.log("selectedDateLong :", selectedDateLong);
			setSubscriptionValidity(selectedDateLong);
			return;
		} else {
			console.log("inside else");
			selectedDateLong = date.valueOf();
			// setSelectedDate(date);
		}
		setSubscriptionValidity(selectedDateLong);
	};

	async function UnAssignSubscription() {
		setButtonLoading(() => true);
		console.log("inside AssignSubscription");
		try {
			console.log("inside try");

			// let trimUpdatedAccountName = subscriptionName.trim().replace(/\s{2,}/g, " ");
			let subscriptionDetailsJson = getUnassignCompositionDetailsJson(
				accountId,
				subscriptionName,
				subscriptionKey,
			);
			console.log(subscriptionDetailsJson);
			let UnAssignSubscriptionResponse =
				await dashboardApi.unAssignSubsctiptionToAccount(subscriptionDetailsJson);
			console.log("account details:", UnAssignSubscriptionResponse);
			if (UnAssignSubscriptionResponse.data.footer.msg === "SUCCESS") {
				showSnackbarWithMessage("Subscription plan unassigned successfully");
				setButtonLoading(() => false);
				setOrgname("");
				setOwner("");
				setPlantype("");
				setSelectedSubscriptions("");
				setSelectedUsers("");
			} else {
				showSnackbarWithMessage("Failure while unassigning subscription");
				setButtonLoading(() => false);
				setOrgname("");
				setOwner("");
				setPlantype("");
				setSelectedSubscriptions("");
				setSelectedUsers("");
			}
		} catch (error) {
			setButtonLoading(() => false);
			setOrgname("");
			setOwner("");
			setPlantype("");
			setSelectedSubscriptions("");
			setSelectedUsers("");
		}
		fetchSubscriptionDetails();
	}

	useEffect(() => {
		fetchSubscriptions();
		searchAccount();
	}, []);

	async function fetchSubscriptions() {
		try {
			console.log("accountId", accountId);
			const subscriptionJson = getAssociatedSubscriptionsJson(accountId);
			const response = await dashboardApi.getAllAssociatedSubscriptionsWithAccount(subscriptionJson);
			console.log(response);
			const transformedArray = response.data.subscriptionsList.map((item) => ({
				value: item.subscriptionname,
				label: item.subscriptionname,
			}));
			setSubscriptions(transformedArray);
		} catch (error) {
			console.error("Error fetching subscriptions:", error);
		}
	}

	async function searchAccount(value) {
		try {
			console.log(value);
			let userResourceJson = getAllAccountDetailsJson();
			let searchuserResponse = await dashboardApi.fetchAllAccountDetails(userResourceJson);
			console.log(searchuserResponse);
			if (searchuserResponse?.data?.resourcePropertiesList) {
				const users = searchuserResponse.data.resourcePropertiesList.map((user) => {
					return {
						value: user.fields.accountname,
						label: user.fields.accountname,
						accountId: user.fields.accountid,
						owner: user.fields.username,
						orgname: user.fields.orgname,
						plantype: user.fields.plantype,
					};
				});
				setOptions(users);

				console.log(users);
			} else {
				setOptions([]); // Set no options when no users are found
			}
		} catch (error) {
			console.error("An error occurred while searching for users:", error);
			setOptions([]);
			// setError("An error occurred while searching for users");
		} finally {
			setIsLoading(false);
		}
		// fetchSubscriptions();
	}

	function handleInputChange(value) {
		if (value.trim().length >= 3) {
			setIsLoading(true);
			searchSubscriptions(value);
			searchAccount(value);
		}
	}

	function handleInputSubscription(value) {
		if (value.trim().length >= 3) {
			setIsLoading(true);
			//searchSubscriptions(value);
		}
	}

	async function searchSubscriptions(accountid) {
		try {
			const subscriptionJson = getAssociatedSubscriptionsJson(accountid);
			const response = await dashboardApi.getAllAssociatedSubscriptionsWithAccount(subscriptionJson);
			const transformedArray = response.data.subscriptionsList.map((item) => ({
				value: item.subscriptionname,
				label: item.subscriptionname,
				key: item.subscriptionkey,
			}));
			setSubscriptions(transformedArray);
		} catch (error) {
			console.error("Error searching subscriptions:", error);
			setSubscriptions([]);
		} finally {
			setIsLoading(false);
		}
	}

	async function fetchSubscriptionDetails(subscription) {
		if (!subscription) return;

		setIsLoading(true);

		try {
			let userResourceJson = getSearchSubscriptionJson(subscription);
			let searchSubscriptionResponse = await dashboardApi.fetchSubscriptions(userResourceJson);
			console.log(searchSubscriptionResponse);
			const subscriptionData = searchSubscriptionResponse.data.resourcePropertiesList;

			console.log("subscriptionData", subscriptionData);
			// Populate fields with fetched details
			setSubscriptionName(subscriptionData[0].fields.subscriptionname);
			setSubscriptionKey(subscriptionData[0].fields.subscriptionkey);
		} catch (error) {
			console.error("Error fetching subscription details:", error);
		} finally {
			setIsLoading(false);
		}
	}

	// Function to handle subscription change
	function handleSubscriptionChange(selected) {
		console.log(selected);
		setSelectedSubscriptions(selected);
		fetchSubscriptionDetails(selected.value);
		// setSubscriptionName(selected);
	}

	async function handleChange1(selectedItems) {
		console.log(selectedItems);
		setPlantype(selectedItems.plantype);
		setOwner(selectedItems.owner);
		setOrgname(selectedItems.orgname);
		setAccountId(selectedItems.accountId);
		setSelectedUsers(selectedItems);
		searchSubscriptions(selectedItems.accountId);

		// fetchAttributes(selectedItems);
	}

	function renderAdditionalFields() {
		// Check if there's an account name present
		if (selectedUsers !== "") {
			return (
				<div>
					<TextField
						id={"owner"}
						required
						className="input-field"
						label="Owner"
						variant="outlined"
						size="small"
						value={owner}
						fullWidth
						margin="normal"
						style={{ marginTop: "5px" }}
						InputLabelProps={{
							style: { fontFamily: fontFamily },
						}}
						InputProps={{ style: { fontFamily: fontFamily } }}
					/>
					<TextField
						id={"orgname"}
						required
						className="input-field"
						label="Orgname"
						variant="outlined"
						size="small"
						value={orgname}
						fullWidth
						margin="normal"
						style={{ marginTop: "5px" }}
						InputLabelProps={{
							style: { fontFamily: fontFamily },
						}}
						InputProps={{ style: { fontFamily: fontFamily } }}
					/>
					<TextField
						id={"currentsubscription"}
						required
						className="input-field"
						label="Current Subscription"
						variant="outlined"
						size="small"
						value={plantype}
						fullWidth
						margin="normal"
						style={{ marginTop: "5px" }}
						InputLabelProps={{
							style: { fontFamily: fontFamily },
						}}
						InputProps={{ style: { fontFamily: fontFamily } }}
					/>
				</div>
			);
		}
		return null; // Return null if no account name is present
	}

	return (
		<div
			style={{
				position: "relative",
				filter: filter,
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			{" "}
			<CssBaseline />
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						// Comment out maxWidth to restore previous looks for contextHeader
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Unassign Discount
								</h1>

								<hr />

								<Select
									autoFocus
									options={options}
									value={selectedUsers}
									onChange={handleChange1}
									onInputChange={handleInputChange}
									isLoading={isLoading}
									placeholder="Search for Account"
									inputId="search-assign-account"
									noOptionsMessage={() => "No results found"}
									formatOptionLabel={(option) => (
										<div>
											<div>
												{option.accountId} | {option.label} | {option.owner}
											</div>
										</div>
									)}
									styles={{
										width: "100%",
										control: (provided) => ({
											...provided,
											width: "100%",
											marginTop: "5px",
											marginBottom: "10px",
											minHeight: "36px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>
								{renderAdditionalFields()}
								<Select
									options={subscriptions}
									value={selectedSubscriptions}
									onChange={(e) => handleSubscriptionChange(e, selectedSubscriptions)}
									onInputChange={handleInputSubscription}
									isLoading={isLoading}
									placeholder="Search for Subscription Plans"
									inputId="search-subscription-plans"
									noOptionsMessage={() => "No results found"}
									formatOptionLabel={(option) => (
										<div>
											<div>{option.label}</div>
										</div>
									)}
									styles={{
										width: "100%",
										control: (provided) => ({
											...provided,
											width: "100%",
											marginTop: "5px",
											marginBottom: "10px",
											minHeight: "36px",
											fontFamily: fontFamily,
											zIndex: 999,
										}),
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>

								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										data-button-name="cancel-manage-account"
									>
										Cancel
									</Button>

									{/* {updateAccDetails === true && ( */}
									<Button
										id="createButton"
										onClick={UnAssignSubscription}
										variant="outlined"
										buttonType="main"
										isLoading={buttonLoading}
										data-button-name="unassign-subcription"
									>
										Unassign
									</Button>
									{/* )} */}
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
				messageLength={50}
			/>
		</div>
	);
}

export default UnassignSubscriptionPlan;
