import React, { useContext } from "react";
import useRazorpayAdapter from "./RazorpayAdapter";
import CashfreeAdapter from "./CashfreeAdapter";
import ManualPaymentAdapter from "./ManualPaymentAdapter";
import Button from "components/inputs/Button";
import { neutral, textsize } from "assets/css/MainCss";
import { FontContext } from "components/UI/Theme";
import { startPayment, completePayment, cancelPayment, paymentFailure } from "store/paymentSlice";
import { useDispatch } from "react-redux";

export default function PaymentComponent({ invoiceId, totalAmount, updatePaymentStatus }) {
	const { fontFamily } = useContext(FontContext);
	const dispatch = useDispatch();

	const paymentAdapters = {
		razorpay: new useRazorpayAdapter(),
		cashfree: new CashfreeAdapter({
			mode: "sandbox",
			onSuccess: (response) => {
				console.log("Payment Successful", response);
				updatePaymentStatus(invoiceId, "Paid");
				dispatch(completePayment());
			},
			onCancel: (response) => {
				console.log("Payment Canceled", response);
				dispatch(cancelPayment());
			},
			onFailure: (response) => {
				console.log("Payment Failed", response);
				dispatch(paymentFailure());
			},
		}),
		manual: new ManualPaymentAdapter(),
	};

	const handlePayment = (value) => {
		const amount = totalAmount; // Example amount
		const adapter = paymentAdapters[value];
		dispatch(startPayment());
		adapter.makePayment(amount, {
			orderId: "order_id_generated_by_your_backend",
			invoiceId, // pass the invoiceId here
		});
	};

	return (
		<div>
			{/* <Button
				variant="contained"
				onClick={() => handlePayment("razorpay")}
				style={{
					backgroundColor: neutral.buttoncolor,
					color: neutral.buttontextcolor,
					fontSize: textsize.buttontextsize,
					fontFamily: fontFamily,
					width: "180px",
					height: "35px",
					marginBottom: "20px",
					marginRight: "10px",
				}}
			>
				Pay with Razorpay
			</Button> */}

			<Button
				variant="contained"
				data-cy-contextmenu-header="paywithcashfree"
				onClick={() => handlePayment("cashfree")}
				style={{
					backgroundColor: neutral.buttoncolor,
					color: neutral.buttontextcolor,
					fontSize: textsize.buttontextsize,
					fontFamily: fontFamily,
					width: "180px",
					height: "35px",
					marginBottom: "20px",
					marginRight: "10px",
				}}
			>
				Pay with Cashfree
			</Button>
		</div>
	);
}
