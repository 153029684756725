import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import columnDefinitions from "./columnDefinations";

const IpAddressGrid = ({ selectedIP, removeIpAddress }) => {
	console.log("selectedIP", selectedIP);

	const createRowData = () => {
		if (!selectedIP || !Array.isArray(selectedIP)) {
			return [];
		}
		return selectedIP.map((ip, index) => ({
			ip: ip,
			action: "",
			id: index, // Add an id field to uniquely identify rows
		}));
	};

	return (
		<div
			className="ag-theme-alpine"
			style={{ height: 200, width: 400, marginTop: "10px" }}
		>
			<AgGridReact
				rowData={createRowData()}
				columnDefs={columnDefinitions(removeIpAddress)}
				sortingOrder={["asc", "desc"]}
				rowMultiSelectWithClick
				deltaRowDataMode
				suppressContextMenu
				suppressDragLeaveHidesColumns
				suppressCellFocus
				suppressScrollOnNewData
				preventDefaultOnContextMenu
				rowSelection="multiple"
				onGridReady={(params) => {
					params.api.sizeColumnsToFit();
				}}
				gridOptions={{
					headerHeight: 35,
					rowHeight: 35,
				}}
			/>
		</div>
	);
};

export default IpAddressGrid;
