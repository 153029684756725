import React, { useCallback } from "react";
import { useState, useEffect, useRef } from "react";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { getSearchUserJson } from "components/requestcontext/SearchUser";
import { dashboardApi } from "components/misc/DashboardApi";
import { getAddUsersGroupResourceJson } from "components/requestcontext/AddUsersInGroup";
import { getFetchGroupJson } from "components/requestcontext/FetchGroup";
import { GlobalGroup } from "Constants";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { getAddGroupResourceJson } from "components/requestcontext/AddGroup";
import Notification from "../notification/Notification";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useContext } from "react";
import { FontContext } from "../../UI/Theme";
import FormContainer from "layout/FormContainer";

export default function CreateGroup() {
	const classes = useStyles();
	const history = useHistory();
	const selectRef = useRef();

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [data1, setdata1] = useState([]);
	const [groupname, setgroupname] = useState("");
	const [description, setdescription] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { fontFamily, themeColor } = useContext(FontContext);
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};
	const [buttonLoading, setButtonLoading] = useState(false);

	//create group to share resource to the groups
	async function creategroup(e) {
		setButtonLoading(() => true);

		console.log(groupname, description);
		e.preventDefault();
		try {
			let userResourceJson = getAddGroupResourceJson(groupname, description);
			let addgroupResponse = await dashboardApi.addGroup(userResourceJson);
			const groupinfo = addgroupResponse.data.resourceProperties;
			console.log(groupinfo);
			if (groupinfo !== null) {
				sessionStorage.setItem("groupid", groupinfo.fields.groupid);
				GlobalGroup.setGroupId(groupinfo.fields.groupid);
				console.log(GlobalGroup);
				console.log(addgroupResponse);

				if (addgroupResponse.data.footer.msg === "SUCCESS") {
					console.log("ADD GROUP RESPONSE:", addgroupResponse);
					showSnackbarWithMessage("Group created successfully");

					let addUsersInGroupJson = getAddUsersGroupResourceJson(data1);
					let addUsersInGroupResponse = await dashboardApi.addUsersInGroup(addUsersInGroupJson);
					try {
						if (addUsersInGroupResponse.data === true) {
							console.log("ADD GROUP RESPONSE:", addUsersInGroupResponse);
							showSnackbarWithMessage(groupname + " created successfully");
							GlobalGroup.length = 0;
							setgroupname("");
							setdescription("");
							setSelectedUsers([]);
							setButtonLoading(() => false);
							// history.push("/mygroups");
						} else if (addUsersInGroupResponse.data === false) {
							setgroupname("");
							setdescription("");
							setSelectedUsers([]);
							setButtonLoading(() => false);
							//TODO: showSnackbarWithMessage("users are already present in group"
						}
					} catch (error) {
						console.log("error occured", error);
					}
				}
			} else if (addgroupResponse.data.footer.code === 1) {
				showSnackbarWithMessage("Group already present with given name.");
			}
		} catch (error) {
			showSnackbarWithMessage("An error occurred while Group creating");
			console.error("An error occurred while Group creating:", error);
		}
	}

	const searchUsers = useCallback(async () => {
		const min_search_chars = 3;

		if (searchTerm.length >= min_search_chars) {
			try {
				setIsLoading(true);
				let userResourceJson = getSearchUserJson(searchTerm);
				let searchuserResponse = await dashboardApi.searchAccountUsers(userResourceJson);
				console.log(searchuserResponse.data.resourcePropertiesList);
				const fileItems = searchuserResponse.data.resourcePropertiesList.map((item) => {
					for (var i = 0; i < item.length; i++) {
						var dataa = item[i];
						console.log(dataa);
						//  resourceIds.push(item[i].fields.resourceid); // add resourceid to resourceIds array
					}
					return {
						value: item.fields.email,
						label: `(${item.fields.email})`,
						avatar: item.fields.avatarUrl,
						userid: item.fields.userid,
					};
				});
				setOptions(fileItems);
			} catch (error) {
				console.error("An error occurred while searching for users:", error);
				setOptions([]);
			} finally {
				setIsLoading(false);
			}
		}
	}, [searchTerm]);

	const fetchGroups = useCallback(async () => {
		let fetchGroupJson = getFetchGroupJson(searchTerm);
		let fetchGroupResponse = await dashboardApi.fetchGroups(fetchGroupJson);
		console.log(fetchGroupResponse);
	}, [searchTerm]);

	useEffect(() => {
		if (searchTerm.trim() !== "") {
			searchUsers();
			fetchGroups();
		} else {
			setOptions([]);
		}
	}, [searchTerm, searchUsers, fetchGroups]);

	function handleInputChange(newValue) {
		setSearchTerm(newValue);
	}

	function handleChange(selected) {
		console.log("selected", selected);
		const names = selected.map((item) => item.value);
		console.log(names);
		setdata1(names);
		setSelectedUsers(selected);
	}
	async function createGroupAndAddUsers(e) {
		if (groupname.trim() !== "") {
			if (selectedUsers.length === 0) {
				// alert("Please add users to group");
				showSnackbarWithMessage("Please add users to group");
			} else {
				try {
					await creategroup(e);
					//  addUsersInGroup();
				} catch (error) {
					console.log(error);
				}
			}
		}
	}

	const focusSelect = () => {
		if (selectRef.current) {
			selectRef.current.focus();
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			console.log("do validate");
			document.getElementById("GrpDesc").focus();
			console.log(document.getElementById("selector"));
			// document.getElementById("react-select-3-input").focus();
		}
	};

	const handleKeyDownDesc = (event) => {
		if (event.key === "Enter") {
			console.log("do validate");
			// document.getElementById("GrpDesc").focus();
			focusSelect();
		}
	};

	function handleClose(e) {
		history.push("/settings");
	}

	return (
		<div
			className="create-group"
			style={{
				position: "relative",

				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Create Group
								</h1>

								<hr />

								<div>
									<TextField
										autoFocus
										className="input-field"
										label="Group Name"
										id="groupname"
										variant="outlined"
										size="small"
										value={groupname}
										onChange={(e) => setgroupname(e.target.value)}
										fullWidth
										margin="normal"
										style={{ marginTop: "10px" }}
										onKeyDown={handleKeyDown}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
									/>

									<TextField
										className="input-field GrpDesc"
										id="GrpDesc"
										label="Group Description"
										variant="outlined"
										size="small"
										value={description}
										onChange={(e) => setdescription(e.target.value)}
										fullWidth
										margin="normal"
										style={{ marginTop: "10px" }}
										onKeyDown={handleKeyDownDesc}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
									/>

									<Select
										isMulti
										fullWidth
										className="input-field"
										ref={selectRef}
										id="searchUsersGroup"
										margin="normal"
										options={options}
										value={selectedUsers}
										onChange={handleChange}
										onInputChange={handleInputChange}
										isLoading={isLoading}
										label="Add users to group"
										noOptionsMessage={() => "No results found"}
										styles={{
											menu: (provided) => ({
												...provided,
												//  maxHeight: "max-content",
												// overflowY: "auto",
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
											}),
											menuList: (provided) => ({
												...provided,
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
												//  maxHeight: "max-content",
												// overflowY: "auto",
											}),
											control: (provided) => ({
												...provided,
												marginTop: "10px",
												fontFamily: fontFamily,
											}),
										}}
										formatOptionLabel={(option) => (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													fontFamily: fontFamily,
												}}
											>
												{/* <img src={option.avatar} alt={option.value} width="24" height="24" /> */}
												<div>{option.label}</div>
											</div>
										)}
										onKeyDown={(event) => {
											if (event.key === "Enter" && options.length === 0) {
												createGroupAndAddUsers(event);
											} else if (event.key === "Tab") {
												event.stopPropagation();
											}
										}}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
									/>
								</div>

								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										data-button-name="cancel-group-create"
									>
										Cancel
									</Button>

									<Button
										onClick={createGroupAndAddUsers}
										variant="outlined"
										buttonType="main"
										isLoading={buttonLoading}
										data-button-name="new-group-create"
									>
										Create
									</Button>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
}
