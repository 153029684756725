import { dashboardApi } from "components/misc/DashboardApi";
import properties from "components/properties/ApplicationProps";

//check resource(file/folder) exists or not in current dashboard location
async function checkResourceExistsIndashboard(resourceName, locationid) {
	// Create a promise to wrap the setTimeout function
	const delayPromise = () => new Promise((resolve) => setTimeout(resolve, properties.delayTime));

	try {
		await delayPromise();
		//   console.log("checkContextOfDashboard::", ContextDashboardFileitems)
		const response = await dashboardApi.fetchDashboardResources(locationid);
		const fileItems = response.data.resourcePropertiesList;
		//const contextFileName = ContextDashboardFileitems.find((element) => element.fields.filename === resourceName);
		// console.log("contextFileName", contextFileName)
		const obj = fileItems.find((element) => element.fields.filename === resourceName);
		return obj;
	} catch (error) {
		console.error(error);
	}
}

export const checkResourceExists = {
	checkResourceExistsIndashboard,
};
