export default function addDownloadTrackingProperties({ file, resourceDetails, stopUploading }) {
	Object.defineProperty(file, "fileId", { value: crypto.randomUUID() });
	Object.defineProperty(file, "operationType", { value: "download" });
	Object.defineProperty(file, "name", { value: resourceDetails.filename });
	Object.defineProperty(file, "type", { value: resourceDetails.contenttype });
	Object.defineProperty(file, "size", { value: resourceDetails.size });
	Object.defineProperty(file, "downloadCompletePercentageNumber", { value: 0, writable: true });
	Object.defineProperty(file, "downloadPercentage", { value: 0, writable: true });
	Object.defineProperty(file, "downloadSpeed", { value: 0, writable: true });
	Object.defineProperty(file, "downloadSpeedInKB", { value: 0, writable: true });
	Object.defineProperty(file, "etc", { value: 0, writable: true });
	Object.defineProperty(file, "completionTime", { value: 0, writable: true });
	Object.defineProperty(file, "didItTryToDownload", { value: false, writable: true });
	Object.defineProperty(file, "error", { value: null, writable: true });
	Object.defineProperty(file, "errorMssg", { value: "", writable: true });
	Object.defineProperty(file, "isDownloaded", { value: false, writable: true });
	Object.defineProperty(file, "isDownloading", { value: null, writable: true });
	Object.defineProperty(file, "isDownloadStopped", { value: false, writable: true });
	Object.defineProperty(file, "isOperationComplete", { value: false, writable: true });
	Object.defineProperty(file, "stopDownload", {
		value: () => {
			stopUploading();
		},
	});
}
