import { createSlice } from "@reduxjs/toolkit";

const fileDownloadSlice = createSlice({
	name: "fileDownload",
	initialState: {
		isDownloading: false,
	},
	reducers: {
		START_DOWNLOAD: (state) => {
			state.isDownloading = true;
		},
		END_DOWNLOAD: (state) => {
			state.isDownloading = false;
		},
	},
});

export const { START_DOWNLOAD, END_DOWNLOAD } = fileDownloadSlice.actions;

export default fileDownloadSlice.reducer;
