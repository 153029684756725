import properties from "components/properties/ApplicationProps";
import Utils from "components/misc/Utils";

export function getFetchResourceVersionJson(resourceid) {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),

			clientId: Utils.getUUID(),
		},
		action: properties.actions.SEARCH,
		resourceId: resourceid,
		parentFolderId: properties.parentFolderId,
	};

	return stringifyFetchResource;
}
