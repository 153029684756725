import React from "react";
import TextField from "@mui/material/TextField";

const CustomTextField = ({ id, label, value, onChange, onFocus, onBlur, InputProps, ...props }) => {
	return (
		<TextField
			id={id}
			label={label}
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			InputProps={InputProps}
			{...props}
		/>
	);
};

export default CustomTextField;
