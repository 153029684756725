import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react/dist/package/index.cjs";
// import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";

import AgContextMenu from "./AgContextMenu";
import AgContextHeader from "./AgContextHeader";
import { SAVE_SELECTED_RESOURCES, } from "store/resourceGridSlice"; //prettier-ignore
import { dashboardApi } from "components/misc/DashboardApi";
import "assets/css/Dropzone.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "assets/css/grid.css";
import "./AccountBucket.styles.css";

import _sortBy from "lodash/sortBy";
import columnDefinations from "./columnDefinations";

import { useLocation } from "react-router-dom";

import AgGridFooter from "./AgGridFooter";
// import BreadCrumb from "./BreadCrumb";
import { FontContext } from "components/UI/Theme";
import LinearProgress from "@mui/material/LinearProgress";
import eventEmitter from "components/misc/eventEmitter";
import { isEmpty } from "lodash";
import { gridSelectAllEventsList } from "Constants";
import checkIfUserClickOnCheckBox from "utils/helpers/checkIfUserClickOnCheckBox";

import getBucketCreationJSON from "components/requestcontext/bucketCreationFlow";
import { fetchBucketCreationeRequestsThunk } from "store/bucketCreationSlice";
import { debounce } from "lodash";

const BucketCreationComponent = ({ resourcesData, showGridProgressbar }) => {
	const gridRef = useRef();
	const mapOriginalRowNodeToRowIdRef = useRef(null);

	const resourceContainerRef = useRef();
	const gridContainerRef = useRef();
	const gridProgressbarRef = useRef();

	const currentPageRoute = useLocation().pathname;
	const dispatch = useDispatch();
	const { fontFamily, smallTextSize } = useContext(FontContext);

	const rowData = useMemo(() => {
		// console.log("RESOURCE DATA", resourcesData);
		const resources = resourcesData.map(({ fields }) => fields);

		return _sortBy(resources, (resource) => !resource.isfolder);
	}, [resourcesData]);

	// console.log("DATA", JSON.stringify(resourcesData));

	const [colDefs, setColDefs] = useState(columnDefinations);
	const [showContextMenu, setShowContextMenu] = useState(null);

	const [isGridReady, setIsGridReady] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { selectedResources, showVersionsColumn } = useSelector(({ resourceGrid }) => resourceGrid);

	const gridContainerEl = document.querySelector(".grid-container");
	const gridProgressbarEl = document.querySelector("#grid-progressbar");
	const noRowOverlayEl = document.querySelector("#norow-overlay");
	const dashboardDropContainerEl = document.querySelector(".dashboard-drop-container");
	// const dashboardDropEl = document.querySelector(".dashboard-drop");
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	const getSelectedRowsNode = useCallback(() => gridRef.current?.api?.selectionService.getSelectedNodes(), []);
	const getSelectedRowsData = useCallback(() => gridRef.current?.api?.selectionService.getSelectedRows(), []);
	const getRenderedNodes = useCallback(() => gridRef.current?.api?.getRenderedNodes(), []);
	const getAllRowsNode = useCallback(() => gridRef.current?.api?.rowModel.rowsToDisplay, []);
	const updateGridOptions = useCallback((options) => gridRef.current?.api.updateGridOptions({ ...options }), []);
	const getGridOption = useCallback((option) => gridRef.current.api.getGridOption(option), []);
	const deselectAllRows = useCallback(() => gridRef.current?.api?.deselectAll(), []);

	const handleRemoveAllFilters = useCallback(() => {
		gridRef.current?.api.setFilterModel(null);
		gridRef.current?.api.setGridOption("quickFilterText", "");
	}, []);

	const handleDetailsAction = useCallback(() => {
		// setShowDetailsDialog(() => true);
	}, []);

	const handleRefresh = debounce(() => {
		fetchTransactions();
	}, 300);

	const handleCloseContextMenu = () => {
		setShowContextMenu(() => null);
	};

	const contextActionsList = useMemo(
		() => ({
			refresh: handleRefresh,

			removeAllFilters: handleRemoveAllFilters,

			details: handleDetailsAction,
		}),

		[handleRemoveAllFilters, handleDetailsAction, handleRefresh],
	);

	const mapAllRowsToTheirId = useCallback((rowsNode) => {
		const mappedObject = {};

		for (const rowNode of rowsNode) {
			Object.defineProperty(mappedObject, `${rowNode.id}`, { value: rowNode });
		}

		return mappedObject;
	}, []);

	const handleRowsSelection = useCallback(
		(event) => {
			const nativeEvent = event.event || event;
			const isUserClickedOnCheckbox = checkIfUserClickOnCheckBox(nativeEvent);
			const rowId = nativeEvent.target.getAttribute("row-id");
			const rowNode = mapOriginalRowNodeToRowIdRef.current && mapOriginalRowNodeToRowIdRef.current[rowId];
			const isThisRowSelected = rowNode?.selected;
			const areThereAnyOtherPrevSelectedRows = !isEmpty(getSelectedRowsNode());

			// enable multiselection if user click's on checkbox
			if (isUserClickedOnCheckbox) {
				updateGridOptions({ rowMultiSelectWithClick: true });
			}

			// disable multiselection if user deselects all rows
			if (
				event.type === "rowClicked" &&
				isEmpty(getSelectedRowsNode()) &&
				getGridOption("rowMultiSelectWithClick")
			) {
				updateGridOptions({ rowMultiSelectWithClick: false });
			}

			// handle selection by mouse's right click
			if (nativeEvent.type === "contextmenu") {
				if (isThisRowSelected === false && areThereAnyOtherPrevSelectedRows) {
					/* -------------- 🔴 Warning: Don't re-arrage following code 🔴 ------------- */
					deselectAllRows();
					updateGridOptions({ rowMultiSelectWithClick: false });
					rowNode.setSelected(true);
				} else if (isThisRowSelected === false && areThereAnyOtherPrevSelectedRows === false) {
					rowNode.setSelected(true);
				}
			}
		},
		[getSelectedRowsNode, updateGridOptions, getGridOption, deselectAllRows],
	);

	const addRequiredEventToRows = useCallback(() => {
		try {
			const renderedRowNodes = getRenderedNodes();

			renderedRowNodes.forEach((node) => {
				const rowNativeEl = document.querySelector(`div[row-index='${node.rowIndex}']`);
				rowNativeEl.dataset.rowType = "resource";

				rowNativeEl.removeEventListener("click", () => {});
				rowNativeEl.addEventListener("click", handleRowsSelection);
			});
		} catch (error) {
			console.error("Unableto set required row event", error);
		}
	}, [currentPageRoute, getRenderedNodes, handleRowsSelection]);

	useEffect(() => {
		if (!gridContainerEl) return;

		if (smallTextSize === 12) {
			gridContainerEl.style.fontSize = "unset";
		} else {
			gridContainerEl.style.fontSize = "medium";
		}
	}, [smallTextSize, gridContainerEl]);

	/**
	 *	Don't merge with noRowOverlayEl useEffect
	 */
	useEffect(() => {
		if (!gridProgressbarEl) return;

		if (showGridProgressbar) {
			gridProgressbarEl.style.display = "block";
		} else {
			gridProgressbarEl.style.display = "none";
		}
	}, [showGridProgressbar, gridProgressbarEl, noRowOverlayEl]);

	/**
	 *	Don't merge with gridProgressbarEl useEffect
	 */
	useEffect(() => {
		if (!noRowOverlayEl) return;

		if (showGridProgressbar) {
			noRowOverlayEl.textContent = "Loading...";
			noRowOverlayEl.classList.add("animate__animated", "animate__flash", "animate__infinite", "animate__slow");
		} else {
			noRowOverlayEl.textContent = "No Records Found!";
			noRowOverlayEl.classList.remove( "animate__animated", "animate__flash", "animate__infinite", "animate__slow"); // prettier-ignore
		}
	}, [showGridProgressbar, noRowOverlayEl]);

	useEffect(() => {
		if (!showVersionsColumn) {
			setColDefs(() => columnDefinations);
		}
	}, [showVersionsColumn]);

	useEffect(() => {
		document.addEventListener("keydown", (event) => {
			if (event.key === "Escape" && dashboardDropContainerEl) {
				console.log("Escape is pressed!");
				dashboardDropContainerEl.style.display = "none";
			}
		});

		return () => document.removeEventListener("keydown", () => {});
	}, [dashboardDropContainerEl]);

	useEffect(() => {
		eventEmitter.on("resetGridFilters", () => {
			gridRef.current?.api.setFilterModel(null);
			gridRef.current?.api.setGridOption("quickFilterText", "");
		});

		eventEmitter.on("resetGridSelectionService", () => {
			updateGridOptions({ rowMultiSelectWithClick: false });
		});
	}, [updateGridOptions, dispatch]);

	const fetchTransactions = useCallback(() => {
		dispatch(fetchBucketCreationeRequestsThunk());
	}, [dispatch]);

	const handleSubmit = useCallback(async () => {
		if (selectedResources.length === 0) return; // No resources selected

		setIsSubmitting(true);
		try {
			// Iterate through selected resources and send requests
			for (const resource of selectedResources) {
				// console.log("RESOURCE", resource, "length", selectedResources.length);
				const requestData = getBucketCreationJSON(resource);
				const response = await dashboardApi.bucketCreationRequest(requestData);
				if (response.data.footer.msg === "SUCCESS") {
					// Emit event for successful submission
					// eventEmitter.emit("RefreshBucketTransactionGrid");
					showSnackbarWithMessage(response.data.footer.msg);
				} else if (response.data.footer.msg === "FAILURE") {
					showSnackbarWithMessage("Something went wrong!");
				} else {
					showSnackbarWithMessage("Something went wrong!");
				}
			}
			console.log("All requests have been successfully submitted!");
		} catch (error) {
			console.error("An error occurred while submitting requests:", error);
		} finally {
			// eventEmitter.emit("RefreshBucketTransactionGrid");
			fetchTransactions();
			setIsSubmitting(false);
		}
	}, [selectedResources]);

	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			ref={gridContainerRef}
			className="grid-container"
			style={{ fontFamily }}
		>
			<header>
				{/* <BreadCrumb style={{ fontFamily: "inherit", fontSize: "inherit" }} /> */}
				<AgContextHeader
					contextActions={contextActionsList}
					gridApi={gridRef}
				/>
			</header>

			<main
				ref={resourceContainerRef}
				id="resource-container"
				data-cy="dndzone"
				className="ag-theme-quartz"
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				onContextMenu={(event) => {
					handleRowsSelection(event);
					setShowContextMenu(() => ({ mouseX: event.clientX, mouseY: event.clientY }));
				}}
				// {...getRootProps()}
			>
				<div
					ref={gridProgressbarRef}
					id="grid-progressbar"
					style={{
						display: "none",
						position: "absolute",
						zIndex: 1,
						width: "100%",
						top: "33px",
					}}
				>
					<LinearProgress />
				</div>

				<AgGridReact
					sortingOrder={["asc", "desc"]}
					deltaRowDataMode
					suppressContextMenu
					suppressDragLeaveHidesColumns
					suppressCellFocus
					suppressScrollOnNewData
					preventDefaultOnContextMenu
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					ref={gridRef}
					columnDefs={colDefs}
					rowData={rowData}
					rowSelection="multiple"
					overlayNoRowsTemplate="<span id='norow-overlay'>No Records Found!</span>"
					getRowId={(params) => `${params.data.accountid}-${params.data.tranid}`}
					onGridReady={() => setIsGridReady(() => true)}
					gridOptions={{
						headerHeight: 35,
						rowHeight: 35,
						onModelUpdated: (gridEvent) => {
							gridEvent.api.getDisplayedRowCount() === 0
								? gridEvent.api.showNoRowsOverlay()
								: gridEvent.api.hideOverlay();

							addRequiredEventToRows();
							mapOriginalRowNodeToRowIdRef.current = mapAllRowsToTheirId(getAllRowsNode());
						},
						onRowClicked: handleRowsSelection,
						onRowDoubleClicked: (gridEvent) => {
							const resourceDetails = gridEvent.data;
							if (resourceDetails.isfolder) {
								// handleRowDoubleClick(resourceDetails);
								updateGridOptions({ rowMultiSelectWithClick: false });
							}
						},
						onSelectionChanged: (gridEvent) => {
							const selectedRows = getSelectedRowsData();
							dispatch(SAVE_SELECTED_RESOURCES(selectedRows));

							const isUserDeselectsAllRows =
								gridSelectAllEventsList.includes(gridEvent.source) && !getSelectedRowsNode().length;

							if (isUserDeselectsAllRows) {
								updateGridOptions({ rowMultiSelectWithClick: false });
							}
						},
						postSortRows: (params) => {
							const allRowNodes = params.nodes;
							let folderPosition = 0;

							// here we will keep folders always on top
							allRowNodes.forEach((rowNode, index) => {
								const isItFolder = rowNode.data ? rowNode.data.isfolder : undefined;

								if (isItFolder === true) {
									allRowNodes.splice(folderPosition, 0, allRowNodes.splice(index, 1)[0]);
									folderPosition++;
								}
							});
						},
						onRowDataUpdated: addRequiredEventToRows,
						onBodyScroll: addRequiredEventToRows,
						// onSortChanged: addRequiredEventToRows,
						// onFilterChanged: addRequiredEventToRows,
					}}
				/>
				<div
					className="submit-button-container"
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<button
						className={`submit-button ${selectedResources.length > 0 || isHovered ? "visible" : ""}`}
						onClick={handleSubmit}
						disabled={isSubmitting || selectedResources.length === 0}
					>
						{isSubmitting ? "Submitting..." : "Submit"}
					</button>
				</div>
			</main>

			<AgGridFooter
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				// rowData={rowData}
				selectedResources={selectedResources}
			/>

			<AgContextMenu
				contextMenu={showContextMenu}
				handleContextMenuClose={handleCloseContextMenu}
				contextActions={contextActionsList}
			/>
		</div>
	);
};

export default memo(BucketCreationComponent);
