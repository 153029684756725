import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "react-router-dom";

/*-----------Layout-----------*/

import CheckStatus from "routes/CheckStatus";
import useStyles from "routes/Routes";
import ProtectedRoute from "routes/ProtechtedRoute";
import classnames from "classnames";
import { useLayoutState } from "routes/LayoutContext";

/*---------components...........*/

import Header from "components/dashboard/header/Header";
import Sidebar from "components/dashboard/sidenav/Sidebar";
import Dashboard from "pages/Dashboard/DashboardPage";
import RegisterForm from "components/registrationform/RegisterForm";
import NewFolderDialog from "components/dashboard/NewFolderDialog";
import FileUpload from "components/dashboard/FileUpload";
import Settings from "components/settings/Settings";
import Account from "components/accounts/Account";
import ShareWithMe from "pages/ShareWithMe/ShareWithMe";
import FolderSelector from "components/fileService/FolderSelector";
import FileSelector from "components/fileService/FileSelector";
import CreateGroup from "components/dashboard/groupactions/CreateGroup";
// import FetchGroups from "components/dashboard/groupactions/FetchGroups";
import OwnershipRequest from "components/accounts/OwnershipRequest";
import ContactSupport from "components/message/ContactSupport";
import LinkAccount from "components/dashboard/linkAccounts/LinkAccount";
import Payment from "components/accounts/Payment";
import UpdateAttribute from "components/settings/UpdateAttribute";
import OrgOnboarding from "components/dashboard/orgOnboard/OrgOnboarding";
import UpdateGroup from "components/dashboard/groupactions/UpdateGroup";
import AddNewSubscriptionPlan from "components/subscription/AddNewSubscriptionPlan";

/*---------pages-----------*/

import DMScreen from "pages/DMScreen";
import LoginPage from "pages/Login";
import SignUpInvite from "pages/SignUpInvite";
import WelcomeModal from "pages/Login/WelcomeModal";
import Notifications from "pages/Notifications";
import DataUsage from "pages/DataUsage";
import UserOnboarding from "components/registrationform/UserOnboard";
import SearchPage from "pages/SearchPage";
import UpdateSubscriptionPlan from "components/subscription/updateExistedSubscription";
import AssignSubscriptionPlan from "components/subscription/AssignSubscription";
import GenerateInvoice from "components/settings/InvoiceGenerator";
import UnassignSubscriptionPlan from "components/subscription/UnassignSubscription";
import Notification from "components/dashboard/notification/Notification";
import { useSelector } from "react-redux";
import DisplayDataUsageReport from "components/dataUsage/DisplayDataUsageReport/DisplayDataUsageReport";
import FetchGroups from "components/dashboard/groupactions/FetchGroups";
import GenerateDataUsageReport from "components/dataUsage/DataUsageReport";
import ChangeSubscriptionPlan from "components/subscription/changeSubscription/ChangeSubscription";
import UnloadWarning from "components/windowcloseconfirmation/UnloadWarning";
import DNSForm from "components/domainManagement/DNSForm";
import BucketCreation from "pages/Account-BucketCreation/BucketCreation";
import SetupInProgress from "components/dashboard/orgOnboard/gridIpAddress/onboardingProgress/SetupInProgress";
import OrgOnboardForm from "components/dashboard/orgOnboard/onboardOrg";

function Routes(props) {
	const { userRole, checkRealm, isAuthenticated } = props;
	const classes = useStyles();
	const layoutState = useLayoutState();
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { gridOperationsError } = useSelector(({ ui }) => ui);

	const showSnackbarWithMessage = useCallback((message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	}, []);

	useEffect(() => {
		if (gridOperationsError) {
			showSnackbarWithMessage(gridOperationsError);
		}
	}, [gridOperationsError, showSnackbarWithMessage]);

	return (
		<Router>
			<UnloadWarning />
			<Switch>
				{checkRealm === "yes" ? (
					<div className={classes.root}>
						<>
							{isAuthenticated ? (
								<>
									<Header history={props.history} />
									<Sidebar />
								</>
							) : null}

							<div
								className={classnames(classes.content, {
									[classes.contentShift]: layoutState.isSidebarOpened,
								})}
							>
								<div className={classes.fakeToolbar} />
								<FileUpload />
								<Switch>
									<ProtectedRoute
										path="/dashboard"
										feature="dashboard"
										userRole={userRole}
										component={Dashboard}
									/>
									<ProtectedRoute
										path="/search"
										userRole={userRole}
										feature="search"
										component={SearchPage}
									/>
									<ProtectedRoute
										path="/shared-with-me"
										userRole={userRole}
										feature="sharewithme"
										component={ShareWithMe}
									/>
									<ProtectedRoute
										path="/typography"
										userRole={userRole}
										feature="sharewithme"
										component={ShareWithMe}
									/>
									<ProtectedRoute
										path="/createFolder"
										userRole={userRole}
										feature="createFolder"
										component={NewFolderDialog}
									/>
									<ProtectedRoute
										path="/settings"
										userRole={userRole}
										feature="settings"
										component={Settings}
										// component={RenderedCards3}
									/>

									{/* <ProtectedRoute
										path="/account"
										userRole={userRole}
										feature="account"
										component={Account}
									/> */}

									<ProtectedRoute
										path="/creategroups"
										userRole={userRole}
										feature="creategroup"
										component={CreateGroup}
									/>
									<ProtectedRoute
										path="/mygroups"
										userRole={userRole}
										feature="mygroups"
										component={FetchGroups}
									/>
									<ProtectedRoute
										path="/updategroup"
										userRole={userRole}
										feature="updategroup"
										component={UpdateGroup}
									/>
									<ProtectedRoute
										path="/linkaccount"
										userRole={userRole}
										feature="linkaccount"
										component={LinkAccount}
									/>
									<ProtectedRoute
										path="/payment"
										userRole={userRole}
										feature="payment"
										component={Payment}
									/>
									<ProtectedRoute
										path="/accessdenied"
										userRole={userRole}
										component={CheckStatus}
									/>
									<ProtectedRoute
										path="/changeownership"
										userRole={userRole}
										feature="changeownership"
										component={OwnershipRequest}
									/>
									<ProtectedRoute
										path="/updateorg"
										userRole={userRole}
										feature="updateorg"
										// component={OrgOnboarding}
										component={OrgOnboardForm}
									/>
									<ProtectedRoute
										path="/addsubscription"
										userRole={userRole}
										feature="addsubscription"
										component={AddNewSubscriptionPlan}
									/>
									<ProtectedRoute
										path="/updatesubscription"
										userRole={userRole}
										feature="updatesubscription"
										component={UpdateSubscriptionPlan}
									/>
									<ProtectedRoute
										path="/assignsubscriptionplan"
										userRole={userRole}
										feature="assignsubscriptionplan"
										component={AssignSubscriptionPlan}
									/>
									<ProtectedRoute
										path="/unassigncomposition"
										userRole={userRole}
										feature="unassigncomposition"
										component={UnassignSubscriptionPlan}
									/>
									<ProtectedRoute
										path="/changesubscription"
										userRole={userRole}
										feature="changesubscription"
										component={ChangeSubscriptionPlan}
									/>
									<ProtectedRoute
										path="/datamaintenance"
										userRole={userRole}
										feature="datamaintenance"
										component={DMScreen}
									/>
									<ProtectedRoute
										path="/generateinvoice"
										userRole={userRole}
										feature="generateinvoice"
										component={GenerateInvoice}
									/>
									<ProtectedRoute
										path="/notifications"
										userRole={userRole}
										feature="notifications"
										component={Notifications}
									/>
									<ProtectedRoute
										path="/welcometodf"
										userRole={userRole}
										feature="welcomemodal"
										component={WelcomeModal}
									/>
									<ProtectedRoute
										path="/updateatrribute"
										userRole={userRole}
										feature="updateatrribute"
										component={UpdateAttribute}
									/>
									<ProtectedRoute
										path="/datausage"
										userRole={userRole}
										feature="datausage"
										component={DataUsage}
									/>
									<ProtectedRoute
										path="/generatedatausage"
										userRole={userRole}
										feature="generatedatausage"
										component={GenerateDataUsageReport}
									/>
									<ProtectedRoute
										path="/displaydatausagereport"
										userRole={userRole}
										feature="displaydatausagereport"
										component={DisplayDataUsageReport}
									/>
									<ProtectedRoute
										path="/dnsmanagement"
										userRole={userRole}
										feature="dnsmanagement"
										component={DNSForm}
									/>

									<ProtectedRoute
										path="/accountbucketcreation"
										userRole={userRole}
										feature="accountbucketcreation"
										component={BucketCreation}
									/>
									<ProtectedRoute
										path="/setupinprogress"
										userRole={userRole}
										feature="setupinprogress"
										component={SetupInProgress}
									/>

									<Route
										path="/"
										component={LoginPage}
									/>
									<Route
										path="/signup-invite"
										component={SignUpInvite}
									/>
								</Switch>
								<FolderSelector />
								<FileSelector />

								<Notification
									showSnackbar={showSnackbar}
									snackbarMessage={snackbarMessage}
									setShowSnackbar={setShowSnackbar}
								/>
							</div>
						</>
					</div>
				) : checkRealm === "no" ? (
					<>
						<UserOnboarding />
					</>
				) : checkRealm === "unknown" ? (
					<>
						<Route
							path="/support"
							component={ContactSupport}
						/>
						{/* <Redirect to="/support" /> */}
					</>
				) : null}
			</Switch>
		</Router>
	);
}

export default Routes;
