import properties from "components/properties/ApplicationProps";

export function generateDataUsageJSON({ accountId, isTypeBilling, startDate, endDate }) {
	return {
		header: {
			email: sessionStorage.getItem("email"),
			org: properties.orgProperties.orgName,
		},
		accountId,
		isTypeBilling,
		startDate,
		endDate,
	};
}
