import moment from "moment-timezone";
import getCurrencySymbol from "./getCurrencySymbol";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const columnDefinations = (handleRowClick) => [
	{
		headerName: "Invoice ID",
		field: "fields.invoiceid",
		sortable: true,
		filter: true,
		editable: false,
	},
	{
		headerName: "Invoice Date",
		field: "fields.invoicegeneratedon",
		sortable: true,
		filter: true,
		editable: false,
		valueFormatter: (params) => moment.tz(params.value, timeZone).format("DD/MM/YYYY"),
	},
	{
		headerName: "Due Date",
		field: "fields.duedate",
		sortable: true,
		filter: true,
		editable: false,
		valueFormatter: (params) => moment.tz(params.value, timeZone).format("DD/MM/YYYY"),
		cellStyle: (params) => {
			const dueDate = moment.tz(params.value, timeZone);
			const isPastDue = dueDate.isBefore(moment.tz(timeZone), "day");
			return isPastDue && params.data.fields.paymentstatus === "unpaid" ? { color: "black" } : {};
		},
	},
	{
		headerName: "Amount",
		field: "fields.totalamount",
		sortable: true,
		filter: true,
		editable: false,
		valueFormatter: (params) =>
			getCurrencySymbol(params.data.fields.subscriptionchargetype) +
			(params.data.fields.totalamount / 100).toFixed(2),
	},
	{
		headerName: "Status",
		field: "fields.paymentstatus",
		sortable: true,
		filter: true,
		editable: false,
		cellStyle: (params) => {
			if (params.data.fields.paymentstatus === "unpaid") {
				// return { color: "red" };
			} else {
				// return { color: "green" };
			}
		},
	},
	{
		headerName: "Action",
		field: "fields.invoiceid",
		cellRenderer: (params) => (
			<>
				<a
					href="#"
					onClick={() => handleRowClick(params.data)}
					style={{
						...(params.data.fields.paymentstatus === "unpaid" &&
						moment.tz(params.data.fields.duedate, timeZone).isBefore(moment.tz(timeZone), "day")
							? { color: "red" }
							: {}),
						fontWeight: "bold",
						cursor: "pointer",
					}}
				>
					{params.data.fields.paymentstatus === "paid" ? `View` : `Pay`}
				</a>
			</>
		),
	},
];

export default columnDefinations;
