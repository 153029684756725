import { memo, useEffect } from "react";
import "./BreadCrumb.styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
	HANDLE_DASHBOARD_BREADCRUMB_CLICK,
	HANDLE_SEARCH_BREADCRUMB_CLICK,
	HANDLE_SHARED_WITH_ME_BREADCRUMB_CLICK,
} from "store/gridNavigationSlice";
import { useLocation } from "react-router-dom";
import { HIDE_VERSIONS_COLUMN } from "store/resourceGridSlice";
import eventEmitter from "components/misc/eventEmitter";

const BreadCrumb = () => {
	const dispatch = useDispatch();
	const currentPageRoute = useLocation().pathname;
	const breadCrumbFolderLocations = useSelector(({ gridNavigation }) => {
		if (currentPageRoute.includes("dashboard")) {
			return gridNavigation.dashboardUpLocationId;
		}

		if (currentPageRoute.includes("search")) {
			return gridNavigation.searchUpLocationId;
		}

		if (currentPageRoute.includes("shared-with-me")) {
			return gridNavigation.sharedWithMeUpLocationId;
		}
	});

	const breadCrumbEl = document.querySelector(".breadcrumb-container .folders-wrapper");

	useEffect(() => {
		if (breadCrumbEl) {
			breadCrumbEl.scrollLeft = breadCrumbEl.scrollWidth;
		}
	}, [breadCrumbFolderLocations, breadCrumbEl]);

	return (
		<div className="breadcrumb-container">
			<div className="folders-wrapper">
				{breadCrumbFolderLocations.map((obj, index) => {
					return (
						<>
							<span
								className="breadcrumb-folder-name"
								style={{
									backgroundColor: `${
										index === breadCrumbFolderLocations.length - 1 ? "#c8e6f0" : "#fff"
									}`,
								}}
								onClick={() => {
									if (currentPageRoute.includes("dashboard")) {
										dispatch(HANDLE_DASHBOARD_BREADCRUMB_CLICK(index));
									}

									if (currentPageRoute.includes("search")) {
										dispatch(HANDLE_SEARCH_BREADCRUMB_CLICK(index));
									}

									if (currentPageRoute.includes("shared-with-me")) {
										dispatch(HANDLE_SHARED_WITH_ME_BREADCRUMB_CLICK(index));
									}

									dispatch(HIDE_VERSIONS_COLUMN());
									eventEmitter.emit("resetGridFilters");
									eventEmitter.emit("resetGridSelectionService");
								}}
							>
								{obj.name === '/' ? 'Dashboard' : obj.name}
							</span>
							<span className="breadcrumb-folder-separator">/</span>
						</>
					);
				})}
			</div>
		</div>
	);
};

export default memo(BreadCrumb);
