import { dashboardApi } from "components/misc/DashboardApi";
import properties from "components/properties/ApplicationProps";
import { getValidateCashfreePaymentJson } from "components/requestcontext/CashfreePaymentJson";
import { getInvoiceOrderJson } from "components/requestcontext/GetInvoiceOrder";
import { getInvoiceDetailsJson } from "components/requestcontext/InvoiceDetails";
import { getPaymentOrderJson } from "components/requestcontext/PaymentOrder";
import { getValidatePaymentJson } from "components/requestcontext/ValidatePayment";
import React, { Component } from "react";

class RazorpayPayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: 100, // Amount in paise (or the smallest unit of your currency)
			isLoaded: false,
			scriptLoaded: false,
			showInvoice: false,
			showSnackbar: false,
			snackbarMessage: "",
		};
		this.service = "razorpay";
	}

	componentDidMount() {
		this.fetchData();
	}

	showSnackbarWithMessage = (message) => {
		this.setState({ showSnackbar: true, snackbarMessage: message });
	};

	fetchData = async () => {
		try {
			let fetchInvoiceDetailsJson = getInvoiceDetailsJson();
			const invoiceDetailsResponse = await dashboardApi.fetchInvoice(fetchInvoiceDetailsJson);
			console.log("RESOURCE RESPONSE::", invoiceDetailsResponse.data.resourcePropertiesList);
			if (invoiceDetailsResponse.data.resourcePropertiesList.length === 0) {
				this.setState({ showInvoice: true });
			} else {
				this.setState({
					invoiceDetails: invoiceDetailsResponse.data.resourcePropertiesList,
					totalAmount: invoiceDetailsResponse.data.resourcePropertiesList[0].fields.totalamount,
					invoiceId: invoiceDetailsResponse.data.resourcePropertiesList[0].fields.invoiceid,
					paymentstatus: invoiceDetailsResponse.data.resourcePropertiesList[0].fields.paymentstatus,
				});

				const response = await dashboardApi.getAllPlans();
				console.log("RESOURCE RESPONSE::", response.data);

				for (let i = 0; i < response.data.length; i++) {
					if (response.data[i].subscriptionname) {
						let data = [response.data[i]];
						console.log(data);
						this.setState({ resource: data, scriptLoaded: true });
					}
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	makePayment = async (amount, option) => {
		console.log("options:", option);
		try {
			let fetchInvoiceDetailsJson = getInvoiceDetailsJson();
			const invoiceDetailsResponse = await dashboardApi.fetchInvoice(fetchInvoiceDetailsJson);
			console.log("RESOURCE RESPONSE::", invoiceDetailsResponse.data.resourcePropertiesList);
			if (invoiceDetailsResponse.data.resourcePropertiesList.length === 0) {
				this.setState({ showInvoice: true });
			} else {
				//  this.setState({
				// var invoiceDetails = invoiceDetailsResponse.data.resourcePropertiesList;
				var totalAmount = invoiceDetailsResponse.data.resourcePropertiesList[0].fields.totalamount;
				var invoiceId =
					option.invoiceId || invoiceDetailsResponse.data.resourcePropertiesList[0].fields.invoiceid;
				var paymentstatus = invoiceDetailsResponse.data.resourcePropertiesList[0].fields.paymentstatus;
				//  });

				const response = await dashboardApi.getAllPlans();
				console.log("RESOURCE RESPONSE::", response.data);

				for (let i = 0; i < response.data.length; i++) {
					if (response.data[i].subscriptionname) {
						let data = [response.data[i]];
						console.log(data);
						var resource = data;
						//   this.setState({ resource: data, scriptLoaded: true });
					}
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}

		console.log(this.planKey);
		const selectedPlan = resource.find((plan) => plan.subscriptionkey);
		console.log(selectedPlan);

		// if (this.scriptLoaded ) {
		let fetchPaymentOrderJson = getInvoiceOrderJson(totalAmount, this.service, invoiceId);
		const response = await dashboardApi.getPaymentOrder(fetchPaymentOrderJson);

		console.log("RESOURCE RESPONSE::", response.data, response.data.orderId, response.data.paymentSequenceId);
		let id = response.data.paymentSequenceId;

		const options = {
			key: "rzp_test_qL8segGG1GHsae",
			amount: totalAmount,
			currency: response.data.currency,
			name: selectedPlan.subscriptionname.charAt(0).toUpperCase() + selectedPlan.subscriptionname.slice(1),
			description: "Test Transaction",
			accept_partial: false,
			order_id: response.data.orderId,
			image: properties.orgProperties.orgIcon,

			method: {
				upi: false,
			},
			handler: async (res) => {
				console.log(res);
				var today = new Date();
				var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
				var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
				var dateTime = date + " " + time;
				var timestamp = new Date(dateTime).getTime();
				console.log(timestamp);
				// var successUrl = document.getElementById("payment-form").elements["success_url"].value;
				// window.location.href = successUrl;
				//alert(res.razorpay_payment_id);
				//alert(res.razorpay_order_id);
				//	alert(res.razorpay_signature);
				// this.showSnackbarWithMessage("Payment successful");
				if (res.razorpay_payment_id) {
					// Check for successful payment
					console.log("Payment successful");
					let fetchPaymentOrderJson1 = getValidateCashfreePaymentJson(
						this.service,
						response.data.orderId,
						response.data.paymentSeqId,
						totalAmount,
						invoiceId,
					);
					const responseValidatePayment = dashboardApi.validatePayment(fetchPaymentOrderJson1);
					console.log("RESOURCE RESPONSE::", responseValidatePayment);

					//fetchData();
					// setShowSnackbar(false);
				}
			},
			prefill: {
				name: "John Doe",
				email: "john@example.com",
				contact: "1234567890",
				upi: "success@razorpay", //  Use a valid UPI ID here
			},
			notes: {
				address: "Razorpay Corporate Office",
			},
			theme: {
				color: "#3399cc",
			},
			notify: {
				sms: true,
				email: true,
			},
		};
		console.log(options);
		var rzpay = new window.Razorpay(options);
		rzpay.open();
	};
	//};

	render() {
		return (
			<></>
			// <div>
			//     <button onClick={this.loadRazorpay}>Pay with Razorpay</button>
			// </div>
		);
	}
}

export default RazorpayPayment;
