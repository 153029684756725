export function getChangeOwnershipJson(email) {
	let stringifyFetchResource = {
		header: {
			user: sessionStorage.getItem("username"),
			email: sessionStorage.getItem("email"),
			org: sessionStorage.getItem("user_org"),
		},
		action: "CHANGE_OWNERSHIP",
		oldUserName: email,
		makeUserInActive: true,
	};

	return stringifyFetchResource;
}
