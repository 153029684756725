import React, { useContext } from "react";
import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { dashboardApi } from "components/misc/DashboardApi";
import useStyles from "routes/TableStyles";
import { CssBaseline, debounce, Grid } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Select from "react-select";

import { neutral, textsize } from "assets/css/MainCss";
import Notification from "components/dashboard/notification/Notification";
import { FontContext } from "components/UI/Theme";
import { getUpdateAttributeJson } from "components/requestcontext/UpdateAttribute";
import { getSearchUserJson } from "components/requestcontext/SearchUser";
import { getFetchAttributesJson } from "components/requestcontext/FetchAttributes";
import properties from "components/properties/ApplicationProps";
import { GlobalUsername } from "Constants";
import FormContainer from "layout/FormContainer";
export default function OrgOnboarding() {
	//	console.log("extractedOrgName", extractedOrgName);
	const { themeColor, largeTextSize, fontFamily } = useContext(FontContext);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const classes = useStyles();
	const [fields, setFields] = useState([{ attributename: "", attributevalue: "" }]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const readOnlyFields = Object.values(properties.readOnlyFields); // include attribute names in properties file which you want to display in readonly format

	const initialData = {
		db_onboarding: [""],
		kc_onboarding: [""],
		kc_realm: [""],
		login_type: [""],
		region: [""],
		role: [""],
		user_org: [""],
	};
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};
	async function UpdateAttribute() {
		let attributeArray = fields;
		let data = {};
		console.log(options);
		attributeArray.forEach(({ attributename, attributevalue }) => {
			if ((attributename && attributevalue !== "") || null) {
				if (!data[attributename]) {
					data[attributename] = [];
				}
				data[attributename].push(attributevalue);
			} else {
				showSnackbarWithMessage("attributes cant be empty");
			}
		});
		console.log(attributeArray);
		// let o = Object.fromEntries(Object.entries(attributeArray).filter(([_, v]) => v === "" && ));
		// console.log(o);
		var filteredData = Object.fromEntries(
			Object.entries(data).filter(([_, value]) => value !== "" && value !== null),
		);
		console.log(filteredData);

		let combinedData = { ...formData, ...filteredData };
		console.log(combinedData);

		try {
			//	for (let i = 0; i < options.length; i++) {
			let username = selectedUsers[0].value;
			console.log(username);
			let UpdateAttributeJson = getUpdateAttributeJson(username, combinedData);
			let UpdateAttributeJsonResponse = await dashboardApi.updateAttributes(UpdateAttributeJson);
			console.log(UpdateAttributeJsonResponse);
			if (UpdateAttributeJsonResponse.data.footer.code === 0) {
				showSnackbarWithMessage("Update Attribute successfully");
			}
			//}
		} catch (error) {
			console.log(error);
		}
	}
	async function fetchAttributes(selectedItems) {
		try {
			for (let i = 0; i < selectedItems.length; i++) {
				let username = selectedItems[i].value;
				console.log(username);
				let fetchAttributesJson = getFetchAttributesJson(username);
				let fetchAttributesJsonResponse = await dashboardApi.fetchAttributes(fetchAttributesJson);
				console.log(fetchAttributesJsonResponse);
				setFormData(fetchAttributesJsonResponse.data.existingAttributesList);
				if (fetchAttributesJsonResponse.data.footer.code === 0) {
					showSnackbarWithMessage("fetch Attribute successfully");
				}
			}
		} catch (error) {
			console.log(error);
		}
	}
	function handleClose(e) {
		//	history.push("/settings");
		setFormData(initialData);
		setSelectedUsers([]);
		setFields([{ attributename: "", attributevalue: "" }]);
	}
	const handleChange = (index, key, value) => {
		const newFields = [...fields];
		newFields[index][key] = value;
		setFields(newFields);
		console.log(fields);

		if (readOnlyFields.includes(key)) {
			return;
		}
	};

	async function searchUsers(value) {
		try {
			console.log(value);
			let userResourceJson = getSearchUserJson(value);
			let searchuserResponse = await dashboardApi.searchUsers(userResourceJson);

			if (searchuserResponse?.data?.resourcePropertiesList) {
				const users = searchuserResponse.data.resourcePropertiesList.map((user) => {
					sessionStorage.setItem("useridd", user.fields.userid);
					return {
						value: user.fields.email,
						label: user.fields.email,
						avatar: user.fields.avatarUrl,
						userid: user.fields.userid,
					};
				});
				console.log(users);
				const usersWithoutGlobalUsername = users.filter((element) => element.value !== GlobalUsername.username);

				console.log(usersWithoutGlobalUsername);
				setOptions([...usersWithoutGlobalUsername]);
			} else {
				setError("No users found");
				setOptions([]); // Set no options when no users are found
			}
		} catch (error) {
			console.error("An error occurred while searching for users:", error);
			setOptions([]);
			setError("An error occurred while searching for users");
		} finally {
			setIsLoading(false);
		}
	}

	// Function to add a new field to the array

	function handleInputChange(value) {
		console.log(value);
		Promise.resolve(debouncedSearch(value));
	}
	const debouncedSearch = debounce((value) => {
		if (value.trim().length >= 3) {
			setIsLoading(true);
			searchUsers(value);
		}
	}, 500);
	// useEffect(() => {
	// 	if (searchTerm.trim().length < 3) {
	// 		setOptions([]);
	// 	}
	//}, [searchTerm]);

	// useEffect(() => {
	// 	if (searchTerm.trim().length < 3) {
	// 		setOptions([]);
	// 	}
	//}, [searchTerm]);

	async function handleChange1(selectedItems) {
		console.log(selectedItems);
		setFormData(initialData);
		setSelectedUsers(selectedItems);
		fetchAttributes(selectedItems);
	}

	// State to store the current edited data
	const [formData, setFormData] = useState(initialData);

	// Function to handle changes in the text fields
	const handleChange2 = (key, value) => {
		setFormData((prevData) => ({
			...prevData,
			[key]: [value],
		}));
	};
	const [showTextFields, setShowTextFields] = useState(false);

	//   const handleChange = (index, key, value) => {
	//     const updatedFields = [...fields];
	//     updatedFields[index][key] = value;
	//     setFields(updatedFields);
	//   };

	const handleAddField = () => {
		if (!showTextFields) {
			// If the first set is not visible, show it
			setShowTextFields(true);
		} else {
			// If the first set is already visible, add a new set
			setFields([...fields, { attributename: "", attributevalue: "" }]);
		}
	};
	const handleKeyDown = (event, key) => {
		console.log(key);
		if (event.key === "Enter") {
			console.log("do validate");
			document.getElementById("accountname").focus(); // document.getElementById("react-select-3-input").focus();
		}
	};
	return (
		<div
			className="create-group"
			style={{
				position: "relative",

				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Update Attribute
								</h1>

								<hr />

								<div
									style={{
										width: "100%",
										marginTop: "5px",
										marginBottom: "10px",
										zIndex: 2,
										position: "relative",
									}}
								>
									<Select
										isMulti
										autoFocus
										options={options}
										value={selectedUsers}
										onChange={handleChange1}
										onInputChange={handleInputChange}
										isLoading={isLoading}
										placeholder="Search for people"
										inputId="search-update-users"
										noOptionsMessage={() => "No results found"}
										formatOptionLabel={(option) => (
											<div>
												{/* <img src={option.avatar} width="24" height="24" />  */}
												<div>{option.label}</div>
											</div>
										)}
										styles={{
											menu: (provided) => ({
												...provided,
												maxHeight: "200px",
												fontFamily: fontFamily,
											}),
											menuList: (provided) => ({
												...provided,
												maxHeight: "100px",
												overflowY: "auto",
												fontFamily: fontFamily,
											}),
											control: (provided) => ({
												...provided,
												marginTop: "0px",
												fontFamily: fontFamily,
											}),
										}}
										InputProps={{
											style: {
												fontFamily: fontFamily,
											},
										}}
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												fetchAttributes(event);
											} else if (event.key === "Tab") {
												event.stopPropagation();
											}
										}}
									/>
								</div>

								<div style={{ display: "flex", flexWrap: "wrap" }}>
									{Object.keys(formData).map((key, index) => (
										<TextField
											className="kcattributefield"
											id={key}
											key={key}
											label={key}
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											value={formData[key][0]}
											onChange={(e) => handleChange2(key, e.target.value)}
											style={{
												marginRight: index % 2 === 0 ? "0px" : "0px",
												marginLeft: index % 2 === 1 ? "5px" : "0px",
												marginTop: index % 2 === 1 && index % 2 === 2 ? "5px" : "8px",
												flex: "1",
												fontFamily: fontFamily,
												flexBasis: "45%", // Adjust the percentage based on your design
											}}
											onKeyDown={(e) => handleKeyDown(e, key)}
											InputProps={{
												style: { fontFamily: fontFamily },
												readOnly: readOnlyFields.includes(key),
											}}
											InputLabelProps={{ style: { fontFamily: fontFamily } }}
										/>
									))}
								</div>
								<div>
									{showTextFields &&
										fields.map((field, index) => (
											<div
												key={index}
												style={{ display: "flex", marginBottom: "5px" }}
											>
												<TextField
													id={`attributename-${index}`}
													label="Attribute Name"
													variant="outlined"
													size="small"
													fullWidth
													margin="normal"
													value={field.attributename}
													onChange={(e) =>
														handleChange(index, "attributename", e.target.value)
													}
													style={{ marginRight: "3px", fontFamily: fontFamily }}
													InputProps={{
														style: { fontFamily: fontFamily },
													}}
													InputLabelProps={{ style: { fontFamily: fontFamily } }}
												/>
												<TextField
													id={`attributevalue-${index}`}
													label="Attribute Value"
													variant="outlined"
													size="small"
													fullWidth
													margin="normal"
													value={field.attributevalue}
													onChange={(e) =>
														handleChange(index, "attributevalue", e.target.value)
													}
													style={{ marginLeft: "3px", fontFamily: fontFamily }}
													InputProps={{
														style: { fontFamily: fontFamily },
													}}
													InputLabelProps={{ style: { fontFamily: fontFamily } }}
												/>
											</div>
										))}
									<div style={{ display: "flex" }}>
										<Button
											variant="outlined"
											color="primary"
											onClick={handleAddField}
											style={{ minWidth: "20px", padding: "2px" }}
											data-button-name="add-more-attributes"
										>
											+
										</Button>
										<p
											style={{
												color: "black",
												fontSize: "0.75rem",
												marginLeft: "10px",
												marginTop: "5px",
												fontFamily: fontFamily,
											}}
										>
											Add More Attributes
										</p>
									</div>
								</div>
								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										color="primary"
										style={{
											color: neutral.buttoncolor,
											borderColor: neutral.buttoncolor,
											fontSize: textsize.buttontextsize,
											width: "100px",
											fontFamily: fontFamily,
										}}
									>
										Clear
									</Button>
									<Button
										onClick={UpdateAttribute}
										variant="outlined"
										color="primary"
										style={{
											backgroundColor: neutral.buttoncolor,
											color: neutral.buttontextcolor,
											fontSize: textsize.buttontextsize,
											width: "100px",
											fontFamily: fontFamily,
										}}
										data-cy-card="save-updateattribute"
										//	disabled={!groupname} //disable create button if group name is empty
									>
										Save
									</Button>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
}
