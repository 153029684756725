export default function convertToBytes(value, unit) {
	let sizeValue = parseFloat(value);

	if (!sizeValue) {
		sizeValue = 0;
	}

	switch (unit) {
		case "bytes":
			return sizeValue;
		case "kb":
			return sizeValue * 1024;
		case "mb":
			return sizeValue * 1024 ** 2;
		case "gb":
			return sizeValue * 1024 ** 3;
		case "tb":
			return sizeValue * 1024 ** 4;
		default:
			return 0; // Invalid unit
	}
}
