import { createSlice } from "@reduxjs/toolkit";
import properties from "components/properties/ApplicationProps";

const initialState = {
	locationId: -1,
	shouldFetchDashboardResource: false,
};

const droppedLocationIdSlice = createSlice({
	name: "droppedLocationId",
	initialState,
	reducers: {
		CHANGE_DROPPED_LOCATION_ID: (state, { payload }) => {
			state.locationId = payload;
		},

		STOP_CALLING_FETCH_DASHBOARD_THUNK: (state) => {
			state.shouldFetchDashboardResource = false;
		},

		RESET_DROPPED_LOCATION_ID: (state, _) => {
			state.locationId = initialState.locationId;
			state.shouldFetchDashboardResource = true;

			properties.parentFolderId = -1;
		},
	},
});

export const { CHANGE_DROPPED_LOCATION_ID, STOP_CALLING_FETCH_DASHBOARD_THUNK, RESET_DROPPED_LOCATION_ID } =
	droppedLocationIdSlice.actions;

export default droppedLocationIdSlice.reducer;
