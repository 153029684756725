import properties from "components/properties/ApplicationProps";

export function getResourceLabelJson(resourceid, value) {
	let stringifyFetchResource = {
		action: properties.actions.SAVE,
		resourceId: resourceid,
		labels: value,
	};

	return stringifyFetchResource;
}
