import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import debounce from "lodash/debounce";
import { CircularProgress } from "@material-ui/core";

import { getUserDetailsJson } from "components/requestcontext/UserOnboardJson";
import { dashboardApi } from "components/misc/DashboardApi";
import WelcomeModal from "pages/Login/WelcomeModal";


function UserOnboarding() {
	const oidcAuthentication = useAuth();
	const history = useHistory();
	const [checkUser, setCheckUser] = useState(false);
	const [loading, setLoading] = useState(false); // State for loading indicator
	const refreshTokens = useCallback(async () => {
		try {
			const fetchResourceVersionJson = getUserDetailsJson();
			const resource = await dashboardApi.onBoardUser(fetchResourceVersionJson);
			console.log(resource);
			if (resource.data.footer.msg === "SUCCESS") {
				console.log("USER ONBOARDED SUCCESSFULLY");
				let data = await oidcAuthentication.signinSilent();
				console.log("Token refreshed successfully", data);
				setCheckUser(true);
				history.push("/welcometodf");
			}
		} catch (error) {
			console.error("Error refreshing token:", error);
		} finally {
			setLoading(true); // Hide loading indicator
		}
	}, [history, oidcAuthentication]);

	useEffect(() => {

		setLoading(true);
		const debouncedRefreshTokens = debounce(refreshTokens, 1000);
		debouncedRefreshTokens(); // optional if you want to call it immediately on mount
		return () => {
			debouncedRefreshTokens.cancel(); // Cleanup to prevent potential memory leaks
		};
	}, [setLoading, refreshTokens]);

	return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
			{loading && (
				<div>
					<CircularProgress />
				</div>
			)}
			{checkUser && <WelcomeModal />}
		</div>
	);
}

export default UserOnboarding;
