import { configureStore } from "@reduxjs/toolkit";

// Slices
import dashboardSlice from "./dashboardSlice";
import fileUploadSlice from "./fileUploadSlice";
import notificationsSlice from "./notificationsSlice";
import uploadStatsSlice from "./uploadStatsSlice";
import droppedLocationIdSlice from "./droppedLocationIdSlice";
import userAuthDetailsSlice from "./userAuthDetailsSlice";
import userDetailsSlice from "./userDetailsSlice";
import uiSlice from "./uiSlice";
import resourceGridSlice from "./resourceGridSlice";
import sharedWithMeSlice from "./sharedWithMeSlice";
import gridNavigationSlice from "./gridNavigationSlice";
import searchSlice from "./searchSlice";
import fileDownloadSlice from "./fileDownloadSlice";
import paymentSlice from "./paymentSlice";
import refreshCacheSlice from "./refreshCacheSlice";
import bucketCreationReducer from "./bucketCreationSlice";

export const store = configureStore({
	reducer: {
		dashboard: dashboardSlice,
		sharedWithMe: sharedWithMeSlice,
		search: searchSlice,
		droppedLocationId: droppedLocationIdSlice,
		fileUpload: fileUploadSlice,
		fileDownload: fileDownloadSlice,
		payment: paymentSlice,
		notifications: notificationsSlice,
		uploadStats: uploadStatsSlice,
		authDetails: userAuthDetailsSlice,
		userDetails: userDetailsSlice,
		ui: uiSlice,
		resourceGrid: resourceGridSlice,
		gridNavigation: gridNavigationSlice,
		refreshCache: refreshCacheSlice,
		bucketCreation: bucketCreationReducer,
	},
	devTools: process.env.NODE_ENV === "development",
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
