import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SET_GRID_MESSAGE } from "./uiSlice";
import { dashboardApi } from "components/misc/DashboardApi";

export const refreshCacheThunk = createAsyncThunk(
	"refreshCache",
	async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await dashboardApi.refreshCache();

			if (response.data.msg === "SUCCESS") {
				dispatch(SET_GRID_MESSAGE("Cache refreshed"));
				return fulfillWithValue();
			}
		} catch (error) {
			dispatch(SET_GRID_MESSAGE("Cache failure"));
			return rejectWithValue();
		}
	},
);

const initialState = {
	error: null,
	loading: null,
	data: null,
};

const refreshCacheSlice = createSlice({
	name: "refreshCache",
	initialState,
	reducers: {
		RESET_REFRESH_CACHE_SLICE: (state, _) => {
			state = initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(refreshCacheThunk.pending, (state, _) => {
			state.loading = true;
		});

		builder.addCase(refreshCacheThunk.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.error = false;
			state.data = payload;
		});

		builder.addCase(refreshCacheThunk.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
			state.data = initialState.resources;
		});
	},
});

export const { RESET_REFRESH_CACHE_SLICE } = refreshCacheSlice.actions;

export default refreshCacheSlice.reducer;
