import React, { useState } from "react";
import useStyles from "routes/TableStyles";
import debounce from "lodash/debounce";
import { CssBaseline, DialogContent, Grid } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { dashboardApi } from "components/misc/DashboardApi";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/inputs/Button";
import Select from "react-select";
import Dialog from "@mui/material/Dialog";
import { useHistory } from "react-router-dom";
import { getSearchUserJson } from "components/requestcontext/SearchUser";
import { getChangeOwnershipJson } from "components/requestcontext/OwnershipRequest";
import Notification from "components/dashboard/notification/Notification";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import { GlobalUsername } from "Constants";
import FormContainer from "layout/FormContainer";

export default function ChangeOwnership() {
	const classes = useStyles();
	//   const [searchTerm, setSearchTerm] = useState("");
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [openCreateFolder, setOpenCreateFolder] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const history = useHistory();
	const { fontFamily, themeColor } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);

	async function searchUsers(value) {
		try {
			console.log(value);
			let userResourceJson = getSearchUserJson(value);
			let searchuserResponse = await dashboardApi.searchAccountUsers(userResourceJson);
			console.log(searchuserResponse);
			if (searchuserResponse?.data?.resourcePropertiesList) {
				const users = searchuserResponse.data.resourcePropertiesList.map((user) => {
					sessionStorage.setItem("useridd", user.fields.userid);
					return {
						value: user.fields.email,
						label: user.fields.email,
						avatar: user.fields.avatarUrl,
						userid: user.fields.userid,
					};
				});
				console.log(users);
				const usersWithoutGlobalUsername = users.filter((element) => element.label !== GlobalUsername.username);
				console.log(usersWithoutGlobalUsername);
				setOptions([...usersWithoutGlobalUsername]);
			} else {
				setError("No users found");
				setOptions([]); // Set no options when no users are found
			}
		} catch (error) {
			console.error("An error occurred while searching for users:", error);
			setOptions([]);
			setError("An error occurred while searching for users");
		} finally {
			setIsLoading(false);
		}
	}
	const debouncedSearch = debounce((value) => {
		if (value.trim().length >= 3) {
			setIsLoading(true);
			searchUsers(value);
		}
	}, 500);

	function handleInputChange(value) {
		console.log(value);
		Promise.resolve(debouncedSearch(value));
	}

	// useEffect(() => {
	// 	if (searchTerm.trim().length < 3) {
	// 		setOptions([]);
	// 	}
	//}, [searchTerm]);

	async function handleChange(selectedItems) {
		console.log(selectedItems);
		setSelectedUsers(selectedItems);
	}

	const handleClose = () => {
		setOpenCreateFolder(false);
		history.push("/changeownership");
	};

	const handleCloseDialog = () => {
		history.push("/settings");
	};

	async function changeOwnership() {
		setButtonLoading(() => true);
		console.log(selectedUsers.label, selectedUsers);
		try {
			for (let i = 0; i < selectedUsers.length; i++) {
				let optionlabel = selectedUsers[i];
				console.log(optionlabel.label);
				let changeOwnershipResourceJson = getChangeOwnershipJson(optionlabel.label);
				let changeOwnershipResponse = await dashboardApi.changeOwnership(changeOwnershipResourceJson);
				console.log(changeOwnershipResponse);
				setButtonLoading(() => false);
				if (changeOwnershipResponse.data.footer.msg === "SUCCESS") {
					showSnackbarWithMessage("Changed Ownership Successfully");
					setButtonLoading(() => false);
				}
			}
		} catch (error) {
			showSnackbarWithMessage("Failure while change ownership");
		}
		setOpenCreateFolder(false);
		setSelectedUsers([]);
	}

	const handleOpenCreateFolderDialog = () => {
		console.log("hi");
		setOpenCreateFolder(true);
	};

	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	return (
		<>
			<div
				className="resource-table"
				style={{
					position: "relative",

					backgroundColor: themeColor,
					height: "calc(100% - 55px)",
				}}
			>
				<CssBaseline />
				<Grid className={classes.breadcrumb}>
					<Grid
						item
						md={9}
						sm={12}
						xs={12}
						container
						alignItems="center"
						justify="center"
						style={{
							maxWidth: "100%",
							height: "32px",
						}}
					></Grid>
				</Grid>
				<div className={classes.outercontainer}>
					<div className={classes.innercontainer}>
						<TableContainer className={classes.tablecontainer}>
							<FormContainer>
								<form>
									<h1
										style={{
											fontFamily: fontFamily,
											fontSize: 24,
											textAlign: "center",
										}}
									>
										Change Ownership
									</h1>

									<hr />

									<Select
										isMulti
										autoFocus
										className="input-field"
										options={options}
										value={selectedUsers}
										onChange={handleChange}
										onInputChange={handleInputChange}
										isLoading={isLoading}
										placeholder="Search for people"
										inputId="change-owner"
										noOptionsMessage={() => "No results found"}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												e.preventDefault();

												if (
													e.target.getAttribute("aria-expanded") === "true" &&
													e.target.getAttribute("aria-activedescendant")
												) {
													const focusedOption = document.getElementById(
														e.target.getAttribute("aria-activedescendant"),
													);
													if (focusedOption) {
														focusedOption.click();
													}
												}
											}
										}}
										formatOptionLabel={(option) => (
											<div>
												{/* <img src={option.avatar} width="24" height="24" />  */}
												<div>{option.label}</div>
											</div>
										)}
										styles={{
											menu: (provided) => ({
												...provided,
												maxHeight: "200px",
												fontFamily: fontFamily,
											}),
											menuList: (provided) => ({
												...provided,
												maxHeight: "100px",
												overflowY: "auto",
												fontFamily: fontFamily,
											}),
											control: (provided) => ({
												...provided,
												marginTop: "0px",
												fontFamily: fontFamily,
											}),
										}}
										InputProps={{
											style: { fontFamily: fontFamily },
										}}
									/>

									<div className="form-buttons-container">
										<Button
											onClick={handleCloseDialog}
											variant="outlined"
											data-button-name="cancel-to-change-ownership"
										>
											Cancel
										</Button>

										<Button
											onClick={handleOpenCreateFolderDialog}
											variant="outlined"
											buttonType="main"
											isLoading={buttonLoading}
											data-button-name="change-ownership"
										>
											Submit
										</Button>
									</div>

									{openCreateFolder === true && (
										<Dialog
											open={openCreateFolder}
											onClose={handleClose}
											fullWidth
											maxWidth="xs"
										>
											<DialogContent>
												<DialogTitle style={{ marginLeft: "-20px", fontFamily: fontFamily }}>
													This action cannot be reverted,Please confirm.
												</DialogTitle>
											</DialogContent>

											<DialogActions
												style={{
													marginRight: "25px",
													paddingBottom: "10px",
													marginTop: "-10px",
												}}
											>
												<Button
													onClick={handleClose}
													variant="outlined"
													data-button-name="cancel-to-change-ownership"
												>
													Cancel
												</Button>

												<Button
													onClick={changeOwnership}
													variant="outlined"
													buttonType="main"
													isLoading={buttonLoading}
													data-button-name="confirm-to-change-ownership"
												>
													Confirm
												</Button>
											</DialogActions>
										</Dialog>
									)}
								</form>
							</FormContainer>
						</TableContainer>
					</div>
					<Notification
						showSnackbar={showSnackbar}
						snackbarMessage={snackbarMessage}
						setShowSnackbar={setShowSnackbar}
					/>
				</div>
			</div>
		</>
	);
}
