const getLocaleDate = (param) => {
	const localeDate = new Date(param);
	const fullMonths = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]; // prettier-ignore

	const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; // prettier-ignore

	/**
	 * Calendar Date as per month
	 */
	const date = localeDate.getDate();

	/**
	 * Calendar Date as per month in number format with leading zero
	 */
	const dateWithZero = `${localeDate.getDate()}`.length === 2 ? localeDate.getDate() : `0${localeDate.getDate()}`;

	/**
	 * Calendar Month as per year in number format
	 * e.g. 1
	 */
	const month = localeDate.getMonth() + 1;
	/**
	 * Calendar Month as per year in number format with leading zero
	 * e.g. 01
	 */
	const monthWithZero =
		`${localeDate.getMonth() + 1}`.length === 2 ? localeDate.getMonth() + 1 : `0${localeDate.getMonth() + 1}`;

	/**
	 * Calendar Month as per year in full format
	 * e.g. January
	 */
	const fullMonth = fullMonths[localeDate.getMonth()];

	/**
	 * Calendar Month as per year in short format
	 * e.g. Jan
	 */
	const shortMonth = shortMonths[localeDate.getMonth()];

	/**
	 * Calendar Year as per Birth of Christ
	 */
	const year = localeDate.getFullYear();

	const dd_mm_yyyy = `${dateWithZero}/${monthWithZero}/${year}`;
	const mm_dd_yyyy = `${monthWithZero}/${dateWithZero}/${year}`;
	const yyyy_mm_dd = `${year}, ${monthWithZero}-${dateWithZero}`;
	const yyyy_dd_mm = `${year}, ${dateWithZero}-${monthWithZero}`;
	const dd_mmm_yyyy = `${dateWithZero} ${shortMonth}, ${year}`;
	const mmm_dd_yyyy = `${shortMonth} ${dateWithZero}, ${year}`;

	return {
		/**
		 * Calendar Date as per month
		 */
		date,

		/**
		 * Calendar Date as per month in number format with leading zero
		 */
		dateWithZero,

		/**
		 * Calendar Month as per year in number format
		 * e.g. 1
		 */
		month,
		/**
		 * Calendar Month as per year in number format with leading zero
		 * e.g. 01
		 */
		monthWithZero,
		/**
		 * Calendar Month as per year in full format
		 * e.g. January
		 */
		fullMonth,
		/**
		 * Calendar Month as per year in short format
		 * e.g. Jan
		 */
		shortMonth,
		/**
		 * Calendar Year since Birth of Christ
		 */
		year,

		dd_mm_yyyy,
		mm_dd_yyyy,
		yyyy_mm_dd,
		yyyy_dd_mm,
		dd_mmm_yyyy,
		mmm_dd_yyyy,
	};
};

export default getLocaleDate;

export function getLocaleDateFrom(date) {
	return getLocaleDate(date);
}
