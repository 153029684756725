export default function getFilePart(file, filePartSize) {
	let startPointer = 0;
	const endPointer = file.size;
	const filePart = [];

	while (startPointer < endPointer) {
		let newStartPointer = startPointer + filePartSize;
		filePart.push(file.slice(startPointer, newStartPointer));
		startPointer = newStartPointer;
	}

	return filePart;
}
