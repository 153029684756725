import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET_SHARED_WITH_ME_SLICE } from "store/sharedWithMeSlice";
import ResourceTableComponent from "components/ResourceTableComponent";
import { RESET_SHARED_WITH_ME_UP_LOCATION_ID_SLICE } from "store/gridNavigationSlice";

const ShareWithMe = () => {
	const dispatch = useDispatch();
	const sharedWithMeResources = useSelector(({ sharedWithMe }) => sharedWithMe.data.resourcePropertiesList);
	const areShareWithMeResourcesLoading = useSelector(({ sharedWithMe }) => sharedWithMe.loading);

	useEffect(
		() => () => {
			dispatch(RESET_SHARED_WITH_ME_SLICE());
			dispatch(RESET_SHARED_WITH_ME_UP_LOCATION_ID_SLICE());
		},
		[dispatch],
	);

	return (
		<div
			className="resources-page"
			id="shared-with-me-page"
		>
			<ResourceTableComponent
				resourcesData={sharedWithMeResources}
				showGridProgressbar={areShareWithMeResourcesLoading}
			/>
		</div>
	);
};

export default memo(ShareWithMe);
