// paymentSlice.js
import { createSlice } from "@reduxjs/toolkit";

const paymentSlice = createSlice({
	name: "payment",
	initialState: {
		isPaymentInProgress: false,
		paymentError: null, // Add this field to track payment errors
	},
	reducers: {
		startPayment: (state) => {
			state.isPaymentInProgress = true;
			state.paymentError = null; // Clear any previous errors
		},
		completePayment: (state) => {
			state.isPaymentInProgress = false;
		},
		cancelPayment: (state) => {
			state.isPaymentInProgress = false;
		},
		paymentFailure: (state, action) => {
			state.isPaymentInProgress = false;
			state.paymentError = action.payload; // Store the error message or data
		},
	},
});

export const { startPayment, completePayment, cancelPayment, paymentFailure } = paymentSlice.actions;
export default paymentSlice.reducer;
