import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboardApi } from "components/misc/DashboardApi";
import { generateSearchJsonReq } from "components/requestcontext/SearchSupport";
import { SET_GRID_MESSAGE } from "./uiSlice";

const initialState = {
	loading: null,
	error: null,
	searchTerm: "",
	resources: [],
};

/**
 * handle fetching of resources which were searched
 * or matches user search criteria
 */
export const fetchSearchResources = createAsyncThunk(
	"search/fetchSearchResources",
	async (_, { fulfillWithValue, rejectWithValue, getState, dispatch }) => {
		try {
			let searchTerm = getState().search.searchTerm;
			let userResourceJson;

			if (typeof searchTerm === "object") {
				const searchCriteria = structuredClone(searchTerm);
				userResourceJson = generateSearchJsonReq(searchCriteria);
				searchTerm = searchCriteria.fileName;
			} else {
				userResourceJson = generateSearchJsonReq({ fileName: searchTerm });
			}

			const searchuserResponse = await dashboardApi.fetchresources(userResourceJson);
			const searchResources = searchuserResponse.data.resourcePropertiesList;

			if (searchResources === undefined) {
				dispatch(SET_GRID_MESSAGE("Failed to get searched items!"));
				return fulfillWithValue(initialState.resources);
			}

			return fulfillWithValue(searchResources);
		} catch (error) {
			console.log(error);
			return rejectWithValue(error);
		}
	},
);

/**
 * handle row double click for resources fetched by
 * search and show in search dashboard
 */
export const fetchSearchFolderResourcesThunk = createAsyncThunk(
	"search/fetchSearchFolderResources",
	async (parentFolderId, { fulfillWithValue, rejectWithValue, dispatch }) => {
		try {
			const res = await dashboardApi.fetchDashboardResources(parentFolderId);
			const files = res?.data?.resourcePropertiesList;

			if (!files) {
				dispatch(SET_GRID_MESSAGE("Failed To get Resources!"));
				return rejectWithValue("Invalid Response");
			}

			return fulfillWithValue(files);
		} catch (error) {
			console.log(error);
			dispatch(SET_GRID_MESSAGE("Failed To get Resources!"));
			return rejectWithValue(error);
		}
	},
);

const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		SAVE_SEARCHED_RESOURCES: (state, { payload }) => {
			state.resources = payload;
		},

		SAVE_SEARCH_CRITERIA: (state, { payload }) => {
			state.searchTerm = payload;
		},

		RESET_SEARCH_SLICE: (state, _) => {
			state.error = initialState;
			state.loading = initialState.loading;
			state.searchTerm = initialState.searchTerm;
			state.resources = initialState.resources;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchSearchResources.pending, (state, _) => {
			state.loading = true;
		});

		builder.addCase(fetchSearchResources.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.error = false;
			state.resources = payload;
		});

		builder.addCase(fetchSearchResources.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
			state.resources = initialState.resources;
		});

		builder.addCase(fetchSearchFolderResourcesThunk.pending, (state, _) => {
			state.loading = true;
		});

		builder.addCase(fetchSearchFolderResourcesThunk.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.error = false;
			state.resources = payload;
		});

		builder.addCase(fetchSearchFolderResourcesThunk.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
			state.resources = initialState.resources;
		});
	},
});

export const { SAVE_SEARCHED_RESOURCES, RESET_SEARCH_SLICE, SAVE_SEARCH_CRITERIA } = searchSlice.actions;

export default searchSlice.reducer;
