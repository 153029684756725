import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { neutral, textsize } from "assets/css/MainCss";
// import { ContextRequestUpdateGroup } from "components/requestcontext/UpdateResource";
import { dashboardApi } from "components/misc/DashboardApi";
import eventEmitter from "components/misc/eventEmitter";
import Notification from "./notification/Notification";
import { ContextRequestUpdateGroup } from "components/requestcontext/UpdateGroupInfo";
import { useDispatch } from "react-redux";

const UpdateGroupInfoDialog = ({ renameDialogOpen, handleClose, selected }) => {
	const dispatch = useDispatch();

	const [newGroupName, setNewGroupName] = useState("");
	const [newGrpDescription, setNewGrpDescription] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [orginalGroupName, setOrignalGroupName] = useState("");

	console.log("selected", selected);

	const handleChange = (event) => {
		setNewGroupName(event.target.value);
		setNewGrpDescription(selected[0].fields.description);
	};
	const handleChange1 = (event) => {
		setNewGrpDescription(event.target.value);
		//setNewGrpDescription(selected.fields.description);
	};
	useEffect(() => {
		if (renameDialogOpen) {
			setNewGroupName(selected[0].fields.name);
			setOrignalGroupName(selected[0].fields.name);
			setNewGrpDescription(selected[0].fields.description);
		}
	}, [renameDialogOpen, selected]);

	const handleConfirm = () => {
		console.log(newGroupName);
		handleRenameResource(selected, newGroupName, newGrpDescription);
		handleClose();
	};

	const stopPropagationForTab = (event) => {
		if (event.key === "Tab") {
			event.stopPropagation();
			return false;
		}
	};

	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	const handleRenameResource = async (item, groupName, groupDescription) => {
		console.log(item);

		let newGroupName = groupName;

		console.log("Rename file as", newGroupName);
		try {
			if (newGroupName !== "" && newGroupName !== null) {
				let updateRequestJson = ContextRequestUpdateGroup.getUpdateGroupInfoJson(item[0]);
				console.log("item info", item.fields, "updateRequestJson : ", updateRequestJson);
				updateRequestJson.name = newGroupName;
				updateRequestJson.description = newGrpDescription;
				updateRequestJson.orginalGroupName = orginalGroupName;
				console.log("updateinfo Request json body:", updateRequestJson);
				let updateNameResponse = await dashboardApi.updateGroupInfo(updateRequestJson);
				console.log(
					"Update Resource Info Response :",
					updateNameResponse,
					"Footer Message :",
					//   updateNameResponse.data.footer.msg
				);
				if (updateNameResponse.data.footer.msg === "SUCCESS")
					showSnackbarWithMessage("updated group info sucessfully");
				eventEmitter.emit("GroupDashboardEvent");
			} else {
				alert("please specify some resource name");
			}
		} catch (error) {
			console.log(error);
		}
		setNewGroupName("");
		setNewGrpDescription("");
	};
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			console.log("do validate");
			document.getElementById("group_description").focus();
			console.log(document.getElementById("selector"));
			// document.getElementById("react-select-3-input").focus();
		}
	};
	return (
		<div>
			<Dialog
				open={renameDialogOpen}
				onClose={handleClose}
				fullWidth
				maxWidth="xs"
				onKeyDown={stopPropagationForTab}
			>
				<DialogTitle>Rename as</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="groupnamerename"
						label="New Group Name"
						fullWidth
						size="small"
						value={newGroupName}
						onChange={handleChange}
						variant="outlined"
						onKeyDown={handleKeyDown}
					/>

					<TextField
						margin="dense"
						id="group_description"
						label="Group Description"
						fullWidth
						size="small"
						value={newGrpDescription}
						onChange={handleChange1}
						variant="outlined"
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								handleConfirm(event);
							} else if (event.key === "Tab") {
								event.stopPropagation();
							}
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						type="submit"
						onClick={handleConfirm}
						variant="contained"
						style={{
							backgroundColor: neutral.buttoncolor,
							color: neutral.buttontextcolor,
							fontSize: textsize.buttontextsize,
						}}
						data-button-name="Rename-submit"
					>
						Submit
					</Button>
					<Button
						onClick={handleClose}
						variant="outlined"
						color="primary"
						style={{
							color: neutral.buttoncolor,
							borderColor: neutral.buttoncolor,
							fontSize: textsize.buttontextsize,
						}}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
};

export default UpdateGroupInfoDialog;
