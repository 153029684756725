import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Button from "components/inputs/Button";
import { dashboardApi } from "components/misc/DashboardApi";
import Notification from "components/dashboard/notification/Notification";
import { useHistory } from "react-router-dom";
import { FontContext } from "components/UI/Theme";
import FormContainer from "../layout/FormContainer";
import { getAllSubscriptionsJson } from "components/requestcontext/FetchAllSubscriptions";
import Select from "react-select";
import dayjs from "dayjs";
import { getSearchSubscriptionJson } from "components/requestcontext/SearchSubscriptionPlan";
import { getAllAccountDetailsJson } from "components/requestcontext/FetchAllAccounts";
import { getAssociatedSubscriptionsJson } from "components/requestcontext/GetAllAssociatedSubscriptionsWithAccount";
import columnDefinations from "./columnDefinations";
import AssociatedSubscriptions from "./AssociatedSubscription";
import { getChangeSubscriptionDetails } from "components/requestcontext/ChangeSubscription";

function ChangeSubscriptionPlan() {
	const classes = useStyles();
	const history = useHistory();
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [subscriptionName, setSubscriptionName] = useState("");
	const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
	const [subscriptions, setSubscriptions] = useState([]);
	const [accountId, setAccountId] = useState("");
	const { fontFamily } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState("");
	const [owner, setOwner] = useState("");
	const [orgname, setOrgname] = useState("");
	const [plantype, setPlantype] = useState("");
	const [subscriptionKey, setSubscriptionKey] = useState("");
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [colDefs, setColDefs] = useState([]);
	const [associatedSubscriptionItems, setAssociatedSubscriptionItems] = useState([]);
	const [fetchedSubscriptions, setFetchedSubscriptions] = useState([]);
	const [subscriptionChangeDetails, setSubscriptionChangeDetails] = useState([]);

	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	const handleClose = () => {
		history.push("/settings");
	};

	const ChangeSubscription = useCallback(async () => {
		setButtonLoading(true);
		try {
			const updatedSubscriptionChangeDetails = subscriptionChangeDetails.map((subscription) => ({
				...subscription,
				validUntilTime: subscription.validUntilTime
					? dayjs(subscription.validUntilTime).valueOf().toString()
					: // : dayjs(fetchedSubscriptions.validity).valueOf().toString(),
					  null,
			}));
			console.log("updatedSubscriptionChangeDetails", updatedSubscriptionChangeDetails);
			const subscriptionDetailsJson = getChangeSubscriptionDetails(updatedSubscriptionChangeDetails, accountId);
			const changeSubscriptionResponse = await dashboardApi.changeSubscription(subscriptionDetailsJson);

			if (changeSubscriptionResponse.data.footer.msg === "SUCCESS") {
				showSnackbarWithMessage("Subscription plan updated successfully");
				fetchAssociatedSubscriptionsDiscounts();
			} else {
				showSnackbarWithMessage("Failure: Only One Main Subscription can be applied");
			}
		} catch (error) {
			console.error("Error assigning subscription:", error);
		} finally {
			setButtonLoading(false);
			fetchSubscriptionDetails();
			fetchAssociatedSubscriptionsDiscounts();
		}
	}, [accountId, subscriptionChangeDetails]);

	useEffect(() => {
		fetchSubscriptions();
		searchAccount();
	}, []);

	const fetchSubscriptions = useCallback(async () => {
		try {
			const subscriptionJson = getAllSubscriptionsJson();
			const response = await dashboardApi.getAllSubscriptions(subscriptionJson);
			const transformedArray = response.data.subscriptionsList.map((item) => ({
				subscriptionId: item.subscriptionid,
				subscriptionName: item.subscriptionname,
				discountType: item.discounttype,
				description: item.description,
				discount: item.discount,
				isactive: item.isactive,
				isexternal: item.isexternal,
				licenses: item.licenses,
				subscriptionCharge: item.subscriptioncharge,
				subscriptionChargeType: item.subscriptionchargetype,
				subscriptionKey: item.subscriptionkey,
				validUntilTime: item.validuntiltime || null,
				subQty: item.subqty,
				validity: item.validity,
			}));
			setFetchedSubscriptions(transformedArray);
		} catch (error) {
			console.error("Error fetching subscriptions:", error);
		}
	}, []);

	const searchAccount = useCallback(async (value) => {
		try {
			const userResourceJson = getAllAccountDetailsJson();
			const searchuserResponse = await dashboardApi.fetchAllAccountDetails(userResourceJson);

			if (searchuserResponse?.data?.resourcePropertiesList) {
				const users = searchuserResponse.data.resourcePropertiesList.map((user) => ({
					value: user.fields.accountid,
					label: `${user.fields.accountid} | ${user.fields.accountname} | ${user.fields.username}`,
					accountId: user.fields.accountid,
					accountName: user.fields.accountname,
					username: user.fields.username,
					email: user.fields.email,
					owner: user.fields.username,
					orgname: user.fields.orgname,
					plantype: user.fields.plantype,
				}));
				setOptions(users);
			} else {
				setOptions([]);
			}
		} catch (error) {
			console.error("An error occurred while searching for users:", error);
			setOptions([]);
		} finally {
			setIsLoading(false);
		}
	}, []);

	const handleInputChange = (value) => {
		if (value.trim().length >= 3) {
			setIsLoading(true);
			setSelectedUsers(value);
			searchSubscriptions(value);
			searchAccount(value);
		} else {
			setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase())));
		}
	};

	const handleSubscriptionChange = (selected) => {
		const newSubscription = {
			...selected,
		};

		setSelectedSubscriptions((prev) => [...prev, newSubscription]);
		setSubscriptionChangeDetails((prev) => [...prev, newSubscription]);
		setAssociatedSubscriptionItems((prev) => [...prev, newSubscription]);
	};

	const searchSubscriptions = useCallback(async (value) => {
		try {
			const subscriptionJson = getAllSubscriptionsJson(value);
			const response = await dashboardApi.getAllSubscriptions(subscriptionJson);
			const transformedArray = response.data.subscriptionsList.map((item) => ({
				value: item.subscriptionName,
				label: item.subscriptionName,
				key: item.subscriptionKey,
			}));

			setSubscriptions(transformedArray);
		} catch (error) {
			console.error("Error searching subscriptions:", error);
			setSubscriptions([]);
		} finally {
			setIsLoading(false);
		}
	}, []);

	const fetchSubscriptionDetails = useCallback(async (subscription) => {
		if (!subscription) return;

		setIsLoading(true);

		try {
			const userResourceJson = getSearchSubscriptionJson(subscription);
			const searchSubscriptionResponse = await dashboardApi.fetchSubscriptions(userResourceJson);
			const subscriptionData = searchSubscriptionResponse.data.resourcePropertiesList;

			setSubscriptionName(subscriptionData[0].fields.subscriptionname);
			setSubscriptionKey(subscriptionData[0].fields.subscriptionkey);
		} catch (error) {
			console.error("Error fetching subscription details:", error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		if (selectedUsers !== "" && accountId !== "") {
			fetchAssociatedSubscriptionsDiscounts();
		}
	}, [selectedUsers, accountId]);

	const fetchAssociatedSubscriptionsDiscounts = useCallback(async () => {
		if (selectedUsers !== "" && accountId !== "") {
			try {
				const subscriptionJson = getAssociatedSubscriptionsJson(accountId);
				const response = await dashboardApi.getAllAssociatedSubscriptionsWithAccount(subscriptionJson);
				const transformedArray = response.data.subscriptionsList
					.filter((item) => item.subscriptionname)
					.map((item) => ({
						subscriptionId: item.subscriptionid,
						subscriptionName: item.subscriptionname,
						discountType: item.discounttype,
						description: item.description,
						discount: item.discount,
						isactive: item.isactive,
						isexternal: item.isexternal,
						licenses: item.licenses,
						subscriptionCharge: item.subscriptioncharge,
						subscriptionChargeType: item.subscriptionchargetype,
						subscriptionKey: item.subscriptionkey,
						validUntilTime: item.validuntiltime || null,
						subQty: item.subqty,
						validity: item.validity || null,
					}));
				setAssociatedSubscriptionItems(transformedArray);
				setColDefs(columnDefinations);
				setSubscriptions(transformedArray);
				setSubscriptionChangeDetails(transformedArray);
			} catch (error) {
				console.error("Error fetching subscriptions:", error);
			}
		}
	}, [accountId, selectedUsers]);

	useEffect(() => {
		setFilteredOptions(options);
	}, [options]);

	const handleChange1 = useCallback((selectedItems) => {
		setPlantype(selectedItems.plantype);
		setOwner(selectedItems.owner);
		setOrgname(selectedItems.orgname);
		setAccountId(selectedItems.accountId);
		setSelectedUsers(selectedItems);
	}, []);

	const handleDelete = useCallback((subscriptionId, e) => {
		e.preventDefault();
		setAssociatedSubscriptionItems((prevItems) =>
			prevItems.filter((item) => item.subscriptionId !== subscriptionId),
		);
		setSubscriptionChangeDetails((prevItems) => prevItems.filter((item) => item.subscriptionId !== subscriptionId));
	}, []);

	const handleDateChange = useCallback((subscriptionId, newDate) => {
		console.log("newDate", newDate);
		const formattedDate = newDate ? dayjs(newDate).format("YYYY-MM-DDTHH:mm:ssZ") : null;
		setAssociatedSubscriptionItems((prevItems) =>
			prevItems.map((item) =>
				item.subscriptionId === subscriptionId ? { ...item, validUntilTime: formattedDate } : item,
			),
		);
		setSubscriptionChangeDetails((prevItems) =>
			prevItems.map((item) =>
				item.subscriptionId === subscriptionId ? { ...item, validUntilTime: formattedDate } : item,
			),
		);
	}, []);

	const handleNumberChange = (subscriptionId, newValue) => {
		console.log("inside handleNumberChange", subscriptionId, newValue);
		setAssociatedSubscriptionItems((prevItems) =>
			prevItems.map((item) => (item.subscriptionId === subscriptionId ? { ...item, subQty: newValue } : item)),
		);
		setSubscriptionChangeDetails((prevItems) =>
			prevItems.map((item) => (item.subscriptionId === subscriptionId ? { ...item, subQty: newValue } : item)),
		);
	};

	const availableSubscriptions = useMemo(() => {
		return fetchedSubscriptions.filter(
			(sub) => !associatedSubscriptionItems.some((assocSub) => assocSub.subscriptionId === sub.subscriptionId),
		);
	}, [fetchedSubscriptions, associatedSubscriptionItems]);

	const renderAdditionalFields = useMemo(() => {
		if (selectedUsers === "") return null;
		return (
			<main
				id="resource-container"
				className="ag-theme-quartz"
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				onContextMenu={(event) => {
					event.preventDefault();
				}}
			>
				<div
					id="grid-progressbar"
					style={{ display: "none", position: "absolute", zIndex: 1, width: "100%", top: "33px" }}
				></div>

				<AssociatedSubscriptions
					rowData={associatedSubscriptionItems}
					colDefs={colDefs}
					handleDelete={handleDelete}
					handleDateChange={handleDateChange}
					handleNumberChange={handleNumberChange}
				/>
			</main>
		);
	}, [selectedUsers, associatedSubscriptionItems, colDefs, handleDelete]);

	return (
		<div
			style={{
				height: "calc(100% - 55px)",
				minHeight: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Change Subscription or Manage Discount
								</h1>

								<hr />

								<Select
									autoFocus
									options={filteredOptions}
									value={selectedUsers}
									onChange={handleChange1}
									onInputChange={handleInputChange}
									isLoading={isLoading}
									placeholder="Search for Account or User"
									inputId="search-assign-account"
									noOptionsMessage={() => "No results found"}
									formatOptionLabel={(option) => (
										<div>
											<div>{option.label}</div>
										</div>
									)}
									styles={{
										width: "100%",
										control: (provided) => ({
											...provided,
											width: "100%",
											marginTop: "5px",
											marginBottom: "10px",
											minHeight: "36px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>

								{renderAdditionalFields}

								<Select
									options={availableSubscriptions}
									value={null}
									onChange={handleSubscriptionChange}
									isLoading={isLoading}
									placeholder="Select Subscription Plan or Discount"
									inputId="search-subscription-plans"
									noOptionsMessage={() => "No results found"}
									formatOptionLabel={(option) => (
										<div>
											<div>
												{option.subscriptionKey} | {option.subscriptionName}
											</div>
										</div>
									)}
									styles={{
										width: "100%",
										control: (provided) => ({
											...provided,
											width: "100%",
											marginTop: "5px",
											marginBottom: "10px",
											minHeight: "36px",
											fontFamily: fontFamily,
											zIndex: 999,
										}),
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>
								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										data-button-name="cancel-manage-account"
									>
										Cancel
									</Button>

									<Button
										id="createButton"
										onClick={ChangeSubscription}
										variant="outlined"
										buttonType="main"
										isLoading={buttonLoading}
										data-button-name="change-subcription"
										loading={buttonLoading}
										className={classes.button}
									>
										Change
									</Button>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
				messageLength={50}
			/>
		</div>
	);
}

export default ChangeSubscriptionPlan;
