import properties from "components/properties/ApplicationProps";

export function getAddGroupResourceJson(groupname, description) {
	let stringifyFetchResource = {
		header: {
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		action: properties.actions.MOVE,
		name: groupname,
		description: description,
		lastUpdateTime: Date.now(),
	};

	return stringifyFetchResource;
}
