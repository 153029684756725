import { memo, useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { neutral, textsize } from "assets/css/MainCss";
import { useContext } from "react";
import { FontContext } from "../UI/Theme";
import { useDispatch, useSelector } from "react-redux";
import { getDeleteResourceJson } from "components/requestcontext/DeleteResource";
import { dashboardApi } from "components/misc/DashboardApi";
import { useLocation } from "react-router-dom";
import Notification from "./notification/Notification";

const DeleteDialogGrp = ({
	deleteDialogOpen,
	handleClose,
	handleRefresh,
	handleDeleteGroup = () => {},
	handleDelete = () => {},
}) => {
	const dispatch = useDispatch();
	const currentPageRoute = useLocation().pathname;
	const { resourcesToDelete } = useSelector(({ resourceGrid }) => resourceGrid);
	const { fontFamily, largeTextSize, smallTextSize } = useContext(FontContext);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const stopPropagationForTab = useCallback((event) => {
		if (event.key === "Tab") {
			event.stopPropagation();
			return false;
		}
	}, []);

	const showSnackbarWithMessage = useCallback((message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	}, []);

	const handleDeleteButton = useCallback(async () => {
		try {
			handleClose();
			const deleteActionRes = await Promise.allSettled(
				resourcesToDelete.map(async (resource) => {
					try {
						console.log("Resource", resource);
						const deleteResourceJson = getDeleteResourceJson(resource);
						const deleteResponse = await dashboardApi.deleteResource(deleteResourceJson);
						const isResourceDeletedSuccessfully = deleteResponse?.data?.footer.code === 0;

						if (!isResourceDeletedSuccessfully) {
							return Promise.reject({
								status: "failed",
								filename: resource.filename,
								resourceId: resource.resourceid,
							});
						}

						return Promise.resolve({
							status: "success",
							filename: resource.filename,
							resourceId: resource.resourceid,
						});
					} catch (error) {
						throw new Error(error);
					}
				}),
			);

			const success = deleteActionRes
				.filter((res) => {
					if (res.status === "fulfilled") return res.value;
					return null;
				})
				.filter((x) => x);

			if (success.length === resourcesToDelete.length) {
				showSnackbarWithMessage("Files were Deleted successfully");
				return;
			}

			if (success.length < resourcesToDelete.length && success.length !== 0) {
				showSnackbarWithMessage("Some files failed to Delete");
				return;
			}

			showSnackbarWithMessage("Failed to Delete files!");
		} catch (error) {
			console.log(error);
		} finally {
			handleRefresh();
		}
	}, [resourcesToDelete, showSnackbarWithMessage, handleRefresh, handleClose]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Enter" && deleteDialogOpen) {
				// Trigger the click event on the "Delete" button
				const deleteButton = document.getElementById("deleteButton");
				if (deleteButton) {
					deleteButton.click();
				}
			}
		};

		// Add the event listener to the document
		document.addEventListener("keydown", handleKeyDown);

		return () => {
			// Remove the event listener when the component unmounts
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [deleteDialogOpen]);

	return (
		<>
			<div>
				<Dialog
					open={deleteDialogOpen}
					onClose={handleClose}
					fullWidth
					maxWidth="xs"
					onKeyDown={stopPropagationForTab}
				>
					<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
						{"Delete Resource? "}
					</DialogTitle>

					<DialogContent style={{ fontFamily: fontFamily, fontSize: smallTextSize }}>
						Are you sure you want to delete this?
					</DialogContent>
					<DialogActions
						style={{
							marginRight: "18px",
							paddingBottom: "24px",
							marginTop: "-10px",
						}}
					>
						<Button
							id="deleteButton"
							type="submit"
							onClick={() => {
								if (currentPageRoute.includes("mygroups")) {
									handleDelete();
									return;
								}

								handleDeleteButton();
							}}
							variant="contained"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
							}}
							data-button-name="Delete"
						>
							Delete
						</Button>
						<Button
							onClick={handleClose}
							variant="outlined"
							color="primary"
							style={{
								color: neutral.buttoncolor,
								borderColor: neutral.buttoncolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
							}}
						>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</>
	);
};
export default memo(DeleteDialogGrp);
