import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showUserSummery: false,
	showUserDetailedView: false,
	showResourceVersion: false,
	showNewFolderDialog: false,
	showGridHeaderProgressbar: false,
	resourceLabelError: null,
	gridOperationsError: null,
	isDarkThemeOn: null,
	backgroundProgressBar: {
		message: null,
		isDeterminate: false,
		showBackgroundProgressBar: false,
		progressValue: 0,
	},
};

const uiSlice = createSlice({
	name: "ui",
	initialState,
	reducers: {
		SHOW_USER_SUMMERY_POPUP: (state, _) => {
			state.showUserSummery = true;
		},

		HIDE_USER_SUMMERY_POPUP: (state, _) => {
			state.showUserSummery = false;
		},

		SHOW_USER_DETAILED_VIEW: (state, _) => {
			state.showUserDetailedView = true;
		},

		HIDE_USER_DETAILED_VIEW: (state, _) => {
			state.showUserDetailedView = false;
		},

		SHOW_RESOURCE_VERSION_NO: (state, _) => {
			state.showResourceVersion = true;
		},

		HIDE_RESOURCE_VERSION_NO: (state, _) => {
			state.showResourceVersion = false;
		},

		SHOW_NEW_FOLDER_DIALOG: (state, _) => {
			state.showNewFolderDialog = true;
		},

		HIDE_NEW_FOLDER_DIALOG: (state, _) => {
			state.showNewFolderDialog = false;
		},

		SHOW_GRID_HEADER_PROGRESSBAR: (state, _) => {
			state.showGridHeaderProgressbar = true;
		},
		HIDE_GRID_HEADER_PROGRESSBAR: (state, _) => {
			state.showGridHeaderProgressbar = initialState.showGridHeaderProgressbar;
		},

		SET_GRID_MESSAGE: (state, { payload }) => {
			state.gridOperationsError = payload;
		},

		RESET_GRID_MESSAGE: (state, _) => {
			state.gridOperationsError = initialState.gridOperationsError;
		},

		SET_DARK_THEME_ON: (state, _) => {
			state.isDarkThemeOn = true;
		},

		RESET_DARK_THEME_ON: (state, _) => {
			state.isDarkThemeOn = initialState.isDarkThemeOn;
		},

		SHOW_BACKGROUND_PROGRESS_BAR: (state, { payload }) => {
			state.backgroundProgressBar.showBackgroundProgressBar = true;
			state.backgroundProgressBar.message = payload.message;
			state.backgroundProgressBar.isDeterminate = payload.isDeterminate;
			state.backgroundProgressBar.progressValue = payload.progressValue;
		},

		HIDE_BACKGROUND_PROGRESS_BAR: (state, _) => {
			state.backgroundProgressBar = initialState.backgroundProgressBar;
		},
	},
});

export const {
	SHOW_USER_SUMMERY_POPUP,
	HIDE_USER_SUMMERY_POPUP,
	SHOW_USER_DETAILED_VIEW,
	HIDE_USER_DETAILED_VIEW,
	SHOW_RESOURCE_VERSION_NO,
	HIDE_RESOURCE_VERSION_NO,
	SHOW_NEW_FOLDER_DIALOG,
	HIDE_NEW_FOLDER_DIALOG,
	SET_GRID_MESSAGE,
	RESET_GRID_MESSAGE,
	SET_DARK_THEME_ON,
	RESET_DARK_THEME_ON,
	SHOW_GRID_HEADER_PROGRESSBAR,
	HIDE_GRID_HEADER_PROGRESSBAR,
	SHOW_BACKGROUND_PROGRESS_BAR,
	HIDE_BACKGROUND_PROGRESS_BAR,
} = uiSlice.actions;

export default uiSlice.reducer;
