export function getFetchAttributesJson(username) {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		authChangeIds: [username],
		org: sessionStorage.getItem("user_org"),
	};

	return stringifyFetchResource;
}
