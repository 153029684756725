export function getGenerateInvoiceJson(formData) {
	const lastUpdateTime =
		formData.lastupdatetime instanceof Date
			? formData.lastupdatetime.getTime()
			: new Date(formData.lastupdatetime).getTime();

	const dueDate =
		formData.duedate instanceof Date ? formData.duedate.getTime() : new Date(formData.duedate).getTime();

	let stringifyOrgPropsJson = {
		accountId: formData.accountid,
		invoiceId: formData.invoiceid,
		dataUsage: formData.datausageingb,
		dataUsageCharge: formData.datausagecharge,
		subscriptionCharge: formData.subscriptioncharge,
		discountPer: formData.discountpercentage,
		discountAmt: formData.discountedamount,
		totalTaxableAmt: formData.totalamountbeforetax,
		taxAmount: formData.taxamount,
		gstRate: formData.gstrate,
		totalAmount: formData.totalamount,
		dueDate: dueDate,
		paymentStatus: formData.paymentstatus,
		lastUpdateTime: lastUpdateTime,
	};

	return stringifyOrgPropsJson;
}
