import { createSlice, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import { dashboardApi } from "components/misc/DashboardApi";
import getFetchNotificationsJSON from "components/requestcontext/FetchNotifications";

const initialState = {
	data: [],
	loading: null,
	error: null,
};

export const fetchUserNotificationsThunk = createAsyncThunk(
	"fetchUserNotifications",
	async (_, { getState, fulfillWithValue, rejectWithValue }) => {
		const userAuthDetails = getState().authDetails.data;

		try {
			const reqJson = getFetchNotificationsJSON(userAuthDetails);
			const response = await dashboardApi.fetchNotifications(reqJson);
			const notifications = response?.data?.resourcePropertiesList || [];
			const prettyNotifications = notifications
				.map(({ fields }) => {
					Object.defineProperty(fields, "id", { value: crypto.randomUUID() });
					Object.defineProperty(fields, "expiresOn", { value: new Date(fields.validuntiltime) });
					return fields;
				})
				.sort((a, b) => (a.expiresOn > b.expiresOn ? -1 : 1));

			return fulfillWithValue(prettyNotifications);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUserNotificationsThunk.pending, (state, { payload }) => {
			state.loading = true;
		});
		builder.addCase(fetchUserNotificationsThunk.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.error = false;
			state.data = payload;
		});
		builder.addCase(fetchUserNotificationsThunk.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
			state.data = initialState.data;
		});
	},
});

export default notificationsSlice.reducer;
