import numeral from "numeral";

const columnDefinitions = [
	{
		field: "dateOfusage",
		flex: 1,
		checkboxSelection: true,
		headerCheckboxSelection: true,
		editable: false,
		minWidth: 135,
		headerName: "Date",
		sort: "asc",
		// cellRenderer: date,
		filter: "agDateColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			comparator: (filterLocalDateAtMidnight, cellValue) => {
				console.log(filterLocalDateAtMidnight, cellValue);
				/**
				 * we store datae a long value, so we need to convert it string
				 * as dd/mm/yyyy format
				 */
				const dateAsString = new Date(cellValue).toLocaleDateString("en-GB");
				if (dateAsString == null) {
					return 0;
				}
				const dateParts = dateAsString.split("/");
				const year = Number(dateParts[2]);
				const month = Number(dateParts[1]) - 1;
				const day = Number(dateParts[0]);
				const cellDate = new Date(year, month, day);
				// Now that both parameters are Date objects, we can compare
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				} else if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				return 0;
			},
		},
		valueFormatter: (param) => {
			return new Date(param.data.dateOfusage).toLocaleDateString("en-GB");
		},

		cellClass: "hide-sort-order",
		enableRowGroup: true,
		enablePivot: true,
	},
	{
		field: "username",
		headerName: "Username",
		width: 255,
		// cellRenderer: username,
		valueFormatter: (param) => {
			console.log(param);
			if (!param.data.username) return "-";

			return param.data.username;
		},
		sort: "asc",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
	},
	{
		field: "requestCount",
		headerName: "Request Count",
		width: 177,
		cellRenderer: requestcount,
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			// numberParser: (text) => {
			// 	console.log(text);
			// },
			// numberFormatter: (value) => {
			// 	console.log(value);
			// },
		},
	},
	{
		field: "uploadRequestCount",
		headerName: "Uploads",
		width: 125,
		cellRenderer: uploadrequestcount,
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			// numberParser: (text) => {
			// 	console.log(text);
			// },
			// numberFormatter: (value) => {
			// 	console.log(value);
			// },
		},
	},
	{
		field: "downloadRequestCount",
		headerName: "Downloads",
		width: 147,
		cellRenderer: downloadrequestcount,
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			// numberParser: (text) => {
			// 	console.log(text);
			// },
			// numberFormatter: (value) => {
			// 	console.log(value);
			// },
		},
	},
	{
		field: "deleteRequestCount",
		headerName: "Delete",
		width: 122,
		cellRenderer: deleterequestcount,
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			// numberParser: (text) => {
			// 	console.log(text);
			// },
			// numberFormatter: (value) => {
			// 	console.log(value);
			// },
		},
	},
	{
		field: "valueInBytes",
		headerName: "Current Data Usage",
		width: 200,
		valueFormatter: (param) => {
			console.log(param);
			if (!param.data.valueInBytes) return "-";

			return numeral(param.data.valueInBytes).format("0.00b");
		},
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			numberParser: (text) => {
				console.log(text);
			},
			numberFormatter: (value) => {
				console.log(value);
			},
		},
	},
	{
		headerName: "Download Data Usage",
		field: "downloadDataUsage",
		width: 220,
		valueFormatter: (param) => {
			console.log(param);
			if (!param.data.downloadDataUsage) return "-";

			return numeral(param.data.downloadDataUsage).format("0.00b");
		},
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			numberParser: (text) => {
				console.log(text);
			},
			numberFormatter: (value) => {
				console.log(value);
			},
		},
	},
];

function requestcount(param) {
	const { requestCount } = param.data;
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${requestCount}`;

	return requestCount || "-";
}

function uploadrequestcount(param) {
	console.log(param);
	const { uploadRequestCount } = param.data;
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${uploadRequestCount}`;

	return uploadRequestCount || "-";
}

function downloadrequestcount(param) {
	const { downloadRequestCount } = param.data;
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${downloadRequestCount}`;

	return downloadRequestCount || "-";
}

function deleterequestcount(param) {
	const { deleteRequestCount } = param.data;
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${deleteRequestCount}`;

	return deleteRequestCount || "-";
}

export default columnDefinitions;
