import { LinearProgress, Typography, Tooltip } from "@mui/material";
import numeral from "numeral";
import FileIcon from "components/fileService/FileIcons";
import "./UploadProgressNotification.css";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import info_icon from "assets/proto_1/info.png";
import arrow_up from "assets/proto_1/arrow_up.png";

const UploadProgressNotification = ({ notificationDetails }) => {
	const { name, operationType, uploadSpeed, uploadCompletePercentageNumber, etc, type, error, errorMssg, size, completionTime, stopUpload, isUploaded, isUploading, isUploadStopped } = notificationDetails; // prettier-ignore
	const { fontFamily, smallTextSize } = useContext(FontContext);

	const uploadCompleteValue = uploadCompletePercentageNumber ?? 0;
	const showStopUploadButton = isUploading === true && isUploadStopped === false && typeof stopUpload === "function";

	return (
		<div
			className="notification-container"
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<div className="notification-file-type">
				<FileIcon
					contentType={
						uploadCompletePercentageNumber === 100 && isUploaded === true
							? String(uploadCompletePercentageNumber)
							: error
							? "error"
							: type
					}
				/>
			</div>

			<div
				className="notification-info"
				style={{ width: "100%", marginLeft: 8 }}
			>
				<div
					className="file-name"
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 3,
						height: 21,
					}}
				>
					<div style={{ flexGrow: 1 }}>
						<Typography
							variant="body2"
							noWrap
							title={isUploadStopped ? `[STOPPED]::${name}` : name}
							style={{
								fontFamily: fontFamily,
								fontSize: smallTextSize,
								cursor: "pointer",
								textDecoration: `${isUploadStopped ? "line-through" : ""}`,
							}}
						>
							<img
								src={arrow_up}
								alt={`${operationType}__indicator`}
								style={{
									width: 16,
									aspectRatio: "1 / 1",
									transform: `${operationType === "download" ? "rotate(180deg)" : ""}`,
								}}
							/>
							{name.length > 12 ? `${name.slice(0, 12)}...` : name}
						</Typography>
					</div>
					<div>
						<Typography
							variant="body2"
							noWrap
							style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
						>
							{isUploadStopped ? "[STOPPED]" : numeral(uploadCompletePercentageNumber / 100).format("0%")}
						</Typography>
					</div>

					{showStopUploadButton ? (
						<div style={{ marginLeft: 5, display: "grid" }}>
							<Tooltip title={`Stop ${operationType === "download" ? "Download" : "Upload"}`}>
								<button
									style={{ border: "none", background: "#7c8083", width: 12, aspectRatio: "1 / 1" }}
									onClick={() => stopUpload()}
								/>
							</Tooltip>
						</div>
					) : null}
				</div>

				<div className="progress-bar">
					<LinearProgress
						className={error ? "show-error-color" : ""}
						variant="determinate"
						style={{ height: "5px", width: "100%", fontFamily: fontFamily }}
						color={
							uploadCompletePercentageNumber === 100 && isUploaded === true
								? "success"
								: error
								? "error"
								: "primary"
						}
						value={uploadCompleteValue}
					/>
				</div>

				<div
					className="upload-info"
					style={{ display: "flex", justifyContent: "space-between", fontFamily: fontFamily }}
				>
					{uploadCompletePercentageNumber === 100 && isUploaded === false && !error ? (
						<p>Confirming...</p>
					) : error ? (
						<p className="upload-error-info">
							<span>{operationType === "download" ? "Download" : "Upload"} Failed</span>
							<Tooltip title={errorMssg}>
								<img
									src={info_icon}
									alt="Info"
								/>
							</Tooltip>
						</p>
					) : (
						<>
							<p className="upload-speed">
								{etc !== 0 && !isUploaded ? (
									<span>{`${uploadSpeed}/s`}</span>
								) : (
									numeral(size).format("0.00b")
								)}
							</p>
							<p className="upload-etc">
								{isUploaded
									? `${numeral(completionTime).format("00:00:00")}`
									: isFinite(etc)
									? numeral(etc).format("00:00:00")
									: numeral(0).format("00:00:00")}
							</p>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default UploadProgressNotification;
