import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import createGrpIcon from "assets/proto_1/createGrp.png";
import viewGrpIcon from "assets/proto_1/group.png";
import changeOwnershipIcon from "assets/proto_1/changeOwnership.png";
import linkAccountIcon from "assets/proto_1/linkAccount.png";
import createAccountIcon from "assets/proto_1/createAccount.png";
import paymentIcon from "assets/proto_1/payment.png";
import userRoleIcon from "assets/proto_1/user_gear.png";
import orgOnboard from "assets/proto_1/orgOnboard.png";
import updateAttributes from "assets/proto_1/updateAttributes.png";
import chartIcon from "assets/proto_1/chart.png";
import addSubscriptionIcon from "assets/proto_1/addSubscription.png";
import updateSubscriptionIcon from "assets/proto_1/updateSubscription.png";
import changeSubscriptionIcon from "assets/proto_1/changeSubscription.png";
import monitorRequests from "assets/proto_1/monitorRequests.png";
import accountBucketIcon from "assets/proto_1/account-bucket.png";

import { GlobalRole } from "Constants";
import useStyles from "routes/TableStyles";
import properties from "./properties/ApplicationProps";
import { useContext } from "react";
import { FontContext } from "./UI/Theme";

const isDisabled =
	GlobalRole.userrole === properties.userRoles.billing ||
	GlobalRole.userrole === properties.userRoles.member ||
	GlobalRole.userrole === properties.userRoles.user;
const isPayment =
	GlobalRole.userrole === properties.userRoles.user || GlobalRole.userrole === properties.userRoles.member;

export const cardData = [
	{
		title: "Organisation Access Control",
		content: "Update Organisation Access Policies",
		icon: (
			<img
				src={orgOnboard}
				alt="orgOnboardIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isPayment,
		testId: "settings-update-organisation",
	},

	{
		title: "Access Domain Management (DNS)",
		content: "Manage Custom Subdomain Redirection",
		icon: (
			<img
				src={updateAttributes}
				alt="updateAttributeIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isDisabled,
		testId: "settings-custom-subdomains",
	},
	{
		title: "Link Accounts",
		content: "Manually Onboard Colleagues",
		icon: (
			<img
				src={linkAccountIcon}
				alt="linkAccountIcon"
				style={{
					height: "50px",
					width: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isDisabled,
		testId: "settings-link-accounts",
	},
	{
		title: "Change Ownership",
		content: "Migrate Data Ownership",
		icon: (
			<img
				src={changeOwnershipIcon}
				alt="changeOwnershipIcon"
				style={{
					height: "50px",
					width: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isDisabled,
		testId: "settings-change-ownership",
	},
	{
		title: "Create Group",
		content: "Create Groups For Collaboration",
		icon: (
			<img
				src={createGrpIcon}
				alt="createGrpIcon"
				style={{
					height: "50px",
					width: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		// disabled: isDisabled,
		testId: "settings-create-group",
	},
	{
		title: "Manage Groups",
		content: "Manage Group Members",
		icon: (
			<img
				src={viewGrpIcon}
				alt="viewGrpIcon"
				style={{
					height: "50px",
					width: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		// disabled: isDisabled,
		testId: "settings-view-group",
	},
	{
		title: "Role-Based Access Control(RBAC)",
		content: "Modify User Roles",
		icon: (
			<img
				src={userRoleIcon}
				alt="userRoleIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isPayment,
		testId: "settings-user-role",
	},
	{
		title: "Monitor Usage",
		content: "Drill Down Data Usage Trends",
		icon: (
			<img
				src={chartIcon}
				alt="dataUsageIcon"
				style={{
					height: "50px",
					width: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		// disabled: isDisabled,
		testId: "settings-data-usage",
	},

	{
		title: "Payment",
		content: "Make Online Payments",
		icon: (
			<img
				src={paymentIcon}
				alt="paymentIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isPayment,
		testId: "settings-payment",
	},
	{
		title: "Update Invoice",
		content: "Support - Manual Invoice Changes",
		icon: (
			<img
				src={paymentIcon}
				alt="paymentIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isPayment,
		testId: "generate-invoice",
	},

	{
		title: "Update Atrribute",
		content: "Support - Update User's Static Data",
		icon: (
			<img
				src={updateAttributes}
				alt="updateAttributeIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isPayment,
		testId: "settings-update-attribute",
	},
	{
		title: "Add Subscription",
		content: "Support - Introduce Subscription or Discount",
		icon: (
			<img
				src={addSubscriptionIcon}
				alt="updateAttributeIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isPayment,
		testId: "settings-add-subscription",
	},
	{
		title: "Update Subscription Plan",
		content: "Support - Update Existing Subscription or Discount",
		icon: (
			<img
				src={updateSubscriptionIcon}
				alt="updateAttributeIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isDisabled,
		testId: "settings-update-subscription",
	},
	{
		title: "Change Subscription",
		content: "Support - Change Subscription",
		icon: (
			<img
				src={changeSubscriptionIcon}
				alt="updateAttributeIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isDisabled,
		testId: "settings-change-subscription",
	},
	{
		title: "Monitor Requests",
		content: "Support - Drill Down Requests",
		icon: (
			<img
				src={monitorRequests}
				alt="updateAttributeIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isDisabled,
		testId: "settings-generate-datausage",
	},
	{
		title: "Account-Bucket Management",
		content: "Support - Account-Bucket Management",
		icon: (
			<img
				src={accountBucketIcon}
				alt="updateAttributeIcon"
				style={{
					width: "50px",
					height: "50px",
					// marginRight: "5px",
					marginLeft: 0,
				}}
			></img>
		),
		disabled: isDisabled,
		testId: "settings-account-bucket-management",
	},
];

export default function SettingCard({ icon, title, content, disabled, testId }) {
	const classes = useStyles();
	const { fontFamily, smallTextSize, largeTextSize } = useContext(FontContext);
	console.log(testId);
	return (
		<Box className={classes.settingCardStyles}>
			<Card
				variant="outlined"
				sx={{ width: "100%", height: "250px" }}
				disabled={isDisabled}
				data-test-id={testId}
			>
				<CardContent className="icon">
					<div className={classes.settingCardIcon}>{icon}</div>
					<div>
						<Typography
							variant="h6"
							sx={{ fontFamily: fontFamily, fontSize: largeTextSize }}
						>
							{title}
						</Typography>
					</div>
					<div>
						<Typography
							variant="body1"
							sx={{ marginBottom: "0px", fontFamily: fontFamily, fontSize: smallTextSize }}
						>
							{content}
						</Typography>
					</div>
					{/* <div style={{ marginTop: "25px" }}> */}
					<CardActions className={classes.settingCardButton}>
						<Button
							size="small"
							disabled={disabled}
							style={{ fontFamily: fontFamily }}
						>
							Continue
						</Button>
					</CardActions>
					{/* </div> */}
				</CardContent>
			</Card>
		</Box>
	);
}
