import React, { useState, useEffect } from "react";
import { FontSizes, fontFamilies } from "assets/css/Style1";

export const FontContext = React.createContext();

function FontProvider(props) {
	const [fontFamily, setFontFamily] = useState("Arial");
	const [themeColor, setThemeColor] = useState("");
	const [setFilter] = useState("");
	const [smallTextSize, setSmallTextSize] = useState(12);
	const [largeTextSize, setLargeTextSize] = useState(14);

	useEffect(() => {
		const storedFontFamily = localStorage.getItem("fontFamily");
		const storedSmallTextSize = parseInt(localStorage.getItem("smallTextSize"), 10);
		const storedLargeTextSize = parseInt(localStorage.getItem("largeTextSize"), 10);

		if (storedFontFamily) {
			setFontFamily(storedFontFamily);
		}
		if (!isNaN(storedSmallTextSize)) {
			setSmallTextSize(storedSmallTextSize);
		}
		if (!isNaN(storedLargeTextSize)) {
			setLargeTextSize(storedLargeTextSize);
		}
	}, []);

	const changeFontFamily = () => {
		const textElement = document.querySelector("#text");
		const currentIndex = fontFamilies.indexOf(fontFamily);
		const nextIndex = (currentIndex + 1) % fontFamilies.length;

		textElement.style.fontFamily = fontFamilies[nextIndex];
		setFontFamily(fontFamilies[nextIndex]);
		localStorage.setItem("fontFamily", fontFamilies[nextIndex]);
	};

	const changeFontSize = () => {
		const textElement = document.querySelector("#text");
		const smallTextIndex = FontSizes.indexOf(smallTextSize);
		const largeTextIndex = FontSizes.indexOf(largeTextSize);
		const smallTextNextIndex = (smallTextIndex + 1) % FontSizes.length;
		const largeTextNextIndex = (largeTextIndex + 1) % FontSizes.length;

		textElement.style.fontSize = `${FontSizes[smallTextNextIndex]}px`;

		if (smallTextNextIndex === 2 && largeTextNextIndex === 0) {
			setSmallTextSize(FontSizes[0]);
			setLargeTextSize(FontSizes[1]);
		} else {
			setSmallTextSize(FontSizes[smallTextNextIndex]);
			setLargeTextSize(FontSizes[largeTextNextIndex]);
		}

		localStorage.setItem("smallTextSize", FontSizes[smallTextNextIndex]);
		localStorage.setItem("largeTextSize", FontSizes[largeTextNextIndex]);
	};

	const darkTheme = () => {
		setThemeColor("#ffffff");
		setFilter("invert(1)");
	};

	const lightTheme = () => {
		setThemeColor("#ffffff");
		setFilter("invert(0)");
	};

	return (
		<FontContext.Provider
			value={{
				fontFamily,
				changeFontFamily,
				changeFontSize,
				lightTheme,
				darkTheme,
				themeColor,
				smallTextSize,
				largeTextSize,
			}}
		>
			{props.children}
		</FontContext.Provider>
	);
}

export default FontProvider;
