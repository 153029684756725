import React from "react";
// import "../../assets/css/Table.css";
import "../../../assets/css/Table.css";
import { useDispatch } from "react-redux";

import "assets/css/Dropzone.css";

import DataUsageReportComponent from "../index";

const DisplayDataUsageReport = ({ subscriptionDetails }) => {
	console.log("Subscriptiondetails", subscriptionDetails);
	const dispatch = useDispatch();

	return (
		<div
			className="data-usage-report"
			style={{
				height: "100%",
			}}
			id="data-usage-report-display"
		>
			<DataUsageReportComponent resourcesData={subscriptionDetails} />
		</div>
	);
};

export default React.memo(DisplayDataUsageReport);
