import React from "react";
import Button from "@mui/material/Button";
const CustomButton = ({ text, onClick, ...props }) => {
	return (
		<Button
			variant="contained"
			onClick={onClick}
			{...props}
		>
			{text}
		</Button>
	);
};
export default CustomButton;
