import numeral from "numeral";

const columnDefinations = [
	{
		field: "name",
		flex: 1,
		checkboxSelection: true,
		headerCheckboxSelection: true,
		editable: false,
		minWidth: 200,
		headerName: "Group Name",
		sort: "asc",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			comparator: (a, b) => {
				console.log(a, b);
			},
			filterValueGetter: (a, b) => {
				console.log(a, b);
			},
		},
		cellRenderer: fileNameRendere,
	},

	{
		field: "description",
		headerName: "Group Description",
		width: 150,
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		cellRenderer: groupDescription,
	},
	{
		field: "lastupdatetime",
		headerName: "Last Updated Time",
		width: 200,
		filter: "agDateColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			comparator: (filterLocalDateAtMidnight, cellValue) => {
				/**
				 * we store datae a long value, so we need to convert it string
				 * as dd/mm/yyyy format
				 */
				const dateAsString = new Date(cellValue).toLocaleDateString("en-GB");
				if (dateAsString == null) {
					return 0;
				}
				const dateParts = dateAsString.split("/");
				const year = Number(dateParts[2]);
				const month = Number(dateParts[1]) - 1;
				const day = Number(dateParts[0]);
				const cellDate = new Date(year, month, day);
				// Now that both parameters are Date objects, we can compare
				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				} else if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				return 0;
			},
		},
		valueFormatter: (param) => {
			return new Date(param.data.fields.lastupdatetime).toLocaleString([], { hour12: true });
		},
	},
	{
		headerName: "Last Updated User",
		field: "lastupdateuser",
		width: 370,
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		cellRenderer: lastUpdatedUser,
	},
];

export default columnDefinations;

function fileNameRendere(param) {
	const groupFields = param.data.fields;

	// Set data-cy attribute to help cypress to capture element
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${groupFields.name}`;

	return (
		<>
			<span
				// style={{ marginLeft: "2rem" }}
				data-cy={`group-name-${groupFields.name}`}
			>
				{groupFields.name}
			</span>
		</>
	);
}

function groupDescription(param) {
	const groupFields = param.data.fields;

	// Set data-cy attribute to help cypress to capture element
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${groupFields.description}`;

	return (
		<>
			<span
				// style={{ marginLeft: "2rem" }}
				data-cy={`group-description-${groupFields.description}`}
			>
				{groupFields.description}
			</span>
		</>
	);
}

function lastUpdatedUser(param) {
	const groupFields = param.data.fields;

	// Set data-cy attribute to help cypress to capture element
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${groupFields.lastupdateuser}`;

	return (
		<>
			<span
				// style={{ marginLeft: "2rem" }}
				data-cy={`group-lastupdateuser-${groupFields.lastupdateuser}`}
			>
				{groupFields.lastupdateuser}
			</span>
		</>
	);
}
