import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { FontContext } from "components/UI/Theme";
import columnDefinations from "./columnDefinations";
import AgGridFooter from "./agGridFooter";
import AgContextHeader from "./AgContextHeader";
import { useLocation } from "react-router-dom";
import Select from "react-select";

const AccountUsageData = ({
	resourcesData,
	gridContainerRef,
	fontFamily,
	resourceContainerRef,
	gridRef,
	colDefs,
	rowData,
	selectedResources,
	totalDataUsageAccount,
	selectedTable,
	handleTableChange,
	disableUserUsage,
}) => {
	const currentPageRoute = useLocation().pathname;

	const handleRefreshAction = useCallback(() => {
		if (currentPageRoute.includes("generatedatausage")) {
			console.log("refresh header clicked");
		}
	}, [currentPageRoute]);

	const handleRemoveAllFilters = useCallback(() => {
		if (currentPageRoute.includes("generatedatausage")) {
			gridRef.current?.api.setFilterModel(null);
		}
	}, [currentPageRoute]);

	const contextActionsList = useMemo(
		() => ({
			removeAllFilters: handleRemoveAllFilters,
			refresh: handleRefreshAction,
		}),
		[handleRemoveAllFilters, handleRefreshAction],
	);

	const tableOptions = [
		{ value: "Account Usage", label: "Account Usage" },
		{ value: "User Usage", label: "User Usage", isDisabled: disableUserUsage },
	];

	return (
		<div
			ref={gridContainerRef}
			className="grid-container"
			style={{ fontFamily }}
		>
			<header style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Select
					id="tableSelect"
					value={tableOptions.find((option) => option.value === selectedTable)}
					onChange={handleTableChange}
					options={tableOptions}
					isOptionDisabled={(option) => option.isDisabled}
				/>
				<AgContextHeader contextActions={contextActionsList} />
			</header>
			<main
				ref={resourceContainerRef}
				id="resource-container"
				className="ag-theme-quartz"
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
			>
				<AgGridReact
					sortingOrder={["asc", "desc"]}
					rowMultiSelectWithClick
					deltaRowDataMode
					suppressContextMenu
					suppressDragLeaveHidesColumns
					suppressCellFocus
					suppressScrollOnNewData
					preventDefaultOnContextMenu
					ref={gridRef}
					columnDefs={colDefs}
					rowData={rowData}
					rowSelection="multiple"
					overlayNoRowsTemplate="<span id='norow-overlay'>No Resources Found!</span>"
					gridOptions={{
						headerHeight: 35,
						rowHeight: 35,
					}}
				/>
			</main>
			<AgGridFooter
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				rowData={rowData}
				selectedResources={selectedResources}
				totalDataUsageAccount={totalDataUsageAccount}
			/>
		</div>
	);
};

const UserUsageData = ({
	resourcesData,
	gridContainerRef,
	fontFamily,
	resourceContainerRef,
	gridRef,
	colDefs,
	rowData,
	selectedResources,
	totalDataUsageUser,
	selectedTable,
	handleTableChange,
	disableUserUsage,
}) => {
	const tableOptions = [
		{ value: "Account Usage", label: "Account Usage" },
		{ value: "User Usage", label: "User Usage", isDisabled: disableUserUsage },
	];
	const currentPageRoute = useLocation().pathname;
	const handleRefreshAction = useCallback(() => {
		if (currentPageRoute.includes("generatedatausage")) {
			console.log("refresh header clicked");
		}
	}, [currentPageRoute]);

	const handleRemoveAllFilters = useCallback(() => {
		if (currentPageRoute.includes("generatedatausage")) {
			gridRef.current?.api.setFilterModel(null);
		}
	}, [currentPageRoute]);

	const contextActionsList = useMemo(
		() => ({
			removeAllFilters: handleRemoveAllFilters,
			refresh: handleRefreshAction,
		}),
		[handleRemoveAllFilters, handleRefreshAction],
	);
	return (
		<div
			ref={gridContainerRef}
			className="grid-container"
			style={{ fontFamily }}
		>
			<header style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Select
					id="tableSelect"
					value={tableOptions.find((option) => option.value === selectedTable)}
					onChange={handleTableChange}
					options={tableOptions}
					isOptionDisabled={(option) => option.isDisabled}
				/>
				<AgContextHeader
					contextActions={contextActionsList}
					gridApi={gridRef}
				/>
			</header>
			<main
				ref={resourceContainerRef}
				id="resource-container"
				className="ag-theme-quartz"
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
			>
				<AgGridReact
					sortingOrder={["asc", "desc"]}
					rowMultiSelectWithClick
					deltaRowDataMode
					suppressContextMenu
					suppressDragLeaveHidesColumns
					suppressCellFocus
					suppressScrollOnNewData
					preventDefaultOnContextMenu
					ref={gridRef}
					columnDefs={colDefs}
					rowData={rowData}
					rowSelection="multiple"
					overlayNoRowsTemplate="<span id='norow-overlay'>No Resources Found!</span>"
					gridOptions={{
						headerHeight: 35,
						rowHeight: 35,
					}}
				/>
			</main>
			<AgGridFooter
				style={{ fontFamily: "inherit", fontSize: "inherit" }}
				rowData={rowData}
				selectedResources={selectedResources}
				totalDataUsageUser={totalDataUsageUser}
			/>
		</div>
	);
};

const DataUsageReportComponent = ({ resourcesData }) => {
	const gridRef = useRef();
	const resourceContainerRef = useRef();
	const gridContainerRef = useRef();
	const { fontFamily } = useContext(FontContext);
	const colDefs = columnDefinations;
	const { selectedResources, showGridProgressbar } = useSelector(({ resourceGrid }) => resourceGrid);
	const [selectedTable, setSelectedTable] = useState("Account Usage");
	const [totalDataUsageUser, setTotalDataUsageUser] = useState("");
	const [totalDataUsageAccount, setTotalDataUsageAccount] = useState("");
	const [disableUserUsage, setDisableUserUsage] = useState(false);

	const location = useLocation();
	const currentPageRoute = location.pathname;

	useEffect(() => {}, [currentPageRoute]);

	const rowDataForAccount = useMemo(() => {
		console.log("resourcesData", resourcesData);
		const resources = resourcesData.dataUsageDetailsList.map((data) =>
			data.dayWiseDataUsage.map((data, i) => data),
		);
		setTotalDataUsageAccount(resourcesData.dataUsageDetailsList[0].totalDataUsage);
		console.log("Row Data for Account: ", resources[0]);
		return resources[0];
	}, [resourcesData]);

	const rowDataForUser = useMemo(() => {
		console.log("resourcesData", resourcesData);
		const resources = resourcesData.dataUsageDetailsList.map((data) =>
			data.dayWiseDataUsage.map((data, i) => data),
		);

		console.log("Row Data for User: ", resources[1]);

		if (resources[1] === undefined) {
			setDisableUserUsage(true);
			setTotalDataUsageUser("");
		} else {
			setTotalDataUsageUser(resourcesData.dataUsageDetailsList[1].totalDataUsage);
		}
		return resources[1];
	}, [resourcesData]);

	const handleTableChange = (selectedOption) => {
		setSelectedTable(selectedOption.value);
	};

	return (
		<>
			<div style={{ fontFamily: "inherit", fontSize: "inherit", height: "100%" }}>
				{selectedTable === "Account Usage" && (
					<AccountUsageData
						id="accountUsageData"
						resourcesData={resourcesData}
						gridContainerRef={gridContainerRef}
						fontFamily={fontFamily}
						resourceContainerRef={resourceContainerRef}
						gridRef={gridRef}
						colDefs={colDefs}
						rowData={rowDataForAccount}
						selectedResources={selectedResources}
						totalDataUsageAccount={totalDataUsageAccount}
						selectedTable={selectedTable}
						handleTableChange={handleTableChange}
						disableUserUsage={disableUserUsage}
					/>
				)}
				{selectedTable === "User Usage" && (
					<UserUsageData
						id="userUsageData"
						resourcesData={resourcesData}
						gridContainerRef={gridContainerRef}
						fontFamily={fontFamily}
						resourceContainerRef={resourceContainerRef}
						gridRef={gridRef}
						colDefs={colDefs}
						rowData={rowDataForUser}
						selectedResources={selectedResources}
						totalDataUsageUser={totalDataUsageUser}
						selectedTable={selectedTable}
						handleTableChange={handleTableChange}
						disableUserUsage={disableUserUsage}
					/>
				)}
			</div>
		</>
	);
};

export default memo(DataUsageReportComponent);
