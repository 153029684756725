import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import invoice_failure from "assets/proto_1/invoice_failure.png";

import useStyles from "routes/TableStyles";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";

export const cardData = [
	{
		title: "Due Date Not Reached Yet",
		content: "Invoice gets generated after 30days",
		icon: (
			<img
				src={invoice_failure}
				alt="invoice_failure"
				style={{
					height: "50px",
					width: "50px",
					marginLeft: 0,
				}}
			></img>
		),
		// disabled: isDisabled,
		testId: "invoice-failure",
	},
];

export default function InvoiceCard({ icon, title, content, disabled, testId }) {
	console.log("CONTENT::",content)
	const classes = useStyles();
	const { fontFamily, smallTextSize, largeTextSize } = useContext(FontContext);
	console.log(testId);
	return (
		<Box className={classes.settingCardStyles}>
			<Card
				variant="outlined"
				sx={{
					width: "100%",
					height: "250px",
					boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1)",
				}}
				// disabled={isDisabled}
				data-test-id={testId}
			>
				<CardContent className="icon">
					<div className={classes.settingCardIcon}>{icon}</div>
					<div>
						<Typography
							variant="h6"
							align="center"
							sx={{ fontFamily: fontFamily, fontSize: largeTextSize }}
							style={{ marginTop: "20px" }}
						>
							{title}
						</Typography>
					</div>

					<div>
						<Typography
							variant="body1"
							sx={{ marginBottom: "0px", fontFamily: fontFamily, fontSize: smallTextSize }}
							style={{ marginTop: "20px" }}
						>
							{content}
						</Typography>
					</div>

					<CardActions className={classes.settingCardButton}>
						<Button
							size="small"
							disabled={disabled}
							style={{ fontFamily: fontFamily }}
						>
							Back
						</Button>
					</CardActions>
					{/* </div> */}
				</CardContent>
			</Card>
		</Box>
	);
}
