import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop } from "@mui/material";
import { HIDE_USER_SUMMERY_POPUP } from "store/uiSlice";
import { fetchUserProfileSummeryThunk } from "store/userDetailsSlice";
import SimpleView from "./SimpleView";
import DetailedView from "./DetailedView";

import "./UserSummery.styles.css";

const UserSummery = () => {
	const dispatch = useDispatch();
	const userAuthDetails = useSelector(({ authDetails }) => authDetails.data);
	const showDetailedView = useSelector(({ ui }) => ui.showUserDetailedView);
	const { showUserSummery } = useSelector(({ ui }) => ui);
	const { data: userSummery, loading, error } = useSelector(({ userDetails }) => userDetails);

	const ErrorFetchingSummery = memo(() => (
		<div
			style={{
				display: "grid",
				placeItems: "center",
				width: "100%",
			}}
		>
			<p>{error}</p>
		</div>
	));

	useEffect(() => {
		dispatch(fetchUserProfileSummeryThunk());
	}, [dispatch]);

	return (
		<div className="user-summery-container">
			<Backdrop
				className="summery-backdrop"
				open={showUserSummery}
				onClick={(e) => {
					if (!e.target.classList.contains("summery-backdrop")) {
						return;
					}

					dispatch(HIDE_USER_SUMMERY_POPUP());
				}}
			>
				<div className="user-summery-popup">
					<p
						className="popup-close"
						onClick={() => dispatch(HIDE_USER_SUMMERY_POPUP())}
					>
						X
					</p>

					{!!!error && loading === false ? (
						<div className="user-summeries-container">
							{showDetailedView ? (
								<DetailedView
									userAuthDetails={userAuthDetails}
									userSummery={userSummery}
								/>
							) : (
								<SimpleView
									userAuthDetails={userAuthDetails}
									userSummery={userSummery}
								/>
							)}
						</div>
					) : (
						<ErrorFetchingSummery />
					)}
				</div>
			</Backdrop>
		</div>
	);
};

export default memo(UserSummery);
