import React from "react";
import { withRouter } from "react-router-dom";
import "./Style.css";
function ContactSupport() {
	return (
		<div className="app">
			<div className="login-form">
				<div className="title">Contact Support</div>
				<div className="form">
					<form>
						<div className="input-container">
							<label>support@datafrugal.com </label>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default withRouter(ContactSupport);
