import properties from "components/properties/ApplicationProps";
import { User } from "oidc-client-ts";

const getUser = () => {
	const oidcStorage = localStorage.getItem(
		`oidc.user:${window.location.origin}/auth/realms/${properties.defaultKeycloakRealm}:${properties.defaultKeycloakClient}`,
	);
	if (!oidcStorage) {
		return null;
	}

	return User.fromStorageString(oidcStorage);
};

export default getUser;
