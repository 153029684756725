import React, { useState, useEffect, useCallback, useContext, memo } from "react";
import { createPortal } from "react-dom";
import { AppBar, Toolbar, IconButton, Menu, Tooltip, ListItemIcon, Collapse } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import classNames from "classnames";
import useStyles from "./styles";
import { Typography } from "../../../routes/Wrappers";
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "../../../routes/LayoutContext";
import { Link, useHistory } from "react-router-dom";
import { GlobalFirstname, GlobalProfile, GlobalRole, GlobalUsername } from "Constants";
import properties from "components/properties/ApplicationProps";
import Search from "./Search";

import { useDispatch, useSelector } from "react-redux";
import { RESET_TOTAL_UPLOADS_ETC, UPDATE_TOTAL_UPLOADS_ETC, TOGGLE_NOTIFICATION_PANEL } from "store/uploadStatsSlice";

import { Avatar, ListItemAvatar } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import eventEmitter from "components/misc/eventEmitter";
import { ListItemText } from "@mui/material";

// Proto_1 icons
import logoutIcon from "assets/proto_1/logout.png";
import userIcon from "assets/proto_1/user.png";
import themeIcon from "assets/proto_1/theme.png";
import darkthemeIcon from "assets/proto_1/darktheme.png";
import lightthemeIcon from "assets/proto_1/lightTheme.png";
import fontIcon from "assets/proto_1/fontdesign.png";
import up_down_arrows_icon from "assets/proto_1/up_down_arrows.png";
import thememodeIcon from "assets/proto_1/thememode.png";
import refreshCacheIcon from "assets/proto_1/refreshCache.png";

import AdvanceSearch from "./AdvSearch";
import { FontContext } from "components/UI/Theme";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import UserSummery from "components/UI/UserSummery";
import { RESET_DARK_THEME_ON, SET_DARK_THEME_ON, SHOW_USER_SUMMERY_POPUP } from "store/uiSlice";
import NotificationsPanel from "components/NotificationsPanel";
import { useAuth } from "react-oidc-context";
import { RESET_SEARCH_SLICE, SAVE_SEARCH_CRITERIA } from "store/searchSlice";
import { RESET_SEARCH_UP_LOCATION_ID_SLICE, UPDATE_SEARCH_BREADCRUMB_NAME } from "store/gridNavigationSlice";
import { refreshCacheThunk } from "store/refreshCacheSlice";
import Notification from "../notification/Notification";

const Header = () => {
	const auth = useAuth();
	const classes = useStyles();
	const layoutState = useLayoutState();
	const layoutDispatch = useLayoutDispatch();
	const dispatch = useDispatch();
	const { notifications, showNotificationPanelForceFully } = useSelector(({ uploadStats }) => uploadStats);
	// const throttledNotification = useThrottledValue({ value: notifications });
	const { operationComplete } = useSelector(({ fileUpload }) => fileUpload);
	//const totalFilesSize = numeral(allFilesSize.reduce((a, b) => a + b, 0)).format("0.00b");
	const totalUploadingDone = operationComplete.length;
	const { fontFamily, changeFontFamily, smallTextSize, changeFontSize, largeTextSize } = useContext(FontContext);

	let totalUploads = Object.keys(notifications).length;

	const [profileMenu, setProfileMenu] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const history = useHistory();
	const [openSearchDialog, setOpenSearchDialog] = useState(false);
	const [themeOpen, setThemeOpen] = useState(null);
	const [openDropdown, setOpenDropdown] = React.useState(true);
	const [openDropdownfont, setOpenDropdownfont] = React.useState(true);
	const { showUserSummery, isDarkThemeOn } = useSelector(({ ui }) => ui);

	const modalEl = document.querySelector("#modal");
	const notificationsPanelEl = document.querySelector("#notifications-panel");

	/**
	 *  Following code detects style changes to html tag.
	 *	So that we update redux value 'isDarkThemeOn'.
	 * */
	const htmlEl = document.querySelector("html");
	const observer = new MutationObserver((mutations) => {
		mutations.forEach(function (mutationRecord) {
			const isDarkThemeOn = mutationRecord.target.style.filter === "invert(1)";
			if (isDarkThemeOn) {
				dispatch(SET_DARK_THEME_ON());
			} else {
				dispatch(RESET_DARK_THEME_ON());
			}
		});
	});
	observer.observe(htmlEl, { attributes: true, attributeFilter: ["style"] });
	/* ----------------------------------- End ---------------------------------- */

	const handleClickDropdown = () => {
		setOpenDropdown(!openDropdown);
	};
	const handleClickDropdownFont = () => {
		setOpenDropdownfont(!openDropdownfont);
	};

	// Don't delete! handle remaining time for uploads
	// useEffect(() => {
	// 	if (totalUploads === 0) {
	// 		return;
	// 	}

	// 	const removeInterval = setInterval(() => {
	// 		console.log("Calling Uploads ETC interval");
	// 		dispatch(UPDATE_TOTAL_UPLOADS_ETC());
	// 	}, 1000);

	// 	if (totalUploadingDone === totalUploads) {
	// 		console.log("Clearning Uploads ETC interval");
	// 		dispatch(RESET_TOTAL_UPLOADS_ETC());
	// 		clearTimeout(removeInterval);
	// 	}

	// 	return () => clearTimeout(removeInterval);
	// }, [totalUploadingDone, totalUploads, dispatch]);

	// const handleContinueUpload = () => {
	// 	setOpenDialog(false);
	// };

	// const handleStopUpload = () => {
	// 	setOpenDialog(false);
	// 	setOpen1(false);
	// };
	const handleLogInOut = useCallback(() => {
		if (auth.isAuthenticated) {
			//set logout_post_url in  kc
			auth.signoutRedirect();
			sessionStorage.removeItem("keycloakToken");
			sessionStorage.clear();
		} else {
			auth.signinRedirect();
		}
	}, [auth]);

	const handleSearchResources = useCallback(
		async (searchTerm) => {
			try {
				/* ------ 🔴 Warning!!! Do not re-arrange or re-order following code 🔴 ----- */
				dispatch(RESET_SEARCH_SLICE());
				dispatch(SAVE_SEARCH_CRITERIA(searchTerm));
				dispatch(RESET_SEARCH_UP_LOCATION_ID_SLICE());
				dispatch(UPDATE_SEARCH_BREADCRUMB_NAME(searchTerm));
				/* -------------------------------- 🔴 End 🔴 ------------------------------- */
			} catch (error) {
				console.error("An error occurred while searching for users:", error);
			}
		},
		[dispatch],
	);

	React.useEffect(() => {
		eventEmitter.on("clearSearchState", (newMessage) => {
			console.log("clear searchterm::", newMessage);
			setSearchTerm("");
		});
	}, []);

	const handleInputChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (searchTerm.trim().length > 2 && searchTerm !== "") {
				history.push("/search");
				properties.parentFolderId = -1;
				handleSearchResources(searchTerm);
			}
		}
	};

	const handleClearSearch = () => {
		setSearchTerm("");
	};

	function handleOpenDialog() {
		console.log("search dialog");
		setOpenSearchDialog(true);
	}
	const handleCloseDialog = () => {
		setOpenSearchDialog(false);
	};
	const dashboardPage = () => {
		history.push("/dashboard");
	};

	const handleCacheRefresh = useCallback(() => {
		dispatch(refreshCacheThunk());
	}, [dispatch]);

	return (
		<>
			<AppBar
				position="fixed"
				id="header"
				className={classes.appBar}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton
						onClick={() => toggleSidebar(layoutDispatch)}
						className={classNames(classes.headerMenuButtonSandwich, classes.headerMenuButtonCollapse)}
					>
						{layoutState.isSidebarOpened ? (
							<MenuIcon
								classes={{
									root: classNames(classes.headerIcon, classes.headerIconCollapse),
								}}
							/>
						) : (
							<MenuIcon
								classes={{
									root: classNames(classes.headerIcon, classes.headerIconCollapse),
								}}
							/>
						)}
					</IconButton>
					<Typography>
						<div
							className={classes.logotype}
							onClick={dashboardPage}
							id="text"
							data-test-id="datafrugal-logo-container"
							style={{ fontSize: largeTextSize }}
						>
							<img
								className={classes.orgLogoIcon}
								src={properties.orgProperties.orgIcon}
								alt="org logo"
							/>
							{properties.orgProperties.orgNameInHeader}
						</div>
					</Typography>

					<div className={classes.grow} />

					<Search
						searchTerm={searchTerm}
						handleSearchTermChange={handleInputChange}
						handleClearSearch={handleClearSearch}
						handleKeyPress={handleKeyPress}
						handleOpenDialog={handleOpenDialog}
						className={classes.search}
					/>

					<Tooltip title="Show Upload Status">
						<IconButton
							// color="#ffffff"
							aria-haspopup="true"
							aria-controls="mail-menu"
							onClick={() => {
								dispatch(TOGGLE_NOTIFICATION_PANEL());
							}}
							className={classes.headerMenuNotificationButton}
							data-test-id="header-upload-status"
						>
							{totalUploads ? (
								<Typography
									style={{
										color: "#fff",
										backgroundColor: "#12a5c6",
										position: "absolute",
										top: "-10px",
										left: "15px",
										fontSize: "12px",
										padding: "1px 5px",
										borderRadius: "10px",
										textAlign: "center",
										lineHeight: "1",
									}}
								>
									{totalUploads}
								</Typography>
							) : null}
							<img
								src={up_down_arrows_icon}
								alt="notification_icon"
								className={classes.iconStyle}
								classes={{ root: classes.headerIcon }}
							/>
						</IconButton>
					</Tooltip>

					<Tooltip title="Theme Customization">
						<IconButton
							aria-haspopup="true"
							color="inherit"
							className={classes.headerMenuProfileButton}
							aria-controls="theme-menu"
							data-test-id="header-theme-menu"
							onClick={(e) => setThemeOpen(e.currentTarget)}
						>
							<img
								src={themeIcon}
								alt="theme_icon"
								className={classes.iconStyle}
								classes={{ root: classes.headerIcon }}
							/>
						</IconButton>
					</Tooltip>

					<Tooltip title={GlobalUsername.username}>
						<IconButton
							aria-haspopup="true"
							color="inherit"
							className={classes.headerMenuProfileButton}
							aria-controls="profile-menu"
							data-test-id="header-profile-menu"
							onClick={(e) => setProfileMenu(e.currentTarget)}
							style={{ backgroundColor: "white" }}
						>
							<img
								src={GlobalProfile.profile || logoutIcon}
								style={{
									borderRadius: "50%",
									width: "25px",
									height: "25px",
									backgroundColor: "white",
									filter: isDarkThemeOn && "invert(1)",
								}}
								alt="profile_icon"
								className={classes.iconStyle}
								classes={{ root: classes.headerIcon }}
							/>
						</IconButton>
					</Tooltip>

					<Menu
						id="theme-menu"
						open={Boolean(themeOpen)}
						anchorEl={themeOpen}
						onClose={() => setThemeOpen(null)}
						className={classes.headerMenu}
						disableAutoFocusItem
						disableEnforceFocus
						style={{ fontFamily: fontFamily }}
					>
						<List
							sx={{ pt: 0, pb: 0, px: 0, my: 0 }}
							style={{ fontFamily: fontFamily }}
						>
							<ListItemButton onClick={handleClickDropdown}>
								<ListItemIcon>
									<img
										src={thememodeIcon}
										alt="Theme"
										className={classes.iconStyle2}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
										Theme Mode
									</Typography>
								</ListItemText>
								{openDropdown ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse
								in={openDropdown}
								timeout="auto"
								unmountOnExit
							>
								<List
									component="div"
									disablePadding
								>
									<ListItem sx={{ pt: 0, pb: 0, px: 1, my: 0 }}>
										<ListItemButton
											autoFocus
											sx={{ pt: 0, pb: 0, px: 1, my: 0 }}
										>
											<IconButton
												onClick={() => {
													// lightTheme();
													// userSummeryPopUpEl.classList.remove("dark");
													htmlEl.style.filter = "unset";
												}}
												title="Switch to Light Theme"
											>
												<img
													src={lightthemeIcon}
													alt="Light Theme"
													className={classes.iconStyle2}
												/>
											</IconButton>
											<IconButton
												onClick={() => {
													// darkTheme();
													// userSummeryPopUpEl.classList.add("dark");
													htmlEl.style.filter = "invert(1)";
												}}
												title="Switch to Dark Theme"
											>
												<img
													src={darkthemeIcon}
													alt="Dark Theme"
													className={classes.iconStyle2}
												/>
											</IconButton>
										</ListItemButton>
									</ListItem>
								</List>
							</Collapse>

							<ListItemButton onClick={handleClickDropdownFont}>
								<ListItemIcon>
									<img
										src={fontIcon}
										alt="Theme"
										className={classes.iconStyle2}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
										Font Design
									</Typography>
								</ListItemText>
								{openDropdownfont ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse
								in={openDropdownfont}
								timeout="auto"
								unmountOnExit
							>
								<List
									component="div"
									disablePadding
								>
									<ListItem sx={{ pt: 0, pb: 0, px: 1, my: 0 }}>
										<ListItemButton
											autoFocus
											sx={{ pt: 1, pb: 1, px: 1, my: 0 }}
											onClick={() => {
												changeFontFamily("Verdana");
												modalEl.style.fontFamily = fontFamily;
											}}
											title="Change Font Family"
											data-test-id="change-font-family"
										>
											<Typography style={{ fontFamily: fontFamily, fontSize: smallTextSize }}>
												Font Style ({fontFamily})
											</Typography>
										</ListItemButton>
									</ListItem>
									<ListItem sx={{ pt: 0, pb: 0, px: 1, my: 0 }}>
										<ListItemButton
											autoFocus
											sx={{ pt: 1, pb: 1, px: 1, my: 0 }}
											onClick={() => changeFontSize(10)}
											title="Change Font Size"
											data-test-id="change-font-size"
										>
											<Typography style={{ fontFamily: fontFamily, fontSize: smallTextSize }}>
												Font Size
											</Typography>
										</ListItemButton>
									</ListItem>
								</List>
							</Collapse>
						</List>
					</Menu>

					<Menu
						id="profile-menu"
						open={Boolean(profileMenu)}
						anchorEl={profileMenu}
						onClose={() => setProfileMenu(null)}
						className={classes.headerMenu}
						disableAutoFocusItem
						disableEnforceFocus
						style={{ fontFamily: fontFamily }}
					>
						<List sx={{ pt: 0, pb: 0, px: 0, my: 0 }}>
							<ListItem
								sx={{ pt: 0, pb: 0, px: 1, my: 0 }}
								onClick={() => {
									dispatch(SHOW_USER_SUMMERY_POPUP());
									setProfileMenu(() => null);
								}}
								data-test-id="profile-menu-option"
							>
								<ListItemButton
									autoFocus
									sx={{ pt: 0, pb: 0, px: 1, my: 0 }}
								>
									<ListItemAvatar>
										<Avatar style={{ backgroundColor: "white" }}>
											<img
												src={GlobalProfile.profile || userIcon}
												style={{
													borderRadius: "50%",
													width: "25px",
													height: "25px",
													backgroundColor: "white",
													filter: isDarkThemeOn && "invert(1)",
												}}
												alt="profile_icon"
												className={classes.iconStyle}
												classes={{ root: classes.headerIcon }}
											/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText sx={{ padding: 0, color: "black" }}>
										<Typography style={{ fontFamily: fontFamily }}>
											{GlobalFirstname.firstname}
										</Typography>
									</ListItemText>
								</ListItemButton>
							</ListItem>

							{/* Cache Refresh Option For Support*/}
							{GlobalRole.userrole === properties.userRoles.support && (
								<ListItem
									sx={{ pt: 0, pb: 0, px: 1, my: 0 }}
									onClick={() => {
										handleCacheRefresh();
										setProfileMenu(() => null);
									}}
									data-test-id="cache-refresh-option"
								>
									<ListItemButton
										autoFocus
										sx={{ pt: 0, pb: 0, px: 1, my: 0 }}
									>
										<ListItemAvatar>
											<Avatar style={{ backgroundColor: "white" }}>
												<img
													src={refreshCacheIcon} // Add an appropriate icon for cache refresh
													alt="refreshCache_icon"
													className={classes.iconStyle}
													classes={{ root: classes.headerIcon }}
												/>
											</Avatar>
										</ListItemAvatar>
										<ListItemText sx={{ padding: 0, color: "black" }}>
											<Typography style={{ fontFamily: fontFamily }}>Refresh Cache</Typography>
										</ListItemText>
									</ListItemButton>
								</ListItem>
							)}

							<ListItem
								sx={{ pt: 0, pb: 0, px: 1, my: 0 }}
								component={Link}
								to={auth.signoutRedirect ? "/logout" : "/login"}
								data-test-id="auth-menu-option"
								onClick={handleLogInOut}
							>
								<ListItemButton
									autoFocus
									sx={{ pt: 0, pb: 0, px: 1, my: 0 }}
									// onClick={() => handleListItemClick('addAccount')}
								>
									<ListItemAvatar>
										<Avatar style={{ backgroundColor: "white" }}>
											<img
												src={logoutIcon}
												alt="logout_icon"
												className={classes.iconStyle}
												classes={{ root: classes.headerIcon }}
											/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText sx={{ padding: 0, color: "black" }}>
										<Typography style={{ fontFamily: fontFamily }}>
											{auth.signoutRedirect ? "Log Out" : "LOG IN"}
										</Typography>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						</List>
					</Menu>
				</Toolbar>

				<AdvanceSearch
					openSearchDialog={openSearchDialog}
					handleCloseDialog={handleCloseDialog}
					setOpenSearchDialog={setOpenSearchDialog}
				/>

				{createPortal(<UserSummery showPopup={showUserSummery} />, modalEl)}
				{createPortal(<NotificationsPanel showPopup={showNotificationPanelForceFully} />, notificationsPanelEl)}
			</AppBar>
		</>
	);
};

export default memo(Header);
