import { Button } from "@mui/material";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { HIDE_USER_DETAILED_VIEW } from "store/uiSlice";
import { neutral, textsize } from "assets/css/MainCss";

const SummeryView = memo(({ label, info }) => {
	const convertedInfo = typeof info === "object" ? JSON.stringify(info) : info;

	return (
		<div className="detailed-summery-view">
			<p className="label">{label} :</p>
			<p className="info">{String(convertedInfo)}</p>
		</div>
	);
});

const DetailedView = ({ userSummery, userAuthDetails }) => {
	const dispatch = useDispatch();

	return (
		<div className="detailed-view-container">
			<div>
				{Object.keys(userSummery).map((property) => (
					<SummeryView
						key={property}
						label={property}
						info={userSummery[property]}
					/>
				))}
			</div>

			<br />

			{/* Comment out following code to hide auth details froms UserSummery pop up */}
			<div>
				{Object.keys(userAuthDetails).map((property) => (
					<SummeryView
						key={property}
						label={property}
						info={userAuthDetails[property]}
					/>
				))}
			</div>
			{/* End */}

			<Button
				style={{
					alignSelf: "flex-start",
					backgroundColor: neutral.buttoncolor,
					color: "#fff",
					borderColor: neutral.buttoncolor,
					fontSize: textsize.buttontextsize,
					marginTop: "1rem",
				}}
				className="switch-button"
				onClick={() => {
					dispatch(HIDE_USER_DETAILED_VIEW());
				}}
			>
				Show Simple View
			</Button>
		</div>
	);
};

export default memo(DetailedView);
