import { memo, useCallback } from "react";
import contextHeaderOptionsList from "./ContextHeaderList";
import { MenuItem, MenuList } from "@material-ui/core";
import "./AgContextHeader.styles.css";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import properties from "components/properties/ApplicationProps";

const AgContextHeader = ({ contextActions, gridApi, selected }) => {
	console.log("selected", selected);
	const { pathname: currentRouteName } = useLocation();
	const { selectedResources, resourcesForCutPaste } = useSelector(({ resourceGrid }) => resourceGrid);
	const userRole = useSelector(({ authDetails }) => authDetails.data.role);
	const isUserHasPermission = userRole !== properties.userRoles.user;
	const areResourcesSelected = selected.length;
	const areResourcesAvailableForPaste = resourcesForCutPaste.length;

	const onFilterTextBoxChanged = useCallback(() => {
		gridApi.current.api.setGridOption("quickFilterText", document.getElementById("in-grid-search-input").value);
	}, [gridApi]);

	return (
		<div id="ag-context-header">
			<MenuList style={{ display: "flex" }}>
				{contextHeaderOptionsList.map((option) => {
					const isMenuItemDisabled = (option) => {
						const allowedItems = [];

						if (isUserHasPermission) {
							allowedItems.push("refresh", "search", "removeAllFilters");
							if (
								((currentRouteName.includes("mygroups") || currentRouteName.includes("search")) &&
									areResourcesSelected) ||
								areResourcesAvailableForPaste
							) {
								if (areResourcesSelected) allowedItems.push("delete");

								if (areResourcesAvailableForPaste) {
									allowedItems.push("paste");
								}
							}
						}

						return !Boolean(allowedItems.includes(option));
					};

					return (
						<Tooltip title={isMenuItemDisabled(option.action) ? "Disabled" : option.name}>
							<MenuItem
								disableRipple
								className="context-header-option"
								data-test-id={`context-header-${option.action}`}
								disabled={isMenuItemDisabled(option.action)}
								style={{ padding: 8, borderRadius: "50%", margin: "0 4px" }}
								onClick={() => contextActions[option.action]()}
							>
								{option.icon}
							</MenuItem>
						</Tooltip>
					);
				})}
			</MenuList>

			{/* <input
				type="text"
				id="in-grid-search-input"
				placeholder="Filter..."
				onInput={onFilterTextBoxChanged}
			/> */}
		</div>
	);
};

export default memo(AgContextHeader);
