import { createSlice, createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { getTopLevelFolder } from "utils/helpers/getTopLevelFolder";
import properties from "components/properties/ApplicationProps";
import { uploadFile } from "utils/helpers/uploadFile";
import { fetchDashboardResourcesThunk } from "store/dashboardSlice";
import { DEFAULT_STATS_FOR_NOTIFICATIONS, SHOW_NOTIFICATION_PANEL_FORCEFULLY } from "./uploadStatsSlice";
import { CHANGE_DROPPED_LOCATION_ID } from "./droppedLocationIdSlice";
import { createFolderHierarchy } from "utils/helpers/createFolderHierarchy";
import { p1 } from "configs/p1.configs";
import numeral from "numeral";
import addUploadTrackingProperties from "utils/helpers/addUploadTrackingProperties";
import { HIDE_BACKGROUND_PROGRESS_BAR, SET_GRID_MESSAGE, SHOW_BACKGROUND_PROGRESS_BAR } from "./uiSlice";
import { HALF_SECOND } from "Constants";

const MAX_CONCURRENT_UPLOADS = properties.asyncUploadLimit;

export const uploadFilesThunk = createAsyncThunk("fileUpload/UPLOAD_FILES", async (_, { getState, dispatch }) => {
	const { inQueuedBatches } = getState().fileUpload;

	const refreshDashboard = () => {
		dispatch(fetchDashboardResourcesThunk())
			.then(unwrapResult)
			.then(({ parentFolderId }) => {
				dispatch(CHANGE_DROPPED_LOCATION_ID(parentFolderId));
			});
	};

	try {
		dispatch(START_UPLOAD());

		const uploadQueue = [];
		const uploadPromises = [];

		for (const batch of inQueuedBatches) {
			const { droppedFiles, id, isCurrentlyUploading, isUploadingDone } = batch;

			if (isCurrentlyUploading || isUploadingDone) {
				continue;
			}

			dispatch(CHANGE_BATCH_STATUS_FOR_IS_CURRENTLY_UPLOADING(true));

			const filesInFolder = droppedFiles.filter(({ isInFolder }) => isInFolder === true);

			/**
			 * assign uploadLocationId after creating folder structure
			 * That way all files can be uploaded in concurrent manner.
			 */
			for (let i = 0; i < filesInFolder.length; i++) {
				if (filesInFolder[i].allowToUpload === false) {
					// Do not create folder for the file
					continue;
				}

				/**
				 * 🔴-----------------------------------------------------------------🔴
				 * Don't Remove `await` keyword. It stops `for loop` from going into next iteration
				 * until folder structure it created.
				 * 🔴-----------------------------------------------------------------🔴
				 */
				await createFolderHierarchy({ file: filesInFolder[i], dispatch });

				dispatch(
					SHOW_BACKGROUND_PROGRESS_BAR({
						message: "Processing",
						isDeterminate: true,
						showBackgroundProgressBar: true,
						progressValue: (i / filesInFolder.length) * 100,
					}),
				);
			}

			setTimeout(() => dispatch(HIDE_BACKGROUND_PROGRESS_BAR()), HALF_SECOND);

			// Refresh Dashboard after creating folder structure
			if (filesInFolder.length) refreshDashboard();

			dispatch(SHOW_NOTIFICATION_PANEL_FORCEFULLY());

			const allFilesUploadLocationId = [];
			const allFilesDropLocationId = [];

			const handleDashboardRefreshAfterUpload = (file) => {
				// if somehow file is failed to upload an error mssg will be shown
				if (file.error) {
					dispatch(SET_GRID_MESSAGE(`${file.name} is failed to upload`));
				}

				const currentDashboardLocationId = getState().droppedLocationId.locationId;
				allFilesUploadLocationId.push(file.uploadLocationId);
				allFilesDropLocationId.push(file.dropLocationId);

				/**
				 * Let's say there are 13 files in a queue, so first 10 files will
				 * be handled and refresh by  if statement and last 3 files
				 * will be handle by else if statement
				 */
				if (allFilesDropLocationId.length === MAX_CONCURRENT_UPLOADS) {
					if (
						allFilesDropLocationId.includes(currentDashboardLocationId) ||
						allFilesUploadLocationId.includes(currentDashboardLocationId)
					) {
						refreshDashboard();
					}
					// Empty the locations array
					allFilesDropLocationId.length = allFilesUploadLocationId.length = 0;
				} else if (
					!uploadQueue.length &&
					allFilesDropLocationId.length === droppedFiles.length % MAX_CONCURRENT_UPLOADS
				) {
					if (
						allFilesDropLocationId.includes(currentDashboardLocationId) ||
						allFilesUploadLocationId.includes(currentDashboardLocationId)
					) {
						refreshDashboard();
					}
					// Empty the locations array
					allFilesDropLocationId.length = allFilesUploadLocationId.length = 0;
				}
			};

			for (const file of droppedFiles) {
				if (file.allowToUpload === false) continue;

				const uploadTask = async () => {
					try {
						dispatch(DEFAULT_STATS_FOR_NOTIFICATIONS(file));
						await uploadFile(file, dispatch, handleDashboardRefreshAfterUpload);
						dispatch(ADD_FILES_TO_OPERATION_COMPLETE(file));
					} catch (error) {
						console.error(error);
						Object.defineProperty(file, "error", {
							value: JSON.stringify(error, Object.getOwnPropertyNames(error)),
						});
					}
				};

				uploadQueue.push(uploadTask);
			}

			const processQueue = async () => {
				while (uploadQueue.length > 0) {
					if (uploadPromises.length < MAX_CONCURRENT_UPLOADS) {
						const task = uploadQueue.shift();
						const promise = task().finally(() => {
							uploadPromises.splice(uploadPromises.indexOf(promise), 1);
						});
						uploadPromises.push(promise);
					} else {
						await Promise.race(uploadPromises);
					}
				}
			};

			await processQueue();

			const uploadedFiles = droppedFiles.filter((file) => file.isUploaded);
			const failedToUploadFiles = droppedFiles.filter((file) => !file.isUploaded);

			if (failedToUploadFiles.length) {
				dispatch(REMOVE_BATCH_FROM_QUEUE(id));
			}

			if (uploadedFiles.length === droppedFiles.length) {
				dispatch(CHANGE_BATCH_STATUS_FOR_IS_UPLOADING_DONE(id));
				dispatch(CHECK_IF_ALL_BATCHES_ARE_DONE_UPLOADING(id));
				dispatch(CHANGE_BATCH_STATUS_FOR_IS_CURRENTLY_UPLOADING(false));
			}
		}
	} catch (error) {
		console.log(error);
	} finally {
		dispatch(END_UPLOAD());
	}

	const areAllinQueuedBatchesUploaded = inQueuedBatches.every((batch) => batch.isUploadingDone);

	if (areAllinQueuedBatchesUploaded) {
		dispatch(RESET_IN_QUEUED_FILES_SLICE());
	}
});

const initialState = {
	inQueuedBatches: [],
	retryQueue: [],
	allowToPutFilesInQueue: false,
	shouldCheckForConflict: null,
	isConflicting: null,
	shouldAllowToUpload: null,
	messageForUploads: "",
	showUpdateDialogue: false,
	shouldClearAllBatches: null,
	allFilesSize: [],
	operationComplete: [],
	isUploading: false,
};

const fileUploadSlice = createSlice({
	name: "fileUpload",
	initialState,
	reducers: {
		ADD_FILES_TO_OPERATION_COMPLETE: (state, { payload }) => {
			state.operationComplete.push(payload);
		},
		/**
		 * Following action will be called whenever user tries to upload files.
		 * It will put those files in fileUpload.inQueuedBatches
		 */
		PUT_FILES_IN_QUEUE: (state, { payload }) => {
			const { droppedFiles, droppedLocationId } = payload;

			state.shouldCheckForConflict = false;
			state.shouldClearAllBatches = false;
			state.shouldAllowToUpload = false;
			state.isConflicting = false;

			const batchId = crypto.randomUUID();

			droppedFiles.forEach((file) => {
				if (file.hasOwnProperty("fileId")) {
					return;
				}

				// adds some properties to track uploading
				addUploadTrackingProperties({ file, batchId, droppedLocationId });

				state.allFilesSize.push(file.size);
			});

			// droppedFiles.forEach((file) => state.inQueuedFiles.push(file));

			state.inQueuedBatches.push({
				id: batchId,
				uploadFlag: properties.versionFlag.keep_existing_files,
				uploadLocationId: droppedLocationId,
				didUserstopUpload: false,
				didUserSetFlag: false,
				isReadyToUpload: false,
				isCurrentlyUploading: false,
				isUploadingDone: false,
				isUploadingFailed: false,
				error: null,
				droppedFiles,
			});

			// Start checking for conflicts by allowing to dispatch - HANDLE_FILES_FOR_CONFLICT action
			state.shouldCheckForConflict = true;
		},

		HANDLE_FILES_FOR_CONFLICT: (state, { payload }) => {
			state.shouldAllowToUpload = false;

			const { inQueuedBatches, currentDashboardResources, droppedLocationId, currentDashboardId } = payload;
			const namesOfCurrentDashboardResources = currentDashboardResources
				.map(({ fields }) => fields)
				.map(({ filename }) => filename);

			const checkThisBatchForConflict = inQueuedBatches[inQueuedBatches.length - 1];

			if (currentDashboardId !== droppedLocationId) {
				checkThisBatchForConflict.droppedFiles.forEach((file) => {
					Object.defineProperty(file, "uploadFlag", {
						value: properties.versionFlag.replace_existing_files,
					});
					Object.defineProperty(file, "uploadLocationId", {
						value: droppedLocationId,
					});
				});

				state.inQueuedBatches[inQueuedBatches.length - 1].uploadFlag =
					properties.versionFlag.replace_existing_files;
				state.shouldAllowToUpload = true;
				state.shouldCheckForConflict = false;
				return;
			}

			const inQueuedBatchesName = [
				...new Set(
					checkThisBatchForConflict.droppedFiles.map((file) => {
						const topLevelFolder = getTopLevelFolder(file);
						if (topLevelFolder) {
							return topLevelFolder;
						}

						return file.name;
					}),
				),
			];

			const conflictingFiles = namesOfCurrentDashboardResources.filter((resourceName) =>
				inQueuedBatchesName.includes(resourceName),
			);

			const isConflicting = conflictingFiles.length > 0;

			console.log("Look Here for Conflicts: ", isConflicting, conflictingFiles);

			state.isConflicting = isConflicting;

			if (isConflicting) {
				state.showUpdateDialogue = true;
			} else {
				state.shouldAllowToUpload = true;
			}
			state.shouldCheckForConflict = false;
		},

		HANDLE_USER_RESPONSE_FOR_CONFLICT: ({ inQueuedBatches, shouldAllowToUpload }, { payload }) => {
			console.log(inQueuedBatches[inQueuedBatches.length - 1]);

			inQueuedBatches[inQueuedBatches.length - 1].uploadFlag = payload;
			inQueuedBatches[inQueuedBatches.length - 1].droppedFiles.forEach((file) => {
				Object.defineProperty(file, "uploadFlag", { value: payload });
			});

			inQueuedBatches[inQueuedBatches.length - 1].didUserSetFlag = true;
			shouldAllowToUpload = true;
		},

		CHANGE_BATCH_STATUS_FOR_IS_READY_TO_UPLOAD: ({ inQueuedBatches }, { payload }) => {
			inQueuedBatches[inQueuedBatches.length - 1].isReadyToUpload = payload;
		},

		/**
		 * stops multiple upload requeset for same queue.
		 */
		STOP_ALLOWING_TO_UPLOAD: (state, _) => {
			state.shouldAllowToUpload = false;
		},

		CHANGE_BATCH_STATUS_FOR_IS_CURRENTLY_UPLOADING: ({ inQueuedBatches }, { payload }) => {
			inQueuedBatches[inQueuedBatches.length - 1].isCurrentlyUploading = payload;
		},

		// payload need to be batchId
		CHANGE_BATCH_STATUS_FOR_IS_UPLOADING_DONE: ({ inQueuedBatches }, { payload }) => {
			let batchIndex = null;

			inQueuedBatches.map(({ id }, idx) => {
				if (id === payload) {
					batchIndex = idx;
				}
				return null;
			});

			inQueuedBatches[batchIndex].isUploadingDone = true;
			inQueuedBatches[batchIndex].isCurrentlyUploading = false;
			inQueuedBatches[batchIndex].isUploadingFailed = false;
		},

		SHOW_UPDATE_DIALOGUE: (state, _) => {
			state.showUpdateDialogue = true;
		},
		HIDE_UPDATE_DIALOGUE: (state, _) => {
			state.showUpdateDialogue = false;
		},

		CHECK_IF_ALL_BATCHES_ARE_DONE_UPLOADING: (state, _) => {
			const areAllQueuesDoneUploading = state.inQueuedBatches.filter(
				({ isUploadingDone }) => isUploadingDone === true,
			);

			if (areAllQueuesDoneUploading.length === state.inQueuedBatches.length) {
				state.shouldClearAllBatches = true;
			}
		},

		CHECK_FOR_PERMISSIONS: (_, { payload: file }) => {
			if (file.size < p1.uploadFileLimit) {
				Object.defineProperty(file, "allowToUpload", { value: false });
				Object.defineProperty(file, "error", { value: true });
				Object.defineProperty(file, "errorMssg", {
					value: `Can not upload file less than ${numeral(p1.uploadFileLimit).format("0.00b")}.`,
				});
			}
		},

		CANCEL_CURRENT_UPLOAD: (state, _) => {
			state.inQueuedBatches.pop();
			state.isConflicting = false;
		},

		REMOVE_BATCH_FROM_QUEUE: (state, { payload }) => {
			state.inQueuedBatches = state.inQueuedBatches.filter(({ id }) => id === payload);
		},

		RESET_IN_QUEUED_FILES_SLICE: (state, _) => {
			state.inQueuedBatches = initialState.inQueuedBatches;
			// state.inQueuedFiles = initialState.inQueuedFiles;
			state.allowToPutFilesInQueue = initialState.allowToPutFilesInQueue;
			state.isConflicting = initialState.isConflicting;
			state.messageForUploads = initialState.messageForUploads;
			state.shouldAllowToUpload = initialState.shouldAllowToUpload;
			state.shouldCheckForConflict = initialState.shouldCheckForConflict;
			state.shouldClearAllBatches = initialState.shouldClearAllBatches;
			state.showUpdateDialogue = initialState.showUpdateDialogue;
		},

		RESET_OPERATION_COMPLETE: (state, _) => {
			state.operationComplete = initialState.operationComplete;
		},
		START_UPLOAD: (state) => {
			state.isUploading = true;
		},
		END_UPLOAD: (state) => {
			state.isUploading = false;
		},
	},
	extraReducers: (builder) => {
		// handle state updates for uploadFileThunk
		builder.addCase(uploadFilesThunk.pending, (state) => {
			state.isUploading = true; // Set flag to true when upload starts
		});
		builder.addCase(uploadFilesThunk.fulfilled, (state) => {
			state.isUploading = false; // Set flag to false when upload succeeds
		});
		builder.addCase(uploadFilesThunk.rejected, (state) => {
			state.isUploading = false; // Set flag to false when upload fails
		});
	},
});

export const {
	PUT_FILES_IN_QUEUE,
	HANDLE_FILES_FOR_CONFLICT,
	HANDLE_USER_RESPONSE_FOR_CONFLICT,
	CHANGE_BATCH_STATUS_FOR_IS_READY_TO_UPLOAD,
	CHANGE_BATCH_STATUS_FOR_IS_CURRENTLY_UPLOADING,
	CHANGE_BATCH_STATUS_FOR_IS_UPLOADING_DONE,
	CHECK_IF_ALL_BATCHES_ARE_DONE_UPLOADING,
	ADD_FILES_TO_OPERATION_COMPLETE,
	STOP_ALLOWING_TO_UPLOAD,
	SHOW_UPDATE_DIALOGUE,
	HIDE_UPDATE_DIALOGUE,
	CANCEL_CURRENT_UPLOAD,
	REMOVE_BATCH_FROM_QUEUE,
	RESET_IN_QUEUED_FILES_SLICE,
	CHECK_FOR_PERMISSIONS,
	RESET_OPERATION_COMPLETE,
	START_UPLOAD,
	END_UPLOAD,
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
