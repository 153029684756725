import properties from "components/properties/ApplicationProps";

export class ContextRequestUpdateResource {
	//json for updating resource content
	static getUpdateResourceJson(file) {
		console.log(file);
		let requestJson = {
			action: properties.actions.MODIFY,
			fileName: file.fields.filename,
			contentType: file.fields.contenttype,
			contentLength: file.fields.size,
			description: "",
			isItFolder: file.fields.isfolder,
			ownerName: sessionStorage.getItem("username"),
			parentFolderId: file.fields.parentfolderid,
			resourceId: file.fields.resourceid, //TODO :Take resourceId while uploading resource
			updateType: properties.updateType.RESOURCEUPDATE,
			role: properties.role.OWNER,
			lastUpdateUser: sessionStorage.getItem("username"),
			lastUpdateTime: Date.now(),
			versioned: properties.versioned,
		};
		return requestJson;
	}

	//json for renaming resource
	static getUpdateResourceInfoJson(file) {
		const resource = file.hasOwnProperty("fields") ? file.fields : file;

		let requestJson = {
			action: properties.actions.MODIFY,
			fileName: resource.filename,
			contentType: resource.contenttype,
			contentLength: resource.size,
			description: "",
			isItFolder: resource.isfolder,
			ownerName: sessionStorage.getItem("username"),
			parentFolderId: properties.parentFolderId,
			resourceId: resource.resourceid, //TODO :Take resourceId while uploading resource
			updateType: properties.updateType.INFOUPDATE,
			role: properties.role.OWNER,
			lastUpdateUser: sessionStorage.getItem("username"),
			lastUpdateTime: Date.now(),
		};
		return requestJson;
	}
}
