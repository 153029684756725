import properties from "components/properties/ApplicationProps";
import React from "react";

const eventOptions = {
	"Cache Refresh": properties.events.SDCHANGE,
	"User Onboard": properties.events.DOUSERONBOARD,
	"Org Onboard": properties.events.USERONBOARDED,
	"Account Onboard": properties.events.ORGONBOARDED,
	"Bucket Creation": properties.events.ACCOUNTONBOARDED,
	"Update Auth": properties.events.DOKCUPDATE,
	"Auto Link": properties.events.AUTOLINK,
};

// Column definitions
const columnDefinations = [
	{
		checkboxSelection: true,
		headerCheckboxSelection: true,
		headerCheckboxSelectionFilteredOnly: true,
		field: "orgshortname",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Organisation",
		width: 150,
	},
	{
		field: "displayname",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Display Name",
		width: 220,
	},
	{
		field: "email",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Email",
		width: 220,
	},
	{
		field: "buckettype",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Bucket",
		editable: true,
		width: 135,
		// cellEditor: "agTextCellEditor",
		// onCellValueChanged: handleBucketnameChange,
	},

	{
		field: "accountid",
		flex: 1,
		editable: false,
		width: 120,
		headerName: "A/c Id",
		sort: "asc",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		cellRenderer: fileNameRenderer,
	},

	{
		field: "region",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Region",
		width: 110,
	},

	{
		field: "status",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Status",
		width: 165,
	},
	{
		field: "event",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Events",
		cellRenderer: eventRenderer,
		width: 180,
		valueGetter: (params) => params.data.event,
	},
	{
		field: "autoonboard",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "autoonboard",
		width: 100,
	},
	{
		field: "endpointexptime",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Endpoint Exp.",
		width: 100,
	},

	{
		field: "gstno",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "GST",
		width: 120,
	},
	{
		field: "contactemail",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Contact email",
		width: 120,
	},
	{
		field: "contactnumber",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
		headerName: "Contact No",
		width: 120,
	},
];

// Function to render cell content
function fileNameRenderer(params) {
	// console.log("PARAMS", params);
	if (!params || !params.data) {
		// console.log("params or params.data is undefined");
		return null;
	}

	const resource = params.data;

	// Set data-cy attribute to help Cypress capture element
	const rowEl = params.eGridCell?.offsetParent;
	if (rowEl) {
		rowEl.dataset.cy = `row-${resource.accountId}`;
	}

	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			{/* <FileIcon contentType={resource.contenttype} /> */}
			<span
				style={{ marginLeft: "2rem" }}
				data-cy={`resource-name-${resource.accountid}`}
			>
				{resource.accountid}
			</span>
		</div>
	);
}

function eventRenderer(params) {
	const handleEventChange = (e) => {
		const newEvent = e.target.value;

		const updatedData = { ...params.data, event: newEvent };

		params.api.applyTransaction({ update: [updatedData] });

		handleevent(newEvent, params);
	};

	const eventValue = params.data.event || "";

	const eventLabel = Object.keys(eventOptions).find((key) => eventOptions[key] === eventValue) || "";

	return (
		<div style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
			<select
				style={{
					WebkitAppearance: "none",
					MozAppearance: "none",
					appearance: "none",
					backgroundColor: "transparent",
					border: "none",
					padding: "8px 30px 5px 10px",
					fontSize: "14px",
					color: "#333",
					outline: "none",
					width: "100%",
				}}
				value={eventValue}
				onChange={handleEventChange}
			>
				<option value="">Select</option>
				{Object.entries(eventOptions).map(([label, value]) => (
					<option
						key={value}
						value={value}
					>
						{label}
					</option>
				))}
			</select>
			<div
				style={{
					position: "absolute",
					right: "10px",
					top: "50%",
					transform: "translateY(-50%)",
					pointerEvents: "none",
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					width="16"
					height="16"
				>
					<polyline points="6 9 12 15 18 9" />
				</svg>
			</div>
		</div>
	);
}
function handleevent(newEvent, params) {
	const updatedData = { ...params.data, event: newEvent };

	console.log("New event selected:", newEvent);

	params.api.applyTransaction({ update: [updatedData] });
}
function handleBucketnameChange(event) {
	const { data, newValue } = event;
	console.log("Bucketname changed:", newValue);

	const updatedData = { ...data, bucketname: newValue };

	event.api.applyTransaction({ update: [updatedData] });
}

// function handleCellValueChanged(event) {
// 	const { data, colDef, newValue } = event;
// 	console.log("Cell value changed:", { data, colDef, newValue });

// 	// Create an updated data object
// 	const updatedData = { ...data, [colDef.field]: newValue };
// }

export default columnDefinations;
