import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardApi } from "components/misc/DashboardApi";
import { SET_GRID_MESSAGE } from "./uiSlice";

const initialState = {
	loading: null,
	error: null,
	data: {
		header: {},
		footer: {},
		resourceProperties: null,
		parentFolderId: null,
		resourcePropertiesList: [],
	},
	sharedWithMeParentFolderId: -1,
};

export const fetchSharedWithMeResourcesThunk = createAsyncThunk(
	"sharedWithMe/FETCH_SHARED_WITH_ME_RESOURCES",
	async (resource, { fulfillWithValue, rejectWithValue, dispatch }) => {
		try {
			const response = await dashboardApi.fetchSharedDashBoardResource(
				resource?.locationId,
				resource?.resourceId,
			);
			if (!response.data) {
				dispatch(SET_GRID_MESSAGE("Failed To get Resources!"));
				return rejectWithValue(response);
			}

			if (response.data.footer.code === 0) {
				return fulfillWithValue(response.data);
			}

			dispatch(SET_GRID_MESSAGE("Failed To get Resources!"));
			return fulfillWithValue(initialState.data);
		} catch (error) {
			dispatch(SET_GRID_MESSAGE("Failed To get Resources!"));
			return rejectWithValue(error);
		}
	},
);

const sharedWithMeSlice = createSlice({
	name: "sharedWithMe",
	initialState,
	reducers: {
		RESET_SHARED_WITH_ME_SLICE: (state) => {
			state.loading = initialState.loading;
			state.error = initialState.error;
			state.data = initialState.data;
			state.sharedWithMeParentFolderId = initialState.sharedWithMeParentFolderId;
		},

		UPDATE_CURRENT_SHARED_WITH_ME_RESOURCES: (state, { payload }) => {
			state.data.resourcePropertiesList = payload;
		},

		SAVE_CURRENT_SHARED_WITH_ME_RESOURCE_LOCATION_ID: (state, { payload }) => {
			state.sharedWithMeParentFolderId = payload;
		},

		RESET_CURRENT_SHARED_WITH_ME_RESOURCE_LOCATION_ID: (state) => {
			state.sharedWithMeParentFolderId = initialState.sharedWithMeParentFolderId;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchSharedWithMeResourcesThunk.pending, (state, { payload }) => {
			state.loading = true;
		});

		builder.addCase(fetchSharedWithMeResourcesThunk.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.error = initialState.error;
			state.data = payload;
		});

		builder.addCase(fetchSharedWithMeResourcesThunk.rejected, (state, { payload }) => {
			state.loading = false;
			state.data = initialState.data;
			state.error = true;
		});
	},
});

export const {
	RESET_SHARED_WITH_ME_SLICE,
	UPDATE_CURRENT_SHARED_WITH_ME_RESOURCES,
	SAVE_CURRENT_SHARED_WITH_ME_RESOURCE_LOCATION_ID,
	RESET_CURRENT_SHARED_WITH_ME_RESOURCE_LOCATION_ID,
} = sharedWithMeSlice.actions;

export default sharedWithMeSlice.reducer;
