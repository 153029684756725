export function getChangeSubscriptionDetails(subscriptionDetails, accountId) {
	console.log("Subscription Details:", subscriptionDetails);

	const stringSubscriptionDetails = {
		// action: properties.actions.SAVE,
		subscriptionsList: [...subscriptionDetails],
		accountId: accountId,
		// subscriptionKey: "",
		// ...subscriptionDetails,
	};

	return stringSubscriptionDetails;
}
