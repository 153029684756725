import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const SignUpInvite = () => {
	const { token } = useParams();

	useEffect(() => {
		const baseURL = window.location.origin;
		fetch(`${baseURL}/signup-invite?token=${token}`)
			.then((response) => {
				if (response.ok) {
					//send token back to server here
					console.log("User onboarded successfully");
				} else {
					console.error("Error onboarding user");
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, [token]);

	return (
		<>
			<div style={{ height: "100vh", display: "grid", placeItems: "center" }}>
				<CircularProgress size={25} />
			</div>
		</>
	);
};

export default SignUpInvite;
