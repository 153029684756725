import properties from "components/properties/ApplicationProps";
import Utils from "components/misc/Utils";

export function getFetchResourceJson(resource) {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),

			clientId: Utils.getUUID(),
		},
		action: properties.actions.SEARCH,
		resourceId: resource.resourceid,
		version: resource.version,
		contentLength: resource.size,
		accountId: sessionStorage.getItem("accountId"),
	};

	return stringifyFetchResource;
}
