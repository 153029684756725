import { makeStyles } from "@material-ui/styles";
import { icon, neutral, textsize } from "assets/css/MainCss";

const drawerWidth = 211;

export default makeStyles((theme) => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		border: "none",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		border: "none",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(2) + 40,
		[theme.breakpoints.down("sm")]: {
			width: drawerWidth,
		},
	},
	toolbar: {
		marginTop: "50px",
		border: "none",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	sidebarList: {
		borderRadius: "15px",
	},
	textfield: {
		fontSize: "14px",
	},
	drawerWithoutBorder: {
		border: "none",
	},
	mobileBackButton: {
		//   marginTop: theme.spacing(0.5),
		marginTop: "20px",
		marginLeft: 18,
		[theme.breakpoints.only("sm")]: {
			marginTop: theme.spacing(0.625),
		},
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	breadcrumblinkSidenav: {
		fontWeight: 200,
		lineHeight: 1.43,
		letterSpacing: "0.01071em",
		display: "table-cell",
		verticalAlign: "inherit",
		textAlign: "left",
		"&:hover": {
			textDecoration: "none",
		},
	},
	breadcrumblink: {
		display: "inline-block",
		padding: "2px 5px 0px 5px",
		borderRadius: "15px",
		backgroundColor: neutral.textcolor1,
		color: neutral.textcolor,
		textDecoration: "none",
		transition: "background-color 0.3s",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#F3F5FF",
			textDecoration: "none",
			color: neutral.textcolor,
		},
	},
	text1: {
		fontSize: textsize.filedetailstext,
		fontWeight: 200,
		color: neutral.textcolor,
	},
	text2: {
		fontSize: textsize.filedetailstext,
		marginLeft: "5px",
	},
	iconStyle: {
		width: icon.width,
		height: icon.height,
	},
}));
