import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	refreshDashboardGrid: false,
	selectedResources: [],
	resourcesForCutPaste: [],
	shareResources: [],
	usersToShareResources: [],
	resourcesToDelete: [],
	resourceToRename: [],
	groupToUpdate: [],
	resourceVersions: [],
	activeFolderDetails: [],
	showVersionsColumn: false,
};

const resourceGridSlice = createSlice({
	name: "resourceGridSlice",
	initialState,
	reducers: {
		SAVE_SELECTED_RESOURCES: (state, { payload }) => {
			state.selectedResources = payload;
		},

		SAVE_CUT_RESOURCES: (state, { payload }) => {
			state.resourcesForCutPaste = payload;
		},

		RESET_CUT_RESOURCES: (state, _) => {
			state.resourcesForCutPaste = initialState.resourcesForCutPaste;
		},

		SAVE_SHARE_RESOURCES: (state, { payload }) => {
			state.shareResources = payload;
		},

		RESET_SHARE_RESOURCES: (state, _) => {
			state.shareResources = initialState.shareResources;
		},

		SAVE_USERS_TO_SHARE_RESOURCES: (state, { payload }) => {
			state.usersToShareResources = payload;
		},

		RESET_USERS_TO_SHARE_RESOURCES: (state, _) => {
			state.usersToShareResources = initialState.usersToShareResources;
		},

		SAVE_RESOURCES_TO_DELETE: (state, { payload }) => {
			state.resourcesToDelete = payload;
		},

		RESET_RESOURCES_TO_DELETE: (state, _) => {
			state.resourcesToDelete = initialState.resourcesToDelete;
		},

		SAVE_RESOURCES_TO_RENAME: (state, { payload }) => {
			state.resourceToRename = payload;
		},

		SAVE_GROUP_TO_UPDATE: (state, { payload }) => {
			state.groupToUpdate = payload;
		},

		RESET_RESOURCES_TO_RENAME: (state, _) => {
			state.resourceToRename = initialState.resourceToRename;
		},

		SAVE_RESOURCE_VERSIONS_DETAILS: (state, { payload }) => {
			state.resourceVersions = payload;
		},

		SHOW_VERSIONS_COLUMN: (state, _) => {
			state.showVersionsColumn = true;
		},

		HIDE_VERSIONS_COLUMN: (state, _) => {
			state.showVersionsColumn = false;
		},

		RESET_RESOURCE_VERSIONS_DETAILS: (state, _) => {
			state.resourceVersions = initialState.resourceVersions;
		},

		SAVE_ACTIVE_FOLDER_DETAILS: (state, { payload }) => {
			state.activeFolderDetails = payload;
		},

		RESET_ACTIVE_FOLDER_DETAILS: (state, _) => {
			state.activeFolderDetails = initialState.activeFolderDetails;
		},

		REFRESH_CURRENT_DASHBOARD_GRID: (state, _) => {
			state.refreshDashboardGrid = true;
		},

		STOP_REFRESHING_CURRENT_DASHBOARD_GRID: (state, _) => {
			state.refreshDashboardGrid = false;
		},
	},
});

export const {
	SAVE_SELECTED_RESOURCES,
	SAVE_CUT_RESOURCES,
	RESET_CUT_RESOURCES,
	SAVE_SHARE_RESOURCES,
	RESET_SHARE_RESOURCES,
	SAVE_USERS_TO_SHARE_RESOURCES,
	RESET_USERS_TO_SHARE_RESOURCES,
	SAVE_RESOURCES_TO_DELETE,
	RESET_RESOURCES_TO_DELETE,
	SAVE_RESOURCES_TO_RENAME,
	SAVE_GROUP_TO_UPDATE,
	RESET_RESOURCES_TO_RENAME,
	SAVE_RESOURCE_VERSIONS_DETAILS,
	RESET_RESOURCE_VERSIONS_DETAILS,
	SHOW_VERSIONS_COLUMN,
	HIDE_VERSIONS_COLUMN,
	SAVE_ACTIVE_FOLDER_DETAILS,
	RESET_ACTIVE_FOLDER_DETAILS,
	REFRESH_CURRENT_DASHBOARD_GRID,
	STOP_REFRESHING_CURRENT_DASHBOARD_GRID,
} = resourceGridSlice.actions;

export default resourceGridSlice.reducer;
