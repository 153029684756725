import properties from "components/properties/ApplicationProps";
import { GlobalUsername } from "Constants";
//TO DO: remove default parent id

// add null check for parentFolderId

export class ContextRequestAddResource {
	//generate file json
	static generateAddResourceJson(file, locationid) {
		let requestJson = {
			action: properties.actions.SAVE,
			fileName: file.name,
			contentType: file.type,
			contentLength: file.size,
			description: "",
			isItFolder: file.isItFolder,

			parentFolderId: locationid,
			role: properties.role.OWNER,
			lastUpdateUser: GlobalUsername.username,
			lastUpdateTime: Date.now(),
		};
		return requestJson;
	}

	static generateAddResourceJsonFromFile(file) {
		let requestJson = {
			action: properties.actions.SAVE,
			fileName: file.name,
			contentType: file.type,
			contentLength: file.size,
			description: "",
			isItFolder: file.isItFolder,

			parentFolderId: file.uploadLocationId,
			role: properties.role.OWNER,
			lastUpdateUser: GlobalUsername.username,
			lastUpdateTime: Date.now(),
		};
		return requestJson;
	}

	//get folder json
	static generateAddFolderJson(folderName, locationid) {
		let requestJson = {
			action: properties.actions.SAVE,
			fileName: folderName,
			contentType: "folder",
			contentLength: "",
			description: "",
			isItFolder: true,

			parentFolderId: locationid,
			role: properties.role.OWNER,
			lastUpdateUser: GlobalUsername.username,
			lastUpdateTime: Date.now(),
		};
		return requestJson;
	}

	static generateUploadResourceJson(file) {
		let requestJson = {
			action: properties.actions.INSERT,
			fileName: file.name,
			contentType: file.type,
			contentLength: file.size,
			description: file.description || "",

			parentFolderId: file.uploadLocationId,
			versionFlag: properties.versionFlag.allNew_files,
			resourceId: properties.defaultResourceId,
			lastUpdateUser: GlobalUsername.username,
			lastUpdateTime: Date.now(),
			role: properties.role.OWNER,
			/**
			 * isItFolder must be `false`, because folder hierarchy is created before even uploadFile is called.
			 * Since folder hierarchy is created before, correct parentFolderId is assigned via file.uploadLocationId
			 */
			isItFolder: false,
		};
		return requestJson;
	}

	static generateKeepOrReplaceUploadResourceJson(file) {
		return {
			header: {
				clientId: `${file.fileId}`,
			},
			action: properties.actions.INSERT,
			fileName: file.name,
			contentType: file.type,
			contentLength: file.size,
			description: file.description,

			parentFolderId: file.uploadLocationId,
			versionFlag: file.uploadFlag || properties.versionFlag.keep_existing_files,
			resourceId: file.resourceid || properties.defaultResourceId,
			lastUpdateUser: GlobalUsername.username,
			lastUpdateTime: Date.now(),
			role: properties.role.OWNER,
			/**
			 * isItFolder must be `false`, because folder hierarchy is created before even uploadFile is called.
			 * Since folder hierarchy is created before, correct parentFolderId is assigned via file.uploadLocationId
			 */
			isItFolder: false,
		};
	}

	static generateUploadFolderResourceJson(file, locationid) {
		let requestJson = {
			action: properties.actions.INSERT,
			fileName: file,
			contentType: "folder",
			contentLength: "",
			isItFolder: true,
			description: "",

			parentFolderId: locationid,
			versionFlag: properties.versionFlag.keep_existing_files,
			resourceId: properties.defaultResourceId,
			lastUpdateUser: GlobalUsername.username,
			lastUpdateTime: Date.now(),
			role: properties.role.OWNER,
		};
		return requestJson;
	}
}
