import { useState, useEffect, memo } from "react";
import UpdateDialogue from "./UpdateDialogue";
import Notification from "./notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import {
	HANDLE_FILES_FOR_CONFLICT,
	RESET_IN_QUEUED_FILES_SLICE,
	STOP_ALLOWING_TO_UPLOAD,
	uploadFilesThunk,
} from "store/fileUploadSlice";

const FileUpload = () => {
	const dispatch = useDispatch();
	const currentDashboardResources = useSelector(({ dashboard }) => dashboard.data.resourcePropertiesList);
	const currentDashboardId = useSelector(({ dashboard }) => dashboard.data.parentFolderId);
	const droppedLocationId = useSelector(({ droppedLocationId }) => droppedLocationId.locationId);
	const {
		inQueuedBatches,
		messageForUploads,
		shouldAllowToUpload,
		showUpdateDialogue,
		shouldCheckForConflict,
		shouldClearAllBatches,
	} = useSelector(({ fileUpload }) => fileUpload);

	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);

	useEffect(() => {
		if (shouldCheckForConflict) {
			dispatch(
				HANDLE_FILES_FOR_CONFLICT({
					inQueuedBatches,
					currentDashboardResources,
					droppedLocationId,
					currentDashboardId,
				}),
			);
		}

		if (shouldClearAllBatches) {
			dispatch(RESET_IN_QUEUED_FILES_SLICE());
		}

		if (messageForUploads !== snackbarMessage) {
			setSnackbarMessage(() => messageForUploads);
		}

		console.log("InQueued Batches: ", inQueuedBatches);
	}, [
		currentDashboardId,
		droppedLocationId,
		shouldCheckForConflict,
		shouldClearAllBatches,
		inQueuedBatches,
		currentDashboardResources,
		messageForUploads,
		snackbarMessage,
		dispatch,
	]);

	useEffect(() => {
		if (shouldAllowToUpload === true) {
			dispatch(uploadFilesThunk());
			dispatch(STOP_ALLOWING_TO_UPLOAD());
		}
	}, [shouldAllowToUpload, dispatch]);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (inQueuedBatches.length > 0) {
				const message = "Uploads are in progress. Are you sure you want to leave?";
				event.returnValue = message;
				return message;
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [inQueuedBatches]);

	return (
		<>
			{showUpdateDialogue ? <UpdateDialogue open1={showUpdateDialogue} /> : null}

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</>
	);
};

export default memo(FileUpload);
