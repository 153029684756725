import { dashboardApi } from "components/misc/DashboardApi";
import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import useRazorpay from "react-razorpay";
import useStyles from "routes/TableStyles";
import { FontContext } from "components/UI/Theme";
import { getPaymentOrderJson } from "components/requestcontext/PaymentOrder";
import { getValidatePaymentJson } from "components/requestcontext/ValidatePayment";
import properties from "components/properties/ApplicationProps";
import { getFetchAccountDetails } from "components/requestcontext/FetchAccountDetails";
import InvoiceReport from "./InvoiceReport";
import { CssBaseline, Grid, TableContainer } from "@mui/material";
import { getInvoiceDetailsJson } from "components/requestcontext/InvoiceDetails";
import "./styles.css";
import InvoiceCard, { cardData } from "components/dashboard/InvoiceFailureCard";
import { useHistory } from "react-router-dom";
import Notification from "components/dashboard/notification/Notification";
import AgContextHeader from "components/dataUsage/AgContextHeader";
import BreadCrumb from "components/dashboard/breadcrumb/InvoiceBreadcrumb";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import EventEmitter from "components/misc/eventEmitter";

export default function Payment() {
	const history = useHistory();
	const { largeTextSize, fontFamily } = useContext(FontContext);
	const gridRef = useRef();
	const classes = useStyles();
	const { themeColor } = useContext(FontContext);
	const currentPageRoute = useLocation().pathname;

	const [Razorpay, isLoaded] = useRazorpay();
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const [resource, setResource] = useState([]);
	const [totalAmount, setTotalAmount] = useState();
	//const [openDialogue, setopenDialogue] = useState(false);
	const [userid, setuserid] = useState("");
	const [invoiceDetails, setInvoiceDetails] = useState([]);
	const [invoiceId, setInvoiceId] = useState();
	const [paymentstatus, setpaymentstatus] = useState("");
	const [showInvoice, setShowInvoice] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	useEffect(() => {
		fetchData();
		EventEmitter.on("InvoicePaid", () => {
			fetchData();
		});
	}, []);
	const showSnackbarWithMessage = useCallback((message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	}, []);

	const fetchData = async () => {
		try {
			let fetchInvoiceDetailsJson = getInvoiceDetailsJson();
			const invoiceDetailsResponse = await dashboardApi.fetchInvoice(fetchInvoiceDetailsJson);
			console.log("RESOURCE RESPONSE::", invoiceDetailsResponse.data.resourcePropertiesList);
			if (invoiceDetailsResponse.data.resourcePropertiesList.length === 0) {
				setShowInvoice(true);
			} else {
				setInvoiceDetails(invoiceDetailsResponse.data.resourcePropertiesList); //need to add grid for multiple invoices
				setTotalAmount(invoiceDetailsResponse.data.resourcePropertiesList[0].fields.totalamount);
				setInvoiceId(invoiceDetailsResponse.data.resourcePropertiesList[0].fields.invoiceid);
				setpaymentstatus(invoiceDetailsResponse.data.resourcePropertiesList[0].fields.paymentstatus);

				// let accountDetailsJson = getFetchAccountDetails();
				// let accountDetailsResponse = await dashboardApi.fetchAccount(accountDetailsJson);
				// console.log("account details:", accountDetailsResponse);
				// let accountDetails = accountDetailsResponse.data.resourcePropertiesList;
				// console.log(accountDetails, accountDetails[0].fields.plantype);
				// setuserid(accountDetails[0].fields.userid);
				const response = await dashboardApi.getAllPlans();
				console.log("RESOURCE RESPONSE::", response.data);

				for (let i = 0; i < response.data.length; i++) {
					// console.log(response.data[i], accountDetails[0].fields);
					if (
						response.data[i].subscriptionname
						// === accountDetails[0].fields.plantype
					) {
						console.log(response.data[i]);
						let data = [response.data[i]];
						console.log(data);
						setResource(data);
						setScriptLoaded(true);
					}
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handlePayment = useCallback(
		async (planKey) => {
			console.log(planKey);
			const selectedPlan = resource.find((plan) => plan.subscriptionkey === planKey);
			console.log(selectedPlan);

			if (scriptLoaded && Razorpay && selectedPlan) {
				let fetchPaymentOrderJson = getPaymentOrderJson(totalAmount, selectedPlan.subscriptionkey, invoiceId);
				const response = await dashboardApi.getPaymentOrder(fetchPaymentOrderJson);
				console.log(
					"RESOURCE RESPONSE::",
					response.data,
					response.data.orderId,
					response.data.paymentSequenceId,
				);
				let id = response.data.paymentSequenceId;

				const options = {
					key: "rzp_test_qL8segGG1GHsae",
					amount: totalAmount,
					currency: response.data.currency,
					name:
						selectedPlan.subscriptionname.charAt(0).toUpperCase() + selectedPlan.subscriptionname.slice(1),
					description: "Test Transaction",
					accept_partial: false,
					order_id: response.data.orderId,
					image: properties.orgProperties.orgIcon,

					method: {
						upi: false,
					},
					handler: async (res) => {
						console.log(res);
						var today = new Date();
						var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
						var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
						var dateTime = date + " " + time;
						var timestamp = new Date(dateTime).getTime();
						console.log(timestamp);
						// var successUrl = document.getElementById("payment-form").elements["success_url"].value;
						// window.location.href = successUrl;
						//alert(res.razorpay_payment_id);
						//alert(res.razorpay_order_id);
						//	alert(res.razorpay_signature);
						showSnackbarWithMessage("Payment successful");
						if (res.razorpay_payment_id) {
							// Check for successful payment
							console.log("Payment successful");

							let fetchPaymentOrderJson = getValidatePaymentJson(
								totalAmount,
								selectedPlan.subscriptionkey,
								res,
								id,
								userid,
								invoiceId,
								timestamp,
							);

							// setpaymentstatus("paid");
							const response = await dashboardApi.validatePayment(fetchPaymentOrderJson);
							console.log("RESOURCE RESPONSE::", response);
							fetchData();
							setShowSnackbar(false);
						}
					},
					prefill: {
						name: "John Doe",
						email: "john@example.com",
						contact: "1234567890",
						upi: "success@razorpay", //  Use a valid UPI ID here
					},
					notes: {
						address: "Razorpay Corporate Office",
					},
					theme: {
						color: "#3399cc",
					},
					notify: {
						sms: true,
						email: true,
					},
				};
				console.log(options);
				var rzpay = new window.Razorpay(options);
				rzpay.open();
			}
		},
		[Razorpay, scriptLoaded, resource, totalAmount, userid, invoiceId],
	);

	function handleCardClick(e) {
		history.push("/settings");
	}
	const handleRefreshAction = useCallback(() => {
		if (currentPageRoute.includes("generatedatausage")) {
			console.log("refresh header clicked");
		}
	}, [currentPageRoute]);

	const handleRemoveAllFilters = useCallback(() => {
		if (currentPageRoute.includes("generatedatausage")) {
			gridRef.current?.api.setFilterModel(null);
		}
	}, [currentPageRoute]);

	const contextActionsList = useMemo(
		() => ({
			removeAllFilters: handleRemoveAllFilters,
			refresh: handleRefreshAction,
		}),
		[handleRemoveAllFilters, handleRefreshAction],
	);

	return (
		<div
			style={{
				position: "relative",

				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			{/* <Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid> */}
			<header style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<BreadCrumb />
				<AgContextHeader
					contextActions={contextActionsList}
					gridApi={gridRef}
				/>
			</header>
			<div
				className={classes.outercontainer}
				style={{ border: "1px solid #dddddd", borderRadius: 8 }}
			>
				{/* <div className={classes.innercontainer}> */}
				<TableContainer
				// className={classes.tablecontainer}
				>
					<div
						// className="container mt-5"
						className="ag-theme-quartz"
						style={{ fontFamily: "inherit", fontSize: "inherit" }}
					>
						<div
							// className="row d-flex justify-content-center align-items-center"
							className="ag-theme-quartz"
							style={{ fontFamily: "inherit", fontSize: "inherit" }}
						>
							<div className="col-md-12">
								{resource.map((plan) => (
									<InvoiceReport
										invoiceDetails={invoiceDetails}
										key={plan.subscriptionkey}
										plan={plan}
										amount={totalAmount}
										onSelect={handlePayment}
										paymentstatus={paymentstatus}
									/>
								))}
								{showInvoice === true && (
									<TableContainer
									// className={classes.tablecontainer}
									>
										{cardData
											.filter((card) => {
												return true; // Show all other cards
											})
											.map((card, index) => {
												return (
													<Grid
														item
														xs={12}
														sm={6}
														md={3}
														id="text"
														key={index}
														onClick={() => handleCardClick(card.title)}
														data-cy-card={card.testId}
														sx={{
															flexDirection: "column",
															justifyContent: "center",
															alignItems: "center",
															display: "flex",
															fontFamily: fontFamily,
															minHeight: "200px",
														}}
													>
														<InvoiceCard
															icon={card.icon}
															title={card.title}
															content={card.content}
															disabled={card.disabled}
															testId={card.testId}
														/>
													</Grid>
												);
											})}
									</TableContainer>
								)}
								<Notification
									showSnackbar={showSnackbar}
									snackbarMessage={snackbarMessage}
									setShowSnackbar={setShowSnackbar}
								/>
							</div>
						</div>
					</div>
				</TableContainer>
				{/* </div> */}
			</div>
		</div>
	);
}
