import React, { useEffect } from "react";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid, InputAdornment, MenuItem, Select, InputLabel, IconButton, Checkbox } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { dashboardApi } from "components/misc/DashboardApi";
import Notification from "components/dashboard/notification/Notification";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import { getFetchAccountDetails } from "components/requestcontext/FetchAccountDetails";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import FormContainer from "layout/FormContainer";

import { getAllSubscriptionsJson } from "components/requestcontext/FetchAllSubscriptions";
// import Select from "react-select";
import { getSearchSubscriptionJson } from "components/requestcontext/SearchSubscriptionPlan";
import { getUpdateSubscriptionDetailsJson } from "components/requestcontext/UpdateSubscriptionDetails";
import properties from "components/properties/ApplicationProps";
import ReactSelect from "react-select";
import Switch from "@mui/material/Switch";

function UpdateSubscriptionPlan() {
	const classes = useStyles();
	const history = useHistory();
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [subscriptionName, setSubscriptionName] = useState("");
	const [subscriptionDisplayName, setSubscriptionDisplayName] = useState("");
	const [subscriptionNameToUpdate, setSubscriptionNameToUpdate] = useState("");
	const [subscriptionCharge, setSubscriptionCharge] = useState("");
	const [subscriptionValidity, setSubscriptionValidity] = useState();
	const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
	const [subscriptions, setSubscriptions] = useState([]);
	const [subscriptionIsActive, setSubscriptionIsActive] = useState(false);
	const [subscriptionIsExternal, setSubscriptionIsExternal] = useState(false);
	const [subscriptionAddons, setSubscriptionAddons] = useState("");
	const [subscriptionId, setSubscriptionId] = useState("");
	const { fontFamily, themeColor, filter } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [subscriptionDescription, setSubscriptionDescription] = useState("");
	const [subscriptionKey, setSubscriptionKey] = useState("");
	const [subscriptionChargeType, setSubscriptionChargeType] = useState("USD");
	const [discountType, setDiscountType] = useState("notional");
	const [switchValue, setSwitchValue] = useState("subscription");
	const [discount, setDiscount] = useState("");
	const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
	const label = { inputProps: { "aria-label": "Size switch demo" } };

	//snackbar
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};
	const handleClose = () => {
		history.push("/settings");
	};

	const handleKeyDownRef1 = (e, nextField) => {
		if (e.key === "Enter") {
			console.log("do validate");
			document.getElementById("subscriptioncharge");
			// nextField.focus();
		}
	};

	const handleCurrencyChange = (event) => {
		setSubscriptionChargeType(event.target.value);
	};

	const handleDiscountTypeChange = (event) => {
		setDiscountType(event.target.value);
	};

	async function UpdateSubscription() {
		setButtonLoading(true);
		console.log("inside UpdateSubscription");
		let accountDetailsJson = getFetchAccountDetails();

		let accountDetailsResponse = await dashboardApi.fetchAccountDetailsOfUser(accountDetailsJson);
		console.log("account details:", accountDetailsResponse);
		let accountDetails = accountDetailsResponse.data.resourcePropertiesList;
		console.log(accountDetails);
		let accountid = accountDetails[0].fields.accountid;

		try {
			console.log("inside try");
			const updatedSubscriptionName = subscriptionNameToUpdate || subscriptionName;
			const updatedSubscriptionValidity =
				typeof subscriptionValidity === "number" ? subscriptionValidity : Date.parse(subscriptionValidity);

			// let trimUpdatedAccountName = subscriptionName.trim().replace(/\s{2,}/g, " ");
			let subscriptionDetailsJson = getUpdateSubscriptionDetailsJson(
				accountid,
				updatedSubscriptionName,
				subscriptionCharge,
				updatedSubscriptionValidity,
				discount,
				subscriptionAddons,
				subscriptionIsActive,
				subscriptionId,
				subscriptionDescription,
				discountType,
				subscriptionChargeType,
				subscriptionIsExternal,
				subscriptionKey,
			);
			let subscriptionDetailsResponse = await dashboardApi.updateSubsctiption(subscriptionDetailsJson);
			console.log("account details:", subscriptionDetailsResponse);
			if (subscriptionDetailsResponse.data.footer.msg === "SUCCESS") {
				showSnackbarWithMessage("Subscription plan updated successfully");
				setButtonLoading(() => false);
				setSubscriptionId("");
				setSubscriptionDisplayName("");
				setSubscriptionDescription("");
				setSubscriptionCharge("");
				setSubscriptionValidity("");
				setDiscount("");
				setSubscriptionIsActive("");
				setSubscriptionIsExternal("");
				setSubscriptionAddons("");
				setSubscriptionKey("");
				setSubscriptionChargeType("");
				setDiscountType("");
				setSubscriptionValidity("");
				setSubscriptionName("");
			} else {
				console.log(subscriptionDetailsResponse.data.footer.msg);
				showSnackbarWithMessage("Failure while updating subscription or discount");
				setButtonLoading(() => false);
			}
		} catch (error) {
			showSnackbarWithMessage("Failure while updating subscription or discount");
			setButtonLoading(() => false);
			setSubscriptionId("");
			setSubscriptionDisplayName("");
			setSubscriptionDescription("");
			setSubscriptionCharge("");
			setSubscriptionValidity("");
			setDiscount("");
			setSubscriptionIsActive("");
			setSubscriptionIsExternal("");
			setSubscriptionAddons("");
			setSubscriptionKey("");
			setSubscriptionChargeType("");
			setDiscountType("");
			setSubscriptionValidity("");
			setSubscriptionName("");
		}
		fetchSubscriptionDetails();
	}

	useEffect(() => {
		fetchSubscriptions();
	}, []);

	async function fetchSubscriptions() {
		try {
			const subscriptionJson = getAllSubscriptionsJson();
			const response = await dashboardApi.getAllSubscriptions(subscriptionJson);
			const transformedArray = response.data.subscriptionsList.map((item) => ({
				value: item.subscriptionname,
				label: item.subscriptionname,
			}));
			setSubscriptions(transformedArray);
		} catch (error) {
			console.error("Error fetching subscriptions:", error);
		}
	}

	function handleInputChange(value) {
		if (value.trim().length >= 3) {
			setIsLoading(true);
			searchSubscriptions(value);
		}
	}

	const handleSwitchTypeChange = (event) => {
		if (event.target.checked) {
			// Subscription discount
			setSwitchValue("discount");
			setDiscountType("notional");
		} else {
			// Discount percentage
			setDiscountType("normal");
			setSwitchValue("subscription");
		}
	};

	async function searchSubscriptions(value) {
		try {
			const subscriptionJson = getAllSubscriptionsJson(value);
			const response = await dashboardApi.getAllSubscriptions(subscriptionJson);
			const transformedArray = response.data.subscriptionsList.map((item) => ({
				value: item.subscriptionname,
				label: item.subscriptionname,
			}));
			setSubscriptions(transformedArray);
		} catch (error) {
			console.error("Error searching subscriptions:", error);
			setSubscriptions([]);
		} finally {
			setIsLoading(false);
		}
	}

	async function fetchSubscriptionDetails(subscription) {
		if (!subscription) return;

		setIsLoading(true);

		try {
			let userResourceJson = getSearchSubscriptionJson(subscription);
			let searchSubscriptionResponse = await dashboardApi.fetchSubscriptions(userResourceJson);
			console.log(searchSubscriptionResponse);
			const subscriptionData = searchSubscriptionResponse.data.resourcePropertiesList;

			console.log("subscriptionData", subscriptionData);
			// Populate fields with fetched details
			setSubscriptionId(subscriptionData[0].fields.subscriptionid);
			setSubscriptionDisplayName(subscriptionData[0].fields.subscriptionname);
			setSubscriptionDescription(subscriptionData[0].fields.description);
			setSubscriptionCharge(subscriptionData[0].fields.subscriptioncharge);
			setSubscriptionValidity(subscriptionData[0].fields.validity);
			setDiscount(subscriptionData[0].fields.discount);
			setSubscriptionIsActive(subscriptionData[0].fields.isactive);
			setSubscriptionIsExternal(subscriptionData[0].fields.isexternal);
			setSubscriptionAddons(subscriptionData[0].fields.licenses);
			setSubscriptionKey(subscriptionData[0].fields.subscriptionkey);
			setSubscriptionChargeType(subscriptionData[0].fields.subscriptionchargetype);
			setDiscountType(subscriptionData[0].fields.discounttype);
			setSubscriptionValidity(subscriptionData[0].fields.validity);

			// Set switch based on discount type
			if (
				subscriptionData[0].fields.discounttype === "notional" ||
				subscriptionData[0].fields.discounttype === "percentage"
			) {
				setSwitchValue("discount");
			} else {
				setSwitchValue("subscription");
			}

			console.log(subscriptionName, subscriptionCharge, subscriptionValidity, discount);
		} catch (error) {
			console.error("Error fetching subscription details:", error);
		} finally {
			setIsLoading(false);
		}
	}

	// Function to handle subscription change
	function handleSubscriptionChange(selected) {
		console.log(selected);
		setSubscriptionName(selected.label);
		setSelectedSubscriptions(selected);
		fetchSubscriptionDetails(selected.value);
	}

	const handleDateChange = (date) => {
		console.log("inside handle date change function");
		let selectedDateLong;

		if (!date) {
			// If no date is selected, set the date to 5 years from now
			var date = new Date(); // Now
			selectedDateLong = date.setFullYear(date.getFullYear() + 5);

			console.log("selectedDateLong :", selectedDateLong);
			setSubscriptionValidity(selectedDateLong);
			return;
		} else {
			selectedDateLong = date.valueOf();
			setSelectedDate(date);
		}

		setSubscriptionValidity(selectedDateLong);
	};

	return (
		<div
			style={{
				position: "relative",
				filter: filter,
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			{" "}
			<CssBaseline />
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						// Comment out maxWidth to restore previous looks for contextHeader
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Configure Subscription Plan
								</h1>

								<hr />

								{/* <TextField
									autoFocus
									fullWidth
									variant="outlined"
									select
									label="Search subscription name"
									value={subscriptionName}
									onChange={(e) => handleSubscriptionChange(e.target.value)} // Update subscriptionName on change
									InputLabelProps={{ style: { fontFamily: fontFamily } }}
									InputProps={{
										style: { fontFamily: fontFamily },
										sx: {
											// Apply styles here
											width: "100%",
											height: "35px",
											marginTop: "3px",
											marginBottom: "10px",
											fontFamily: fontFamily,
											zIndex: 9999,
										},
									}}
								>
									{subscriptions.map((option) => (
										<MenuItem
											key={option.value}
											value={option.value} // Use option.value as the value
										>
											{option.label}
										</MenuItem>
									))}
								</TextField> */}
								<ReactSelect
									// autoFocus
									options={subscriptions}
									value={
										subscriptionName
											? { value: subscriptionName, label: subscriptionName }
											: "Search for Subscription Plans"
									}
									onChange={(selected) => handleSubscriptionChange(selected)}
									onInputChange={handleInputChange}
									isLoading={isLoading}
									placeholder="Search for Subscription Plans" // Placeholder added here
									inputId="search-update-subscription"
									noOptionsMessage={() => "No results found"}
									formatOptionLabel={(option) => (
										<div>
											<div>{option.label}</div>
										</div>
									)}
									styles={{
										width: "100%",
										control: (provided) => ({
											...provided,
											width: "100%",
											marginTop: "5px",
											marginBottom: "10px",
											minHeight: "36px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
											zIndex: 9999,
										}),
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>

								<TextField
									id={"subscriptionkey"}
									className="input-field"
									label="Key"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={subscriptionKey}
									onChange={(e) => setSubscriptionKey(e.target.value)}
									style={{ marginTop: "8px" }}
									// onKeyDown={(e) => handleKeyDown(e, subscriptionKeyRef)}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
									InputProps={{ readOnly: true, style: { fontFamily: fontFamily } }}
									// inputRef={subscriptionKeyRef}
								/>

								<TextField
									id={"subscriptionname"}
									required
									className="input-field"
									label="Name"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={subscriptionNameToUpdate ? subscriptionNameToUpdate : subscriptionName}
									onChange={(e) => {
										const value = e.target.value;
										// setSubscriptionName(value);
										setSubscriptionNameToUpdate(value);
									}}
									style={{ marginTop: "3px" }}
									onKeyDown={handleKeyDownRef1}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>

								<TextField
									id={"subscriptiondescription"}
									required
									className="input-field"
									label="Description"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={subscriptionDescription ? subscriptionDescription : ""}
									onChange={(e) => {
										const value = e.target.value;
										setSubscriptionDescription(value);
									}}
									style={{ marginTop: "3px", marginBottom: "15px" }}
									onKeyDown={handleKeyDownRef1}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>

								<div
									style={{
										marginTop: "-3%",
										fontFamily: fontFamily,
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										marginLeft: "auto",
									}}
								>
									<span>Subscription</span>
									<div style={{ display: "flex", alignItems: "center" }}>
										<Switch
											id="subscriptionswitch"
											style={{
												color: "#12a5c6",
												width: "fit-content",
											}}
											{...label}
											checked={switchValue === "discount"}
											onChange={handleSwitchTypeChange}
										/>
										<span style={{ marginRight: "3px" }}>Discount</span>
									</div>
								</div>

								{switchValue === "discount" ? (
									<>
										<TextField
											id={"discount"}
											required
											className="input-field"
											label="Type"
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											value={discount}
											onChange={(e) => {
												const value = e.target.value;
												const sanitizedValue = value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except .
												const decimalCount = sanitizedValue.split(".").length - 1;

												if (decimalCount <= 1) {
													setDiscount(sanitizedValue);
												}
											}}
											style={{ marginTop: "3px" }}
											// onKeyDown={(e) => handleKeyDown(e, DiscountTypeRef)}
											InputLabelProps={{
												style: { fontFamily: fontFamily },
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Select
															id={discountType}
															value={discountType}
															onChange={handleDiscountTypeChange}
															variant="standard"
														>
															{Object.entries(properties.discountType).map(
																([discountCode, discountLabel]) => (
																	<MenuItem
																		key={discountCode}
																		value={discountCode}
																	>
																		{discountLabel}
																	</MenuItem>
																),
															)}
														</Select>
													</InputAdornment>
												),
												style: { fontFamily: fontFamily },
											}}
											// inputRef={DiscountTypeRef}
										/>
										<DatePicker
											id="dateformat"
											className="datepicker"
											placeholder={selectedDate ? selectedDate : "select date"}
											slotProps={{
												textField: { size: "small" },
												field: { clearable: true },
											}}
											sx={{
												fontFamily: "inherit",
												width: "100%",
												marginBottom: "15px",
												marginTop: "10px",
											}}
											label="Validity"
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													className="input-field"
													size="small"
													fullWidth
													margin="normal"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton>
																	<CalendarTodayIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
													style={{ fontFamily: fontFamily }}
												/>
											)}
											inputFormat="dd/MM/yyyy"
											disablePast
											openTo="year"
											views={["year", "month", "day"]}
											clearable
											mask="__/__/____"
											onChange={handleDateChange}
											value={selectedDate}
										/>
									</>
								) : (
									<>
										<TextField
											id="subscriptioncharge"
											className="input-field"
											label="Charge"
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											value={subscriptionCharge}
											onChange={(e) => {
												const value = e.target.value;
												const sanitizedValue = value.replace(/[^\d.]/g, "");
												const decimalCount = sanitizedValue.split(".").length - 1;

												if (decimalCount <= 1) {
													setSubscriptionCharge(sanitizedValue);
												}
											}}
											style={{ marginTop: "3px" }}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Select
															id="subscriptionchargetype"
															value={subscriptionChargeType}
															onChange={handleCurrencyChange}
															variant="standard"
															// inputRef={currencySelectorRef}
														>
															{Object.entries(properties.currencies).map(
																([currencyCode, currencyLabel]) => (
																	<MenuItem
																		id={currencyCode}
																		key={currencyCode}
																		value={currencyCode}
																	>
																		{currencyLabel}
																	</MenuItem>
																),
															)}
														</Select>
													</InputAdornment>
												),
												style: { fontFamily: fontFamily },
											}}
										/>
										<DatePicker
											id="dateformat"
											className="datepicker"
											placeholder={selectedDate ? selectedDate : "select date"}
											slotProps={{
												textField: { size: "small" },
												field: { clearable: true },
											}}
											sx={{
												fontFamily: "inherit",
												width: "100%",
												marginBottom: "5px",
												marginTop: "4px",
											}}
											label="Validity"
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													className="input-field"
													size="small"
													fullWidth
													margin="normal"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton>
																	<CalendarTodayIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
													style={{ fontFamily: fontFamily }}
												/>
											)}
											inputFormat="dd/MM/yyyy"
											disablePast
											openTo="year"
											views={["year", "month", "day"]}
											clearable
											mask="__/__/____"
											onChange={handleDateChange}
											value={selectedDate}
										/>
										<TextField
											id={"subscriptionaddons"}
											required
											className="input-field"
											label="Licenses"
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											type="number"
											value={subscriptionAddons}
											onChange={(e) => {
												const value = e.target.value.replace(/\D/g, "");
												setSubscriptionAddons(value);
											}}
											style={{ marginTop: "3px" }}
											InputLabelProps={{
												style: { fontFamily: fontFamily },
											}}
											inputProps={{
												min: 1, // Minimum value allowed
												max: 100, // Maximum value allowed
												step: 1, // Step increment
												style: { fontFamily: fontFamily },
											}}
										/>
									</>
								)}
								<div style={{ display: "flex", alignItems: "center" }}>
									<Checkbox
										id={"subscriptionisactive"}
										checked={subscriptionIsActive}
										onChange={(e) => setSubscriptionIsActive(e.target.checked)}
										color="primary"
									/>
									<span style={{ marginLeft: -1 }}>Active</span>

									<div style={{ marginLeft: 16, display: "flex", alignItems: "center" }}>
										<Checkbox
											id={"subscriptionisactive"}
											checked={subscriptionIsExternal}
											onChange={(e) => setSubscriptionIsExternal(e.target.checked)}
											color="primary"
										/>
										<span style={{ marginLeft: -1 }}>External</span>
									</div>
								</div>

								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										data-button-name="cancel-manage-account"
									>
										Cancel
									</Button>

									{/* {updateAccDetails === true && ( */}
									<Button
										id="createButton"
										onClick={UpdateSubscription}
										variant="outlined"
										buttonType="main"
										isLoading={buttonLoading}
										data-button-name="update-subscription"
									>
										Update
									</Button>
									{/* )} */}
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
				messageLength={50}
			/>
		</div>
	);
}

export default UpdateSubscriptionPlan;
