import properties from "components/properties/ApplicationProps";
import { checkIfItsAFolder } from "./checkIfItsAFolder";

export default function addUploadTrackingProperties({ file, batchId, droppedLocationId }) {
	Object.defineProperties(file, {
		fileId: { value: crypto.randomUUID() },
		operationType: { value: "upload" },
		batchId: { value: batchId },
		path: { value: file.path || file.webkitRelativePath },
		allowToUpload: { value: true, writable: true },
		didItTryToUpload: { value: false, writable: true },
		completionTime: { value: null, writable: true },
		dropLocationId: { value: droppedLocationId },
		description: { value: "", writable: true },
		etc: { value: 0, writable: true },
		error: { value: null, writable: true },
		errorCode: { value: null, writable: true },
		errorMssg: { value: null, writable: true },
		isUploading: { value: null, writable: true },
		isUploaded: { value: false, writable: true },
		uploadPercentage: { value: 0, writable: true },
		uploadCompletePercentageNumber: { value: 0, writable: true },
		uploadSpeedInKB: { value: 0, writable: true },
		uploadLocationId: { value: droppedLocationId, writable: true },
		uploadSpeed: { value: 0, writable: true },
		stopUpload: { value: null, writable: true },
		isUploadStopped: { value: false, writable: true },
		isOperationComplete: { value: false, writable: true },
		isInFolder: { value: Boolean(checkIfItsAFolder(file.path)) || Boolean(checkIfItsAFolder(file.webkitRelativePath)) }, // prettier-ignore
		uploadFlag: { value: properties.versionFlag.keep_existing_files,writable: true }, // prettier-ignore
	});
}
