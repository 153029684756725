import properties from "components/properties/ApplicationProps";

export function getSubscriptionAccountDetailsJson(
	accountid,
	subscriptionName,
	subscriptionKey,
	subscriptionValidity,
	subscriptionCounter,
) {
	let stringifyFetchResource = {
		action: properties.actions.SAVE,
		accountId: accountid.accountId,
		subscriptionName: subscriptionName,
		subscriptionKey: subscriptionKey,
		validity: subscriptionValidity,
		subQty: subscriptionCounter,
	};

	return stringifyFetchResource;
}
