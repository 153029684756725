import properties from "components/properties/ApplicationProps";

export default function getFetchInvoiceDetailsJSON(formdata) {
	return {
		accountid: formdata.accountid,
		accountname: formdata.accountname,
		datausagecharge: formdata.datausagecharge,
		datausageingb: formdata.datausageingb,
		discountedamount: formdata.discountedamount,
		discountpercentage: formdata.discountpercentage,
		duedate: formdata.duedate,
		enddate: formdata.enddate,
		gstrate: formdata.gstrate,
		invoicegeneratedon: formdata.invoicegeneratedon,
		invoiceid: formdata.invoiceid,
		lastupdatetime: formdata.lastupdatetime,
		orgid: formdata.orgid,
		orgname: formdata.orgname,
		paymentstatus: formdata.paymentstatus,
		startdate: formdata.startdate,
		subscriptioncharge: formdata.subscriptioncharge,
		taxamount: formdata.taxamount,
		totalamount: formdata.totalamount,
		totalamountbeforetax: formdata.totalamountbeforetax,
	};
}
