import { useEffect, useContext, useState, useCallback, memo } from "react";
import { FontContext } from "components/UI/Theme";
import useStyles from "routes/TableStyles";
import {
	Button,
	CircularProgress,
	CssBaseline,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import { generateDataUsageJSON } from "components/requestcontext/DataUsageCalculate";
import { dashboardApi } from "components/misc/DashboardApi";
import { useDispatch, useSelector } from "react-redux";
import { neutral, textsize } from "assets/css/MainCss";
import { dateRangeSelector, getFormattedDate } from "utils/helpers/dateRangeSelector";
import dayjs from "dayjs";
import convertUTCToLocaleTimeInMs from "utils/helpers/convertUTCToLocaleTimeInMs";
import convertBytesToGigaBytes from "utils/helpers/convertBytesToGigaBytes";
import "./DataUsage.styles.css";
import { SET_GRID_MESSAGE } from "store/uiSlice";

const DataUsage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { themeColor, fontFamily, smallTextSize } = useContext(FontContext);
	const [loading, setLoading] = useState(false);
	const [dateRangeText, setDateRangeText] = useState({});
	const [showBarChart, setShowBarChart] = useState(true);
	const [storageUsed, setStorageUsed] = useState(0);
	const [chartsData, setChartsData] = useState([]);
	const [noDataFound, setNoDataFound] = useState(false);
	const accountId = useSelector(({ userDetails }) => userDetails.data?.accountid);

	const { handleSubmit, watch, getValues, control } = useForm();

	const handleToggleChartButtons = useCallback(() => setShowBarChart((prev) => !prev), []);

	const changeFontSize = useCallback(() => {
		const usageValueElList = document.querySelectorAll(".usage-value");
		const usageInfoElList = document.querySelectorAll(".usage-info");
		const selectEl = document.querySelectorAll(".MuiSelect-select");

		if (smallTextSize === 14) {
			usageValueElList.forEach((usageValueEl) => (usageValueEl.style.fontSize = "1.5rem"));
			selectEl.forEach((usageInfoEl) => (usageInfoEl.style.fontSize = "1.1rem"));
			usageInfoElList.forEach((usageInfoEl) => (usageInfoEl.style.fontSize = "1rem"));
		} else {
			usageValueElList.forEach((usageValueEl) => (usageValueEl.style.fontSize = "1.3rem"));
			selectEl.forEach((usageInfoEl) => (usageInfoEl.style.fontSize = "1rem"));
			usageInfoElList.forEach((usageInfoEl) => (usageInfoEl.style.fontSize = "0.8rem"));
		}
	}, [smallTextSize]);

	const handleSubmitButton = useCallback(
		async (formData) => {
			try {
				//  startDate and endDate will only be available when user selects custom date range
				//	which will be dayjs object, not default Date object
				const dayjsStartDate = formData?.startDate || new Date();
				const dayjsEndDate = formData?.endDate || new Date();

				setLoading(() => true);

				const rangeSelection = getValues("dateRange");
				const { rangeStartDate, rangeEndDate } = dateRangeSelector({
					rangeSelection,
					dayjsStartDate,
					dayjsEndDate,
				});

				// if user select date from different years, it will show those different years
				if (rangeStartDate.getFullYear() !== rangeEndDate.getFullYear()) {
					setDateRangeText(() => ({
						startDate: rangeStartDate.toLocaleDateString("en-GB", {
							month: "short",
							day: "numeric",
							year: "2-digit",
						}),
						endDate: rangeEndDate.toLocaleDateString("en-GB", {
							month: "short",
							day: "numeric",
							year: "2-digit",
						}),
					}));
				} else {
					setDateRangeText(() => ({
						startDate: getFormattedDate(rangeStartDate),
						endDate: getFormattedDate(rangeEndDate),
					}));
				}

				if (getValues("dateRange") !== "custom") {
					setDateRangeText(() => ({
						startDate: getFormattedDate(rangeStartDate),
						endDate: getFormattedDate(rangeEndDate),
					}));
				}

				const startDateString = rangeStartDate.toDateString(); // E.g. 'Thu Jan 01 1970'
				const endDateString = rangeEndDate.toDateString(); // E.g. 'Thu Jan 01 1970'

				const shortStartDateString = new Date(startDateString).toLocaleDateString([], {
					day: "numeric",
					month: "short",
					year: "numeric",
				});
				const shortEndDateString = new Date(endDateString).toLocaleDateString([], {
					day: "numeric",
					month: "short",
					year: "numeric",
				});

				dispatch(SET_GRID_MESSAGE(`Fetching data usage from ${shortStartDateString} to ${shortEndDateString}`));

				// Convert timezone offset from minutes to milliseconds
				const startDateTimzoneOffsetToUTC = new Date(startDateString).getTimezoneOffset() * 60000;
				const endDateTimzoneOffsetToUTC = new Date(endDateString).getTimezoneOffset() * 60000;

				/**
				 * Convert locale time to UTC
				 * Add(+) if locale is in eastern or who's GMT offset is in plus e.g. Asia/Calcutta[GMT+5:30]
				 * Substract)(-) if locale is in western or who's GMT offset is in minus e.g. America/Los_Angeles[GMT-8:00]
				 *
				 * 🔴🔴🔴 WARNING: Do not remove or convert following substraction/minus to addition/plus 🔴🔴🔴
				 */
				const convertLocaleStartDateToUTC = new Date(startDateString).getTime() - startDateTimzoneOffsetToUTC;
				const convertLocaleEndDateToUTC = new Date(endDateString).getTime() - endDateTimzoneOffsetToUTC;

				const requestJSON = generateDataUsageJSON({
					accountId,
					isTypeBilling: getValues("dateRange") === "billing",
					startDate: convertLocaleStartDateToUTC,
					endDate: convertLocaleEndDateToUTC,
				});

				const response = await dashboardApi.calculateDataUsage(requestJSON);
				if (
					response.hasOwnProperty("data") &&
					response.data.hasOwnProperty("dataUsageDetailsList") &&
					response.data.footer.code !== 1 // Check for any error message
				) {
					const modifiedData = response.data.dataUsageDetailsList[0].dayWiseDataUsage.map((perDayData) => {
						const adjustedOffsetUsageDate = convertUTCToLocaleTimeInMs(perDayData.dateOfusage);
						const chartXaxisDay = new Date(adjustedOffsetUsageDate).toLocaleString([], {
							month: "2-digit",
							day: "numeric",
						});
						const valueInGB = convertBytesToGigaBytes(perDayData.valueInBytes);

						// add following xAxisData and yAxisData to obejct so charts can pick up
						Object.defineProperty(perDayData, "xAxisData", { value: chartXaxisDay, writable: false });
						Object.defineProperty(perDayData, "yAxisData", {
							value: valueInGB,
							writable: false,
						});

						return perDayData;
					});
					setStorageUsed(() =>
						convertBytesToGigaBytes(response.data.dataUsageDetailsList[0].totalStorgaeUsage),
					);
					setChartsData(() => modifiedData);
					setNoDataFound(() => false);
				} else if (response?.data?.footer.code === 1) {
					setStorageUsed(() => 0);
					setNoDataFound(() => true);
				}
				setLoading(() => false);
			} catch (error) {
				console.log(error);
			}
		},
		[accountId, getValues, dispatch],
	);

	useEffect(() => {
		changeFontSize();

		if (accountId) {
			handleSubmitButton();
		}
	}, [accountId, changeFontSize, handleSubmitButton]);

	return (
		<div
			className="data-usage"
			style={{
				position: "relative",
				fontFamily,
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				>
					<Typography style={{ fontFamily: "inherit" }}>Data Usage</Typography>
				</Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div
					className={classes.innercontainer}
					style={{
						overflow: "auto",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						padding: "0 1rem",
						fontSize: smallTextSize,
						backgroundColor: "#eeeeee",
					}}
				>
					<div className="usage-controllers-container">
						<div className="date-range-container">
							<form onSubmit={handleSubmit((formData) => handleSubmitButton(formData))}>
								<Controller
									name="dateRange"
									// defaultValue="billing"
									defaultValue="currentMonth"
									control={control}
									render={({ field }) => {
										return (
											<FormControl
												className="date-range-selector"
												size="small"
												fullWidth
											>
												<InputLabel
													id="usage-range-selector-label"
													sx={{
														fontFamily: "inherit",
													}}
												>
													Date Range
												</InputLabel>
												<Select
													labelId="usage-range-selector-label"
													id="usage-range-selector"
													label="Date Range"
													sx={{
														fontFamily: "inherit",
													}}
													{...field}
												>
													{/* <MenuItem value={"billing"}>Billing Period</MenuItem> */}
													<MenuItem value={"currentMonth"}>Current Month</MenuItem>
													<MenuItem value={"today"}>Last 24 Hours</MenuItem>
													<MenuItem value={"week"}>Last 7 Days</MenuItem>
													<MenuItem value={"month"}>Last 30 Days</MenuItem>
													<MenuItem value={"custom"}>Custom Date</MenuItem>
												</Select>
											</FormControl>
										);
									}}
								/>

								{watch("dateRange") === "custom" ? (
									<div className="custom-date-range">
										<Controller
											name="startDate"
											defaultValue={dayjs(new Date())}
											control={control}
											render={({ field: { onChange, value } }) => {
												return (
													<DatePicker
														disableFuture
														className="date-range-picker"
														label="Start Date"
														format="DD/MM/YYYY"
														maxDate={watch("endDate")}
														views={["year", "month", "day"]}
														slotProps={{
															textField: { size: "small" },
															field: { clearable: true },
														}}
														sx={{
															fontFamily: "inherit",
														}}
														value={value}
														onChange={(event) => {
															onChange(event);
														}}
													/>
												);
											}}
										/>

										<Controller
											name="endDate"
											defaultValue={dayjs(new Date())}
											control={control}
											render={({ field: { onChange, value } }) => {
												return (
													<DatePicker
														className="date-range-picker"
														disableFuture
														label="End Date"
														format="DD/MM/YYYY"
														minDate={watch("startDate")}
														views={["year", "month", "day"]}
														slotProps={{
															textField: { size: "small" },
															field: { clearable: true },
														}}
														sx={{
															fontFamily: "inherit",
														}}
														value={value}
														onChange={(event) => {
															onChange(event);
														}}
													/>
												);
											}}
										/>
									</div>
								) : null}

								<Button
									className="date-range-selector"
									variant="outlined"
									type="submit"
									size="small"
									disabled={loading}
									style={{
										backgroundColor: `${loading ? "#0000001f" : neutral.buttoncolor}`,
										color: neutral.buttontextcolor,
										fontSize: textsize.buttontextsize,
										fontFamily: "inherit",
										width: "100px",
										boxShadow: "unset",
									}}
									data-button-name="submit-data-usage"
								>
									{loading ? <CircularProgress size={15} /> : "Submit"}
								</Button>
							</form>
						</div>

						<ToggleButtonGroup
							exclusive
							fullWidth
							size="small"
							value={showBarChart}
							onChange={handleToggleChartButtons}
						>
							<ToggleButton selected={showBarChart}>
								<SignalCellularAltIcon />
							</ToggleButton>

							<ToggleButton selected={!showBarChart}>
								<ShowChartIcon />
							</ToggleButton>
						</ToggleButtonGroup>
					</div>

					<div className="data-usage-container">
						<div
							className="usage-info-container"
							style={{ gridArea: "dataRange" }}
						>
							<p className="usage-value">{`${dateRangeText.startDate} - ${dateRangeText.endDate}`}</p>
							<p className="usage-info">Date Range</p>
						</div>

						<div
							className="usage-info-container"
							style={{ gridArea: "dataUsage" }}
						>
							<p className="usage-value">{storageUsed ? storageUsed.toFixed(2) : "0"} GB</p>{" "}
							<p className="usage-info">Max Storage Used</p>
						</div>

						<div
							className="chart-container"
							style={{
								height: "100%",
								width: "100%",
								gridArea: "chart",
								display: "grid",
								placeItems: "center",
							}}
						>
							{noDataFound ? (
								<p> No data is Found!...</p>
							) : showBarChart ? (
								<BarChart data={chartsData} />
							) : (
								<LineChart data={chartsData} />
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(DataUsage);
