import orgIcon from "assets/proto_1/Data_Frugal_Logo.svg";

let firstname = sessionStorage.getItem("firstName");
const properties = {
	parentFolderId: -1,
	fetchSWM: -1,
	fetchSharedByUserId: -1,
	defaultDashboardHomeRootFolderID: -1,
	defaultBreadcrumbParentId: -1,
	defaultShareDashboardHomeRootFolderID: -1,
	defaultSharedResourceByUserHomeRootFolderID: -1,
	userId: 18,
	parallelUploadLimit: 10,
	asyncUploadLimit: 10,
	defaultResourceId: 0,
	defaultRootBreadcrumbName: "Dashboard",
	defaultSharedWithMeRootBreadcrumbName: "Shared with me",
	defaultSharedByUserRootBreadcrumbName: "Shared by " + firstname,
	defaultGroupBreadcrumbName: "Group",
	resourceId: sessionStorage.getItem("resourceidd"),
	defaultRootBreadcrumbForSearch: "Search Results",
	defaultKeycloakClient: "mm-app",
	defaultKeycloakRealm: "DATAFRUGAL",
	delayTime: 200,
	delayToken: 240000,
	currency: "INR",
	actions: {
		SEARCH: "SEARCH",
		SAVE: "SAVE",
		DELETE: "DELETE",
		MODIFY: "MODIFY",
		MOVE: "MOVE",
		SHARE: "SHARE",
		INSERT: "INSERT",
		EDITOR: "EDITOR",
		GENERATE_NOTIFICATION: "GENERATE_NOTIFICATION",
		ACCEPT_NOTIFICATION: "ACCEPT_NOTIFICATION",
		REJECT_NOTIFICATION: "REJECT_NOTIFICATION",
	},

	events: {
		SDCHANGE: "SDCHANGE", //renamed CACHE-REFRESH as SDCHANGE
		DOUSERONBOARD: "DOUSERONBOARD",
		USERONBOARDED: "USERONBOARDED",
		DOORGONBOARD: "DOORGONBOARD",
		ORGONBOARDED: "ORGONBOARDED",
		ACCOUNTONBOARDED: "ACCOUNTONBOARDED",
		DSONBOARDED: "DSONBOARDED",
		DOKCUPDATE: "DOKCUPDATE",
		AUTOLINK: "AUTOLINK",
		DOORGUPDATE: "DOORGUPDATE",
		SIM: "SIM",
		AUTO: "AUTO",
		NOTIFY: "NOTIFY",
	},

	bucketType: {
		STANDARD: "STANDARD",
		INTELLIGENT: "INTELLIGENT",
	},

	shareOutSideOrg: {
		true: "true",
		false: "false",
	},
	role: {
		OWNER: "OWNER",
		EDITOR: "EDITOR",
		VIEWERS: "VIEWERS",
	},
	activeStatus: {
		true: true,
		false: false,
	},

	versioned: true,
	contentType: "application/json",
	Access_Control_Allow_Origin: "*",
	shareOutsideOrg: false,
	bucketSuffix: "-cloud",
	reusebucket: "",
	updateType: {
		INFOUPDATE: "INFOUPDATE",
		RESOURCEUPDATE: "RESOURCEUPDATE",
	},
	requestRoutes: {
		fetchDashboardResources: "/ext/api/fetchDashboardResources",
		fetchSharedDashBoardResource: "/ext/api/fetchSharedDashBoardResource",
		fetchResource: "/ext/api/fetchResource",
		addResource: "/ext/api/addResource",
		completeAddResource: "/ext/api/completeAddResource",
		fetchResourceContent: "/ext/api/fetchResourceContent",
		onBoardUser: "/int/api/onBoardUser",
		deleteResource: "/ext/api/deleteResource",
		updateResource: "/ext/api/updateResource",
		updateResourceInfo: "/ext/api/updateResourceInfo",
		completeUpdateResource: "/ext/api/completeUpdateResource",
		moveResource: "/ext/api/moveResource",
		addSharedResource: "/ext/api/addSharedResource",
		searchUsers: "/ext/api/searchUsers",
		searchUsersWithRoleUser: "/ext/api/searchUsersWithRoleUser",
		searchAccountUsers: "/ext/api/searchAccountUsers",
		fetchGroups: "/ext/api/fetchGroups",
		addGroup: "/int/api/addGroup",
		addUsersInGroup: "/int/api/addUsersInGroup",
		onBoardOrg: "/int/api/onBoardOrg",
		captureOrgDetails: "/int/api/captureOrgDetails",
		fetchOrgDetails: "ext/api/fetchOrgDetails",
		getallplans: "/int/api/getAllPlans",
		getToken: "/int/api/getToken",
		fetchUsersInGroup: "/ext/api/fetchUsersInGroup",
		fetchresources: "/ext/api/fetchresources",
		fetchsharedresourcesbyuser: "/ext/api/fetchsharedresourcesbyuser",
		fetchResourceVersions: "/ext/api/fetchResourceVersions",
		cancelRequest: "/ext/api/cancelRequest",
		cancelRequests: "/ext/api/cancelRequests",
		uploadResource: "/ext/api/uploadResource",
		completeUploadResource: "/ext/api/completeUploadResource",
		deleteGroup: "/int/api/deleteGroup",
		updateGroupInfo: "/int/api/updateGroup",
		resourceLabels: "/ext/api/handleResourceLabels",
		unsharedresource: "/ext/api/unsharedResource",
		insertOrUpdateResource: "/ext/api/insertOrUpdateResource",
		completeInsertOrUpdateResource: "/ext/api/completeInsertOrUpdateResource",
		linkUserAccount: "/ext/api/linkUserAccount",
		changeOwnership: "/ext/api/changeOwnership",
		createaccount: "/int/api/createAccount",
		updateUsersRoleAttribute: "/ext/api/updateUsersRoleAttribute",
		sendInvitations: "/ext/api/sendInvitations",
		cloneresource: "/ext/api/cloneResource",
		acceptInvitationToLinkUser: "/ext/api/acceptInvitationToLinkUser",
		fetchNotifications: "/ext/api/fetchNotifications",
		generateNotification: "/ext/api/generateNotification",
		updateAttributes: "/ext/api/updateAttributes",
		fetchAttributes: "/ext/api/fetchAttributes",
		fetchuserprofile: "/ext/api/fetchuserprofile",
		rejectNotification: "/ext/api/rejectNotification",
		updateGroupUsers: "/int/api/updateGroupUsers",
		updateGroup: "/int/api/updateGroup",
		updateAccount: "/int/api/updateAccount",
		fetchAccountDetailsOfUser: "/ext/api/fetchAccountDetailsOfUser",
		getDataUsage: "/ext/api/dataUsageCalculate",
		getAllPlans: "/int/api/getAllPlans",
		getPaymentOrder: "/int/api/getPaymentOrder",
		validatePayment: "/int/api/validatePayment",
		fetchInvoice: "/ext/api/fetchInvoice",
		calculateTotalAmount: "/ext/api/calculateTotalAmount",
		createSubscription: "/ext/api/createSubscription",
		getAllSubscriptions: "/int/api/getAllSubscriptions",
		fetchSubscriptions: "/ext/api/fetchSubscriptions",
		updateSubsctiption: "/ext/api/updateSubscription",
		assignSubscriptionToAccount: "/ext/api/assignSubscriptionToAccount",
		unAssignSubscription: "/ext/api/unAssignSubscription",
		fetchAllAccountDetails: "/ext/api/fetchAllAccountDetails",
		updateInvoice: "/ext/api/updateInvoice",
		fetchInvoiceForSupport: "/ext/api/fetchInvoiceForSupport",
		getAllAssociatedSubscriptionsWithAccount: "/int/api/getAllAssociatedSubscriptionsWithAccount",
		fetchAllOrgDetails: "/ext/api/fetchAllOrgDetails",
		fetchOrgAccountDetails: "/ext/api/fetchOrgAccountDetails",
		calculateDataUsage: "/ext/api/calculateDataUsage",
		fetchAccountUsers: "/ext/api/fetchAccountUsers",
		updateGroupUsers: "/int/api/updateGroupUsers",
		fetchSharedUsers: "/ext/api/fetchSharedUsers",
		getSubscriptionFeatures: "/int/api/getSubscriptionFeatures",
		changeSubscription: "/ext/api/changeSubs",
		getAllRegions: "/ext/api/getAllRegions",
		addCustomSubdomain: "/ext/api/addCustomSubdomain",
		fetchDNSDetails: "//ext/api/fetchDNSDetails",
		refreshCache: "/ext/api/refreshCache",
		orgEvents: "/ext/api/orgEvents",
		fetchAllTransactions: "/ext/api/fetchAllTransactions",
		supportInTheMiddle: "/int/api/triggerIntEvent", // dummy route for support in the middle, replace later
		getState: "/int/api/user/current-state",
		handleNotification: "/ext/api/handleNotification",
		fetchOrgNAccountDetails: "/ext/api/fetchOrgNAccountDetails",
	},

	orgProperties: {
		org: "DATAFRUGAL",
		orgIcon: orgIcon,
		orgShortName: "DATAFRUGAL",
		orgName: "DATAFRUGAL",
		orgNameInHeader: "DATAFRUGAL", //orgname used in toolbar
		isVersioned: true,
		shareOutSideOrg: false,
		encrypted: true,
		lastUpdateUser: sessionStorage.getItem("username"),
		reuseBucketName: "local-datafrugal-ap-south-1",
	},

	masterOrgProperties: {
		orgname: "MASTER",
	},

	bucketProperties: {
		bucketPrefix: "local-", //use value "demo-" for demo
		bucketSuffix: "-ap-south-1",
		encrypted: true,
	},

	folderDetails: [
		{
			contenttype: "folder",
			description: "",
			filename: "Dashboard",
			isfolder: true,
			lastupdatetime: "",
			locationid: -1,
			parentfolderid: -1,
			parentfoldername: "/",
			resourceid: 0,
			size: 0,
			uri: null,
			version: 1,
		},
	],

	sharedFolderDetails: [
		{
			contenttype: "folder",
			description: "",
			filename: "Shared with me",
			isfolder: true,
			lastupdatetime: "",
			locationid: -1,
			parentfolderid: -1,
			parentfoldername: "/",
			resourceid: 0,
			size: 0,
			uri: null,
			version: 1,
		},
	],

	versionFlag: {
		allNew_files: "all_new",
		replace_existing_files: "replace_existing",
		keep_existing_files: "keep_existing",
	},

	userRoles: {
		unknown: "unknown",
		user: "user",
		member: "member",
		billing: "billing",
		systemadmin: "systemadmin",
		support: "support",
	},

	//Add user roles to whom you want to display welcome screen of instructions
	showWelcomeModalBoxToUserRoles: {
		user: "user",
	},

	//roles which are not in below list will be redirected to support page after login
	showAllFieldToUserRoles: {
		user: "user",
		member: "member",
		billing: "billing",
		systemadmin: "systemadmin",
		support: "support",
	},

	//read only fields to be shown to user in update attribute form
	readOnlyFields: {
		kc_realm: "kc_realm",
		user_org: "user_org",
		terms_and_conditions: "terms_and_conditions",
		login_type: "login_type",
		kc_onboarding: "kc_onboarding",
		db_onboarding: "db_onboarding",
	},

	currencies: {
		INR: "INR (₹)",
		USD: "USD ($)",
		EUR: "EUR (€)",
		GBP: "GBP (£)",
		JPY: "JPY (¥)",
		AUD: "AUD ($)",
		SGD: "SGD ($)",
	},

	discountType: {
		notional: "notional",
		percentage: "percentage",
	},
	fetchedInvoiceDetails: [
		"accountid",
		"accountname",
		"invoicegeneratedon",
		"orgid",
		"paymentstatus",
		"orgname",
		"invoiceid",
	],
	initialData: {
		accountid: "",
		accountname: "",
		datausagecharge: "",
		datausageingb: "",
		discountedamount: "",
		discountpercentage: "",
		duedate: "",
		enddate: "",
		gstrate: "",
		invoicegeneratedon: "",
		invoiceid: "",
		lastupdatetime: "",
		orgid: "",
		orgname: "",
		paymentstatus: "",
		startdate: "",
		subscriptioncharge: "",
		taxamount: "",
		totalamount: "",
		totalamountbeforetax: "",
	},
	status: {
		success: "SUCCESS",
		redirecturl: "https://localhost/payment",
		invoiceid: "52650",
	},

	companyDetails: {
		name: "Celeritio Software Private Limited",
		email: "accounts@celeritio.com",
		contact: "+919867406057",
		gst: "27AAHCC1171G1ZV",
		pan: "AAHCC1171G",
	},

	domainRegex: {
		subdomainRegex: /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)$/,
		domainRegex: /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(\.[a-zA-Z0-9-]{1,63}){0,126}$/,
	},
};
export default properties;
