import React, { useContext, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CssBaseline, Grid, TableContainer } from "@material-ui/core";
import useStyles from "routes/TableStyles";
import OrgOnboarding from "./OrgOnboarding";
import { FontContext } from "components/UI/Theme";
import FormContainer from "layout/FormContainer";
import JoinOrgForm from "views/JoinOrgFormView";
import { GlobalRole } from "Constants";

const OrgOnboardForm = () => {
	const [selectedForm, setSelectedForm] = useState("updateOrganisation");
	const { themeColor } = useContext(FontContext);
	const classes = useStyles();

	// Assuming GlobalRole is an object with user role info
	const userRole = GlobalRole.userrole; // or get it from props/context

	const formStyle = (form) => ({
		backgroundColor: selectedForm === form ? "#fff" : "#e0e0e0",
		color: selectedForm === form ? "#000" : "#777",
		border: `1px solid `,
		borderBottom: selectedForm === form ? "none" : ``,
		borderRadius: selectedForm === form ? "8px 8px 0px 0px" : "8px 8px 0px 0px",

		// borderRadius: selectedForm === form ? "" : "",
	});

	return (
		<div
			className="resource-table"
			style={{ backgroundColor: themeColor, height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{ height: "32px" }}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div
					className={classes.innercontainer}
					style={{ height: "auto" }}
				>
					<TableContainer
						className={classes.tablecontainer}
						style={{ overflowY: "hidden" }}
					>
						<FormContainer>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "0rem",
									padding: "0.5rem",
								}}
							>
								{userRole === "user" ? (
									// Render the toggle buttons only if user role is 'user'
									<>
										<div style={{ display: "flex", width: "100%" }}>
											<ToggleButtonGroup
												value={selectedForm}
												exclusive
												onChange={(e, form) => form && setSelectedForm(form)}
												style={{
													backgroundColor: "transparent",
													display: "flex",
													width: "100%",
													marginLeft: "1.9rem",
												}}
											>
												<ToggleButton
													value="updateOrganisation"
													style={formStyle("updateOrganisation")}
												>
													Set Up Organisation
												</ToggleButton>
												<ToggleButton
													value="joinOrgForm"
													style={formStyle("joinOrgForm")}
												>
													Join Organisation
												</ToggleButton>
											</ToggleButtonGroup>
										</div>
										<div style={{ marginTop: "-43px", width: "100%" }}>
											{selectedForm === "updateOrganisation" ? (
												<OrgOnboarding />
											) : (
												<JoinOrgForm />
											)}
										</div>
									</>
								) : (
									// Show only the OrgOnboarding component if the user role is not 'user'
									<OrgOnboarding />
								)}
							</div>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
		</div>
	);
};

export default OrgOnboardForm;
