import { dashboardApi } from "components/misc/DashboardApi";
import { getFetchResourceJson } from "components/requestcontext/FetchResource";

export default async function generateFetchCurl(resource) {
	console.log("filename", resource.filename);
	const escapedFilename = escapeFilename(resource.filename);
	return generateCurlCommand(resource, escapedFilename);
}

function escapeFilename(filename) {
	// Replace special characters and multiple consecutive special characters with underscores
	const invalidChars = /[/?:|"<>()\s]+/g;
	const escapedFilename = filename.replace(invalidChars, "_");
	return escapedFilename;
}

const generateCurlCommand = async (resource, newFilename) => {
	const fetchResourceJson = getFetchResourceJson(resource);
	console.log("fetchResourceJson", fetchResourceJson);

	try {
		let curlCommand = await dashboardApi.fetchResource(fetchResourceJson);
		console.log("curlCommand", curlCommand);
		let endpointurl = curlCommand.data.endpoint.endpoint;
		console.log("endpointurl", endpointurl);

		const generatedUrl = `curl -k -X GET -L --url "${endpointurl}" -H "Content-Type:application/json" -o "${newFilename}"`;
		console.log("generatedUrl", generatedUrl);

		// Copy the endpoint URL to the clipboard
		return navigator.clipboard
			.writeText(generatedUrl)
			.then(() => {
				console.log("Endpoint URL copied to clipboard");
				return Promise.resolve({ status: 200, mssg: "cURL is copied successfully" });
			})
			.catch((error) => {
				console.error("Unable to copy endpoint URL to clipboard: ", error);
				return Promise.reject({ status: 400, mssg: "Failed to copy cURL" });
			});
	} catch (error) {
		console.error("Error fetching cURL command: ", error);
		throw new Error(error);
	}
};