import properties from "components/properties/ApplicationProps";

export function getCustomSubdomain(domain, txtrecordname) {
	let stringifyCustomSubdomain = {
		action: properties.actions.SAVE,
		domain: domain,
		txtRecordName: txtrecordname,
	};
	return stringifyCustomSubdomain;
}
