import React from "react";
import IconButton from "@mui/material/IconButton";
import CopyAllIcon from "@mui/icons-material/CopyAll";

const CustomCopyButton = ({ id, onClick, positionStyle = {}, ...props }) => {
	return (
		<IconButton
			id={id}
			onClick={onClick}
			style={{ position: "absolute", top: 0, right: 0, ...positionStyle }}
			{...props}
		>
			<CopyAllIcon />
		</IconButton>
	);
};

export default CustomCopyButton;
