import { GlobalGroup } from "Constants";

export function getFetchDataUsageJson(accountId, startDate, endDate, selectedUsers,orgname) {
	console.log(selectedUsers);

	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		startDate: startDate,
		endDate: endDate,
		accountId: accountId,
		orgName:orgname,
		userList: selectedUsers.map((user) => user.value),
	};

	return stringifyFetchResource;
}
