import properties from "components/properties/ApplicationProps";

export function getAssociatedSubscriptionsJson(accountId) {
	let stringifyFetchResource = {
		action: properties.actions.SEARCH,
		accountId: accountId,
	};

	return stringifyFetchResource;
}
