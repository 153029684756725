import React, { useState, useEffect, useRef, useCallback } from "react";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { dashboardApi } from "components/misc/DashboardApi";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Notification from "../notification/Notification";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { FontContext } from "../../UI/Theme";
import { getSearchUsersToLinkJson } from "components/requestcontext/SearchUsersToLink";
import getGenerateNotificationJSON from "components/requestcontext/GenerateNotification";
import properties from "components/properties/ApplicationProps";
import { GlobalUsername } from "Constants";
import { useDebouncedState } from "@react-hookz/web/esm/useDebouncedState";
import FormContainer from "layout/FormContainer";

export default function LinkAccount() {
	const classes = useStyles();
	const history = useHistory();
	const assignrole = useRef();

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [selectedRole, setSelectedRole] = useState(null);
	const [searchTerm, setSearchTerm] = useDebouncedState("", 300);
	const [buttonLoading, setButtonLoading] = useState(false);
	const { themeColor, fontFamily } = useContext(FontContext);

	const showSnackbarWithMessage = useCallback((message) => {
		setShowSnackbar(() => true);
		setSnackbarMessage(() => message);
	}, []);

	useEffect(() => {
		async function searchUsers() {
			const min_search_chars = 3;

			if (searchTerm.length >= min_search_chars) {
				try {
					setIsLoading(() => true);
					const userResourceJson = getSearchUsersToLinkJson(searchTerm);
					const searchuserResponse = await dashboardApi.searchUsersWithRoleUser(userResourceJson);
					console.log(searchuserResponse.data.resourcePropertiesList);
					const fileItems = searchuserResponse.data.resourcePropertiesList.map((item) => ({
						value: item.fields.email,
						label: `(${item.fields.email})`,
						avatar: item.fields.avatarUrl,
						userid: item.fields.userid,
					}));
					const usersWithoutGlobalUsername = fileItems.filter(
						(element) => element.value !== GlobalUsername.username,
					);

					console.log(usersWithoutGlobalUsername);
					setOptions(() => usersWithoutGlobalUsername);
				} catch (error) {
					console.error("An error occurred while searching for users:", error);
					setOptions(() => []);
				} finally {
					setIsLoading(() => false);
				}
			}
		}
		if (searchTerm.trim() !== "" && searchTerm.length >= 3) {
			searchUsers();
		} else {
			setOptions(() => []);
		}
	}, [searchTerm]);

	const handleInputChange = useCallback(
		(newValue) => {
			console.log("Handle InputChange: ", newValue);
			setSearchTerm(() => newValue);
		},
		[setSearchTerm],
	);

	const handleChange = useCallback((selected) => {
		console.log("Handle Change: ", selected);
		setSelectedUsers(() => selected);
	}, []);

	const generateNotificationhandler = useCallback(
		async (data) => {
			setButtonLoading(() => true);
			try {
				const reqJson = getGenerateNotificationJSON(data);

				const res = await dashboardApi.generateNotification(reqJson);
				console.log(reqJson);

				if (res.data.footer.code === 0) {
					showSnackbarWithMessage("Invitation Link sent successfully.");
					setButtonLoading(() => false);
				} else if (
					res.data.footer.msg &&
					res.data.footer.msg.includes("already have linked accounts or notification alraedy sent to user")
				) {
					showSnackbarWithMessage("User is already linked or an invite has been already sent");
					setButtonLoading(() => false);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[showSnackbarWithMessage],
	);

	const handleLinkAccountAction = useCallback(async () => {
		console.log("Selected users data:", selectedUsers);
		console.log("Selected user role:", selectedUsers);

		if (selectedUsers.length === 0) {
			return;
		}

		const userAccount = selectedUsers.map(({ value, userid }) => ({
			email: value,
			userid,
			role: selectedRole.value,
		}));

		try {
			generateNotificationhandler(userAccount);
		} catch (error) {
			console.error("An error occurred while linking user accounts:", error);
			showSnackbarWithMessage("Error linking user accounts");
		}

		setSelectedRole(() => []);
		setOptions(() => []);
		setSelectedUsers(() => []);
	}, [generateNotificationhandler, selectedRole, selectedUsers, showSnackbarWithMessage]);

	const handleClose = useCallback(() => {
		history.push("/settings");
	}, [history]);

	const USER_ROLES = [
		{ id: "c23e3711-9845-4071-a9d4-649189f76b18", label: "Member", value: properties.userRoles.member },
		{ id: "0dea2cfc-26e4-454c-a628-0e96b84dd769", label: "Billing", value: properties.userRoles.billing },
		{ id: "ed0b4232-bf68-430f-a7ec-3b7ce8724186", label: "System Admin", value: properties.userRoles.systemadmin },
	];

	const handleChangeRole = useCallback((selectedRole) => {
		setSelectedRole(() => selectedRole);
	}, []);

	return (
		<div
			className="link-account"
			style={{
				position: "relative",

				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			{" "}
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Associate Users
								</h1>

								<hr />

								<TextField
									label="Admin"
									className="input-field"
									id="username"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={`${sessionStorage.getItem("username")} (${sessionStorage.getItem("role")})`}
									InputProps={{
										readOnly: true,
										style: { fontFamily: fontFamily },
									}}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
								/>

								<Select
									autoFocus
									isMulti
									className="input-field"
									margin="normal"
									options={options}
									value={selectedUsers}
									onChange={handleChange}
									onInputChange={handleInputChange}
									isLoading={isLoading}
									inputId="link-user"
									placeholder="Link users"
									noOptionsMessage={() => "No results found"}
									styles={{
										// marginTop:"50px",
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										control: (provided) => ({
											...provided,
											marginTop: "5px",
											minHeight: "36px",
											width: "100%",
											fontFamily: fontFamily,
										}),
									}}
									formatOptionLabel={(option) => (
										<div style={{ display: "flex", alignItems: "center" }}>
											<div>{option.label}</div>
										</div>
									)}
									fullWidth
									data-test-id="linkaccount-input"
								/>

								<Select
									ref={assignrole}
									className="input-field"
									margin="normal"
									options={USER_ROLES}
									value={selectedRole}
									onChange={handleChangeRole}
									isLoading={isLoading}
									placeholder="Assign role"
									inputId="role-assign"
									noOptionsMessage={() => "No results found"}
									styles={{
										width: "100%",
										control: (provided) => ({
											...provided,
											width: "100%",
											marginTop: "10px",
											minHeight: "36px",
											fontFamily: fontFamily,
										}),
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
									}}
								/>

								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										data-button-name="cancel-user-role"
									>
										Cancel
									</Button>

									<Button
										onClick={handleLinkAccountAction}
										variant="outlined"
										buttonType="main"
										isLoading={buttonLoading}
										data-test-id="linkaccount-submit"
										data-button-name="send-link-meassage"
									>
										Link User
									</Button>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
}
