import React, { useContext, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { CssBaseline, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import useStyles from "routes/TableStyles";
import { FontContext } from "components/UI/Theme";
import { neutral, textsize } from "assets/css/MainCss";

import { getfetchInvoiceJson } from "components/requestcontext/FetchInvoice";
import { dashboardApi } from "components/misc/DashboardApi";
import { getGenerateInvoiceJson } from "components/requestcontext/GenerateInvoice";
import Notification from "components/dashboard/notification/Notification";
import properties from "components/properties/ApplicationProps";
import FormContainer from "layout/FormContainer";
import getFetchInvoiceDetailsJSON from "components/requestcontext/FetchInvoiceDetails";

export default function UpdateInvoice() {
	const { themeColor, fontFamily } = useContext(FontContext);
	const classes = useStyles();
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [selecteduser, setSelectedUser] = useState("");
	const [error, setError] = useState("");
	const [formData, setFormData] = useState(properties.initialData); // Initialize formData state with initialData

	async function fetchAttributes() {
		try {
			console.log(selecteduser);
			let userResourceJson = getfetchInvoiceJson(selecteduser);
			let searchuserResponse = await dashboardApi.fetchInvoice(userResourceJson);
			console.log(searchuserResponse);

			if (
				searchuserResponse.data.resourcePropertiesList &&
				searchuserResponse.data.resourcePropertiesList.length > 0
			) {
				let invoicedetails = getFetchInvoiceDetailsJSON(
					searchuserResponse.data.resourcePropertiesList[0].fields,
				);
				console.log();
				setFormData(invoicedetails); // Set formData with the fetched data
			} else {
				showSnackbarWithMessage("No data found");
				setFormData(properties.initialData); // Set formData to initialData if no data is found
			}
		} catch (error) {
			console.log(error);
			// Handle errors here
		}
	}

	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	async function UpdateInvoices() {
		try {
			console.log(formData);
			let userResourceJson = getGenerateInvoiceJson(formData);
			let searchuserResponse = await dashboardApi.updateInvoice(userResourceJson);
			console.log(searchuserResponse);
			if (searchuserResponse.data.footer.msg === "SUCCESS") {
				showSnackbarWithMessage("Updating Invoice Details");
			}
		} catch (error) {
			console.log(error);
			showSnackbarWithMessage("Failure while updating invoice details");
			// Handle errors here
		}
	}

	function handleClose(e) {
		setFormData(properties.initialData);
		setSelectedUser("");
	}

	const handleChange2 = (key, value) => {
		try {
			let formattedValue = value;
			console.log(value);
			let fieldError = ""; // Track error message for each field

			// If the value is a Date object, convert it to the desired format
			if (value instanceof Date) {
				formattedValue = dayjs(value).format("MM/DD/YYYY");

				if (!dayjs(value).isValid()) {
					console.log("hii");
					fieldError = "Invalid date";
				}
			}

			// For other inputs, perform sanitization and validation
			if (typeof value === "string") {
				const sanitizedValue = value.replace(/[^0-9./]/g, ""); // Adjusted regex to allow for slashes
				const decimalCount = sanitizedValue.split(".").length - 1;

				if (key === "discountpercentage") {
					const floatValue = parseFloat(sanitizedValue);
					if (floatValue > 100) {
						fieldError = "Discount percentage cannot be greater than 100";
					}
				}

				// Check for special characters and decimal count
				if (!sanitizedValue.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/) && decimalCount <= 1) {
					formattedValue = parseFloat(sanitizedValue);
				} else {
					fieldError = "Special characters are not allowed";
				}
			}

			// Update the formData state with the formatted value
			setFormData((prevData) => ({
				...prevData,
				[key]: formattedValue,
			}));

			// Update the error state for the specific field
			setError((prevError) => ({
				...prevError,
				[key]: fieldError,
			}));
		} catch (error) {
			console.log(error);
			// Handle errors here
		}
	};

	const capitalizeAndSplitLabel = (label) => {
		return label.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
	};

	return (
		<div
			className="create-group"
			style={{
				position: "relative",

				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Update Invoice
								</h1>

								<hr />

								<div
									style={{
										width: "100%",
										zIndex: 2,
										position: "relative",
									}}
								>
									<TextField
										autoFocus
										className="input-field"
										label="Search for email or account name or invoiceid"
										id="search-accountid"
										variant="outlined"
										size="small"
										value={selecteduser}
										onChange={(e) => setSelectedUser(e.target.value)}
										fullWidth
										margin="normal"
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												fetchAttributes(event);
											} else if (event.key === "Tab") {
												event.stopPropagation();
											}
										}}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
									/>
								</div>

								<div style={{ display: "flex", flexWrap: "wrap" }}>
									{Object.entries(formData).map(([key, value], index) => (
										<React.Fragment key={key}>
											{key === "duedate" ||
											key === "startdate" ||
											key === "enddate" ||
											key === "lastupdatetime" ||
											key === "invoicegeneratedon" ? (
												<DatePicker
													id="dateformat"
													className="datepicker"
													slotProps={{
														textField: { size: "small" },
													}}
													sx={{
														fontFamily: "inherit",
														width: "49.3%",
														marginRight: index % 2 === 0 ? "0px" : "0px",
														marginLeft: index % 2 === 1 ? "5px" : "0px",
														marginTop: index % 2 === 1 && index % 2 === 2 ? "7px" : "10px",
													}}
													label={capitalizeAndSplitLabel(key)}
													value={value ? dayjs(value) : null}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															className="input-field"
															size="small"
															style={{ fontFamily: fontFamily }}
														/>
													)}
													inputFormat="MM/DD/YYYY"
													openTo="year"
													views={["year", "month", "day"]}
													mask="__/__/____"
													readOnly={
														key === "startdate" ||
														key === "enddate" ||
														key === "lastupdatetime" ||
														key === "invoicegeneratedon"
													}
													onChange={(date) => handleChange2(key, date)}
												/>
											) : (
												<TextField
													className="invoicedetails"
													id={key}
													key={key}
													//label={key}
													label={capitalizeAndSplitLabel(key)}
													variant="outlined"
													size="small"
													fullWidth
													margin="normal"
													value={value}
													onChange={(e) => handleChange2(key, e.target.value)}
													style={{
														marginRight: index % 2 === 0 ? "0px" : "0px",
														marginLeft: index % 2 === 1 ? "5px" : "0px",
														marginTop: index % 2 === 1 && index % 2 === 2 ? "7px" : "10px",
														flex: "1",
														fontFamily: fontFamily,
														flexBasis: "45%",
													}}
													InputProps={{
														style: { fontFamily: fontFamily },
														readOnly: properties.fetchedInvoiceDetails.includes(key),
													}}
													InputLabelProps={{ style: { fontFamily: fontFamily } }}
													helperText={error[key]} // Set specific error message for each field
													error={!!error[key]} // set to true to change the border/helperText color to red
												/>
											)}
										</React.Fragment>
									))}
								</div>

								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										color="primary"
										style={{
											color: neutral.buttoncolor,
											borderColor: neutral.buttoncolor,
											fontSize: textsize.buttontextsize,
											width: "100px",
											fontFamily: fontFamily,
										}}
									>
										Clear
									</Button>
									<Button
										onClick={UpdateInvoices}
										variant="outlined"
										color="primary"
										style={{
											backgroundColor: neutral.buttoncolor,
											color: neutral.buttontextcolor,
											fontSize: textsize.buttontextsize,
											width: "100px",
											fontFamily: fontFamily,
										}}
										data-button-name="save-UpdateInvoices"
									>
										Save
									</Button>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
}
