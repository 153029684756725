import { makeStyles } from "@material-ui/styles";
import { alpha } from "@mui/material";
import { bgcolor, font, icon, neutral, textsize } from "assets/css/MainCss";
const drawerWidth = 225;
const notificationdrawerWidth = 210;
export default makeStyles((theme) => ({
	root: {
		display: "flex",
		height: "40px",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		// height: '100%',
		overflowY: "none",
		//position:'relative',
	},
	drawerPaper: {
		width: notificationdrawerWidth,
		// make notification to take only needed height
		// height: '100%',
		maxHeight: "50% !important",
		minHeight: "50px",
		borderRadius: "10px 10px 0 0",
		// margin: "5px",
		marginBottom: 0,
		overflowY: "none",
		//	backgroundColor: "#ffffff",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	logotype: {
		//   color: "white",
		position: "relative",
		letterSpacing: "1px",
		marginLeft: theme.spacing(2.0),
		marginRight: theme.spacing(2.5),
		//   fontWeight: 400,
		//   fontSize: 16,
		color: neutral.textcolor,
		fontSize: textsize.headerlogotext,
		fontFamily: font.fontFamily,
		whiteSpace: "nowrap",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
		"&:hover": {
			backgroundColor: theme.palette.background.light,
			// border: '15x solid transparent',
			cursor: "pointer",
			outline: `4px solid ${theme.palette.background.light}`,
		},
	},
	appBar: {
		width: "100%",
		height: "55px",
		border: "none",
		boxShadow: "none",
		backgroundColor: bgcolor.themecolor,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	toolbar: {
		border: "none",
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1),
	},
	hide: {
		display: "none",
	},
	grow: {
		flexGrow: 1,
	},
	search: {
		width: "470px",
		height: "40px",
		fontFamily: font.fontFamily,
		fontWeight: 500,
		fontSize: "1rem",
		lineHeight: 1.43,
		letterSpacing: "0.01071em",
		display: "table-cell",
		verticalAlign: "inherit",
		textAlign: "left",
		padding: 0,
		color: neutral.textcolor,
	},
	searchFocused: {
		backgroundColor: alpha(theme.palette.common.black, 0.08),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: 250,
		},
	},
	searchIcon: {
		width: 36,
		right: 0,
		height: "100%",
		position: "absolute",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transition: theme.transitions.create("right"),
		"&:hover": {
			cursor: "pointer",
		},
	},
	searchIconOpened: {
		right: theme.spacing(1.25),
	},
	inputRoot: {
		color: "inherit",
		width: "100%",
	},
	inputInput: {
		height: 36,
		padding: 0,
		paddingRight: 36 + theme.spacing(1.25),
		width: "100%",
	},
	messageContent: {
		display: "flex",
		flexDirection: "column",
	},
	headerMenu: {
		marginTop: theme.spacing(7),
		borderRadius: "10px",
	},
	headerMenuList: {
		display: "flex",
		flexDirection: "column",
	},
	headerMenuItem: {
		"&:hover, &:focus": {
			backgroundColor: neutral.hovereffect,
		},
	},
	headerMenuNotificationButton: {
		marginLeft: theme.spacing(2),
		padding: theme.spacing(0.5),
		color: neutral.buttontextcolor,
	},
	headerMenuProfileButton: {
		marginLeft: theme.spacing(1),
		padding: theme.spacing(0.5),
	},
	headerMenuButtonSandwich: {
		[theme.breakpoints.down("sm")]: {},
		padding: theme.spacing(0.3),
	},
	headerMenuButtonCollapse: {
		//	marginRight: theme.spacing(2),
	},
	headerIcon: {
		fontSize: 28,
		color: neutral.textcolor,
		marginLeft: "5px",
	},
	headerIconCollapse: {
		color: neutral.textcolor,
	},
	profileMenu: {
		Width: "100px",
		borderRadius: "10px",
	},
	profileMenuUser: {
		display: "flex",
		flexDirection: "column",
		//  / padding: theme.spacing(2),
	},
	profileMenuItem: {
		color: theme.palette.text.hint,
	},
	profileMenuIcon: {
		marginRight: theme.spacing(2),
		color: theme.palette.text.hint,
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
	profileMenuLink: {
		fontSize: 15,
		textDecoration: "none",
		"&:hover": {
			cursor: "pointer",
		},
	},
	messageNotification: {
		height: "auto",
		display: "flex",
		alignItems: "center",
		"&:hover, &:focus": {
			backgroundColor: theme.palette.background.light,
		},
	},
	messageNotificationSide: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginRight: theme.spacing(2),
	},
	messageNotificationBodySide: {
		alignItems: "flex-start",
		marginRight: 0,
	},
	sendMessageButton: {
		margin: theme.spacing(4),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		textTransform: "none",
	},
	sendButtonIcon: {
		marginLeft: theme.spacing(2),
	},
	purchaseBtn: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
		marginRight: theme.spacing(3),
	},
	iconStyle: {
		width: icon.width,
		height: icon.height,
	},
	iconStyle1: {
		width: "26px",
		height: "26px",
	},
	orgLogoIcon: {
		width: "20px",
		aspectRatio: "1/1",
		marginRight: "10px",
		position: "relative",
		top: "-2px",
		"&:hover": {
			backgroundColor: theme.palette.background.light,
		},
	},
	progressbar: {
		color: neutral.buttoncolor,
	},
	iconStyle2: {
		height: "25px",
		width: "25px",
	},
}));
