import React, { useState, useContext, useCallback, useEffect } from "react";
import {
	CssBaseline,
	Grid,
	TableContainer,
	Typography,
	Box,
	Alert,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import useStyles from "routes/TableStyles";

import Notification from "components/dashboard/notification/Notification";
import { FontContext } from "components/UI/Theme";
import FormContainer from "layout/FormContainer";
import { getCustomSubdomain } from "components/requestcontext/addCustomSubdomain";
import { useHistory } from "react-router-dom";
import { dashboardApi } from "components/misc/DashboardApi";

import { getFetchDNSdetailsJson } from "components/requestcontext/FetchDNSdetails";

import { neutral } from "assets/css/MainCss";
import CustomTextField from "components/CustomComponents/TextField";
import CustomButton from "components/CustomComponents/Button";
import CustomCopyButton from "components/CustomComponents/CopyButton";
import CustomTypography from "components/CustomComponents/Typography";

function DNSForm() {
	const classes = useStyles();
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dnsAlertMessage, setdnsAlertMessage] = useState("");
	const [txtRecordValue, setTxtRecordValue] = useState("");
	const [customUrlValue, setCustomUrlValue] = useState("");

	const [subdomain, setSubdomain] = useState("");
	const [domain, setDomain] = useState("");
	const [txtRecordName, setTxtRecordName] = useState("");

	const { fontFamily, themeColor, smallTextSize, largeTextSize } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		setTxtRecordName(subdomain ? `${subdomain}.${domain}` : domain);
	}, [subdomain, domain]);

	useEffect(() => {
		fetchDNSdetails((response) => {
			console.log("Callback received response:", response);
		});
	}, []);

	const subdomainRegex = /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)$/;
	const domainRegex = /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(\.[a-zA-Z0-9-]{1,63}){0,126}$/;

	const validateSubdomain = (value) => subdomainRegex.test(value);
	const validateDomain = (value) => domainRegex.test(value);

	const fetchDNSdetails = useCallback(async () => {
		try {
			const dnsReqJson = getFetchDNSdetailsJson();
			const response = await dashboardApi.fetchDNSDetails(dnsReqJson);
			console.log("response :", response);

			if (response.data.footer.msg === "SUCCESS") {
				const dnsdetails = response.data.resourcePropertiesList[0]?.fields;
				if (dnsdetails) {
					setCustomUrlValue(dnsdetails.customurl || "");
					setTxtRecordValue(dnsdetails.txtrecordvalues || "");
					setTxtRecordName(dnsdetails.txtrecordname || "");
					setDomain(dnsdetails.domain || "");
					setSubdomain(dnsdetails.subdomain || "");
				} else {
					console.error("DNS details are missing");
				}
			} else {
				console.error("Failed to fetch DNS details:", response.data.footer.msg);
			}
		} catch (error) {
			console.error("Error fetching DNS details:", error);
		}
	}, [setCustomUrlValue, setTxtRecordValue]);

	const handleSubdomainChange = (e) => {
		setSubdomain(e.target.value);
	};

	const handleDomainChange = (e) => {
		setDomain(e.target.value);
	};

	const handleSubmit = useCallback(
		async (event) => {
			event.preventDefault();
			if (!validateSubdomain(subdomain)) {
				setSnackbarMessage("Invalid subdomain format");
				setShowSnackbar(true);
				return;
			}
			if (!validateDomain(domain)) {
				setSnackbarMessage("Invalid domain format");
				setShowSnackbar(true);
				return;
			}

			setButtonLoading(true);

			try {
				const customSubdomainJSON = getCustomSubdomain(domain, txtRecordName);
				const response = await dashboardApi.addCustomSubdomain(customSubdomainJSON);

				if (response.data.footer.msg === "SUCCESS") {
					setdnsAlertMessage(response.data.message);
					setTxtRecordValue(response.data.recordName);
					setCustomUrlValue(response.data.customUrl);
					setSnackbarMessage(
						"DNS record created successfully. Please check the dialog for more information.",
					);
					setDialogOpen(true);
				} else {
					setSnackbarMessage(response.data.footer.msg);
					setDialogOpen(false);
				}
			} catch (error) {
				setSnackbarMessage("Failed to create DNS record");
			} finally {
				setButtonLoading(false);
				setShowSnackbar(true);
			}
		},
		[domain, txtRecordName, subdomain],
	);

	const handleCancel = useCallback(() => {
		history.push("./settings");
	}, [history]);

	const handleCloseDialog = () => {
		setDialogOpen(false);
		fetchDNSdetails();
	};

	const handleCopyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setSnackbarMessage("Details copied to clipboard!");
				setShowSnackbar(true);
			})
			.catch((error) => console.log(error));
	};

	const handleCopyAll = () => {
		const formattedText = `TXT Record Name: "${txtRecordName}"\nRecord value: "${txtRecordValue}"\nCustom URL: "${customUrlValue}"`;
		handleCopyToClipboard(formattedText);
	};

	return (
		<div
			style={{
				position: "relative",
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Setup Custom Subdomains
								</h1>

								<hr />

								<Typography
									style={{
										fontFamily: fontFamily,
									}}
								>
									Configure Custom DataFrugal Subdomain
								</Typography>

								{/* {customUrlValue === null && ( */}
								<Box
									display="flex"
									alignItems="center"
									marginBottom="10px"
									width="100%" // Ensure the Box takes full width
								>
									<CustomTextField
										autoFocus
										required
										className="input-field"
										id="subdomainInput"
										label="Subdomain"
										variant="outlined"
										size="small"
										margin="normal"
										value={subdomain}
										onChange={handleSubdomainChange}
										style={{
											marginRight: "5px",
											flexGrow: 1,
											maxWidth: "30%",
											fontFamily: smallTextSize,
										}}
										InputLabelProps={{
											style: { fontFamily: smallTextSize },
											readOnly: !!customUrlValue,
										}}
										InputProps={{ style: { fontFamily: smallTextSize } }}
									/>

									<CustomTextField
										id="domainInput"
										className="input-field"
										label="Domain"
										variant="outlined"
										placeholder="datafrugal.com"
										required
										size="small"
										margin="normal"
										value={domain}
										onChange={handleDomainChange}
										style={{
											flexGrow: 2,
											fontFamily: fontFamily,
										}}
										InputProps={{
											style: { fontFamily: fontFamily },
											readOnly: !!customUrlValue,
										}}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
									/>
								</Box>
								{/* )} */}

								<CustomTextField
									id="txtRecordName"
									className="input-field"
									label="TXT Record Name"
									variant="outlined"
									placeholder="subdomain.example.com"
									size="small"
									fullWidth
									margin="normal"
									value={txtRecordName}
									InputProps={{
										readOnly: true,
										style: { fontFamily: fontFamily },
									}}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
									style={{ backgroundColor: "#edecec", marginTop: "-0.3rem" }}
								/>
								<Alert
									severity="info"
									style={{ marginTop: "-0.5rem" }}
								>
									We will be giving txt record that you need to add in this txt record name
								</Alert>

								{/* Conditionally render Custom URL and Custom Record Value */}
								{customUrlValue && (
									<>
										<CustomTextField
											id="customurl"
											className="input-field"
											label="Custom URL"
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											value={customUrlValue}
											InputProps={{
												readOnly: true,
												style: { fontFamily: fontFamily },
											}}
											InputLabelProps={{
												style: { fontFamily: fontFamily },
											}}
											style={{ marginTop: "1rem" }}
										/>

										<CustomTextField
											id="customrecordvalue"
											className="input-field"
											label="Custom Record Value"
											variant="outlined"
											size="small"
											fullWidth
											margin="normal"
											value={txtRecordValue}
											InputProps={{
												readOnly: true,
												style: { fontFamily: fontFamily },
											}}
											InputLabelProps={{
												style: { fontFamily: fontFamily },
											}}
											style={{ marginTop: "1rem" }}
										/>
									</>
								)}

								<div className="form-buttons-container">
									<CustomButton
										text="Cancel"
										onClick={handleCancel}
										variant="outlined"
										data-button-name="cancel-domain-setup"
									/>

									<CustomButton
										text="Submit"
										onClick={handleSubmit}
										id="createDomain"
										style={{
											backgroundColor: neutral.buttoncolor,
											color: neutral.buttontextcolor,
										}}
										buttonType="main"
										isLoading={buttonLoading}
										data-button-name="setup-domain"
										disabled={!!customUrlValue}
									/>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
				messageLength={50}
			/>

			{/* Dialog for displaying TXT record information */}
			<Dialog
				id="dnsdetailsdialog"
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				fullWidth
				maxWidth="xs"
			>
				<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
					<strong> TXT Record Information</strong>
				</DialogTitle>
				<DialogContent>
					<Typography
						variant="body1"
						color="textSecondary"
						style={{ fontFamily: fontFamily, fontSize: largeTextSize }}
					>
						<strong>{dnsAlertMessage}</strong>
						<br />
						<br />
						<Box
							className="dnsdetailsbox"
							border={1}
							borderColor="grey.400"
							padding={2}
							position="relative"
						>
							<CustomCopyButton
								id="copydnsdetails"
								onClick={handleCopyAll}
							/>
							<CustomTypography style={{ marginTop: 0, color: "gray" }}>
								TXT record name: <strong>{txtRecordName}</strong>
							</CustomTypography>
							<CustomTypography style={{ marginTop: "8px", color: "gray" }}>
								Record value: <strong>{txtRecordValue}</strong>
							</CustomTypography>
							<CustomTypography style={{ marginTop: "8px", color: "gray" }}>
								Custom URL: <strong>{customUrlValue}</strong>
							</CustomTypography>
						</Box>
					</Typography>
				</DialogContent>
				<DialogActions
					style={{
						marginRight: "0.5rem",
						paddingBottom: "10px",
						marginTop: "0.5rem",
					}}
				>
					<CustomButton
						text="Close"
						onClick={handleCloseDialog}
						variant="outlined"
						data-button-name="close-dns-details"
					/>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default DNSForm;
