import React from "react";
import cross_icon from "assets/proto_1/fileContentTypes/cross.png";
import useStyles from "components/dashboard/sidenav/styles";

import file from "assets/proto_1/fileContentTypes/file.png";
import folder from "assets/proto_1/fileContentTypes/folder.png";
import mp3 from "assets/proto_1/fileContentTypes/mp3.png";
import mp4 from "assets/proto_1/fileContentTypes/mp4.png";
import pdf from "assets/proto_1/fileContentTypes/pdf.png";
import image from "assets/proto_1/fileContentTypes/image.png";
import checked from "assets/proto_1/fileContentTypes/checked.png";

const FileIcon = ({ contentType }) => {
	const classes = useStyles();

	const iconIdentifier = () => {
		if (contentType.includes("text/")) {
			return (
				<img
					// src={document_icon}
					src={file}
					alt="textfile_icon"
					className={classes.iconStyle}
				/>
			);
		} else if (contentType.includes("audio")) {
			return (
				<img
					// src={mp3_icon}
					src={mp3}
					alt="mp3_icon"
					className={classes.iconStyle}
				/>
			);
		} else if (contentType.includes("image")) {
			return (
				<img
					// src={image_icon}
					src={image}
					alt="image_icon"
					className={classes.iconStyle}
				/>
			);
		} else if (contentType.includes("video")) {
			return (
				<img
					// src={mp4_icon}
					src={mp4}
					alt="mp4_icon"
					className={classes.iconStyle}
				/>
			);
		} else if (contentType.includes("pdf")) {
			return (
				<img
					// src={pdf_icon}
					src={pdf}
					alt="pdf_icon"
					className={classes.iconStyle}
				/>
			);
		} else if (contentType.includes("folder")) {
			return (
				<img
					// src={folder_icon}
					src={folder}
					alt="folder_icon"
					className={classes.iconStyle}
				/>
			);
		} else if (contentType.includes("100")) {
			return (
				<img
					// src={green_check_icon}
					src={checked}
					alt="upload_complete"
					className={classes.iconStyle}
				/>
			);
		} else if (contentType.includes("error")) {
			return (
				<img
					src={cross_icon}
					alt="upload_error"
					className={classes.iconStyle}
				/>
			);
		} else {
			return (
				<img
					src={file}
					alt="textfile_icon"
					className={classes.iconStyle}
				/>
			);
		}
	};

	return <span title={contentType}>{iconIdentifier()}</span>;
};

export default FileIcon;
