import numeral from "numeral";
import getFilePart from "components/fileService/SplitFile";
import { ContextRequestAddResource } from "components/requestcontext/AddResource";
import { awsFileupload } from "components/misc/AwsFileUpload";
import { getKeepOrReplaceCompleteUploadJson } from "components/requestcontext/CompleteUploadResource";
import { maxAllowedFileSize, ONE_SECOND } from "Constants";
import { ADD_TO_NOTIFICATIONS } from "store/uploadStatsSlice";
import { throttle as _throttle } from "lodash";
import { requestManager } from "Service/RequestManager";

export async function uploadFile(file, dispatch, handleDashboardRefreshAfterUpload) {
	// Don't allow to upload if file size is invalid or zero.
	if (file.size === 0) {
		Object.defineProperties(file, {
			didItTryToUpload: { value: true },
			isUploaded: { value: false },
			isUploading: { value: false },
			isOperationComplete: { value: true },
			error: { value: true },
			errorMssg: { value: "Invalid File Size" },
			errorCode: { value: 0 },
		});

		dispatch(ADD_TO_NOTIFICATIONS(file));
		throw new Error(file.errorMssg);
	}

	const filePartsArray = getFilePart(file, maxAllowedFileSize);
	const etagsArray = [];
	const uploadCompletePart = [];
	const abortController = new AbortController();

	const stopUploadingFile = () => {
		if (!file.isUploaded) {
			Object.defineProperties(file, {
				isUploadStopped: { value: true },
				isUploading: { value: false },
				isUploaded: { value: false },
				isOperationComplete: { value: true },
				uploadCompletePercentageNumber: { value: 0 },
				uploadSpeed: { value: 0 },
				etc: { value: 0 },
			});

			dispatch(ADD_TO_NOTIFICATIONS(file));
			abortController.abort();
		}
	};

	let endpoint;
	let uploadInsertRes;

	try {
		Object.defineProperty(file, "didItTryToUpload", { value: true });
		const uploadRequestJson = ContextRequestAddResource.generateKeepOrReplaceUploadResourceJson(file);
		uploadInsertRes = await requestManager.insertOrUpdateResource(uploadRequestJson);

		if (uploadInsertRes?.data?.footer.code !== 0) {
			Object.defineProperties(file, {
				isUploading: { value: false },
				isUploaded: { value: false },
				isOperationComplete: { value: true },
				uploadCompletePercentageNumber: { value: 0 },
				uploadSpeed: { value: 0 },
				etc: { value: 0 },
				error: { value: true },
				errorMssg: { value: `Failed to get endpoint url for ${file.name}` },
				errorCode: { value: uploadInsertRes?.data?.footer.code },
			});

			dispatch(ADD_TO_NOTIFICATIONS(file));
			throw new Error(file.errorMssg);
		}
	} catch (error) {
		Object.defineProperties(file, {
			isUploading: { value: false },
			isUploaded: { value: false },
			isOperationComplete: { value: true },
			uploadCompletePercentageNumber: { value: 0 },
			uploadSpeed: { value: 0 },
			etc: { value: 0 },
			error: { value: true },
			errorMssg: { value: `Failed to get endpoint url for ${file.name}` },
			errorCode: { value: uploadInsertRes?.data?.footer.code },
		});

		dispatch(ADD_TO_NOTIFICATIONS(file));
		throw new Error(error);
	}

	const uploadStartTime = Math.floor(Date.now() / 1000);

	Object.defineProperties(file, {
		stopUpload: { value: stopUploadingFile },
		isUploading: { value: true },
	});

	/**
	 * 	Make fileParts to upload in sequential order.
	 * 	Takes less time to upload but will upload single chunk at a time.
	 */
	for (let filePart = 0; filePart < filePartsArray.length; filePart++) {
		endpoint = uploadInsertRes.data.endpointList[filePart].endpoint;

		const updateUploadStats = _throttle(
			(progressEvent) => {
				uploadCompletePart[filePart] = progressEvent.loaded;
				const uploadSpeedPerSecInKB = Math.floor(progressEvent.rate);
				const uploadCompleted = uploadCompletePart.reduce((a, b) => a + b, 0);
				const uploadCompletePercentageNumber = Math.floor((uploadCompleted / file.size) * 100);
				const uploadCompletePercentage = numeral(((uploadCompleted / file.size) * 100) / 100).format("0 %");
				const uploadSpeed = numeral(uploadSpeedPerSecInKB).format("0.00b");
				const remainingUpload = file.size - uploadCompleted;
				const etc = Math.ceil(remainingUpload / uploadSpeedPerSecInKB);

				Object.defineProperties(file, {
					error: { value: false },
					etc: { value: etc },
					uploadSpeed: { value: uploadSpeed },
					uploadSpeedInKB: { value: uploadSpeedPerSecInKB },
					uploadPercentage: { value: uploadCompletePercentage },
					uploadCompletePercentageNumber: {
						value: uploadCompletePercentageNumber,
					},
				});

				dispatch(ADD_TO_NOTIFICATIONS(file));
			},
			ONE_SECOND,
			{ trailing: false },
		);

		const config = {
			method: "put",
			signal: abortController.signal,
			url: endpoint,
			headers: {
				"Content-Type": file.type,
			},
			data: filePartsArray[filePart],
			onUploadProgress: (progressEvent) => {
				updateUploadStats(progressEvent);
			},
		};

		try {
			const awsRes = await awsFileupload(config);
			etagsArray.push({ partNumber: filePart + 1, eTag: awsRes.headers?.etag });
			updateUploadStats.cancel();
		} catch (error) {
			throw new Error(`Failed to upload part ${filePart} of ${file.name}`);
		}
	}

	const uploadEndTime = Math.floor(Date.now() / 1000);
	const totalTimeTakenForUpload = uploadEndTime - uploadStartTime;
	let completeResponse;

	try {
		const completeRequestJson = getKeepOrReplaceCompleteUploadJson({
			etagsArray,
			file,
			requestId: uploadInsertRes.data.header.requestId,
		});
		completeResponse = await requestManager.completeInsertOrUpdateResource(completeRequestJson);
	} catch (error) {
		console.log(error);
		throw new Error(`Failed completeUploadResource request for ${file.name} with ${file.uploadFlag} flag`);
	}

	// handle fileupload success
	if (completeResponse?.data?.footer?.code === 0) {
		Object.defineProperties(file, {
			isUploaded: { value: true },
			isUploading: { value: false },
			isOperationComplete: { value: true },
			completionTime: { value: totalTimeTakenForUpload },
			error: { value: false },
			errorMssg: { value: "" },
			errorCode: { value: 0 },
			uploadCompletePercentageNumber: { value: 100 },
			uploadSpeed: { value: 0 },
		});

		dispatch(ADD_TO_NOTIFICATIONS(file));
		handleDashboardRefreshAfterUpload(file);
	} else {
		Object.defineProperties(file, {
			isUploaded: { value: false },
			isUploading: { value: false },
			isOperationComplete: { value: true },
			completionTime: { value: null },
			error: { value: true },
			errorMssg: { value: JSON.stringify(completeResponse) },
			errorCode: { value: 1 },
			uploadCompletePercentageNumber: { value: 0 },
			uploadSpeed: { value: 0 },
		});

		dispatch(ADD_TO_NOTIFICATIONS(file));
		handleDashboardRefreshAfterUpload(file);
		throw new Error(file.errorMssg);
	}
}
