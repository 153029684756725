import { memo, useCallback, useContext, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, List, ListItem } from "@mui/material";
import UploadProgressNotification from "components/UI/UploadProgressNotification";
import { neutral } from "assets/css/MainCss";
import {
	CLEAR_ALL_NOTIFICATIONS,
	HIDE_NOTIFICATION_PANEL_FORCEFULLY,
	// STOP_ALL_UPLOADS,
} from "store/uploadStatsSlice";
import useStyles from "components/dashboard/header/styles";
import { FontContext } from "components/UI/Theme";
// import StopIcon from "@mui/icons-material/Stop";
import "./NotificationsPanel.styles.css";

const NotificationsPanel = () => {
	var classes = useStyles();
	const dispatch = useDispatch();
	const { notifications, totalUploadsETC } = useSelector(({ uploadStats }) => uploadStats);
	// const { operationComplete } = useSelector(({ fileUpload }) => fileUpload);
	// const totalUploadingDone = operationComplete.length;
	let totalUploads = Object.keys(notifications).length;

	const { fontFamily } = useContext(FontContext);
	const closeNotificationsExplicitly = useRef(false);

	const handleDrawerClose = useCallback(() => {
		console.log("handledrawerclose");
		closeNotificationsExplicitly.current = true;
		dispatch(HIDE_NOTIFICATION_PANEL_FORCEFULLY());
	}, [dispatch]);

	return (
		<div className="notifications-panel-container animate__animated animate__faster">
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						color: "#ffffff",
						height: 30,
						backgroundColor: neutral.buttoncolor,
					}}
				>
					<IconButton
						className={classes.cancelIcon}
						color="inherit"
						style={{ padding: 8, pointerEvents: "auto" }}
						onClick={handleDrawerClose}
					>
						<CloseIcon />
					</IconButton>

					{totalUploads ? (
						<span style={{ margin: 0, fontSize: 14, lineHeight: 0.5, fontFamily: fontFamily }}>
							{/* Uploaded {totalUploadingDone}/{totalUploads} */}
						</span>
					) : (
						<></>
					)}

					<IconButton
						style={{ padding: 8, pointerEvents: "auto", color: "#fff" }}
						onClick={() => {
							dispatch(CLEAR_ALL_NOTIFICATIONS());
						}}
					>
						<DeleteIcon color={"inherit"} />
					</IconButton>
				</div>

				{totalUploads ? (
					<div
						style={{
							overflow: "auto",
							pointerEvents: "auto",
						}}
					>
						<List
							style={{
								margin: "0",
								padding: "0",
								width: "100%",
								maxHeight: "300px",
							}}

							// react-window implementation

							// itemSize={48}
							// height={totalUploads * 48}
							// itemCount={totalUploads}
							// itemData={Object.keys(throttledNotification)}
						>
							{/* {({ index, style }) => {
									const notificationsDetails = Object.keys(throttledNotification).map((fileId) => throttledNotification[fileId]);

									return (
										<ListItem
											key={index}
											style={{padding: '4px 8px'}}
										>
											<UploadProgressNotification notificationDetails={notificationsDetails[index]} />
										</ListItem>
									);;
								}} */}

							{Object.keys(notifications)
								.reverse()
								.map((fileId) => {
									return (
										<ListItem
											key={fileId}
											style={{ padding: "4px 8px" }}
										>
											<UploadProgressNotification notificationDetails={notifications[fileId]} />
										</ListItem>
									);
								})}
						</List>
					</div>
				) : (
					<div
						style={{
							textAlign: "center",
							fontFamily: fontFamily,
							minHeight: 75,
							display: "grid",
							placeItems: "center",
						}}
					>
						<p style={{ fontSize: 12 }}>There are no uploads.</p>
					</div>
				)}

				{totalUploads ? (
					<div
						style={{
							// display: "inline-flex",
							display: "none",
							justifyContent: "space-between",
							alignItems: "center",
							height: 30,
							color: "#fff",
							padding: "0 10px",
							width: "100%",
							backgroundColor: "#12a5c6",
							fontFamily: fontFamily,
						}}
					>
						<span>Remaining Time: </span>
						<span>{numeral(totalUploadsETC).format("00:00:00")}</span>
					</div>
				) : null}

				{/* {totalUploads ? (
					<div
						onClick={() => dispatch(STOP_ALL_UPLOADS(notifications))}
						style={{
							display: "inline-flex",
							justifyContent: "center",
							alignItems: "center",
							height: 30,
							color: "#fff",
							padding: "0 10px",
							width: "100%",
							backgroundColor: "#12a5c6",
							cursor: "pointer",
							fontFamily: fontFamily,
						}}
					>
						<span>
							<IconButton style={{ color: "#fff", padding: 0 }}>
								<StopIcon />
							</IconButton>
						</span>
						<span style={{ color: "#fff" }}>Stop All</span>
					</div>
				) : null} */}
			</>
		</div>
	);
};

export default memo(NotificationsPanel);
