export function getFetchCloneJson(id) {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},

		action: "SEARCH",
		resourceId: id,
		isItFolder: false,
	};

	return stringifyFetchResource;
}
