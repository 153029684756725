import properties from "../properties/ApplicationProps";
let BreadcrumbJson = {
	folderDetails: [
		{
			contenttype: "folder",
			description: "",
			filename: properties.defaultRootBreadcrumbName,
			isfolder: true,
			lastupdatetime: "",
			locationid: properties.parentFolderId,
			parentfolderid: -1,
			parentfoldername: "/",
			resourceid: 0,
			size: 0,
			uri: null,
			version: 1,
		},
	],
	sharedFolderDetails: [
		{
			contenttype: "folder",
			description: "",
			filename: properties.defaultSharedWithMeRootBreadcrumbName,
			isfolder: true,
			lastupdatetime: "",
			locationid: properties.fetchSWM,
			parentfolderid: -1,
			parentfoldername: "/",
			resourceid: 0,
			size: 0,
			uri: null,
			version: 1,
		},
	],

	sharedFolderByUserDetails: [
		{
			contenttype: "folder",
			description: "",
			filename: "Dashboard (Shared)",
			isfolder: true,
			lastupdatetime: "",
			locationid: properties.fetchSharedByUserId,
			parentfolderid: -1,
			parentfoldername: "/",
			resourceid: 0,
			size: 0,
			uri: null,
			version: 1,
		},
	],
	folderDetailsForSearch: [
		{
			contenttype: "folder",
			description: "",
			filename: properties.defaultRootBreadcrumbForSearch,
			isfolder: true,
			lastupdatetime: "",
			locationid: properties.parentFolderId,
			parentfolderid: -1,
			parentfoldername: "/",
			resourceid: 0,
			size: 0,
			uri: null,
			version: 1,
		},
	],

	fetchGroups: [
		{
			name: "Groups",
		},
	],

	settings: [
		{
			name: "Settings",
		},
	],

	Invoice: [
		{
			name: "Invoices",
		},
	],
};
export default BreadcrumbJson;
