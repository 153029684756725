import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Themes from "./themes";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./routes/LayoutContext";
import { UserProvider } from "./routes/UserContext";
import { registry } from "./Service/AuthService";
import { appConfig } from "Constants";
import axios from "axios";
import { store } from "./store";
import { Provider } from "react-redux";
import FontProvider from "components/UI/Theme";
import { AuthProvider } from "react-oidc-context";
import { localeList } from "utils/helpers/dayjs_localeList";
import { WebStorageStateStore } from "oidc-client-ts";

// import dayjs/local module dynamically
const localeLanguage = localeList.includes(navigator.language.toLocaleLowerCase())
	? navigator.language.toLocaleLowerCase()
	: "en";

(async () => {
	await import(`dayjs/locale/${localeLanguage}`);
})();

async function startUp() {
	await appConfig.get();
	console.log(appConfig.config.REACT_APP_KEYCLOAK_BASE_URL);

	const axiosInstance = axios.create({
		baseURL: appConfig.config.REACT_APP_KEYCLOAK_BASE_URL,
		transformRequest: [
			(data) => {
				if (data) {
					const rawData = typeof data === "string" ? JSON.parse(data) : data;

					if (rawData?.header?.clientId) {
						return JSON.stringify(rawData);
					} else {
						return JSON.stringify(
							Object.defineProperty(rawData, "header", { value: { clientId: crypto.randomUUID() } }),
						);
					}
				}
			},
		],
	});
	const clientId = process.env.REACT_APP_CLIENT_ID || "mm-app";

	const oidcConfig = {
		authority: `${window.location.origin}/auth/realms/DATAFRUGAL`,
		client_id: clientId,
		post_logout_redirect_uri: `${window.location.origin}`,
		redirect_uri: window.location.origin + window.location.pathname,
		scope: "openid profile",
		silent_redirect_uri: window.location.origin + "/silent-renew",
		userStore: new WebStorageStateStore(),
	};

	axiosInstance.interceptors.response.use(
		(response) => {
			console.log("response", response);
			return response; // Ensure you return the response
		},
		function (error) {
			if (error.response.status === 404) {
				return { status: error.response.status };
			}
			return Promise.reject(error.response);
		},
	);

	registry.registerInstance("transport", axiosInstance);
	// registry.registerInstance("auth", new AuthenticationService());
	//console.log(registry.getInstance("auth"));

	ReactDOM.render(
		<FontProvider>
			<LayoutProvider>
				<UserProvider>
					<ThemeProvider theme={Themes.default}>
						<CssBaseline />
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Provider store={store}>
								<AuthProvider {...oidcConfig}>
									<App />
								</AuthProvider>
							</Provider>
						</LocalizationProvider>
					</ThemeProvider>
				</UserProvider>
			</LayoutProvider>
		</FontProvider>,
		document.getElementById("root"),
	);
}

startUp();

serviceWorker.unregister();
