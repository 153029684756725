import { memo, useCallback, useEffect, useRef, useState } from "react";
import contextHeaderOptionsList from "./ContextHeaderList";
import { MenuItem, MenuList, Paper } from "@material-ui/core";
import "./AgContextHeader.styles.css";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import properties from "components/properties/ApplicationProps";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Grow from "@mui/material/Grow";
import InputAdornment from "@mui/material/InputAdornment";
import BackspaceIcon from "@mui/icons-material/Backspace";
import IconButton from "@mui/material/IconButton";

const AgContextHeader = ({ contextActions, gridApi }) => {
	const searchFilter = useRef();
	const { pathname: currentRouteName } = useLocation();
	const [showSearchFilterInput, setShowSearchFilterInput] = useState(false);

	const { selectedResources, resourcesForCutPaste } = useSelector(({ resourceGrid }) => resourceGrid);
	const userRole = useSelector(({ authDetails }) => authDetails.data.role);
	const isUserHasPermission = userRole !== properties.userRoles.user;
	const areResourcesSelected = selectedResources.length;
	const areResourcesAvailableForPaste = resourcesForCutPaste.length;
	const areThereAnySharedResources = selectedResources.filter(({ shared }) => shared === true).length;

	const searchFilterIconEl = document.querySelector('li[data-test-id="context-header-search"]');

	const onFilterTextBoxChanged = useCallback(() => {
		gridApi.current.api.setGridOption("quickFilterText", searchFilter.current.value);
	}, [gridApi]);

	const handleClearSearchFilter = useCallback(() => {
		gridApi.current.api.setGridOption("quickFilterText", "");
		searchFilter.current.value = "";
		searchFilter.current.focus();
	}, [gridApi]);

	useEffect(() => {
		searchFilterIconEl?.addEventListener("click", () => {
			setShowSearchFilterInput((prev) => !prev);
		});
	}, [searchFilterIconEl]);

	return (
		<div id="ag-context-header">
			<MenuList style={{ display: "flex" }}>
				{contextHeaderOptionsList.map((option) => {
					const isMenuItemDisabled = (option) => {
						const allowedItems = [];

						if (isUserHasPermission) {
							allowedItems.push("refresh", "search", "removeAllFilters");
							if (
								((currentRouteName.includes("accountbucketcreation") ||
									currentRouteName.includes("search")) &&
									areResourcesSelected) ||
								areResourcesAvailableForPaste
							) {
							}
						}

						return !Boolean(allowedItems.includes(option));
					};

					return (
						<Tooltip title={isMenuItemDisabled(option.action) ? "Disabled" : option.name}>
							<MenuItem
								disableRipple
								className="context-header-option"
								data-test-id={`context-header-${option.action}`}
								disabled={isMenuItemDisabled(option.action)}
								style={{ padding: 8, borderRadius: "50%", margin: "0 4px" }}
								onClick={() => contextActions[option.action]()}
							>
								{option.icon}
							</MenuItem>
						</Tooltip>
					);
				})}
			</MenuList>

			<Popper
				sx={{ zIndex: 1200, marginRight: "1rem !important" }}
				open={showSearchFilterInput}
				anchorEl={searchFilterIconEl}
				placement="left"
				transition
			>
				{({ TransitionProps }) => (
					<Grow
						{...TransitionProps}
						timeout={300}
					>
						<Paper>
							<TextField
								autoFocus
								size="small"
								inputRef={searchFilter}
								className="search-filter-input"
								placeholder="Enter Filename..."
								onBlur={() => {
									if (searchFilter.current.value) {
										return;
									}

									setShowSearchFilterInput(() => false);
									searchFilter.current.value = "";
									gridApi.current.api.setGridOption("quickFilterText", "");
								}}
								onKeyDown={(e) => {
									if (e.key === "Escape") {
										setShowSearchFilterInput(() => false);
										searchFilter.current.value = "";
										gridApi.current.api.setGridOption("quickFilterText", "");
									}
								}}
								onChange={onFilterTextBoxChanged}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleClearSearchFilter}>
												<BackspaceIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

export default memo(AgContextHeader);
