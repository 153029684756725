import { memo } from "react";
import { Menu, MenuItem, Fade } from "@mui/material";
import "./AgContextMenu.styles.css";
import contextMenuOptionsList from "./ContextMenuList";
import { useSelector } from "react-redux";
import properties from "components/properties/ApplicationProps";
import { useLocation } from "react-router-dom";

const AgContextMenu = ({ contextMenu, handleContextMenuClose, contextActions }) => {
	const { pathname: currentRouteName } = useLocation();
	const { selectedResources, resourcesForCutPaste } = useSelector(({ resourceGrid }) => resourceGrid);
	const userRole = useSelector(({ authDetails }) => authDetails.data.role);
	const isUserHasPermission = userRole !== properties.userRoles.user;
	const areResourcesSelected = selectedResources.length;
	const isOnlySingleResourceSelected = selectedResources.length === 1;
	const noResourceIsSelected = !selectedResources.length;
	const areResourcesAvailableForPaste = resourcesForCutPaste.length;
	const areThereAnySharedResources = selectedResources.filter(({ shared }) => shared === true).length;

	return (
		<Menu
			id="ag-context-menu"
			open={contextMenu !== null}
			onClose={handleContextMenuClose}
			anchorReference="anchorPosition"
			anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
			TransitionComponent={Fade}
		>
			{contextMenuOptionsList.map((menu, index) => {
				const isMenuItemDisabled = (option) => {
					const allowedItems = [];

					if (isUserHasPermission) {
						if (
							((currentRouteName.includes("dashboard") || currentRouteName.includes("search")) &&
								areResourcesSelected) ||
							areResourcesAvailableForPaste ||
							noResourceIsSelected
						) {
							if (
								noResourceIsSelected &&
								(currentRouteName.includes("dashboard") || currentRouteName.includes("search"))
							) {
								allowedItems.push("createFolder");
							}

							if (isOnlySingleResourceSelected) {
								allowedItems.push("rename", "details", "showVersions");
							}

							if (isOnlySingleResourceSelected && currentRouteName.includes("search")) {
								allowedItems.push("goToParent");
							}

							if (areResourcesSelected) {
								allowedItems.push("cut", "download", "delete", "share");

								if (areThereAnySharedResources) {
									allowedItems.push("unshare");
								}
							}

							if (areResourcesAvailableForPaste) {
								allowedItems.push("paste");
							}
						}

						if (currentRouteName.includes("shared-with-me") && areResourcesSelected) {
							allowedItems.push("download", "clone");

							if (isOnlySingleResourceSelected) {
								allowedItems.push("details", "showVersions");
							}
						}
					}

					return !allowedItems.includes(option);
				};

				return (
					<MenuItem
						key={index}
						onClick={() => {
							contextActions[menu.action]();
							handleContextMenuClose();
						}}
						dense
						id="text"
						disabled={isMenuItemDisabled(menu.action)}
						data-cy-contextmenu-header={menu.action}
					>
						<span style={{ marginRight: "20px", marginLeft: "5px" }}>{menu.icon}</span>
						{menu.name}
					</MenuItem>
				);
			})}
		</Menu>
	);
};

export default memo(AgContextMenu);
