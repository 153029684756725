import properties from "components/properties/ApplicationProps";

export function getShareResourceJson({ resource, usersWhomToShare }) {
	const stringifyFetchResource = {
		action: properties.actions.SHARE,
		resourceId: resource.resourceid,
		lastUpdateUser: sessionStorage.getItem("username"),
		lastUpdateTime: Date.now(),

		senderRole: properties.role.OWNER,
		fileName: resource.filename,
		parentFolderId: resource.parentfolderid,
		isItFolder: resource.isfolder,

		roleDetails: [
			{
				role: properties.role.VIEWERS,
				userInfo: usersWhomToShare,
			},
		],
	};

	return stringifyFetchResource;
}
