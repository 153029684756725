import React, { memo, useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { neutral, textsize } from "assets/css/MainCss";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import { FontContext } from "../../UI/Theme";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { RESET_SEARCH_SLICE, SAVE_SEARCH_CRITERIA } from "store/searchSlice";
import { RESET_SEARCH_UP_LOCATION_ID_SLICE, UPDATE_SEARCH_BREADCRUMB_NAME } from "store/gridNavigationSlice";
import { Controller, useForm } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel, RadioGroup, Radio, Alert,
} from "@mui/material"; // prettier-ignore
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { dateRangeSelector } from "utils/helpers/dateRangeSelector";
import setInputFocus from "utils/helpers/setInputFocus";
import convertToBytes from "utils/helpers/convertToBytes";
import { SET_GRID_MESSAGE } from "store/uiSlice";

const defaultFormValues = {
	fileName: "",
	dateRange: "",
	startDate: dayjs(new Date()),
	endDate: dayjs(new Date()),
	minsize: "",
	maxsize: "",
	dataUnit: "mb",
	includeSharedWithMeResources: false,
};

const AdvanceSearch = ({ openSearchDialog, setOpenSearchDialog }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { fontFamily, largeTextSize } = useContext(FontContext);
	const [showErrorMssg, setShowErrorMssg] = useState(false);

	const { handleSubmit, control, getValues, watch, reset, setError, clearErrors } = useForm({
		defaultValues: defaultFormValues,
	});

	const checkIfThisIsAcceptableQuery = useCallback(() => {
		const { fileName, startDate, endDate, minsize, maxsize, dateRange } = getValues();
		return fileName || minsize || maxsize || dateRange || (dateRange && (startDate || endDate));
	}, [getValues]);

	const checkIfThereAreErrors = useCallback(() => {
		// if user does not give any valid parameter, this will show Alert with error
		if (!checkIfThisIsAcceptableQuery()) {
			setShowErrorMssg(() => true);
			setInputFocus('input[id="filename"]');
			return true;
		}

		if (checkIfThisIsAcceptableQuery()) {
			// if user only select minsize and not maxsize, then it will show error to maxsize field
			if (getValues().minsize && !getValues().maxsize) {
				setError("maxsize", { type: "required", message: "Please inset a valid maximum file size." });
				setInputFocus('input[id="maxsize"]');
				return true;
			}

			// if user only select maxsize and not minsize, then it will show error to minsize field
			if (!getValues().minsize && getValues().maxsize) {
				setError("minsize", { type: "required", message: "Please inset a valid minimum file size." });
				setInputFocus('input[id="minsize"]');
				return true;
			}

			// if minsize is greter than maxsize show error
			if (getValues().minsize && getValues().maxsize && +getValues().minsize > +getValues().maxsize) {
				setError("minsize", { type: "required", message: "Minsize can not be more than maxsize." });
				setInputFocus('input[id="minsize"]');
				return true;
			}

			// if both min and max size are same, then show error
			if (getValues().minsize && getValues().maxsize && getValues().minsize === getValues().maxsize) {
				setError("minsize", { type: "required", message: "Minsize can not be same as maxsize." });
				setError("maxsize", { type: "required", message: "Maxsize can not be same as minsize." });
				setInputFocus('input[id="minsize"]');
				return true;
			}

			// if user only select endDate and not startDate, then it will show error to startDate
			if (!getValues().startDate && getValues().endDate) {
				setError("startDate", { type: "required", message: "Please inset a valid start date." });
				setInputFocus('input[id="startDate"]');
				return true;
			}

			// if user only select startDate and not endDate, then it will show error to endDate
			if (!getValues().endDate && getValues().startDate) {
				setError("endDate", { type: "required", message: "Please inset a valid end date." });
				setInputFocus('input[id="endDate"]');
				return true;
			}

			// if both startDate and endDate are missing, then it will show error mssg to both
			if (!getValues().endDate && !getValues().startDate && watch("dateRange") === "custom") {
				setError("startDate", { type: "required", message: "Please inset a valid start date." });
				setError("endDate", { type: "required", message: "Please inset a valid end date." });
				setInputFocus('input[id="startDate"]');
				return true;
			}
		}

		setShowErrorMssg(() => false);
		return false;
	}, [checkIfThisIsAcceptableQuery, getValues, setError, watch]);

	const handleSearch = useCallback(
		(formData) => {
			try {
				if (checkIfThereAreErrors()) {
					return;
				}

				clearErrors();
				setShowErrorMssg(() => false);
				const isDateRangeUnknown = getValues("dateRange") === "";

				//  startDate and endDate will only be available when user selects custom date range
				//	they will be dayjs object, not default Date object
				const dayjsStartDate = formData?.startDate || new Date();
				const dayjsEndDate = formData?.endDate || new Date();

				const rangeSelection = getValues("dateRange");
				const { rangeStartDate, rangeEndDate } = dateRangeSelector({
					rangeSelection,
					dayjsStartDate,
					dayjsEndDate,
				});

				const startDateString = rangeStartDate.toDateString(); // E.g. 'Thu Jan 01 1970'
				const endDateString = rangeEndDate.toDateString(); // E.g. 'Thu Jan 01 1970'

				// Convert timezone offset from minutes to milliseconds
				const startDateTimzoneOffsetToUTC = new Date(startDateString).getTimezoneOffset() * 60000;
				const endDateTimzoneOffsetToUTC = new Date(endDateString).getTimezoneOffset() * 60000;

				/**
				 * Convert locale time to UTC
				 * Add if locale is in eastern or who's GMT offset is in Plus e.g. Asia/Calcutta[GMT+5:30]
				 * Substract if locale is in western or who's GMT offset is in Minus e.g. America/Los_Angeles[GMT-8:00]
				 *
				 * 🔴🔴🔴 WARNING: Do not remove or convert following substraction/minus to addition/plus 🔴🔴🔴
				 */
				const convertedLocaleStartDateToUTC = new Date(startDateString).getTime() - startDateTimzoneOffsetToUTC;
				const convertedLocaleEndDateToUTC = new Date(endDateString).getTime() - endDateTimzoneOffsetToUTC;

				setOpenSearchDialog(false);
				history.push("/search");

				/* ------ 🔴 Warning!!! Do not re-arrange or re-order following code 🔴 ----- */
				dispatch(RESET_SEARCH_SLICE());
				dispatch(
					SAVE_SEARCH_CRITERIA({
						fileName: formData.fileName,
						minSize: convertToBytes(formData.minsize, getValues("dataUnit")),
						maxSize: convertToBytes(formData.maxsize, getValues("dataUnit")),
						startDate: isDateRangeUnknown ? 0 : convertedLocaleStartDateToUTC,
						endDate: isDateRangeUnknown ? 0 : convertedLocaleEndDateToUTC,
						includeSharedWithMe: formData.includeSharedWithMeResources || false,
					}),
				);
				dispatch(RESET_SEARCH_UP_LOCATION_ID_SLICE());
				dispatch(UPDATE_SEARCH_BREADCRUMB_NAME(formData.fileName));
				reset();
				/* -------------------------------- 🔴 End 🔴 ------------------------------- */
			} catch (error) {
				console.error(error);
				dispatch(SET_GRID_MESSAGE("Something went wrong!"));
			}
		},
		[history, setOpenSearchDialog, checkIfThereAreErrors, dispatch, getValues, reset],
	);

	function handleCloseDialog() {
		setOpenSearchDialog(false);
		setShowErrorMssg(() => false);
		reset();
	}

	return (
		<>
			<Dialog
				open={openSearchDialog}
				onClose={handleCloseDialog}
				maxWidth="false" // Set maxWidth to false to disable the default maxWidth
				PaperProps={{
					style: {
						maxWidth: "500px",
						maxHeight: "fit-content",
					},
				}}
			>
				<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>Search File(s)</DialogTitle>

				<DialogContent style={{ paddingBottom: 14 }}>
					<form
						onSubmit={handleSubmit((formData) => handleSearch(formData))}
						style={{ margin: 0 }}
					>
						<Controller
							name="fileName"
							control={control}
							rules={{
								minLength: {
									value: 3,
									message: "Min. 3 characters are required",
								},
							}}
							render={({ field, fieldState }) => {
								return (
									<TextField
										id="filename"
										autoFocus
										error={!!fieldState.error?.message}
										helperText={fieldState.error?.message}
										fullWidth
										label="File Name"
										variant="outlined"
										size="small"
										style={{ marginTop: "10px" }}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
										{...field}
									/>
								);
							}}
						/>

						<Controller
							name="dateRange"
							control={control}
							render={({ field }) => {
								return (
									<FormControl
										id="daterange"
										size="small"
										style={{ marginTop: 16 }}
										fullWidth
									>
										<InputLabel
											id="usage-range-selector-label"
											sx={{
												fontFamily: "inherit",
											}}
										>
											Date Range
										</InputLabel>
										<Select
											labelId="usage-range-selector-label"
											id="usage-range-selector"
											label="Date Range"
											sx={{
												fontFamily: "inherit",
											}}
											{...field}
										>
											<MenuItem value={"today"}>Today</MenuItem>
											<MenuItem value={"week"}>Last 7 Days</MenuItem>
											<MenuItem value={"month"}>Last 30 Days</MenuItem>
											<MenuItem value={"custom"}>Custom</MenuItem>
										</Select>
									</FormControl>
								);
							}}
						/>

						{watch("dateRange") === "custom" ? (
							<div
								className="custom-date-range"
								style={{ marginTop: 16, alignItems: "unset" }}
							>
								<Controller
									name="startDate"
									control={control}
									render={({ field: { onChange, value }, fieldState }) => {
										return (
											<DatePicker
												disableFuture
												label="Start Date"
												format="DD/MM/YYYY"
												maxDate={watch("endDate")}
												views={["year", "month", "day"]}
												slotProps={{
													textField: {
														size: "small",
														error: !!fieldState.error?.message,
														helperText: fieldState.error?.message,
														id: "startDate",
													},
													field: { clearable: true },
												}}
												sx={{
													fontFamily: "inherit",
													marginRight: "5px",
													width: 221,
												}}
												value={value}
												onChange={(event) => {
													onChange(event);
												}}
											/>
										);
									}}
								/>

								<Controller
									name="endDate"
									control={control}
									render={({ field: { onChange, value }, fieldState }) => {
										return (
											<DatePicker
												disableFuture
												label="End Date"
												format="DD/MM/YYYY"
												minDate={watch("startDate")}
												views={["year", "month", "day"]}
												slotProps={{
													textField: {
														size: "small",
														error: !!fieldState.error?.message,
														helperText: fieldState.error?.message,
														id: "endDate",
													},
													field: { clearable: true },
												}}
												sx={{
													fontFamily: "inherit",
													marginLeft: "5px",
													width: 221,
												}}
												value={value}
												onChange={(event) => {
													onChange(event);
												}}
											/>
										);
									}}
								/>
							</div>
						) : null}

						<Controller
							name="minsize"
							control={control}
							render={({ field: { onChange, ...others }, fieldState }) => {
								return (
									<TextField
										fullWidth
										label="Min. Size"
										id="minsize"
										variant="outlined"
										size="small"
										type="number"
										error={!!fieldState.error?.message}
										helperText={fieldState.error?.message}
										style={{ marginTop: 16 }}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{
											style: { fontFamily: fontFamily },
											inputProps: { min: 0 },
										}}
										onChange={(event) => {
											onChange(event.target.value);

											if (!event.target.value < +getValues("maxsize")) {
												clearErrors("maxsize");
											}
										}}
										{...others}
									/>
								);
							}}
						/>

						<Controller
							name="maxsize"
							control={control}
							render={({ field: { onChange, ...others }, fieldState }) => {
								return (
									<TextField
										fullWidth
										id="maxsize"
										label="Max. size"
										variant="outlined"
										size="small"
										type="number"
										error={!!fieldState.error?.message}
										helperText={fieldState.error?.message}
										style={{ marginTop: 16 }}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily }, inputProps: { min: 1 } }}
										onChange={(event) => {
											onChange(event.target.value);

											if (!event.target.value < +getValues("minsize")) {
												clearErrors("minsize");
											}
										}}
										{...others}
									/>
								);
							}}
						/>

						<Controller
							name="dataUnit"
							control={control}
							render={({ field }) => {
								return (
									<FormControl>
										<RadioGroup
											row
											{...field}
										>
											<FormControlLabel
												value="kb"
												control={<Radio />}
												label="KB"
											/>
											<FormControlLabel
												value="mb"
												control={<Radio />}
												label="MB"
											/>
											<FormControlLabel
												value="gb"
												control={<Radio />}
												label="GB"
											/>
										</RadioGroup>
									</FormControl>
								);
							}}
						/>
						<br />

						<Controller
							name="includeSharedWithMeResources"
							control={control}
							disabled
							render={({ field }) => {
								return (
									<FormControlLabel
										control={
											<Checkbox
												{...field}
												checked={field.value}
											/>
										}
										label="Include shared file(s)."
									/>
								);
							}}
						/>

						{showErrorMssg ? <Alert severity="error">"Specify atleast one value.</Alert> : null}

						<DialogActions>
							<Button
								onClick={handleCloseDialog}
								variant="outlined"
								color="primary"
								style={{
									color: neutral.buttoncolor,
									borderColor: neutral.buttoncolor,
									fontSize: textsize.buttontextsize,
									fontFamily: fontFamily,
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								style={{
									backgroundColor: neutral.buttoncolor,
									color: neutral.buttontextcolor,
									fontSize: textsize.buttontextsize,
									fontFamily: fontFamily,
								}}
								data-button-name="search-submit"
							>
								Search
							</Button>
						</DialogActions>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default memo(AdvanceSearch);
