import React, { useEffect, useCallback } from "react";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid, IconButton, InputAdornment } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { dashboardApi } from "components/misc/DashboardApi";
import Notification from "components/dashboard/notification/Notification";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import FormContainer from "layout/FormContainer";
import Selector from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { getFetchRequestJson } from "components/requestcontext/FetchRequestJson";
import { getFetchOrgAccountJson } from "components/requestcontext/FetchOrgAccountDetails";
import { getFetchDataUsageJson } from "components/requestcontext/CalculateDataUsage";
import { getSearchUserFromAccountJson } from "components/requestcontext/SearchUsersFromAccount";

import DisplayDataUsageReport from "./DisplayDataUsageReport/DisplayDataUsageReport";

function GenerateDataUsageReport() {
	const classes = useStyles();
	const history = useHistory();
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [accountOptions, setAccountOptions] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState([]);
	const [accountId, setAccountId] = useState("");
	const { fontFamily, themeColor, filter } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [orgs, setOrgs] = useState([]);
	const [bucketname, setBucketName] = useState("");
	const [selectedOrgs, setSelectedOrgs] = useState("");
	const [orgname, setOrgname] = useState("");
	const [subscriptionCounter, setSubscriptionCounter] = useState("");
	const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [subscriptionDetails, setSubscriptionDetails] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [options, setOptions] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [displayDataUsage, setDisplayDataUsage] = useState(false);

	//snackbar
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};
	const handleClose = () => {
		history.push("/settings");
	};

	const handleCustomStartDateChange = (date) => {
		if (!date) return;

		const startDateLong = date.valueOf();
		console.log("startDateLong", startDateLong);
		setStartDate(startDateLong);
	};

	const handleCustomEndDateChange = (date) => {
		if (!date) return;

		const endDateLong = date.valueOf();
		console.log("endDateLong", endDateLong);
		setEndDate(endDateLong);
	};

	async function CalculateDataUsage() {
		setButtonLoading(() => true);
		console.log("inside AssignSubscription");
		try {
			console.log("inside try");
			if (!isDateRangeValid(startDate, endDate)) {
				showSnackbarWithMessage("Please select a date range within 100 days.");
				setButtonLoading(false);
				return;
			}

			// const trimUpdatedAccountName = subscriptionName.trim().replace(/\s{2,}/g, " ");
			const subscriptionDetailsJson = getFetchDataUsageJson(
				accountId,
				startDate,
				endDate,
				selectedUsers,
				orgname,
			);
			console.log(subscriptionDetailsJson);
			const assignSubscriptionResponse = await dashboardApi.calculateDataUsage(subscriptionDetailsJson);
			console.log("account details:", assignSubscriptionResponse);
			if (assignSubscriptionResponse.data.footer.msg === "SUCCESS") {
				setSubscriptionDetails(assignSubscriptionResponse.data);
				showSnackbarWithMessage("Generating Data Usage Report..");
				setDisplayDataUsage(true);
				setButtonLoading(() => false);
				// history.push("/displaydatausagereport", { subscriptionDetails: assignSubscriptionResponse.data });
			} else {
				setSubscriptionDetails(null);
				showSnackbarWithMessage("Failure while generating report");
				setButtonLoading(() => false);
			}
		} catch (error) {
			setButtonLoading(() => false);
			setSubscriptionDetails(null);
		}
	}

	useEffect(() => {
		// fetchOrgRelatedAccounts();
		fetchAllOrgDetails();
	}, []);

	async function fetchOrgRelatedAccounts(orgshortname) {
		console.log("inside fetch accounts function ");
		console.log("Orgname :", orgshortname);
		//fetch org related accounts
		try {
			const fetchorgAccounts = getFetchOrgAccountJson(orgshortname);
			const response = await dashboardApi.fetchOrgAccountDetails(fetchorgAccounts);
			console.log("Response :", response);

			const transformedArray = response.data.resourcePropertiesList.map((item) => ({
				value: item.fields.accountname,
				label: item.fields.accountname,
				accounttype: item.fields.accounttype,
				account_userid: item.fields.userid,
				accountid: item.fields.accountid,
				username: item.fields.username,
			}));

			// if (transformedArray.length === 1) {
			// 	setSelectedAccount(transformedArray[0]);
			// 	setAccountOptions(transformedArray);
			setOrgname(orgshortname);
			// 	handleSubscriptionChange(transformedArray[0]);
			// } else {
			setAccountOptions(transformedArray);
			// }
			// setAccountOptions(transformedArray);
		} catch (error) {
			console.error("Error fetching subscriptions:", error);
		}
	}

	async function fetchAllOrgDetails(value) {
		//fetchallorgs inside this
		try {
			console.log(value);
			const fetchOrgsJson = getFetchRequestJson();
			const searchOrgResponse = await dashboardApi.fetchAllOrgDetails(fetchOrgsJson);
			console.log(searchOrgResponse);
			if (searchOrgResponse?.data?.resourcePropertiesList) {
				const orgs = searchOrgResponse.data.resourcePropertiesList.map((org) => {
					return {
						value: org.fields.displayname,
						label: org.fields.displayname,
						orgId: org.fields.orgid,
						orgrootname: org.fields.orgrootname,
						orgshortname: org.fields.orgshortname,
						bucketname: org.fields.bucketname,
					};
				});
				setOrgs(orgs);
				console.log(orgs);
				return orgs.map((org) => org.orgshortname);
			} else {
				setOrgs([]); // Set no options when no users are found
			}
		} catch (error) {
			console.error("An error occurred while searching for users:", error);
			setOrgs([]);
			// setError("An error occurred while searching for users");
		} finally {
			setIsLoading(false);
		}
	}

	function handleInputChange(value) {
		if (value.trim().length >= 3) {
			console.log("value", value);
			setIsLoading(true);
			fetchAllOrgDetails(value);
			// searchAccount(value);
		}
	}

	function handleSearchUserChange(value) {
		setSearchTerm(value);
	}

	async function searchAccount(value) {
		try {
			console.log(value);
			const fetchorgAccounts = getFetchOrgAccountJson(value);
			const fetchorgAccountsresponse = await dashboardApi.fetchOrgAccountDetails(fetchorgAccounts);
			if (fetchorgAccountsresponse?.data?.resourcePropertiesList) {
				const fetchedAccountsDetails = fetchorgAccountsresponse.data.resourcePropertiesList.map((account) => {
					return {
						value: account.fields.accountname,
						label: account.fields.accountname,
						accountId: account.fields.accountid,
						ownerid: account.fields.userid,
						orgid: account.fields.orgid,
						plantype: account.fields.plantype,
					};
				});
				setAccountOptions(fetchedAccountsDetails);
			} else {
				setAccountOptions([]); // Set no options when no users are found
			}
		} catch (error) {
			console.error("An error occurred while searching for users:", error);
			setAccountOptions([]);
			// setError("An error occurred while searching for users");
		} finally {
			setIsLoading(false);
		}
	}
	console.log(accountOptions);

	const searchUsers = useCallback(async (orgName, accountId) => {
		console.log("inside searchUsers function");
		try {
			setIsLoading(true);
			const userResourceJson = getSearchUserFromAccountJson(orgName, accountId);
			console.log("User Resource JSON:", userResourceJson); // Log the API request
			const searchuserResponse = await dashboardApi.searchUsersFromAccount(userResourceJson);
			console.log("Search User Response:", searchuserResponse); // Log the API response
			const fileItems = searchuserResponse.data.resourcePropertiesList.map((item) => ({
				value: item.fields.email,
				label: `${item.fields.email}`,
				avatar: item.fields.avatarUrl,
				userid: item.fields.userid,
			}));
			setOptions(fileItems);
		} catch (error) {
			console.error("An error occurred while searching for users:", error);
			setOptions([]);
			// Handle error here (e.g., show error message)
		} finally {
			setIsLoading(false);
		}
	}, []);

	// Function to handle subscription change
	async function handleSubscriptionChange(selected) {
		setSelectedUsers([]);
		console.log(selected);
		setSelectedAccount(selected);
		setAccountId(selected.accountid);
		if (selected && selected.accountid) {
			await searchUsers(orgname, selected.accountid);
		}
	}

	async function handleChange1(selectedItems) {
		console.log(selectedItems);
		setSelectedAccount([]);
		setSelectedUsers([]);
		setOrgname(selectedItems.orgshortname);
		// setAccountId(selectedItems);
		setBucketName(selectedItems.bucketname);
		setSelectedOrgs(selectedItems);
		fetchOrgRelatedAccounts(selectedItems.orgshortname);
		handleSubscriptionChange(selectedItems.orgshortname);
		// fetchAttributes(selectedItems);
	}

	function handleChange(selected) {
		const names = selected.map((item) => item.userid);
		console.log(names);
		setSelectedUsers(selected);
		console.log("selected", selected);
	}

	const isDateRangeValid = (start, end) => {
		const maxAllowedDays = 100;
		const millisecondsInADay = 24 * 60 * 60 * 1000;
		const startDate = new Date(start);
		const endDate = new Date(end);
		const diffInDays = Math.round((endDate - startDate) / millisecondsInADay);
		return diffInDays >= 0 && diffInDays <= maxAllowedDays;
	};

	return (
		<div
			style={{
				position: "relative",
				filter: filter,
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						// Comment out maxWidth to restore previous looks for contextHeader
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						{displayDataUsage === false && (
							<FormContainer>
								<form>
									<h1
										style={{
											fontFamily: fontFamily,
											fontSize: 24,
											textAlign: "center",
										}}
									>
										Generate Data Usage Report
									</h1>

									<hr />

									<Selector
										autoFocus
										options={orgs}
										value={selectedOrgs}
										onChange={handleChange1}
										onInputChange={handleInputChange}
										isLoading={isLoading}
										placeholder="Select Org"
										inputId="select-org"
										noOptionsMessage={() => "No results found"}
										formatOptionLabel={(option) => (
											<div>
												<div>{option.label}</div>
											</div>
										)}
										styles={{
											width: "100%",
											control: (provided) => ({
												...provided,
												width: "100%",
												marginTop: "5px",
												marginBottom: "10px",
												minHeight: "36px",
												fontFamily: fontFamily,
												zIndex: 9999,
											}),
											menu: (provided) => ({
												...provided,
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
												zIndex: 9999,
											}),
											menuList: (provided) => ({
												...provided,
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
												zIndex: 9999,
											}),
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
									/>

									{/* {renderAdditionalFields()} */}

									<TextField
										id={"bucketname"}
										className="input-field"
										label="Bucket Name"
										variant="outlined"
										size="small"
										fullWidth
										margin="normal"
										value={bucketname}
										onChange={(e) => {
											const value = e.target.value.replace(/\D/g, "");
											setSubscriptionCounter(value);
										}}
										style={{ marginTop: "3px" }}
										InputLabelProps={{
											style: { fontFamily: fontFamily },
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
										// inputRef={subscriptionCounterRef}
									/>

									<Selector
										options={accountOptions}
										value={selectedAccount}
										onChange={(e) => handleSubscriptionChange(e, selectedAccount)}
										onInputChange={handleInputChange}
										isLoading={isLoading}
										placeholder="Select Account"
										inputId="selected-account"
										noOptionsMessage={() => "No results found"}
										formatOptionLabel={(option) => (
											<div>
												{/* <div>
												{"uid"} | {"name"} | {"type"}
											</div> */}
												<div>
													{option.accountid} | {option.label} | {option.username}
												</div>
											</div>
										)}
										styles={{
											width: "100%",

											control: (provided) => ({
												...provided,
												width: "100%",

												marginBottom: "10px",
												minHeight: "36px",
												fontFamily: fontFamily,
												zIndex: 999,
											}),
											menu: (provided) => ({
												...provided,
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
												zIndex: 9999,
											}),
											menuList: (provided) => ({
												...provided,
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
												zIndex: 9999,
											}),
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
									/>

									<Selector
										isMulti
										fullWidth
										className="input-field"
										inputId="select-users-from-account"
										margin="normal"
										placeholder="Select User from Account"
										options={options}
										value={selectedUsers}
										onChange={handleChange}
										onInputChange={handleSearchUserChange}
										isLoading={isLoading}
										label="Search Users From account"
										noOptionsMessage={() => "No results found"}
										styles={{
											width: "100%",

											control: (provided) => ({
												...provided,
												width: "100%",

												marginBottom: "10px",
												minHeight: "36px",
												fontFamily: fontFamily,
												zIndex: 999,
											}),
											menu: (provided) => ({
												...provided,
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
												zIndex: 9999,
											}),
											menuList: (provided) => ({
												...provided,
												minHeight: "30px",
												maxHeight: "150px",
												fontFamily: fontFamily,
												zIndex: 9999,
											}),
										}}
										InputProps={{ style: { fontFamily: fontFamily } }}
									/>

									<DatePicker
										id="startDate"
										format="DD/MM/YYYY"
										views={["year", "month", "day"]}
										className="startdatepicker"
										placeholder={selectedDate ? selectedDate : "select date"}
										slotProps={{
											textField: { size: "small" },
											field: { clearable: true },
										}}
										sx={{
											fontFamily: "inherit",
											width: "100%",
											marginBottom: "10px",
										}}
										label="Start Date"
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												className="input-field"
												size="small"
												fullWidth
												margin="normal"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton>
																<CalendarTodayIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
												style={{ fontFamily: fontFamily }}
											/>
										)}
										inputFormat="dd/MM/yyyy"
										openTo="year"
										// views={["year", "month", "day"]}
										clearable
										mask="__/__/____"
										onChange={handleCustomStartDateChange}
										value={startDate}
									/>

									<DatePicker
										id="endDate"
										format="DD/MM/YYYY"
										className="enddatepicker"
										views={["year", "month", "day"]}
										placeholder={selectedDate ? selectedDate : "select date"}
										slotProps={{
											textField: { size: "small" },
											field: { clearable: true },
										}}
										sx={{
											fontFamily: "inherit",
											width: "100%",
											marginBottom: "15px",
										}}
										label="End Date"
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												className="input-field"
												size="small"
												fullWidth
												margin="normal"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton>
																<CalendarTodayIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
												style={{ fontFamily: fontFamily }}
											/>
										)}
										inputFormat="dd/MM/yyyy"
										openTo="year"
										// views={["year", "month", "day"]}
										clearable
										mask="__/__/____"
										onChange={handleCustomEndDateChange}
										value={endDate}
									/>

									<div className="form-buttons-container">
										<Button
											onClick={handleClose}
											variant="outlined"
											data-button-name="cancel-manage-account"
										>
											Cancel
										</Button>

										{/* {updateAccDetails === true && ( */}
										<Button
											id="createButton"
											onClick={CalculateDataUsage}
											variant="outlined"
											buttonType="main"
											isLoading={buttonLoading}
											data-button-name="generate-data-usage-report"
										>
											Generate
										</Button>
										{/* )} */}
									</div>
								</form>
							</FormContainer>
						)}

						{/* {subscriptionDetails ? <DataUsageReport subscriptionDetails={subscriptionDetails} /> : null} */}
						{subscriptionDetails ? (
							<DisplayDataUsageReport
								subscriptionDetails={subscriptionDetails}
								// resourcesData={resourcesData}
							/>
						) : null}
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
				messageLength={50}
			/>
		</div>
	);
}

export default GenerateDataUsageReport;
