import { memo } from "react";
import {
	CartesianGrid,
	Legend,
	LineChart as ReLineChart,
	Line,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
} from "recharts";
import DataUsageChartsTooltip from "../DataUsageChartsTooltip";

const LineChart = ({ data }) => {
	return (
		<>
			<ResponsiveContainer
				width="100%"
				height="100%"
			>
				<ReLineChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="xAxisData" />
					<YAxis />
					<Tooltip
						content={<DataUsageChartsTooltip />}
						cursor={Boolean(data.length)}
					/>
					<Legend />

					<Line
						dataKey="yAxisData"
						name="Storage Used (in GB)"
						stroke="#82ca9d"
						type="monotone"
						activeDot={{ r: 8 }}
					/>
				</ReLineChart>
			</ResponsiveContainer>
		</>
	);
};

export default memo(LineChart);
