// import properties from "components/properties/ApplicationProps";

// export function getInvoiceOrderJson(totalamount,service) {
// 	let stringifyOrgPropsJson = {
// 		header: {
// 			email: sessionStorage.getItem("email"),
// 			user: sessionStorage.getItem("username"),
// 			org: sessionStorage.getItem("user_org"),
// 		},
// 		amount: totalamount,
// 		currency: properties.currency,
// 		invoiceId: properties.status.invoiceid,
// 		service: service,
// 		redirectUrl: properties.status.redirecturl,
// 	};

// 	return stringifyOrgPropsJson;
// }
import properties from "components/properties/ApplicationProps";
import { v4 as uuidv4 } from 'uuid';

export function getInvoiceOrderJson(totalamount, service,invoiceid) {
    let stringifyOrgPropsJson = {
        header: {
            email: sessionStorage.getItem("email"),
            user: sessionStorage.getItem("username"),
            org: sessionStorage.getItem("user_org"),
        },
        amount: totalamount,
        currency: properties.currency,
        invoiceId: invoiceid, // Generate unique UUID for invoiceId
		orderId: uuidv4(),
        service: service,
        redirectUrl: properties.status.redirecturl,
    };

    return stringifyOrgPropsJson;
}