import React, { memo, useCallback, useEffect, useRef } from "react";
import eventEmitter from "components/misc/eventEmitter";
import { useDispatch, useSelector } from "react-redux";
import { PUT_FILES_IN_QUEUE } from "store/fileUploadSlice";
import { GlobalRole } from "Constants";
import properties from "components/properties/ApplicationProps";

const FileSelector = () => {
	const inputFileRef = useRef();
	const dispatch = useDispatch();
	const droppedLocationId = useSelector(({ droppedLocationId }) => droppedLocationId.locationId);

	const handleFileSelection = useCallback(() => {
		/*Collecting node-element and performing click*/
		try {
			inputFileRef.current.click();
		} catch (error) {}
	}, []);

	const uploadFile = useCallback(
		(e) => {
			const files = e.target.files;
			const droppedFiles = [].concat([...files]);
			dispatch(PUT_FILES_IN_QUEUE({ droppedFiles, droppedLocationId }));

			// dump selected folder so onChange event can detect new folder selection
			e.target.value = "";
		},
		[droppedLocationId, dispatch],
	);

	useEffect(() => {
		eventEmitter.on("selecttuploadfile", () => {
			handleFileSelection();
		});
	}, [handleFileSelection]);

	return (
		<>
			<div>
				<label className="custom-file-upload">
					<input
						ref={inputFileRef}
						type="file"
						multiple
						accept="*"
						path="true"
						disabled={GlobalRole.userrole === properties.userRoles.user}
						style={{ display: "none" }}
						onChange={uploadFile}
					/>
				</label>
			</div>
		</>
	);
};

export default memo(FileSelector);
