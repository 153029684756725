// InvoiceReport.js
import React, { useContext, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./styles.css";
import { neutral, textsize } from "assets/css/MainCss";
import Button from "components/inputs/Button";
import { GlobalFirstname, GlobalLastname } from "Constants";
import moment from "moment-timezone";
import properties from "components/properties/ApplicationProps";
import orgIcon from "assets/proto_1/Data_Frugal_Logo.png";
import { FontContext } from "components/UI/Theme";
import Cashfree from "cashfree/PaymentIntegration";
import columnDefinations from "pages/Settings/InvoiceOperations/columnDefination";
import getCurrencySymbol from "pages/Settings/InvoiceOperations/getCurrencySymbol";
import EventEmitter from "components/misc/eventEmitter";
import { useSelector } from "react-redux";

const InvoiceReport = ({ invoiceDetails }) => {
	const { data: userSummery } = useSelector(({ userDetails }) => userDetails);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
	const [totalAmount, setSelectedTotalAmount] = useState(null);
	const [showTable, setShowTable] = useState(true);
	const [isGridReady, setIsGridReady] = useState(false);
	const inputRef = useRef(null);
	const gridRef = useRef(null);
	const { largeTextSize, smallTextSize, fontFamily } = useContext(FontContext);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const printDocument = () => {
		html2canvas(inputRef.current).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF();
			pdf.addImage(imgData, "JPEG", 0, 0);
			pdf.save("Invoice Report.pdf");
		});
	};

	const handleRowClick = (event) => {
		const data = event.data;
		if (!data || !data.fields) {
			console.error("Invalid data object", data);
			return;
		}
		setSelectedInvoice(data);
		setSelectedTotalAmount(data.fields.totalamount);
		setSelectedInvoiceId(data.fields.invoiceid);
		setShowTable(false); // Hide the table when an invoice is selected
	};

	const handleViewTableClick = () => {
		setSelectedInvoice(null);
		setSelectedTotalAmount(null);
		setSelectedInvoiceId(null);
		setShowTable(true); // Show the table when "View Table" is clicked
		EventEmitter.emit("InvoicePaid");
	};

	const updatePaymentStatus = (invoiceId, status) => {
		// Update selectedInvoice state
		setSelectedInvoice((prevInvoice) => ({
			...prevInvoice,
			fields: {
				...prevInvoice.fields,
				paymentstatus: status.toLowerCase(),
			},
		}));

		// Refresh the AG Grid cells to reflect changes
		if (gridRef.current) {
			gridRef.current.api.refreshCells({ force: true });
		}
	};

	return (
		<div
			className="resources-page"
			id="dashboard-page"
		>
			{showTable ? (
				<div
					className="ag-theme-alpine"
					style={{ height: "100vh", width: "100%" }}
				>
					<AgGridReact
						rowData={invoiceDetails}
						columnDefs={columnDefinations(handleRowClick)}
						rowSelection="single"
						onGridReady={(params) => setIsGridReady(true)}
						onRowClicked={handleRowClick}
						gridOptions={{
							headerHeight: 35,
							rowHeight: 35,
							alwaysShowHorizontalScroll: true,
							alwaysShowVerticalScroll: true,
							onModelUpdated: (gridEvent) => {
								gridEvent.api.getDisplayedRowCount() === 0
									? gridEvent.api.showNoRowsOverlay()
									: gridEvent.api.hideOverlay();
							},
							postSortRows: (params) => {
								const allRowNodes = params.nodes;
								let folderPosition = 0;
								allRowNodes.forEach((rowNode, index) => {
									const isItFolder = rowNode.data ? rowNode.data.isfolder : undefined;
									if (isItFolder === true) {
										allRowNodes.splice(folderPosition, 0, allRowNodes.splice(index, 1)[0]);
										folderPosition++;
									}
								});
							},
						}}
						ref={gridRef}
					/>
				</div>
			) : (
				<div>
					<div
						className="invoice-box"
						id="divToPrint"
						ref={inputRef}
					>
						<table
							cellPadding={0}
							cellSpacing={0}
							style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
						>
							<tbody>
								{/* Invoice details */}
								<tr className="top">
									<td colSpan={3}>
										<table>
											<tbody>
												{/* Organization logo and name */}
												<tr>
													<td className="title">
														<tr>
															<td>
																<img
																	alt="img"
																	src={orgIcon}
																	style={{ width: 70, height: 60 }}
																/>
															</td>
														</tr>
														<tr className="item5">
															<td>
																<b>
																	<p style={{ fontSize: "20px", marginTop: "10px" }}>
																		{properties.orgProperties.org}
																	</p>
																</b>
															</td>
														</tr>
													</td>
													<td style={{ paddingLeft: "100px" }}></td>
													<td>
														<table>
															<tbody>
																<tr className="item4">
																	<td
																		style={{
																			padding: 0,
																			margin: 0,
																			fontSize: largeTextSize,
																		}}
																	>
																		<b>
																			{selectedInvoice.fields.orgshortname
																				.charAt(0)
																				.toUpperCase() +
																				selectedInvoice.fields.orgshortname.slice(
																					1,
																				)}{" "}
																			Invoice
																		</b>
																	</td>
																	<td />
																</tr>
																{/* Invoice summary */}
																<tr className="item">
																	<td
																		style={{
																			padding: 0,
																			margin: 0,
																			fontSize: largeTextSize,
																		}}
																	>
																		<b>Invoice Summary</b>
																	</td>
																	<td />
																</tr>
																<tr
																	className="item1"
																	style={{ fontSize: smallTextSize }}
																>
																	<td style={{ padding: 0, margin: 0 }}>
																		Invoice ID
																	</td>
																	<td style={{ padding: 0, margin: 0 }}>
																		<b>{selectedInvoice.fields.invoiceid}</b>
																	</td>
																</tr>
																<tr className="item1">
																	<td style={{ padding: 0, margin: 0 }}>
																		Invoice Date
																	</td>
																	<td style={{ padding: 0, margin: 0 }}>
																		{moment
																			.tz(
																				selectedInvoice.fields
																					.invoicegenerateddate,
																				timeZone,
																			)
																			.format("DD/MM/YYYY")}
																	</td>
																</tr>
																<tr className="item8">
																	<td style={{ padding: 0, margin: 0 }}>Due Date</td>
																	<td style={{ padding: 0, margin: 0 }}>
																		{moment
																			.tz(
																				selectedInvoice.fields.duedate,
																				timeZone,
																			)
																			.format("DD/MM/YYYY")}
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								{/* Company and customer details */}
								<tr className="information">
									<td
										colSpan={2}
										style={{ fontFamily: fontFamily }}
									>
										<table>
											<tbody>
												<tr className="item5">
													<td>
														<b>Company details:</b>
														<br />
														GST No. {properties.companyDetails.gst}
														<br />
														Email: {properties.companyDetails.email}
														<br />
														Contact No. {properties.companyDetails.contact}
														<br />
														Company: {properties.companyDetails.name}
														<br />
														PAN No. {properties.companyDetails.pan}
													</td>
													<td>
														<b>Customer details:</b>
														<br />
														M/S.
														{GlobalFirstname.firstname.charAt(0).toUpperCase() +
															GlobalFirstname.firstname.slice(1)}{" "}
														{GlobalLastname.lastname.charAt(0).toUpperCase() +
															GlobalLastname.lastname.slice(1)}
														<br />
														Email: {sessionStorage.getItem("email")}
														<br />
														GST No. {userSummery?.gstno}
														<br />
														Address of Customer:
													</td>
												</tr>
												<tr className="item3">
													<td>
														<b>
															Billing Period:{" "}
															{moment
																.tz(selectedInvoice.fields.startdate, timeZone)
																.format("DD/MM/YYYY")}{" "}
															To{" "}
															{moment
																.tz(selectedInvoice.fields.enddate, timeZone)
																.format("DD/MM/YYYY")}
														</b>
													</td>
													<td></td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								{/* Invoice items */}
								<tr
									className="heading"
									style={{ fontSize: largeTextSize }}
								>
									<td>Description</td>
									<td>Amount</td>
								</tr>
								<tr className="item1">
									<td>Subscription Charge</td>
									<td>
										{getCurrencySymbol(selectedInvoice.fields.subscriptionchargetype)}
										{Number((selectedInvoice.fields.subscriptioncharge / 100).toFixed(2))}
									</td>
								</tr>
								<tr className="item1">
									<td>Data Usage In GB</td>
									<td>{selectedInvoice.fields.datausageingb.toFixed(2)}</td>
								</tr>

								<tr className="item1">
									<td>Data Usage Charge</td>
									<td>
										{getCurrencySymbol(selectedInvoice.fields.subscriptionchargetype)}
										{Number((selectedInvoice.fields.datausagecharge / 100).toFixed(2))}
									</td>
								</tr>
								<tr className="item1">
									<td>Total Taxable Amount</td>
									<td>
										{getCurrencySymbol(selectedInvoice.fields.subscriptionchargetype)}
										{Number((selectedInvoice.fields.totalamountbeforetax / 100).toFixed(2))}
									</td>
								</tr>
								<tr className="item1">
									<td>Tax Amount</td>
									<td>
										{getCurrencySymbol(selectedInvoice.fields.subscriptionchargetype)}
										{Number((selectedInvoice.fields.taxamount / 100).toFixed(2))}
									</td>
								</tr>
								<tr className="item1">
									<td>Tax Rate(I.G.S.T)</td>
									<td>{selectedInvoice.fields.gstrate}%</td>
								</tr>
								{selectedInvoice.fields.discountpercentage !== 0 && (
									<tr className="item1">
										<td>Discount Applied</td>
										<td>{selectedInvoice.fields.discountpercentage}%</td>
									</tr>
								)}
								<tr className="item6">
									<td style={{ borderBottom: "none" }}>Amount Due</td>
									<td style={{ borderBottom: "none" }}> </td>
								</tr>
								<tr className="total">
									<td />
									<td>
										Total:
										{getCurrencySymbol(selectedInvoice.fields.subscriptionchargetype)}
										{Number((selectedInvoice.fields.totalamount / 100).toFixed(2))}
									</td>
								</tr>
								<tr className="item3">
									<td>
										<b>Terms and Conditions:</b>
										<br />
										1.Not refundable.
										<br />
										2.Delay in payment will be on Net 30 basis.
										<br />
										3.Computer generated invoice doesn't need a signature.
									</td>
									<td>
										<b>
											Payment Status:
											<td>{}</td>
											<b
												style={{
													color:
														selectedInvoice.fields.paymentstatus.toLowerCase() === "unpaid"
															? "#c62c12"
															: "#21a52e",
													backgroundColor:
														selectedInvoice.fields.paymentstatus.toLowerCase() === "unpaid"
															? "#fdebe8"
															: "#d3ffd1",

													padding:
														selectedInvoice.fields.paymentstatus.toLowerCase() === "unpaid"
															? "2px 5px"
															: "2px 8px 2px 8px",
													borderRadius:
														selectedInvoice.fields.paymentstatus.toLowerCase() === "unpaid"
															? "3px"
															: "0",
													fontWeight: "bold",
												}}
											>
												{selectedInvoice.fields.paymentstatus.charAt(0).toUpperCase() +
													selectedInvoice.fields.paymentstatus.slice(1)}
											</b>
										</b>
									</td>

									<td />
								</tr>
							</tbody>
						</table>
					</div>
					<div
						style={{
							marginTop: "20px",
							textAlign: "center",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Button
							onClick={handleViewTableClick}
							variant="contained"
							color="primary"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
								width: "180px",
								height: "35px",
								marginBottom: "20px",
								marginRight: "10px",
							}}
						>
							View
						</Button>

						{selectedInvoice.fields.paymentstatus !== "paid" && (
							<Cashfree
								invoiceId={selectedInvoiceId}
								totalAmount={Number((totalAmount / 100).toFixed(2))}
								updatePaymentStatus={updatePaymentStatus}
							/>
						)}
						<Button
							onClick={printDocument}
							variant="contained"
							color="secondary"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
								width: "180px",
								height: "35px",
								marginBottom: "20px",
							}}
						>
							Download
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default InvoiceReport;
