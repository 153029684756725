import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardApi } from "components/misc/DashboardApi";

import properties from "components/properties/ApplicationProps";

import { fetchAllTransactionsJson } from "components/requestcontext/fetchAllTransactions";

const initialState = {
	loading: false,
	bucketTransactionGrid: false,
	error: null,
	data: {
		header: {},
		footer: {},
		resourceProperties: null,
		parentFolderId: null,
		resourcePropertiesList: [],
	},
	folders: null,
	files: null,
};

export const fetchBucketCreationeRequestsThunk = createAsyncThunk(
	"bucketCreation/fetchRequests",
	async (_, { fulfillWithValue, rejectWithValue }) => {
		try {
			const fetchRequestJson = fetchAllTransactionsJson();
			const response = await dashboardApi.fetchAllTransactions(fetchRequestJson);

			if (response.data.footer.code === 0) {
				return fulfillWithValue(response.data);
			} else {
				return rejectWithValue("Failed to fetch data");
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

const bucketCreationSlice = createSlice({
	name: "bucketCreation",
	initialState,
	reducers: {
		RESET_BUCKET_CREATION_SLICE: (state, _) => {
			state.loading = initialState.loading;
			state.error = initialState.error;
			state.data = initialState.data;
			state.folders = initialState.folders;
			state.files = initialState.files;

			properties.parentFolderId = -1;
		},

		// SET_DUMMY_DATA: (state) => {
		// 	state.data = dummyData;
		// },

		UPDATE_CURRENT_BUCKET_CREATION_RESOURCES: (state, { payload }) => {
			// state.data.resourcePropertiesList = payload;
		},

		REFRESH_BUCKET_TRANSACTION_GRID: (state, _) => {
			state.bucketTransactionGrid = true;
		},

		// FILTEROUT_FOLDERS: (state, { payload }) => {
		// 	const currentDashboardResources = payload.resourcePropertiesList.map(({ fields }) => fields);
		// 	const foldersFromCurrentDashboard = currentDashboardResources
		// 		.map(({ isfolder, filename }) => {
		// 			if (!isfolder) {
		// 				return null;
		// 			}

		// 			return filename;
		// 		})
		// 		.filter((x) => x);

		// 	state.folders = foldersFromCurrentDashboard;
		// },

		// SAVE_CURRENT_BUCKET_CREATION_DETAILS: (state, payload) => {
		// 	state.currentParentFolderId = payload;
		// },

		// FILTEROUT_FILES: (state, { payload }) => {
		// 	const currentDashboardResources = payload.resourcePropertiesList.map(({ fields }) => fields);
		// 	const filesFromCurrentDashboard = currentDashboardResources
		// 		.map(({ isfolder, filename }) => {
		// 			if (isfolder) {
		// 				return null;
		// 			}

		// 			return filename;
		// 		})
		// 		.filter((x) => x);

		// 	state.files = filesFromCurrentDashboard;
		// },
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBucketCreationeRequestsThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchBucketCreationeRequestsThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.data = action.payload;
			})
			.addCase(fetchBucketCreationeRequestsThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const {
	RESET_BUCKET_CREATION_SLICE,
	FILTEROUT_FOLDERS,
	FILTEROUT_FILES,
	SAVE_BUCKET_CREATION_PARENT_FOLDER_ID,
	UPDATE_CURRENT_BUCKET_CREATION_RESOURCES,
	REFRESH_BUCKET_TRANSACTION_GRID,
	// SET_DUMMY_DATA,
} = bucketCreationSlice.actions;

export default bucketCreationSlice.reducer;
