export function generateSearchJsonReq({ fileName, minSize, maxSize, startDate, endDate, includeSharedWithMe }) {
	let stringifyFetchResource = {
		searchField: fileName,
		minLimit: minSize,
		maxLimit: maxSize,
		startDate,
		endDate,
		includeSharedWithMe,
		shared: false,
	};

	return stringifyFetchResource;
}
