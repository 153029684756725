import ResourceTableComponent from "components/ResourceTableComponent";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET_SEARCH_SLICE } from "store/searchSlice";
import { SHOW_GRID_HEADER_PROGRESSBAR } from "store/uiSlice";

const SearchPage = () => {
	const dispatch = useDispatch();
	const searchedResources = useSelector(({ search }) => search.resources);
	const areSearchedResourcesLoading = useSelector(({ search }) => search.loading);

	useEffect(() => {
		dispatch(SHOW_GRID_HEADER_PROGRESSBAR());

		return () => dispatch(RESET_SEARCH_SLICE());
	}, [dispatch]);

	return (
		<div
			className="resources-page"
			id="dashboard-page"
		>
			<ResourceTableComponent
				resourcesData={searchedResources}
				showGridProgressbar={areSearchedResourcesLoading}
			/>
		</div>
	);
};

export default memo(SearchPage);
