class eventEmitterInstance {
	constructor() {
		this.events = {};
	}

	on(eventName, callback) {
		console.log(eventName);
		if (!this.events[eventName]) {
			this.events[eventName] = [];
		}

		this.events[eventName].push(callback);
	}
	off(eventName) {
		if (this.events[eventName]) {
			this.events[eventName] = [];
		}
	}
	removeAllListeners(eventName) {
		if (eventName) {
			delete this.events[eventName];
		} else {
			this.events = {};
		}
	}
	emit(eventName, ...args) {
		if (this.events[eventName]) {
			this.events[eventName].forEach((callback) => {
				callback(...args);
			});
		}
	}
}

const EventEmitter = new eventEmitterInstance();

export default EventEmitter;
