import properties from "components/properties/ApplicationProps";

export class ContextRequestUpdateGroup {
	static getUpdateGroupInfoJson(file) {
		let requestJson = {
			action: properties.actions.SAVE, // change at server side, need to change action as "MODIFY"
			name: file.fields.name,
			description: file.fields.description,
			lastUpdateTime: Date.now(),
			orginalGroupName: "",
		};
		return requestJson;
	}
}
