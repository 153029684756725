import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
	root: {
		display: "flex",
		maxWidth: "100vw",
		overflowX: "hidden",
		//fontFamily: localStorage.getItem("fontFamily")
	},
	content: {
		flexGrow: 1,
		//padding: theme.spacing(1),
		width: `calc(100vw - 210px)`,
		minWidth: "80%",
		minHeight: "100vh",
	},
	contentShift: {
		width: `calc(100vw - ${210 + theme.spacing(6)}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	fakeToolbar: {
		//...theme.mixins.toolbar,
		height: "55px",
	},
	link: {
		"&:not(:first-child)": {
			paddingLeft: 15,
		},
	},
	[`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
		content: {
			width: `calc(120vw - 210px)`,
			// width: "1000px"
		},
		contentShift: {
			width: `calc(100vw - ${210 + theme.spacing(6)}px)`,
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
		content: {
			padding: theme.spacing(1),
		},
	},
}));
