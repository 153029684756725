import numeral from "numeral";
import { DatePicker } from "@mui/x-date-pickers/DatePicker"; // Import DatePicker
import { TextField, IconButton, InputAdornment } from "@mui/material"; // Import necessary Material-UI components
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; // Import calendar icon
import dayjs from "dayjs"; // Import dayjs for date handling

const columnDefinitions = [
	{
		field: "subscriptionid",
		headerName: "Id",
		width: 100,
		cellRenderer: subscriptionIdRenderer,
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			numberParser: (text) => {
				console.log(text);
			},
			numberFormatter: (value) => {
				console.log(value);
			},
		},
	},
	{
		field: "discounttype",
		headerName: "Type",
		width: 150,
		cellRenderer: subscriptionTypeRenderer,
		sort: "asc",
		filter: "agNumberColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
			numberParser: (text) => {
				console.log(text);
			},
			numberFormatter: (value) => {
				console.log(value);
			},
		},
	},
	{
		field: "subscriptionname",
		headerName: "Name",
		width: 250,
		cellRenderer: subscriptionNameRenderer,
		sort: "asc",
		filter: "agTextColumnFilter",
		filterParams: {
			buttons: ["reset", "apply"],
			closeOnApply: true,
		},
	},
	// {
	// 	field: "subqty",
	// 	headerName: "Number Of Times",
	// 	editable: true,
	// 	width: 150,
	// 	cellRenderer: subscriptionNumberOfTimesRenderer,
	// 	sort: "asc",
	// 	filter: "agTextColumnFilter",
	// 	filterParams: {
	// 		buttons: ["reset", "apply"],
	// 		closeOnApply: true,
	// 	},
	// 	cellDataType: "number",
	// },
];

function subscriptionIdRenderer(param) {
	const subscription = param.data;
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${subscription.subscriptionId}`;
	return (
		<>
			<span data-cy={`subscription-id-${subscription.subscriptionId}`}>{subscription.subscriptionId}</span>
		</>
	);
}

function subscriptionTypeRenderer(param) {
	const subscription = param.data;
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${subscription.discounttype}`;
	return (
		<>
			<span data-cy={`subscription-type-${subscription.discounttype}`}>{subscription.discounttype}</span>
		</>
	);
}

function subscriptionNameRenderer(param) {
	const subscription = param.data;
	const rowEl = param.eGridCell.offsetParent;
	rowEl.dataset.cy = `row-${subscription.subscriptionname}`;
	return (
		<>
			<span data-cy={`subscription-name-${subscription.subscriptionname}`}>{subscription.subscriptionname}</span>
		</>
	);
}

// function subscriptionNumberOfTimesRenderer(param) {
// 	console.log("params", param);
// 	const subscription = param.data;
// 	let subQty = subscription.subQty ? Number(subscription.subQty) : 1;
// 	if (subQty < 1 || isNaN(subQty)) {
// 		subQty = 1;
// 	}
// 	const rowEl = param.eGridCell.offsetParent;
// 	rowEl.dataset.cy = `row-${subQty}`;
// 	return <span data-cy={`subscription-name-${subQty}`}>{subQty}</span>;
// }

export default columnDefinitions;
