import cutIcon from "assets/proto_1/cut.png";
import pasteIcon from "assets/proto_1/paste.png";
import shareIcon from "assets/proto_1/share.png";
import deleteIcon from "assets/proto_1/delete.png";
import downloadIcon from "assets/proto_1/download.png";
import refreshIcon from "assets/proto_1/refresh.png";
import unshareIcon from "assets/proto_1/unshare.png";
// import searchIcon from "assets/proto_1/search.png";
import filterIcon from "assets/proto_1/filter.png";

const contextHeaderOptionsList = [
	// {
	// 	name: "Search",
	// 	type: "item",
	// 	icon: (
	// 		<img
	// 			src={searchIcon}
	// 			alt="search_icon"
	// 			className="context-icon"
	// 		></img>
	// 	),
	// 	action: "search",
	// 	href: "#",
	// },
	{
		name: "Cut",
		type: "item",
		icon: (
			<img
				src={cutIcon}
				alt="cut_icon"
				className="context-icon"
			></img>
		),
		action: "cut",
		href: "#",
	},
	{
		name: "Paste",
		type: "item",
		icon: (
			<img
				src={pasteIcon}
				alt="paste_icon"
				className="context-icon"
			></img>
		),
		action: "paste",
	},
	{
		name: "Share",
		type: "item",
		icon: (
			<img
				src={shareIcon}
				alt="share_icon"
				className="context-icon"
			></img>
		),
		action: "share",
	},
	{
		name: "Unshare",
		type: "item",
		icon: (
			<img
				src={unshareIcon}
				alt="delete_icon"
				className="context-icon"
			></img>
		),
		action: "unshare",
	},
	{
		name: "Download",
		type: "item",
		icon: (
			<img
				src={downloadIcon}
				alt="download_icon"
				className="context-icon"
			></img>
		),
		action: "download",
	},
	{
		name: "Delete",
		type: "item",
		icon: (
			<img
				src={deleteIcon}
				alt="delete_icon"
				className="context-icon"
			></img>
		),
		action: "delete",
	},
	{
		name: "Remove All Filters",
		type: "item",
		icon: (
			<img
				src={filterIcon}
				alt="filter_icon"
				className="context-icon"
			></img>
		),
		action: "removeAllFilters",
	},
	{
		name: "Refresh",
		type: "item",
		icon: (
			<img
				// src={refresh_icon}
				src={refreshIcon}
				alt="delete_icon"
				className="context-icon"
			></img>
		),
		action: "refresh",
	},
];

export default contextHeaderOptionsList;
