import React from "react";
import useStyles from "routes/TableStyles";
import { CssBaseline } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../dashboard/breadcrumb/SettingBreadcrumb";
import { GlobalRole } from "Constants";
import properties from "components/properties/ApplicationProps";
import SettingCard from "components/SettingCards";
import { cardData } from "components/SettingCards";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import { useSelector } from "react-redux";

const Settings = () => {
	const history = useHistory();
	const classes = useStyles();
	const { fontFamily, themeColor } = useContext(FontContext);
	const userAuthDetails = useSelector(({ authDetails }) => authDetails.data);

	const handleCreateGroupClick = () => {
		// Handle "Create Group" button click
		history.push("/creategroups");
	};

	const handleViewGroupsClick = () => {
		// Handle "My Groups" button click
		history.push("/mygroups");
	};
	const handleAccountLinkClick = () => {
		// Handle "My Groups" button click
		history.push("/linkaccount");
	};
	const handleViewChangeOwnership = () => {
		// Handle "My Groups" button click
		history.push("/changeownership");
		// setOpenDialog(true);
	};
	const handleCreateAccount = () => {
		history.push("/account");
	};
	const handleMakePayment = () => {
		history.push("/payment");
	};

	const handleDM = () => {
		history.push("/datamaintenance");
	};
	const handleOrgOnboard = () => {
		history.push("/orgonboard");
	};
	const handleUpdateOnboard = () => {
		history.push("/updateorg");
	};
	const handleUpdateGroup = () => {
		history.push("/updategroup");
	};
	const handleNewSubscriptionPlan = () => {
		history.push("/addsubscription");
	};

	const handleUpdateSubscriptionPlan = () => {
		history.push("/updatesubscription");
	};

	const handleChangeSubscription = () => {
		history.push("/changesubscription");
	};

	const handleDataUsage = () => {
		history.push("/datausage");
	};
	const handleDataUsageReport = () => {
		history.push("/generatedatausage");
	};
	const handleGenerateInvoice = () => {
		history.push("/generateinvoice");
	};
	const handleDomainManagement = () => {
		history.push("/dnsmanagement");
	};
	const handleAccountBucketCreation = () => {
		history.push("/accountbucketcreation");
	};
	function handleBreadCrumbClick(item) {
		console.log(item);
		// let crumbIndex = breadcrumbList.findIndex(
		//   (x) => x.locationid === item.locationid
		// );
		// console.log("breadcrumb clicked", item);
		// breadcrumbList.length = crumbIndex;
	}

	const handleUpdateAttribute = () => {
		history.push("/updateatrribute");
	};
	const handleCardClick = (title) => {
		switch (title) {
			case "Create Group":
				handleCreateGroupClick();
				console.log("Clicked on Create Group");
				break;

			case "Manage Groups":
				handleViewGroupsClick();
				console.log("Clicked on Manage Groups");
				break;

			case "Change Ownership":
				handleViewChangeOwnership();
				console.log("Clicked on Change Ownership");
				break;

			case "Link Accounts":
				handleAccountLinkClick();
				console.log("Clicked on Link Accounts");
				break;

			case "Payment":
				handleMakePayment();
				console.log("Clicked on Payment");
				break;

			case "Role-Based Access Control(RBAC)":
				handleDM();
				console.log("Clicked on User Role");
				break;

			case "User Onboard":
				//handleonboard();
				console.log("Clicked on Onboarding");
				break;

			case "Organisation Access Control":
				handleUpdateOnboard();
				break;

			case "Org Onboarding":
				handleOrgOnboard();
				break;

			case "Update Atrribute":
				handleUpdateAttribute();
				break;

			case "Update Group":
				handleUpdateGroup();
				break;

			case "Add Subscription":
				handleNewSubscriptionPlan();
				break;

			case "Update Subscription Plan":
				handleUpdateSubscriptionPlan();
				break;

			case "Change Subscription":
				handleChangeSubscription();
				break;

			case "Monitor Usage":
				handleDataUsage();
				break;

			case "Monitor Requests":
				handleDataUsageReport();
				break;

			case "Update Invoice":
				handleGenerateInvoice();
				break;

			case "Access Domain Management (DNS)":
				handleDomainManagement();
				break;

			case "Account-Bucket Management":
				handleAccountBucketCreation();
				break;

			default:
				// Handle the default case when the title doesn't match any specific case
				console.log("Clicked on an unknown title");
				break;
		}
	};

	const hiddenCardsForBilling = [
		"Change Ownership",
		"Link Accounts",
		"Organisation Access Control",
		"Role-Based Access Control(RBAC)",
		"Org Onboarding",
		"Update Atrribute",
		"Manage Account",
		"Add Subscription",
		"Update Subscription Plan",
		"Update Invoice",
		"Monitor Requests",
		"Change Subscription",
		"Access Domain Management (DNS)",
		"Account-Bucket Management",
	];
	const hiddenCardsForMember = [
		"Change Ownership",
		"Link Accounts",
		"Payment",
		"Update Atrribute",
		"Organisation Access Control",
		"Role-Based Access Control(RBAC)",
		"Org Onboarding",
		"Manage Account",
		"Add Subscription",
		"Monitor Usage",
		"Update Subscription Plan",
		"Monitor Requests",
		"Change Subscription",
		"Access Domain Management (DNS)",
		"Account-Bucket Management",
	];
	const hiddenCardsForUser = [
		"Manage Account",
		"Manage Groups",
		"Change Ownership",
		"Create Group",
		"Update Atrribute",
		"Link Accounts",
		"Role-Based Access Control(RBAC)",
		"Org Onboarding",
		"Add Subscription",
		"Monitor Usage",
		"Update Subscription Plan",
		"Monitor Requests",
		"Change Subscription",
		// "Organisation Access Control",
		"Access Domain Management (DNS)",
		"Account-Bucket Management",
	];
	const hiddenCardsForPayment = [
		"Payment",
		"Org Onboarding",
		"Update Atrribute",
		"Add Subscription",
		"Update Subscription Plan",
		"Update Invoice",
		"Monitor Requests",
		"Change Subscription",
		"Account-Bucket Management",
		"Manage Account",
	];
	const hiddenCardsForSystemadmin = [
		"Org Onboarding",
		"Update Atrribute",
		"Add Subscription",
		"Update Subscription Plan",
		"Update Invoice",
		"Monitor Requests",
		"Change Subscription",
		"Account-Bucket Management",
		"Manage Account",
	];
	const hiddenCardsForSupport = [];

	// const hiddenCardsForsadmin = ["Organisation Access Control"];
	//render read only page of org oboarding for sadmin

	// const isDisabled =
	// 	GlobalRole.userrole === "billing" || GlobalRole.userrole === "member" || GlobalRole.userrole === "user";
	// const isPayment = GlobalRole.userrole === "user" || GlobalRole.userrole === "member";

	return (
		<div
			style={{
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<React.Fragment>
				<CssBaseline />
				<Grid className={classes.breadcrumb}>
					<Grid
						item
						md={9}
						sm={12}
						xs={12}
						container
						alignItems="center"
						justify="center"
						style={{
							// Comment out maxWidth to restore previous looks for contextHeader
							maxWidth: "100%",
							height: "32px",
						}}
					>
						<BreadCrumb handleBreadCrumbClick={handleBreadCrumbClick} />
					</Grid>
				</Grid>
				<div
					className={classes.outercontainer}
					style={{ border: "1px solid #dddddd", borderRadius: 8 }}
				>
					<div className={classes.innercontainer}>
						<TableContainer className={classes.tablecontainer}>
							{cardData
								.filter((card) => {
									// Filter out cards based on user role
									if (
										(GlobalRole.userrole === properties.userRoles.billing &&
											hiddenCardsForBilling.includes(card.title)) ||
										(GlobalRole.userrole === properties.userRoles.member &&
											hiddenCardsForMember.includes(card.title)) ||
										(GlobalRole.userrole === properties.userRoles.user &&
											hiddenCardsForUser.includes(card.title)) ||
										(GlobalRole.userrole === properties.userRoles.user &&
											hiddenCardsForPayment.includes(card.title)) ||
										(GlobalRole.userrole === properties.userRoles.member &&
											hiddenCardsForPayment.includes(card.title)) ||
										((GlobalRole.userrole === properties.userRoles.member ||
											GlobalRole.userrole === properties.userRoles.systemadmin) &&
											hiddenCardsForSystemadmin.includes(card.title)) ||
										(GlobalRole.userrole === properties.userRoles.support &&
											hiddenCardsForSupport.includes(card.title))
									) {
										return false; // Hide cards for specific roles
									}
									return true; // Show all other cards
								})
								.map((card, index) => {
									// if (
									// 	card.title === "Organisation Access Control" &&
									// 	userAuthDetails.user_org.toUpperCase() === "DATAFRUGAL" &&
									// 	GlobalRole.userrole !== properties.userRoles.support
									// ) {
									// 	return null; // Do not render the card
									// }

									return (
										<Grid
											item
											xs={12}
											sm={6}
											md={3}
											id="text"
											// spacing={12}
											key={index}
											onClick={() => handleCardClick(card.title)}
											data-cy-card={card.testId}
											sx={{
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												margin: "1%",
												// marginLeft:"2%",
												rowSpacing: 100,
												columnGap: 10,
												display: "inline-block",
												fontFamily: fontFamily,

												// margin: "10px",
												// justifyContent: "space-between",
											}}
											// rowSpacing={200}
											// columnGap={50}
											// columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											// style={{marginLeft:"30px"}}
										>
											<SettingCard
												icon={card.icon}
												title={card.title}
												content={card.content}
												disabled={card.disabled}
												testId={card.testId}
											/>
										</Grid>
									);
								})}
						</TableContainer>
					</div>
				</div>
			</React.Fragment>
		</div>
	);
};

export default Settings;
