import properties from "components/properties/ApplicationProps";

export function getDeleteGroupJson(item) {
	let stringifyFetchResource = {
		header: {
			user: sessionStorage.getItem("username"),
			org: properties.orgProperties.orgName,
		},
		action: properties.actions.DELETE,
		// groupid: item.fields.groupid,
		orginalGroupName: item.fields.name,
		description: item.fields.description,
	};

	return stringifyFetchResource;
}
