import properties from "components/properties/ApplicationProps";

export function getUserDetailsJson() {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		role: properties.role.EDITOR,
		userName: sessionStorage.getItem("username"),
		firstName: sessionStorage.getItem("firstName"),
		lastName: sessionStorage.getItem("lastName"),
		email: sessionStorage.getItem("email"),
		active: properties.activeStatus.true,
		org: sessionStorage.getItem("kcRealm"),
		idpUserId: sessionStorage.getItem("idpUserId"),
		lastUpdateTime: Date.now(),
		event: properties.events.DOUSERONBOARD,
	};

	return stringifyFetchResource;
}
