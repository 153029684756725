import { CssBaseline, Grid, IconButton, TableContainer, Tooltip, Typography } from "@mui/material";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { FontContext } from "components/UI/Theme";
import useStyles from "routes/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { dashboardApi } from "components/misc/DashboardApi";
import Button from "@mui/material/Button";
import refreshIcon from "assets/proto_1/refresh.png";
import { neutral, textsize } from "assets/css/MainCss";
import { getLocaleDateFrom } from "utils/helpers/getLocaleDate";
import eventEmitter from "components/misc/eventEmitter";

import Notification from "components/dashboard/notification/Notification";

import "./Notifications.styles.css";
import { fetchUserNotificationsThunk } from "store/notificationsSlice";
import { getAcceptNotificationJSON, getRejectNotificationJSON } from "components/requestcontext/HandleNotification";
import properties from "components/properties/ApplicationProps";

const Notifications = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { fontFamily, themeColor, largeTextSize, smallTextSize } = useContext(FontContext);

	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const userNotifications = useSelector(({ notifications }) => notifications.data);

	useEffect(() => {
		dispatch(fetchUserNotificationsThunk());
		// eventEmitter.on("RefreshNotificationScreen", () => {
		// 	dispatch(fetchUserNotificationsThunk());
		// });
	}, [dispatch]);

	const fetchNotifications = useCallback(() => {
		dispatch(fetchUserNotificationsThunk());
	}, [dispatch]);

	const showSnackbarWithMessage = useCallback((message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	}, []);

	const acceptInviteButtonHandler = useCallback(
		async (notificationData) => {
			console.log("notificationData", notificationData);
			try {
				const acceptInviteJson = getAcceptNotificationJSON(notificationData);
				acceptInviteJson.action = properties.actions.ACCEPT_NOTIFICATION;
				const res = await dashboardApi.handleNotificationRequest(acceptInviteJson);

				if (res.data.footer.msg === "User's invite accepted successfully") {
					showSnackbarWithMessage("User's invite accepted successfully");
					fetchNotifications();
					// setTimeout(() => {
					// 	window.location.href = "/dashboard";
					// }, 3000);
				} else if (res.data.footer.code === 1 && res.data.footer.msg.includes("already has linked account")) {
					// showSnackbarWithMessage("Your account is already linked. [Redirecting]");
					// setTimeout(() => {
					// 	window.location.href = "/dashboard";
					// }, 2000);
				} else {
					showSnackbarWithMessage("Something went wrong!");
				}
			} catch (error) {
				console.log(error);
			}
		},
		[showSnackbarWithMessage],
	);

	const rejectInviteButtonHandler = useCallback(
		async (notificationData) => {
			try {
				const rejectInviteJson = getRejectNotificationJSON(notificationData);
				rejectInviteJson.action = properties.actions.REJECT_NOTIFICATION;
				const res = await dashboardApi.handleNotificationRequest(rejectInviteJson);

				if (res.data.footer.code === 0) {
					dispatch(fetchUserNotificationsThunk());
				} else {
					showSnackbarWithMessage("Something went wrong!");
				}
			} catch (error) {
				console.log(error);
			}
		},
		[showSnackbarWithMessage, dispatch],
	);

	const UserNotification = memo(({ notification }) => {
		const inviteTime = new Date(notification.lastupdatetime);
		const expiryTime = new Date(notification.validuntiltime);
		const isInviteExpired = new Date().getTime() >= new Date(expiryTime).getTime();
		const disableButtons = isInviteExpired;

		return (
			<div className="user-notification-container">
				<div>
					<div className="sect-1">
						<h3 className="notifications-type">{notification.notificationtype}</h3>

						<div className="notifications-dates-container">
							{!isInviteExpired ? (
								<span className="notifications-date">{`${
									getLocaleDateFrom(inviteTime).mmm_dd_yyyy
								} ${inviteTime.toLocaleString("en-US", {
									hour: "numeric",
									minute: "numeric",
									hour12: true,
								})} `}</span>
							) : (
								<span className="notifications-date notifications-expired">Expired !</span>
							)}
						</div>
					</div>

					<p
						className="notifications-message"
						style={{ fontSize: smallTextSize }}
					>
						Hi, a user with username &nbsp;
						<span className="notifications-inviter">{notification.lastupdateuser}</span> has sent you a
						group invitation to join as <span className="notifications-role">{notification.role}</span>.
						Please Click on the 'Accept' button to accept the invitation.
					</p>
				</div>

				<div className="notification-buttons-container">
					<Button
						type="submit"
						disabled={disableButtons}
						variant="contained"
						style={{
							backgroundColor: `${disableButtons ? neutral.buttondisable : neutral.buttoncolor}`,
							color: neutral.buttontextcolor,
							fontSize: textsize.buttontextsize,
							boxShadow: "unset",
							marginLeft: "4px",
							fontFamily,
						}}
						onClick={() => {
							console.log("Calling Invite Accept");

							acceptInviteButtonHandler(notification);
						}}
						data-button-name="accept-notification"
					>
						Accept
					</Button>

					<Button
						type="button"
						disabled={disableButtons}
						variant="contained"
						style={{
							backgroundColor: `${disableButtons ? neutral.buttondisable : neutral.buttonDanger}`,
							color: neutral.buttontextcolor,
							fontSize: textsize.buttontextsize,
							boxShadow: "unset",
							marginLeft: "4px",
							fontFamily: fontFamily,
						}}
						onClick={() => {
							rejectInviteButtonHandler(notification);
						}}
					>
						Reject
					</Button>
				</div>
			</div>
		);
	});

	const NoNotifications = memo(() => (
		<div
			style={{
				display: "grid",
				placeItems: "center",
				height: "100%",
				fontSize: smallTextSize,
			}}
		>
			<p>You don't have any notifications.</p>
		</div>
	));

	return (
		<div
			style={{
				position: "relative",
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
				fontFamily,
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				>
					<div className="notifications-context">
						<Typography style={{ fontSize: largeTextSize, fontFamily }}>Notifications</Typography>

						<Tooltip title="Refresh Notifications">
							<IconButton
								aria-haspopup="true"
								color="inherit"
								className={classes.headerMenuProfileButton}
								aria-controls="profile-menu"
								onClick={() => dispatch(fetchUserNotificationsThunk())}
							>
								<img
									src={refreshIcon}
									alt="profile_icon"
									className="notifications-refresh"
								/>
							</IconButton>
						</Tooltip>
					</div>
				</Grid>
			</Grid>
			<div
				className={classes.outercontainer}
				style={{ border: "1px solid #dddddd", borderRadius: 8 }}
			>
				<div className={classes.innercontainer}>
					<TableContainer className={`${classes.tablecontainer} notifications-container`}>
						{userNotifications.length ? (
							userNotifications.map((notification) => (
								<UserNotification
									key={notification.id}
									notification={notification}
								/>
							))
						) : (
							<NoNotifications />
						)}
					</TableContainer>
				</div>
			</div>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
				messageLength={50}
			/>
		</div>
	);
};

export default Notifications;
