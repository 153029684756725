import { FontContext } from "components/UI/Theme";
import "./FormContainer.styles.css";
import { useCallback, useContext, useEffect } from "react";

const FormContainer = ({ children }) => {
	const { largeTextSize } = useContext(FontContext);

	const changeFontSize = useCallback(() => {
		const pageTitle = document.querySelector("h1");

		if (largeTextSize === 16) {
			pageTitle.style.fontSize = "28px";
		} else {
			pageTitle.style.fontSize = "24px";
		}
	}, [largeTextSize]);

	useEffect(() => {
		changeFontSize();
	}, [changeFontSize]);

	return <div className="form-container">{children}</div>;
};

export default FormContainer;
