import properties from "components/properties/ApplicationProps";

export function getFetchGroupJson(searchTerm) {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		action: properties.actions.SEARCH,
		searchField: searchTerm || null,
	};

	return stringifyFetchResource;
}
