import React, { useCallback, useState, useEffect, useRef, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "react-select";
import { getSearchUserJson } from "components/requestcontext/SearchUser";
import { dashboardApi } from "components/misc/DashboardApi";
import { getupdateUsersInGroupJson } from "components/requestcontext/UpdateUsersInGroup";
import useStyles from "routes/TableStyles";
import Notification from "../notification/Notification";
import { neutral, textsize } from "assets/css/MainCss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { getFetchUsersInGroup } from "components/requestcontext/FetchUsersInGroup";
import { FontContext } from "../../UI/Theme";

export default function UpdateGroupDialog({ updateGroupDialogOpen, handleCloseUpdateGroupAction, selected }) {
	const groupItem = selected[0];
	const classes = useStyles();
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [error, setError] = useState(null);

	const history = useHistory();
	const selectRef = useRef();
	const { fontFamily, themeColor, largeTextSize } = useContext(FontContext);

	const { handleSubmit, control } = useForm({});

	useEffect(() => {
		if (updateGroupDialogOpen) {
			fetchUsersInGroup();
		}
	}, [updateGroupDialogOpen]);

	async function fetchUsersInGroup() {
		const fetchGroupUsersResponse = await dashboardApi.fetchUsersInGroup(
			getFetchUsersInGroup(groupItem.fields.name),
		);

		if (fetchGroupUsersResponse?.data?.resourcePropertiesList) {
			const usersInGroup = fetchGroupUsersResponse.data.resourcePropertiesList.map((groupdata) => ({
				value: groupdata.fields.email,
				label: groupdata.fields.email,
				firstname: groupdata.fields.firstname,
				lastname: groupdata.fields.lastname,
			}));
			setSelectedUsers([...usersInGroup]);
			setError(null);
		} else {
			setError("No users are added in group");
			setSelectedUsers([]);
		}
	}

	async function handleUpdateGroupUsers() {
		if (selectedUsers.length === 0) {
			setSnackbarMessage("No users selected. Please add users to the group.");
			setShowSnackbar(true);
			return;
		}

		const userNames = selectedUsers.map((user) => user.value);
		const groupId = groupItem.fields.groupid;

		try {
			const updateGroupUsersResponse = await dashboardApi.updateUsersInGroup(
				getupdateUsersInGroupJson(groupId, userNames),
			);

			if (updateGroupUsersResponse.data.footer.msg === "SUCCESS") {
				setSnackbarMessage("Users updated successfully in the group");
				setShowSnackbar(true);
				handleCloseUpdateGroupAction(); // Close the dialog
			} else {
				setSnackbarMessage("Failed to update users in the group");
				setShowSnackbar(true);
			}
		} catch (error) {
			console.error("An error occurred while updating users in the group:", error);
			setSnackbarMessage("An error occurred while updating users in the group");
			setShowSnackbar(true);
		}
	}

	const searchUsers = useCallback(async () => {
		const min_search_chars = 3;

		if (searchTerm.length >= min_search_chars) {
			try {
				setIsLoading(true);
				let userResourceJson = getSearchUserJson(searchTerm);
				let searchuserResponse = await dashboardApi.searchAccountUsers(userResourceJson);
				const fileItems = searchuserResponse.data.resourcePropertiesList.map((item) => {
					return {
						value: item.fields.email,
						label: `(${item.fields.email})`,
						avatar: item.fields.avatarUrl,
						userid: item.fields.userid,
					};
				});
				setOptions(fileItems);
			} catch (error) {
				console.error("An error occurred while searching for users:", error);
				setOptions([]);
			} finally {
				setIsLoading(false);
			}
		}
	}, [searchTerm]);

	useEffect(() => {
		if (searchTerm.trim() !== "") {
			searchUsers();
		} else {
			setOptions([]);
		}
	}, [searchTerm, searchUsers]);

	function handleInputChange(newValue) {
		setSearchTerm(newValue);
	}

	function handleChange(selected) {
		setSelectedUsers(selected);
	}

	return (
		<div>
			<Dialog
				open={updateGroupDialogOpen}
				onClose={handleCloseUpdateGroupAction}
				fullWidth
				maxWidth="xs"
			>
				<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
					Update Users in Group
				</DialogTitle>
				<DialogContent>
					<form onSubmit={handleSubmit(handleUpdateGroupUsers)}>
						<Controller
							control={control}
							name="newFileName"
							render={({ field }) => (
								<Select
									isMulti
									fullWidth
									className="input-field"
									ref={selectRef}
									id="searchUsersGroup"
									margin="normal"
									options={options}
									value={selectedUsers}
									onChange={handleChange}
									onInputChange={handleInputChange}
									isLoading={isLoading}
									label="Add users to group"
									noOptionsMessage={() => "No results found"}
									styles={{
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										control: (provided) => ({
											...provided,
											marginTop: "5px",
											fontFamily: fontFamily,
										}),
									}}
									formatOptionLabel={(option) => (
										<div style={{ display: "flex", alignItems: "center", fontFamily: fontFamily }}>
											<div>{option.label}</div>
										</div>
									)}
									InputLabelProps={{ style: { fontFamily: fontFamily } }}
									InputProps={{ style: { fontFamily: fontFamily } }}
								/>
							)}
						/>

						<DialogActions
							style={{
								marginRight: "-8px",
								paddingBottom: "10px",
								marginTop: "0.5rem",
							}}
						>
							<Button
								onClick={handleCloseUpdateGroupAction}
								variant="outlined"
								color="primary"
								style={{
									color: neutral.buttoncolor,
									borderColor: neutral.buttoncolor,
									fontSize: textsize.buttontextsize,
									fontFamily: fontFamily,
								}}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="contained"
								style={{
									backgroundColor: neutral.buttoncolor,
									color: neutral.buttontextcolor,
									fontSize: textsize.buttontextsize,
									fontFamily: fontFamily,
								}}
								data-button-name="Rename"
							>
								Submit
							</Button>
						</DialogActions>
					</form>
				</DialogContent>
			</Dialog>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
}
