import { dashboardApi } from "components/misc/DashboardApi";
import { getFetchResourceJson } from "components/requestcontext/FetchResource";
import addDownloadTrackingProperties from "./addDownloadTrackingProperties";
import { throttle as _throttle } from "lodash";
import numeral from "numeral";
import axios from "axios";
import {
	ADD_TO_NOTIFICATIONS,
	DEFAULT_STATS_FOR_NOTIFICATIONS,
	SHOW_NOTIFICATION_PANEL_FORCEFULLY,
} from "store/uploadStatsSlice";
import { START_DOWNLOAD, END_DOWNLOAD } from "store/fileDownloadSlice";
import { useDispatch } from "react-redux";

const downloadFile = async (resource, dispatch) => {
	console.log("Dispatching START_DOWNLOAD");
	dispatch(START_DOWNLOAD());
	console.log("Download started");

	const file = resource.hasOwnProperty("fields") ? resource.fields : resource;

	try {
		const fetchResourceJson = getFetchResourceJson(file);
		const resource = await dashboardApi.fetchResource(fetchResourceJson);
		console.log("Response of fetch file:", resource);
		const url = resource.data.endpoint.endpoint;

		const downloadingFile = {};
		const abortController = new AbortController();
		const stopUploading = () => {
			if (downloadingFile.isDownloaded) {
				return;
			}

			abortController.abort();
			Object.defineProperty(downloadingFile, "isDownloadStopped", { value: true });
			Object.defineProperty(downloadingFile, "isDownloaded", { value: false });
			Object.defineProperty(downloadingFile, "isDownloading", { value: false });
			Object.defineProperty(downloadingFile, "downloadCompletePercentageNumber", { value: 0 });
			Object.defineProperty(downloadingFile, "downloadSpeed", { value: 0 });
			Object.defineProperty(downloadingFile, "etc", { value: 0 });

			dispatch(ADD_TO_NOTIFICATIONS(downloadingFile));
		};

		// adds some properties to track downloading
		addDownloadTrackingProperties({ file: downloadingFile, resourceDetails: file, stopUploading });

		dispatch(DEFAULT_STATS_FOR_NOTIFICATIONS(downloadingFile));
		dispatch(SHOW_NOTIFICATION_PANEL_FORCEFULLY());

		const downloadStartTime = Math.floor(Date.now() / 1000);
		Object.defineProperty(downloadingFile, "didItTryToDownload", { value: true });
		Object.defineProperty(downloadingFile, "isDownloading", { value: true });

		const updateDownloadStats = _throttle(
			(progressEvent) => {
				const downloadSpeedPerSecInKB = Math.floor(progressEvent.rate);
				const downloadCompleted = progressEvent.loaded;
				const downloadCompletePercentageNumber = Math.floor((downloadCompleted / progressEvent.total) * 100);
				const downloadCompletePercentage = numeral(
					((downloadCompleted / progressEvent.total) * 100) / 100,
				).format("0 %");
				const downloadSpeed = numeral(downloadSpeedPerSecInKB).format("0.00b");
				const remainingdownload = progressEvent.total - downloadCompleted;
				const etc = Math.ceil(remainingdownload / downloadSpeedPerSecInKB);

				Object.defineProperty(downloadingFile, "error", { value: false });
				Object.defineProperty(downloadingFile, "etc", { value: etc });
				Object.defineProperty(downloadingFile, "downloadSpeed", { value: downloadSpeed });
				Object.defineProperty(downloadingFile, "downloadSpeedInKB", { value: downloadSpeedPerSecInKB });
				Object.defineProperty(downloadingFile, "downloadPercentage", {
					value: downloadCompletePercentage,
				});
				Object.defineProperty(downloadingFile, "downloadCompletePercentageNumber", {
					value: downloadCompletePercentageNumber,
				});

				dispatch(ADD_TO_NOTIFICATIONS(downloadingFile));
			},
			1000,
			{ trailing: false },
		);

		const downloadedBlob = await axios({
			signal: abortController.signal,
			method: "GET",
			url,
			headers: {
				"Content-Disposition": `attachment; filename="${file.filename}"`,
			},
			responseType: "blob",
			onDownloadProgress: (progressEvent) => {
				updateDownloadStats(progressEvent);
			},
		})
			.then((response) => {
				Object.defineProperty(downloadingFile, "isDownloaded", { value: true });
				Object.defineProperty(downloadingFile, "isDownloading", { value: false });
				Object.defineProperty(downloadingFile, "error", { value: false });
				Object.defineProperty(downloadingFile, "errorMssg", { value: "" });
				Object.defineProperty(downloadingFile, "errorCode", { value: 0 });
				Object.defineProperty(downloadingFile, "downloadCompletePercentageNumber", { value: 100 });
				Object.defineProperty(downloadingFile, "downloadSpeed", { value: 0 });

				updateDownloadStats.cancel();
				dispatch(ADD_TO_NOTIFICATIONS(downloadingFile));

				console.log(`[${new Date().toLocaleTimeString()}]Axios Res`, response);

				return response.data;
			})
			.catch((error) => {
				console.log(error);
				const { code } = error;

				updateDownloadStats.cancel();

				if (code === "ERR_NETWORK") {
					Object.defineProperty(downloadingFile, "isDownloaded", { value: false });
					Object.defineProperty(downloadingFile, "isDownloading", { value: false });
					Object.defineProperty(downloadingFile, "downloadCompletePercentageNumber", { value: 0 });
					Object.defineProperty(downloadingFile, "downloadSpeed", { value: 0 });
					Object.defineProperty(downloadingFile, "error", { value: true });
					Object.defineProperty(downloadingFile, "errorCode", { value: code });
					Object.defineProperty(downloadingFile, "errorMssg", {
						value: `[${code}]Failed To Download`,
					});

					dispatch(ADD_TO_NOTIFICATIONS(downloadingFile));
				}
			});

		const downloadEndTime = Math.floor(Date.now() / 1000);
		const totalTimeTakenForDownload = downloadEndTime - downloadStartTime;
		Object.defineProperty(downloadingFile, "completionTime", { value: totalTimeTakenForDownload });

		dispatch(ADD_TO_NOTIFICATIONS(downloadingFile));
		dispatch(END_DOWNLOAD());
		console.log("Dispatching END_DOWNLOAD");
		console.log("Download completed");

		return downloadedBlob;
	} catch (error) {
		console.log(error);
		dispatch(END_DOWNLOAD());
	}
};

export default downloadFile;
