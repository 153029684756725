import dayjs from "dayjs";

/**
 * @param {Date} date Date Object
 * @returns formatted date with locale: `en-GB`, month: `short` and day: `numeric`
 */
export const getFormattedDate = (date) => date.toLocaleDateString("en-GB", { month: "short", day: "numeric" });

export const dateRangeSelector = ({ rangeSelection, dayjsStartDate, dayjsEndDate }) => {
	let rangeStartDate;
	let rangeEndDate;

	if (rangeSelection === "billing") {
		const date = new Date();
		rangeStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
		rangeEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	}

	// startDate and endDate for current month is First Date and Last Date of Month respectively
	if (rangeSelection === "currentMonth") {
		const date = new Date();
		rangeStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
		rangeEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	}

	// last 1 day from today's date
	if (rangeSelection === "today") {
		const date = new Date();
		rangeStartDate = new Date(date.setDate(date.getDate() - 1));
		rangeEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
	}

	// last 7 days from today's date
	if (rangeSelection === "week") {
		const date = new Date();
		rangeStartDate = new Date(date.setDate(date.getDate() - 7));
		rangeEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
	}

	// last 30 days from today's date
	if (rangeSelection === "month") {
		const date = new Date();
		rangeStartDate = new Date(date.setDate(date.getDate() - 30));
		rangeEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
	}

	if (rangeSelection === "custom") {
		// dayjs Date object is different than native Date object
		// converting to native Date Object
		const startDate = dayjs(dayjsStartDate).toDate();
		const endDate = dayjs(dayjsEndDate).toDate();
		rangeStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
		rangeEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
	}

	if (rangeSelection === "") {
		rangeStartDate = new Date(0);
		rangeEndDate = new Date(0);
	}

	return { rangeStartDate, rangeEndDate };
};
