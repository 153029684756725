import { Route } from "react-router-dom";
import { BrowserRouter as Redirect } from "react-router-dom";
import { roles } from "components/settings/rolesAndPermissions";

// Check if the user has permission to access a specific feature
const hasPermission = (userRole, feature) => {
	return roles[userRole].includes(feature);
};

// Custom Route component to handle permissions
const ProtectedRoute = ({ component: Component, userRole, feature, ...rest }) => {
	console.log(userRole, feature, { ...rest });
	console.log("PROTECTED ROUTES::", userRole, feature);
	return (
		<Route
			{...rest}
			render={(props) =>
				hasPermission(userRole, feature) ? <Component {...props} /> : <Redirect to="/accessdenied" />
			}
		/>
	);
};
export default ProtectedRoute;
