export function getUnShareResource(resourceid) {
	let stringifyOrgPropsJson = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		resourceId: resourceid,
	};

	return stringifyOrgPropsJson;
}
