import { useContext } from "react";
import { Button as MaterialUIButton, CircularProgress } from "@mui/material";
import { FontContext } from "components/UI/Theme";
import { neutral } from "assets/css/MainCss";

const Button = ({
	buttonType = "default",
	disabled = false,
	variant = "outlined",
	isLoading = false,
	children = "Button",
	...otherProps
}) => {
	const { fontFamily } = useContext(FontContext);
	const allowedButtonTypes = ["default", "main"];

	if (!allowedButtonTypes.includes(buttonType)) {
		throw Error(
			`Invalid data was passed to buttonType prop: '${buttonType}'. Must be one of ${JSON.stringify(
				allowedButtonTypes,
			)}`,
		);
	}

	const buttonStyle = { fontFamily, minWidth: 100, width: "fit-content", height: 37 };

	if (buttonType === "default") {
		buttonStyle.color = neutral.buttoncolor;
		buttonStyle.backgroundColor = "#fff";
	}

	if (buttonType === "main") {
		buttonStyle.color = "#fff";
		buttonStyle.backgroundColor = neutral.buttoncolor;
	}

	if (buttonType === "main" && isLoading) {
		buttonStyle.backgroundColor = "#0000001f";
	}

	return (
		<MaterialUIButton
			variant={variant}
			style={{ ...buttonStyle }}
			disabled={isLoading || disabled}
			{...otherProps}
		>
			{isLoading ? <CircularProgress size={15} /> : children}
		</MaterialUIButton>
	);
};

export default Button;
