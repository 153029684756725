import { appConfig } from "../Constants";
import properties from "components/properties/ApplicationProps";


class GlobalsettingService {
	token = "";
	setToken(token) {
		this.token = token;
	}
}

export const GlobalSetting = new GlobalsettingService();

class Registry {
	instances = {};

	getInstance(uuid) {
		return this.instances[uuid];
	}

	registerInstance(uuid, instance) {
		this.instances[uuid] = instance;
	}
}

export const registry = new Registry();
