import React from "react";
import Typography from "@mui/material/Typography";

const CustomTypography = ({ style = {}, children, ...props }) => {
	return (
		<Typography
			style={style}
			{...props}
		>
			{children}
		</Typography>
	);
};

export default CustomTypography;
