import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import Alert from "@mui/material/Alert";
import { ExpandLess } from "@material-ui/icons";
import { Collapse, IconButton, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "routes/TableStyles";
import { Grid, InputAdornment, ToggleButton, ToggleButtonGroup } from "@mui/material";

import Switch from "@mui/material/Switch";

import { dashboardApi } from "components/misc/DashboardApi";

import { FontContext } from "components/UI/Theme";
import { GlobalRole } from "Constants";
import Notification from "../notification/Notification";
import fetchOrgNameFromUserEmail from "./fetchOrgName";
import { getSetupOrgJson, getUpdateOrgJson } from "components/requestcontext/UpdateOrg";

import properties from "components/properties/ApplicationProps";
import columnDefinitions from "./gridIpAddress/columnDefinations";
import { isValidIpAddress } from "./gridIpAddress/validateIpAddress";
import IpAddressGrid from "./gridIpAddress/IpAddressGrid";
import debounce from "lodash/debounce";
import { getFetchRegionJson } from "components/requestcontext/FetchRegion";
import { handleGenerateKey } from "components/misc/generateUniqueKey";
import { CopyAll as CopyAllIcon } from "@mui/icons-material";
import { neutral } from "assets/css/MainCss";
import "react-phone-number-input/style.css";

import "react-phone-number-input/style.css";
import { getFetchAccountDetails } from "components/requestcontext/FetchAccountDetails";

import CustomButton from "components/CustomComponents/Button";
import CustomSelect from "components/CustomComponents/Selector";
import CustomTextField from "components/CustomComponents/TextField";
import { getOrgAndAccountDetailsJSON } from "components/requestcontext/FetchOrgandAccountsDetails";

export default function OrgOnboarding() {
	const extractedOrgName = fetchOrgNameFromUserEmail();
	console.log("extractedOrgName", extractedOrgName);
	const history = useHistory();
	const { fontFamily } = useContext(FontContext);
	const [options, setOptions] = useState([]);
	const [, setSearchTerm] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [showEndPointAlert, setShowEndPointAlert] = useState(false);
	const [endpointExpirationTime, setEndpointExpirationTime] = useState(1);
	const [selectedBucketType, setSelectedBucketType] = useState("");
	const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
	const [orgkey, setOrgKey] = useState("");
	const [orgshortname, setorgshortname] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [loader, setLoader] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState([]);
	const [regionOptions, setRegionOptions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState(null);

	const [selectedIP, setSelectedIP] = useState(null);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const classes = useStyles();
	const assignBucketType = useRef();
	const adminRef = useRef();
	const endpointExpirationTimeRef = useRef();
	const label = { inputProps: { "aria-label": "Size switch demo" } };
	const userAuthDetails = useSelector(({ authDetails }) => authDetails.data);
	const [enableAutoOnboarding, setEnableAutoOnboarding] = useState(true);
	const [enableEncryption, setEnableEncryption] = useState(true);
	const [bucketOptions, setBucketOptions] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [newIpAddress, setNewIpAddress] = useState("");
	const [orgcreation, setOrgcreation] = useState(true);
	const [updateOrgDetails, setUpdateOrgDetails] = useState(false);
	const [checked, setChecked] = useState(false);
	const [colDefs] = useState(columnDefinitions);
	//account details
	const [gstno, setGstNo] = useState("");
	const [contactNo, setContactNo] = useState("");
	const [additionalEmail, setAdditionalEmail] = useState("");
	const [orgRootName, setOrgRootName] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const [plans, setplans] = useState([]);
	const [selectedplans, setselectedplans] = useState("");
	const [selectedplanKey, setselectedplanKey] = useState("");
	const [accountid, setaccountid] = useState("");
	const [isEditable, setIsEditable] = useState(true);
	const handleGstKeyDown = (event) => {
		if (event.key === "Enter") {
			console.log("do validate");
			document.getElementById("accountname").focus();
		}
	};

	const handleGenerateKeyClick = () => {
		handleGenerateKey(setOrgKey);
		setIsFocused(false);
	};

	console.log("enableAutoOnboarding", enableAutoOnboarding);
	function handleInputChange(newValue) {
		setSearchTerm(newValue);
	}

	const bucketType = [
		{ label: "STANDARD(RECOMMENDED)", value: "STANDARD" },
		{ label: "INTELLIGENT", value: "INTELLIGENT" },
	];

	const defaultBucketType = [{ label: "STANDARD", value: "STANDARD" }];

	function handleSelectBucket(selected) {
		setSelectedBucketType(selected.value);
		setShowAlert(true);
	}

	function handleChangePlans(selected) {
		console.log("Selected", selected);
		setselectedplans(selected);
		setselectedplanKey(selected.key);

		// setMenuOpen2(false);
	}

	function handleCloseOrgOnboard() {
		history.push("./settings");
	}

	const fetchOrgNAccountDetails = useCallback(async () => {
		try {
			console.log("Fetching organization details...");

			// Pass accountid to the request JSON
			let orgDetailsJson = getOrgAndAccountDetailsJSON(accountid);

			let orgDetailsResponse = await dashboardApi.fetchOrgNAccountDetails(orgDetailsJson);
			console.log("Response fetch org details:", orgDetailsResponse);

			if (orgDetailsResponse.data && orgDetailsResponse.data.resourcePropertiesList) {
				let orgDetails = orgDetailsResponse.data.resourcePropertiesList;

				const optionsData = orgDetails.map((org) => ({
					label: org.fields.orgshortname,
					value: org.fields.orgshortname,
				}));

				setOptions(optionsData);

				if (orgDetails.length === 0) {
					setUpdateOrgDetails(false);
					setOrgcreation(true);
				} else {
					const regionValue = orgDetails[0].fields.region;
					console.log(regionValue);
					setSelectedRegion(() => ({
						label: regionValue,
						value: regionValue,
					}));

					// console.log("Selected Region2:", regionValue);
					setOrgcreation(false);
					setUpdateOrgDetails(true);
					setChecked(orgDetails[0].fields.versioned);
					setEnableAutoOnboarding(orgDetails[0].fields.isautoonboardenabled);
					setOrgRootName(orgDetails[0].fields.displayname);
					setEndpointExpirationTime(orgDetails[0].fields.endpointexpirationtime);
					setGstNo(orgDetails[0].fields.gstno);
					setContactNo(orgDetails[0].fields.contactnumber);
					setAdditionalEmail(orgDetails[0].fields.contactemail);
					setselectedplans(() => orgDetails[0].fields.plantype);

					if (GlobalRole.userrole === properties.userRoles.support) {
						setorgshortname("");
					}

					if (GlobalRole.userrole === properties.userRoles.user || properties.userRoles.systemadmin) {
						const isDFOrgPresent = orgDetails[0].fields.orgrootname;
						const isMasterOrgPresent = orgDetails[0].fields.orgrootname;
						if (isDFOrgPresent === properties.orgProperties.orgName || sessionStorage.getItem("user_org")) {
							setIsEditable(false);
						}
						if (isMasterOrgPresent === properties.masterOrgProperties.orgname) {
							setOrgRootName("");
						}
					}

					const whitelistedIps = orgDetails[0].fields.whitelistedips;
					if (!whitelistedIps || whitelistedIps.trim() === "") {
						setSelectedIP([]);
					} else {
						setSelectedIP(whitelistedIps.split(","));
					}
				}
			} else {
				throw new Error("Invalid response structure");
			}
		} catch (error) {
			console.error("Error fetching organization details:", error);
			showSnackbarWithMessage("Failure while fetching organization details");
		}
	}, [accountid]);

	const fetchAccountDetails = useCallback(async () => {
		try {
			const accountDetailsJson = getFetchAccountDetails();
			const accountDetailsResponse = await dashboardApi.fetchAccountDetailsOfUser(accountDetailsJson);
			const accountDetails = accountDetailsResponse.data.resourcePropertiesList;

			// Check if resourcePropertiesList is empty
			if (accountDetails.length > 0) {
				setaccountid(accountDetails[0].fields.accountid);
			} else {
				console.log("No account details found. Skipping setting account ID.");
			}

			const response = await dashboardApi.getAllPlans();
			const transformedArray = response.data.filter((item) => {
				return item.subscriptionkey === selectedplans;
			});

			console.log("transformed array length:", transformedArray.length);

			// Set all plans regardless of the transformed array length
			setplans(() =>
				response.data.map((item) => ({
					value: item.subscriptionname,
					label: item.subscriptionname,
					key: item.subscriptionkey,
				})),
			);

			// If transformedArray has items, set selected plans; otherwise, handle the empty case
			if (transformedArray.length > 0) {
				setselectedplans(() => ({
					value: transformedArray[0].subscriptionname,
					label: transformedArray[0].subscriptionname,
					key: transformedArray[0].subscriptionkey,
				}));
				setselectedplanKey(() => transformedArray[0].subscriptionkey);
			} else {
				// Optionally handle the case where transformedArray is empty
				console.log("No matching subscription found for the selected key.");
				// You can set a default selected plan or leave it as is
			}
		} catch (error) {
			console.log(error);
		}
	}, [selectedplans]);

	useEffect(() => {
		fetchRegions();
	}, []);

	console.log("Plans: ", plans);

	const addIpAddress = () => {
		const trimmedIpAddress = newIpAddress.trim();
		if (trimmedIpAddress !== "" && isValidIpAddress(trimmedIpAddress)) {
			if (Array.isArray(selectedIP) && selectedIP.includes(trimmedIpAddress)) {
				showSnackbarWithMessage("IP already exists");
			} else {
				setSelectedIP((prevList) =>
					Array.isArray(prevList) ? [...prevList, trimmedIpAddress] : [trimmedIpAddress],
				);
				setNewIpAddress("");
			}
		} else {
			console.log("Invalid IP address:", newIpAddress);
		}
	};

	const removeIpAddress = (ipToRemove) => {
		// Filter out the IP address to be removed
		const updatedIPs = selectedIP.filter((ip) => ip !== ipToRemove);
		setSelectedIP(updatedIPs);
	};

	async function fetchRegions() {
		try {
			console.log("Fetching organization details...");
			let fetchRegionJson = getFetchRegionJson();

			let fetchRegionResponse = await dashboardApi.getAllRegions(fetchRegionJson);

			if (fetchRegionResponse.data && fetchRegionResponse.data.resourcePropertiesList) {
				let RedionDetails = fetchRegionResponse.data.resourcePropertiesList;

				const optionsData = RedionDetails.map((region) => ({
					label: region.fields.region,
					value: region.fields.region,
				}));

				setRegionOptions(() => optionsData);
			} else {
				throw new Error("Invalid response structure");
			}
		} catch (error) {
			console.error("Error fetching regions:", error);
			showSnackbarWithMessage("Failure while fetching regions");
		}
	}

	const handleSetupOrg = async (e) => {
		e.preventDefault(); // Prevent the default form submission
		setButtonLoading(true);

		try {
			const formData = {
				admin: sessionStorage.getItem("username"),
				// orgName: GlobalRole.userrole === "support" ? orgshortname : orgname,
				orgName: GlobalRole.userrole === properties.userRoles.support ? selectedOrg.value : extractedOrgName,
				orgRootName: GlobalRole.userrole === properties.userRoles.support ? selectedOrg.value : orgRootName,
				orgKey: orgkey,
				endpointExpirationTime,
				bucketType: selectedBucketType,
				orgVersioning: checked,
				whitelistedIps: selectedIP ? selectedIP.join(",") : "",
				autoOnBoardingEnabled: enableAutoOnboarding,
				region: selectedRegion.value,
				encrypted: enableEncryption,
				subscriptionKey: selectedplanKey,
				gstNo: gstno,
				contactNumber: contactNo,
				contactEmail: additionalEmail,
			};

			const orgOnboardJson = getSetupOrgJson(formData);
			console.log("OrgOnboardJson", orgOnboardJson);

			const orgOnboardResponse = await dashboardApi.orgEvents(orgOnboardJson);
			console.log(orgOnboardResponse);

			if (orgOnboardResponse.data.footer.code === 0) {
				const message = orgOnboardResponse.data.footer.msg;
				showSnackbarWithMessage(message);

				if (message === "SUCCESS") {
					showSnackbarWithMessage("Org set up successful. [Redirecting...]");
					setTimeout(() => {
						window.location.href = "/dashboard";
					}, 1000);
				} else if (message === "Onboarding in Progress") {
					setTimeout(() => {
						window.location.href = "/settings";
					}, 1000);
				}
			} else {
				showSnackbarWithMessage(orgOnboardResponse.data.footer.msg);
			}
		} catch (err) {
			console.log("Error:", err);
		} finally {
			setButtonLoading(false);
			setSelectedBucketType("");
			setShowAlert(false);
		}
	};

	const handleUpdateOrg = useCallback(
		async (e) => {
			e.preventDefault(); // Prevent the default form submission
			setButtonLoading(true);

			try {
				const formData = {
					admin: sessionStorage.getItem("username"),
					accountid: accountid,
					// orgName: GlobalRole.userrole === "support" ? orgshortname : orgname,
					orgName:
						GlobalRole.userrole === properties.userRoles.support ? selectedOrg.value : extractedOrgName,
					orgRootName: GlobalRole.userrole === properties.userRoles.support ? selectedOrg.value : orgRootName,
					orgKey: orgkey,
					endpointExpirationTime,
					bucketType: selectedBucketType,
					orgVersioning: checked,
					whitelistedIps: selectedIP ? selectedIP.join(",") : "",
					autoOnBoardingEnabled: enableAutoOnboarding,
					region: selectedRegion.value,
					encrypted: enableEncryption,
					subscriptionKey: selectedplanKey,
					gstNo: gstno,
					contactNumber: contactNo,
					contactEmail: additionalEmail,
				};

				const orgOnboardJson = getUpdateOrgJson(formData);
				console.log("OrgOnboardJson", orgOnboardJson);

				const orgOnboardResponse = await dashboardApi.orgEvents(orgOnboardJson);
				console.log(orgOnboardResponse);

				if (orgOnboardResponse.data.footer.code === 0) {
					const message = orgOnboardResponse.data.footer.msg;
					showSnackbarWithMessage(message);

					if (message === "SUCCESS") {
						showSnackbarWithMessage("Org Updated successfully");
					}
				} else {
					showSnackbarWithMessage(orgOnboardResponse.data.footer.msg);
				}
			} catch (err) {
				console.log("Error:", err);
			} finally {
				setButtonLoading(false);
				setSelectedBucketType("");
				setShowAlert(false);
			}
		},
		[
			accountid,
			additionalEmail,
			selectedplanKey,
			checked,
			contactNo,
			enableAutoOnboarding,
			enableEncryption,
			endpointExpirationTime,
			extractedOrgName,
			gstno,
			orgRootName,
			orgkey,
			selectedBucketType,
			selectedIP,
			selectedOrg,
			selectedRegion,
		],
	);

	const handleAdvancedClick = () => {
		setShowAdvancedOptions(!showAdvancedOptions);
	};

	const focusBucketSelector = () => {
		if (assignBucketType.current) {
			assignBucketType.current.focus();
			setBucketOptions(true);
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			console.log("do validate");
			// document.getElementById("GrpDesc").focus();
			focusBucketSelector();
		}
	};

	const handleIpAddressKeyDown = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			addIpAddress();
		}
	};

	const handleExpirationTimeChange = (value) => {
		const intValue = parseInt(value, 10);
		if (intValue > 168) {
			setShowEndPointAlert(true);
		} else {
			setShowEndPointAlert(false);
			setEndpointExpirationTime(intValue);
		}
	};

	const handleCopyKey = () => {
		if (navigator.clipboard) {
			navigator.clipboard
				.writeText(orgkey)
				.then(() => {
					console.log("Key copied to clipboard");
					showSnackbarWithMessage("Key copied to clipboard");
				})
				.catch((err) => {
					console.error("Failed to copy key:", err);
					showSnackbarWithMessage("Failed to copy key");
				});
		} else {
			// Fallback for browsers that do not support the Clipboard API
			const textArea = document.createElement("textarea");
			textArea.value = orgkey;
			document.body.appendChild(textArea);
			textArea.select();
			try {
				document.execCommand("copy");
				console.log("Key copied to clipboard");
				showSnackbarWithMessage("Key copied to clipboard");
			} catch (err) {
				console.error("Failed to copy key:", err);
				showSnackbarWithMessage("Failed to copy key");
			} finally {
				document.body.removeChild(textArea);
			}
		}
	};

	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	const autoOnboardingSwitchHandler = useCallback((event) => {
		//THIS IS THE SOLUTION - use event.target.checked to get value of switch
		console.log("event value:", event.target.checked);
		setEnableAutoOnboarding((prev) => !prev);
	}, []);

	const bucketEncryptionSwitchHandler = useCallback((event) => {
		//THIS IS THE SOLUTION - use event.target.checked to get value of switch
		console.log("event value:", event.target.checked);
		setEnableEncryption((prev) => !prev);
	}, []);

	// console.log("checked", checked);
	// console.log("Enable Auto Onboarding: ", enableAutoOnboarding);

	const debouncedSearch = debounce((value) => {
		if (value.trim().length >= 3) {
			setLoader(true);
			fetchOrgNAccountDetails(value);
		}
	}, 500);

	function handleInputChange(value) {
		console.log(value);
		Promise.resolve(debouncedSearch(value));
	}

	async function handleChange(selectedItems) {
		console.log(selectedItems);
		setSelectedOrg(selectedItems);
		setorgshortname(selectedItems.value);
		setLoader(false);
	}
	const handleChangeContact = (e) => {
		let value = e.target.value;

		value = value.replace(/\D/g, "");

		setContactNo(value);

		if (value.length > 10) {
			showSnackbarWithMessage("Phone number cannot exceed 10 digits");
		}
	};

	const phoneNumberPattern = /^\d{10}$/;

	const handleBlur = () => {
		if (!phoneNumberPattern.test(contactNo)) {
			showSnackbarWithMessage("Invalid phone number. Must be exactly 10 digits.");
		}
	};

	async function handleChangeRegion(selectedItems) {
		setSelectedRegion(selectedItems);
		setLoader(false);
	}

	useEffect(() => {
		if (accountid) {
			fetchOrgNAccountDetails();
		}
	}, [accountid, fetchOrgNAccountDetails]);

	useEffect(() => {
		fetchAccountDetails();
	}, [fetchAccountDetails]);

	return (
		<>
			<form>
				<h1
					style={{
						fontFamily: fontFamily,
						fontSize: 24,
						textAlign: "center",
					}}
				>
					{GlobalRole.userrole === properties.userRoles.support ||
					GlobalRole.userrole === properties.userRoles.systemadmin
						? "Update Organisation"
						: "Set up Org"}
				</h1>

				<hr />

				{GlobalRole.userrole !== properties.userRoles.support && (
					<>
						<CustomTextField
							id="admin"
							ref={adminRef}
							label="Contact Email"
							variant="outlined"
							size="small"
							fullWidth
							margin="normal"
							value={sessionStorage.getItem("username")}
							InputProps={{ readOnly: true, style: { fontFamily: "Arial, sans-serif" } }}
							InputLabelProps={{ style: { fontFamily: "Arial, sans-serif" } }}
						/>
						<CustomTextField
							autoFocus
							required
							className="input-field"
							id="orgrootname"
							label="Org Name"
							variant="outlined"
							size="small"
							fullWidth
							margin="normal"
							value={orgRootName}
							onChange={(e) => setOrgRootName(e.target.value)}
							style={{ marginTop: "5px" }}
							InputLabelProps={{
								style: { fontFamily: fontFamily },
							}}
							InputProps={{
								style: { fontFamily: fontFamily },
							}}
							disabled={!isEditable}
						/>

						<CustomTextField
							required
							className="input-field"
							id="orgkey"
							label="Unique Key"
							placeholder="Enter or generate unique key"
							variant="outlined"
							size="small"
							fullWidth
							margin="normal"
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										style={{ marginLeft: "5px" }}
									>
										<IconButton
											aria-label="copy key"
											onClick={handleCopyKey}
											disabled={!orgkey}
											sx={{
												ml: 1,
												display: "flex",
												alignItems: "center",
												padding: 0,
											}}
										>
											<CopyAllIcon sx={{ fontSize: "1.5rem" }} />
										</IconButton>
									</InputAdornment>
								),
							}}
							style={{ marginTop: "5px", padding: 0 }}
							inputProps={{
								style: { fontFamily: fontFamily },
							}}
							InputLabelProps={{
								style: { fontFamily: fontFamily },
							}}
							value={orgkey}
							onChange={(e) => setOrgKey(e.target.value)}
							onFocus={() => setIsFocused(true)}
							disabled={!isEditable}
						/>
						<Grid
							container
							spacing={3}
						>
							<Grid
								item
								xs={12}
							>
								{isFocused && (
									<CustomButton
										id="generatekey"
										text="Generate Key"
										onClick={handleGenerateKeyClick}
										style={{
											marginLeft: "18.5rem",
											marginBottom: "5px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: neutral.buttoncolor,
											color: neutral.buttontextcolor,
											padding: "4px 12px",
											fontSize: "12px",
											height: "36px",
											whiteSpace: "nowrap",
											borderRadius: "5px",
										}}
									/>
								)}
							</Grid>
						</Grid>
					</>
				)}

				{GlobalRole.userrole === properties.userRoles.support && (
					<CustomSelect
						id="searchorg"
						autoFocus
						options={options}
						value={selectedOrg}
						onChange={handleChange}
						onInputChange={handleInputChange}
						isLoading={loader}
						placeholder="Search for an Organisation"
						noOptionsMessage={() => "No results found"}
						style={{ marginTop: "5px" }}
						formatOptionLabel={(option) => (
							<div>
								<div>{option.label}</div>
							</div>
						)}
						styles={{
							width: "100%",
							control: (provided) => ({
								...provided,
								width: "100%",
								marginTop: "10px",
								minHeight: "36px",
								fontFamily: fontFamily,
							}),
							menu: (provided) => ({
								...provided,
								minHeight: "30px",
								maxHeight: "150px",
								fontFamily: fontFamily,
								backgroundColor: "#fff",
								zIndex: 1000,
							}),
							menuList: (provided) => ({
								...provided,
								minHeight: "30px",
								maxHeight: "150px",
								fontFamily: fontFamily,
								backgroundColor: "#fff",
							}),
						}}
						InputLabelProps={{
							style: { fontFamily: fontFamily },
						}}
						InputProps={{ style: { fontFamily: fontFamily } }}
						data-test-id="select-org"
					/>
				)}

				<CustomSelect
					required
					id="selectregion"
					options={regionOptions}
					value={selectedRegion}
					onChange={(selectedOption) => {
						handleChangeRegion(selectedOption);
					}}
					isLoading={loader}
					isDisabled={!isEditable}
					placeholder="Select a region"
					noOptionsMessage={() => "No results found"}
					style={{ marginTop: "5px" }}
					formatOptionLabel={(option) => (
						<div>
							<div>{option.label}</div>
						</div>
					)}
					styles={{
						width: "100%",
						control: (provided, state) => ({
							...provided,
							width: "100%",
							marginTop: "5px",
							minHeight: "36px",
							fontFamily: fontFamily,
							backgroundColor: "#fff",
							color: state.isDisabled ? "#777" : provided.color,
							borderColor: state.isDisabled ? "#ccc" : provided.borderColor,
							boxShadow: state.isDisabled ? null : provided.boxShadow,
							opacity: state.isDisabled ? 1 : 1,
						}),
						singleValue: (provided, state) => ({
							...provided,
							color: state.isDisabled ? "#777" : provided.color,
						}),
						menu: (provided) => ({
							...provided,
							minHeight: "30px",
							maxHeight: "150px",
							fontFamily: fontFamily,
							backgroundColor: "#fff",
							zIndex: 1000,
						}),
						menuList: (provided) => ({
							...provided,
							minHeight: "30px",
							maxHeight: "150px",
							fontFamily: fontFamily,
							backgroundColor: "#fff",
						}),
					}}
					InputLabelProps={{
						style: { fontFamily: fontFamily },
					}}
					InputProps={{ style: { fontFamily: fontFamily } }}
					data-test-id="select-org"
				/>

				<CustomTextField
					required
					ref={endpointExpirationTimeRef}
					className="input-field"
					id="endpointExpirationTime"
					label="Endpoint Expiration Time (in Hours)"
					variant="outlined"
					size="small"
					fullWidth
					margin="normal"
					type="number"
					inputProps={{
						min: 1,
						max: 168,
						step: 1,
						style: { fontFamily: fontFamily },
					}}
					InputLabelProps={{
						style: { fontFamily: fontFamily },
					}}
					value={endpointExpirationTime}
					onChange={(e) => handleExpirationTimeChange(e.target.value)}
					onKeyDown={handleKeyDown}
					// disabled={!isEditable}
				/>
				{showEndPointAlert && (
					<Alert
						severity="error"
						style={{ marginTop: "-0.5rem" }}
					>
						Endpoint expiration time cannot be more than 168 hours.
					</Alert>
				)}

				{GlobalRole.userrole === properties.userRoles.support && (
					<>
						<div className="select-container">
							<CustomSelect
								ref={assignBucketType}
								className="input-field"
								menuIsOpen={bucketOptions}
								margin="normal"
								options={bucketType}
								value={bucketType.value}
								defaultValue={defaultBucketType[0]}
								onChange={handleSelectBucket}
								onInputChange={handleInputChange}
								isLoading={isLoading}
								placeholder="Bucket Type"
								noOptionsMessage={() => "No results found"}
								isSearchable={true}
								onMenuOpen={() => setBucketOptions(true)}
								onMenuClose={() => setBucketOptions(false)}
								styles={{
									width: "100%",
									control: (provided) => ({
										...provided,
										width: "100%",
										marginTop: "10px",
										minHeight: "36px",
										fontFamily: fontFamily,
									}),
									menu: (provided) => ({
										...provided,
										minHeight: "30px",
										maxHeight: "150px",
										fontFamily: fontFamily,
									}),
									menuList: (provided) => ({
										...provided,
										minHeight: "30px",
										maxHeight: "150px",
										fontFamily: fontFamily,
									}),
								}}
								InputLabelProps={{
									style: { fontFamily: fontFamily },
								}}
								InputProps={{ style: { fontFamily: fontFamily } }}
							/>
							{showAlert && (
								<Alert severity="info">
									The selected bucket type cannot be modified once it has been chosen.
								</Alert>
							)}
						</div>
					</>
				)}
				{/* <div style={{ display: "inline-flex", width: "100%" }}> */}
				<div
					style={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						// marginTop: "2%",
						fontFamily: fontFamily,
					}}
				>
					<div style={{ display: "flex", alignItems: "inherit" }}>
						<span>Auto Onboard User:</span>
						<Switch
							id="auto-onboardswitch"
							style={{
								color: "#12a5c6",
								width: "fit-content",
							}}
							{...label}
							defaultChecked
							checked={enableAutoOnboarding}
							onChange={autoOnboardingSwitchHandler}
							// disabled={!isEditable}
						/>
					</div>

					<div style={{ display: "flex", alignItems: "inherit" }}>
						<span>Encrypted:</span>
						<Switch
							id="auto-onboardswitch"
							style={{
								color: "#12a5c6",
								width: "fit-content",
							}}
							{...label}
							defaultChecked
							checked={enableEncryption}
							onChange={bucketEncryptionSwitchHandler}
							// disabled={!isEditable}
						/>
					</div>
				</div>

				{/* </div> */}

				{GlobalRole.userrole !== properties.userRoles.support && (
					<>
						<CustomTextField
							required
							className="input-field"
							id="gstnumber"
							label="GST No."
							variant="outlined"
							size="small"
							fullWidth
							margin="normal"
							value={gstno}
							onChange={(e) => setGstNo(e.target.value)}
							style={{ marginTop: "5px" }}
							onKeyDown={handleGstKeyDown}
							InputLabelProps={{
								style: { fontFamily: fontFamily },
							}}
							InputProps={{ style: { fontFamily: fontFamily } }}
						/>

						<CustomTextField
							required
							className="input-field"
							id="additionalemail"
							label="Additional Email Address"
							variant="outlined"
							size="small"
							fullWidth
							margin="normal"
							value={additionalEmail}
							onChange={(e) => setAdditionalEmail(e.target.value)}
							style={{ marginTop: "5px" }}
							InputLabelProps={{
								style: { fontFamily: fontFamily },
							}}
							InputProps={{ style: { fontFamily: fontFamily } }}
						/>

						<CustomTextField
							required
							className="input-field"
							id="contactnumber"
							label="Contact No."
							variant="outlined"
							size="small"
							fullWidth
							margin="normal"
							value={contactNo}
							onChange={handleChangeContact}
							onBlur={handleBlur}
							style={{ marginTop: "5px" }}
							InputLabelProps={{
								style: { fontFamily: fontFamily },
							}}
							InputProps={{ style: { fontFamily: fontFamily } }}
						/>

						<div style={{ marginBottom: "10px" }}>
							<CustomSelect
								required
								id={"plantype"}
								label="Plan Type"
								className="input-field"
								margin="normal"
								options={plans}
								value={selectedplans}
								onChange={handleChangePlans}
								// isDisabled={!isEditable}
								placeholder="Plan Type"
								inputId="plan-type"
								noOptionsMessage={() => "No results found"}
								styles={{
									width: "100%",
									control: (provided) => ({
										...provided,
										width: "100%",
										marginTop: "5px",
										minHeight: "36px",
										fontFamily: fontFamily,
									}),
									menu: (provided) => ({
										...provided,
										minHeight: "30px",
										maxHeight: "150px",
										fontFamily: fontFamily,
										zIndex: 1000,
									}),
									menuList: (provided) => ({
										...provided,
										minHeight: "30px",
										maxHeight: "150px",
										fontFamily: fontFamily,
									}),
								}}
								InputProps={{ style: { fontFamily: fontFamily } }}
							/>
						</div>
					</>
				)}

				{/* )} */}

				<div style={{ display: "flex", alignItems: "center" }}>
					<hr className={classes.hr} />
					{(GlobalRole.userrole === properties.userRoles.support ||
						GlobalRole.userrole === properties.userRoles.systemadmin) && (
						<div className={classes.advancedSettingButtonContainer}>
							<div
								className={classes.advancedSettingButton}
								onClick={handleAdvancedClick}
								style={{ padding: "0px" }}
							>
								<span
									style={{
										fontSize: "15px",
										fontWeight: "bold",
										color: "grey",
										fontFamily: fontFamily,
									}}
								>
									Advanced Options
								</span>
								<IconButton id="advancedOptions">
									<ExpandLess
										className={classes.advancedSettingicon}
										style={{
											transform: showAdvancedOptions ? "rotate(180deg)" : "rotate(0)",
											//   transition: 'transform 0.3s ease',
										}}
									/>
								</IconButton>
							</div>
						</div>
					)}
				</div>

				<Collapse
					in={
						showAdvancedOptions &&
						(GlobalRole.userrole === properties.userRoles.support ||
							GlobalRole.userrole === properties.userRoles.systemadmin)
					}
				>
					<Typography style={{ fontSize: "13px", marginTop: "10px", fontFamily: fontFamily }}>
						Enable object exchange with chosen IP addresses
					</Typography>
					<div className={classes.advancedSettingOptions}>
						<div
							className="select-container"
							style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
						>
							<CustomTextField
								id="ipaddresstext"
								className="input-field"
								label="Enter an IP Address"
								variant="outlined"
								size="small"
								value={newIpAddress}
								onChange={(e) => setNewIpAddress(e.target.value)}
								style={{ marginBottom: "10px" }}
								fullWidth
								onKeyDown={handleIpAddressKeyDown}
								disabled={
									!(
										GlobalRole.userrole === properties.userRoles.support ||
										GlobalRole.userrole === properties.userRoles.systemadmin
									)
								} // Disable the input field for other roles
							/>
							<CustomButton
								text="Add"
								id="addIp"
								variant="outlined"
								buttonType="main"
								onClick={addIpAddress}
								disabled={
									!isValidIpAddress(newIpAddress.trim()) ||
									!(
										GlobalRole.userrole === properties.userRoles.support ||
										GlobalRole.userrole === properties.userRoles.systemadmin
									)
								} // Disable the button for other roles
								style={{
									marginLeft: "10px",
									marginBottom: "10px",
									fontFamily: fontFamily,
								}}
							/>
						</div>
						<IpAddressGrid
							selectedIP={selectedIP}
							colDefs={colDefs}
							removeIpAddress={removeIpAddress}
						/>
					</div>
				</Collapse>

				<div className="form-buttons-container">
					<CustomButton
						text="Cancel"
						onClick={handleCloseOrgOnboard}
						variant="outlined"
						data-button-id="cancel-org-onboard"
					/>

					{GlobalRole.userrole === properties.userRoles.user && (
						<CustomButton
							text="Save"
							onClick={handleSetupOrg}
							variant="outlined"
							buttonType="main"
							isLoading={buttonLoading}
							data-button-id="create-org-onboard"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
							}}
						/>
					)}

					{(GlobalRole.userrole === properties.userRoles.support ||
						GlobalRole.userrole === properties.userRoles.systemadmin) && (
						<CustomButton
							text="Update"
							onClick={handleUpdateOrg}
							variant="outlined"
							buttonType="main"
							isLoading={buttonLoading}
							data-button-id="update-org-onboard"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
							}}
						/>
					)}
				</div>
			</form>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</>
	);
}
