//Adding JSON for the requests having same Request Body

import properties from "components/properties/ApplicationProps";

export function getFetchRequestJson() {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		action: properties.actions.SEARCH,
	};

	return stringifyFetchResource;
}
