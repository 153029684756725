import properties from "components/properties/ApplicationProps";

export function getMoveResourceJson() {
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		action: properties.actions.MOVE,
		resourceId: properties.resourceId,
		locationId: 0,
		lastUpdateUser: sessionStorage.getItem("username"),
		lastUpdateTime: Date.now(),
		destLocationId: null,
	};

	return stringifyFetchResource;
}
