import React, { useState, useEffect } from "react";
import numeral from "numeral";
import moment from "moment-timezone";
import FileIcon from "components/fileService/FileIcons";
import "animate.css";
import "./DetailsDialogue.css";
import generateFetchCurl from "./GenerateCurl";
import Notification from "components/dashboard/notification/Notification";
import codeIcon from "assets/proto_1/code.png";
import useStyles from "../../../assets/css/Common";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
// import ResourceLabelCopy from "./ResourceLabelCopy";

const DetailsDialogue = ({ resource, handleCloseState }) => {
	const [resourceDetails, setResourceDetails] = useState(resource);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { fontFamily, smallTextSize, largeTextSize } = useContext(FontContext);

	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const classes = useStyles();
	let timeoutId;
	//let resourceid ;
	const removeFileDetailsHanlder = () => {
		const backdropEl = document.querySelector(".details-dialogue-backdrop");
		const detailsDialogueEl = document.querySelector(".details-dialogue");

		backdropEl?.classList.remove("animate__fadeIn");
		backdropEl?.classList.add("animate__fadeOut");

		detailsDialogueEl?.classList.remove("animate__fadeInRight");
		detailsDialogueEl?.classList.add("animate__fadeOutRight");

		timeoutId = setTimeout(() => {
			setResourceDetails([]);
			handleCloseState();
		}, 500);
	};

	useEffect(() => {
		return () => {
			setResourceDetails(() => []);
			clearTimeout(timeoutId);
		};
	}, [timeoutId]);

	const copyCurlToClipboard = async () => {
		if (resourceDetails[0].isfolder === false) {
			try {
				console.log(resourceDetails[0].filename);
				const res = await generateFetchCurl(resourceDetails[0]);
				console.log(res);
				showSnackbarWithMessage("cURL copied successfully.");
			} catch (error) {
				showSnackbarWithMessage("Failed to copy cURL!");
			}
		}
	};
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	return resourceDetails.length > 0 ? (
		<>
			<div
				className={`details-dialogue-backdrop animate__animated animate__fadeIn animate__faster`}
				onClick={removeFileDetailsHanlder}
				id="text"
				style={{ fontFamily: fontFamily }}
				onContextMenu={(e) => e.preventDefault()}
			/>

			<div className="details-dialogue-wrapper">
				<div
					className={`details-dialogue animate__animated animate__fadeInRight animate__faster`}
					onContextMenu={(e) => e.preventDefault()}
				>
					<div
						className="details-dialogue-close"
						onClick={removeFileDetailsHanlder}
					>
						<p
							style={{
								lineHeight: 0,
							}}
						>
							X
						</p>
					</div>

					<div className="details-dialogue-details">
						<div className="sect-1">
							{resourceDetails.length > 0 ? (
								<FileIcon
									fileElement={resourceDetails[0]}
									contentType={resourceDetails[0]?.contenttype}
								/>
							) : null}
							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: largeTextSize, marginTop: "1rem" }}
							>
								{resourceDetails[0]?.filename.split(".")[0]}
							</p>
						</div>

						<div className="sect-2">
							<p>
								<span></span>
								{resourceDetails[0]?.isfolder === false ? (
									<button className={classes.curlIconBgButtonStyle}>
										<img
											className={classes.iconStyle}
											src={codeIcon}
											alt="curl_code_icon"
											style={{ paddingLeft: "12px" }}
											id="copycurl"
											onClick={copyCurlToClipboard}
										></img>
									</button>
								) : (
									<></>
								)}
							</p>

							<p
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
								className={classes.fontstyle}
							>
								<span>Name</span>
								<span>:</span>
								<span>{resourceDetails[0]?.filename}</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>URI</span>
								<span>:</span>
								<span>{resourceDetails[0]?.resourceid}</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>Description</span>
								<span>:</span>
								<span>{resourceDetails[0]?.Description || "-"}</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>Type</span>
								<span>:</span>
								<span>{resourceDetails[0]?.contenttype}</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>Upload Date</span>
								<span>:</span>
								<span>
									{moment
										.tz(resourceDetails[0]?.lastupdatetime, timeZone)
										.format("DD/MM/YYYY hh:mm:ss a")}
								</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>Size</span>
								<span>:</span>
								<span>{numeral(resourceDetails[0]?.size).format("0.00b")}</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>Parent Folder</span>
								<span>:</span>
								<span>{resourceDetails[0]?.parentfoldername || "-"}</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>Parent Upload Id</span>
								<span>:</span>
								<span style={{ whiteSpace: "nowrap" }}>
									{resourceDetails[0]?.parentfolderid || "-"}
								</span>
							</p>
							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								{/**
								 * Here server send use both parentfolderid and locationid,
								 * but what we need here is parentfolderid or parentFolderId
								 * not locationid.
								 *
								 * We are displaying user parentfolderid or parentFolderId
								 * as Location ID
								 *
								 *
								 */}
								<span>Upload Location Id</span>
								<span>:</span>
								<span>
									{resourceDetails[0]?.locationid === 0 ? "-" : resourceDetails[0]?.locationid ?? "-"}
								</span>
							</p>

							<p
								className={classes.fontstyle}
								id="text"
								style={{ fontFamily: fontFamily, fontSize: smallTextSize }}
							>
								<span>Version No.</span>
								<span>:</span>
								<span>{resourceDetails[0]?.version ?? "-"}</span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</>
	) : null;
};

export default React.memo(DetailsDialogue);
