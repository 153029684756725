import { GlobalGroup } from "Constants";

export function getAddUsersGroupResourceJson(data) {
	console.log("GlobalGroup", GlobalGroup, "data", data);
	let stringifyFetchResource = {
		header: {
			email: sessionStorage.getItem("email"),
			user: sessionStorage.getItem("username"),
			org: sessionStorage.getItem("user_org"),
		},
		groupId: GlobalGroup.groupid,
		usersInGroup: data,
	};

	return stringifyFetchResource;
}
