export default function convertUTCToLocaleTimeInMs(date) {
	const utcDate = new Date(date).getTime();
	const offsetInMs = new Date().getTimezoneOffset() * 60000;
	/**
	 * Convert server sent UTC to locale time
	 * Substract if locale is in eastern or who's GMT offset is in Pluse e.g. Asia/Calcutta[GMT+5:30]
	 * Add if locale is in western or who's GMT offset is in Minus e.g. America/Los_Angeles[GMT-8:00]
	 *
	 * 🔴🔴🔴 WARNING: Do not remove or convert following addition/plus to substraction/minus 🔴🔴🔴
	 */
	return utcDate + offsetInMs;
}
