import React, { useState, useEffect, useContext } from "react";
import { CssBaseline, Grid, TableContainer } from "@mui/material";
import { FontContext } from "components/UI/Theme";
import { useHistory } from "react-router-dom";
import useStyles from "routes/TableStyles";
import "./SetupInProgress.css";

const SetupInProgress = () => {
	const [onboardingInProgress, setOnboardingInProgress] = useState(true);
	const [featuresVisible, setFeaturesVisible] = useState(false); // New state for feature visibility
	const history = useHistory();

	const { themeColor, fontFamily } = useContext(FontContext);
	const classes = useStyles();

	const features = [
		{ title: "Simple UI & Full API Support", description: "Manage storage with our UI or robust APIs." },
		{ title: "Versioning Support", description: "Track and manage file versions easily." },
		{ title: "Pay Only for What You Use", description: "No hidden fees, scale with your needs." },
		{ title: "Access Anywhere", description: "Access data from any device." },
		{ title: "Data Leak Prevention", description: "Advanced security for data protection." },
		{ title: "Easy Administration", description: "Simplified management console." },
		{ title: "One View for Stored Data", description: "Unified dashboard for stored data." },
		{ title: "No. of Accounts Friendly", description: "Manage multiple accounts seamlessly." },
		{ title: "Single File up to 5TB", description: "Upload large files up to 5TB." },
		{ title: "Search Support", description: "Find files with advanced search." },
		{ title: "Usage Analytics Reports", description: "Get detailed usage insights." },
		{ title: "Data Encryption", description: "Secure data with encryption." },
	];

	useEffect(() => {
		const unblockNavigation = history.block((location) => {
			if (onboardingInProgress) {
				return "Onboarding is in process. Please wait!";
			}
			return true;
		});

		return () => unblockNavigation();
	}, [history, onboardingInProgress]);

	const handleExploreFeaturesClick = () => {
		setOnboardingInProgress(false); // Hide onboarding overlay
		setFeaturesVisible(true); // Show feature list
	};

	return (
		<div
			className="create-group"
			style={{
				position: "relative",
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
				overflow: "hidden",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				/>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						{/* Setting up page content with features */}
						<SetupContent
							features={features}
							fontFamily={fontFamily}
							themeColor={themeColor}
							featuresVisible={featuresVisible}
						/>
					</TableContainer>
				</div>
			</div>

			{/* Overlay message about onboarding in progress with loader */}
			{onboardingInProgress && (
				<div className="onboarding-overlay">
					<div className="onboarding-message">
						<div className="loader"></div> {/* Loader animation */}
						<h2>We'll notify you once we prepare your Datafrugal space...</h2>
						<p>You can explore features while we set things up for you!</p>
						<button
							className="explore-btn"
							onClick={handleExploreFeaturesClick}
						>
							Explore Features
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

// Setup content component (shows features)
const SetupContent = ({ features, fontFamily, themeColor, featuresVisible }) => (
	<>
		{/* <p
			className="setup-message"
			style={{ fontFamily }}
		>
			Please wait while we prepare your Datafrugal space!
		</p> */}
		{/* Render features if featuresVisible is true */}
		{featuresVisible && (
			<FeatureList
				features={features}
				themeColor={themeColor}
			/>
		)}
	</>
);

// Feature list component (displays all features)
const FeatureList = ({ features, themeColor }) => (
	<div
		className="create-group"
		style={{
			backgroundColor: themeColor,
			height: "calc(100% - 55px)",
		}}
	>
		<div className="feature-list">
			{features.map((feature, index) => (
				<div
					className="feature-item"
					key={index}
				>
					<span className="feature-icon">✔</span> {/* Simple icon */}
					<div className="feature-info">
						<h4>{feature.title}</h4>
						<p>{feature.description}</p>
					</div>
				</div>
			))}
		</div>
	</div>
);

export default SetupInProgress;
