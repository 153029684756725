import { memo } from "react";
import convertBytesToGigaBytes from "utils/helpers/convertBytesToGigaBytes";
import convertUTCToLocaleTimeInMs from "utils/helpers/convertUTCToLocaleTimeInMs";

const DataUsageChartsTooltip = memo(({ payload }) => {
	if (!payload.length) {
		return <div className="custom-tooltip"></div>;
	}

	const { dateOfusage, valueInBytes } = payload[0]?.payload;
	const valueInGB = convertBytesToGigaBytes(valueInBytes);
	const adjustedOffsetUsageDate = convertUTCToLocaleTimeInMs(dateOfusage);
	const displayDate = new Date(adjustedOffsetUsageDate).toLocaleString([], { month: "short", day: "numeric" });

	return (
		<div
			className="custom-tooltip"
			style={{ border: "1px solid #c7c7c7", backgroundColor: "#fff", borderRadius: 15, padding: "1rem" }}
		>
			<p
				className="intro"
				style={{ fontSize: "1rem", fontWeight: "bolder", margin: 0 }}
			>{`${displayDate}`}</p>

			<p
				className="label"
				style={{ fontSize: "2rem", fontWeight: "bolder", color: "#82ca9d" }}
			>{`${valueInGB} GB`}</p>

			{/* <p className="desc">Anything you want can be displayed here.</p> */}
		</div>
	);
});

export default DataUsageChartsTooltip;
