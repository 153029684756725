//sidenav_icons
// import shared_resource_icon from "assets/shared_resource.png";
// import shared_with_me_icon from "assets/shared_with_me.png";
// import settings_icon from "assets/settings_icon.png";
// import profile_icon from "assets/profile_icon.png";
// import home_icon from "assets/home.png";
// import add_icon from "assets/folder.png";
// import folderUpload_icon from "assets/addfolder.png";
// import fileupload_icon from "assets/addfile.png";
// import group_icon from "assets/group.png";

import newFolderIcon from "assets/proto_1/new_folder.png";
import folderUploadIcon from "assets/proto_1/folder_upload.png";
import fileUploadIcon from "assets/proto_1/file_upload.png";
import dashboardIcon from "assets/proto_1/dashboard.png";
import shareOtherIcon from "assets/proto_1/share_other.png";
// import userIcon from "assets/proto_1/user.png";
import settingsIcon from "assets/proto_1/setting_unfilled.png";
import bellIcon from "assets/proto_1/notification.png";
import { uniqueId } from "lodash";

// import settingFilledIcon from "assets/proto_1/settings_filled.png";
// import settingsIcon from "assets/proto_1/setting_unfilled.png";

export const structure = [
	{
		id: uniqueId(),
		label: "New Folder",
		icon: newFolderIcon,
		action: "NewFolder",
		disabled: true,
		testId: "sidebar-newFolder",
	},
	{
		id: uniqueId(),
		label: "Upload Folder",
		icon: folderUploadIcon,
		action: "UploadFolder",
		testId: "sidebar-uploadFolder",
	},
	{
		id: uniqueId(),
		label: "Upload File",
		icon: fileUploadIcon,
		action: "UploadFile",
		testId: "sidebar-uploadFile",
	},
	{
		id: uniqueId(),
		label: "Dashboard",
		link: "/dashboard",
		action: "resetDashboard",
		icon: dashboardIcon,
		disabled: true,
		testId: "sidebar-dashboard",
	},
	{
		id: uniqueId(),
		label: "Shared with me",
		link: "/shared-with-me",
		icon: shareOtherIcon,
		action: "resetSharedWithMe",
		testId: "sidebar-sharedWithMe",
	},
	{
		id: uniqueId(),
		label: "Notifications",
		link: "/notifications",
		icon: bellIcon,
		disabled: true,
		testId: "sidebar-notifications",
	},
	{
		id: uniqueId(),
		label: "Settings",
		link: "/settings",
		icon: settingsIcon,
		disabled: true,
		testId: "sidebar-settings",
	},
];
