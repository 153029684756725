const getCurrencySymbol = (currencyCode) => {
	switch (currencyCode) {
		case "USD":
			return "$";
		case "INR":
			return "₹";
		default:
			return currencyCode;
	}
};

export default getCurrencySymbol;
