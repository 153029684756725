import React, { useEffect, useState } from "react";
import { GlobalFirstname, GlobalLastname, GlobalProfile, GlobalRole, GlobalUsername } from "Constants";
import { GlobalSetting } from "Service/AuthService";
import { SAVE_USER_AUTH_DETAILS } from "store/userAuthDetailsSlice";
import Routes from "routes/index";
import userIcon from "assets/proto_1/user.png";
import properties from "components/properties/ApplicationProps";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "react-oidc-context";
import { CircularProgress } from "@material-ui/core";

function App() {
	const oidcAuthentication = useAuth();
	const dispatch = useDispatch();
	const [checkRealm, setCheckRealm] = useState("unknown"); // Changed to "unknown" initially
	const [userRole, setUserRole] = useState("unknown");

	useEffect(() => {
		if (!oidcAuthentication.isLoading) {
			if (oidcAuthentication.isAuthenticated) {
				const tokenParsed = jwtDecode(oidcAuthentication.user.access_token);
				console.log("PARSES TOKEN:: ", tokenParsed);
				//getTokenInfo(tokenParsed, oidcAuthentication);
				const userRole = tokenParsed.role?.toLowerCase();
				dispatch(SAVE_USER_AUTH_DETAILS(tokenParsed));
				// Store user details in sessionStorage
				const userDetails = {
					keycloakToken: oidcAuthentication.user.access_token,
					refresh_token: oidcAuthentication.user.refresh_token,
					username: tokenParsed.preferred_username,
					email: tokenParsed.email,
					userId: tokenParsed.sub,
					firstName: tokenParsed.given_name,
					lastName: tokenParsed.family_name,
					kcRealm: tokenParsed.kc_realm,
					user_org: tokenParsed.user_org,
					idpUserId: tokenParsed.sub,
					account_status: tokenParsed.account_status,
					role: tokenParsed.role,
					googleProfile: tokenParsed.picture,
				};

				// Iterate over the userDetails object and set items in sessionStorage
				Object.entries(userDetails).forEach(([key, value]) => {
					sessionStorage.setItem(key, value);
				});
				// Set global state or context values
				GlobalRole.SetRole(userRole);
				GlobalUsername.setUsername(tokenParsed.preferred_username);
				GlobalFirstname.setFirstname(tokenParsed.given_name);
				GlobalSetting.setToken(oidcAuthentication.user.access_token);
				GlobalProfile.SetProfile(tokenParsed.picture);
				GlobalLastname.setLastname(tokenParsed.family_name);

				setUserRole(userRole);
				if (
					// tokenParsed.db_onboarding === "completed" &&
					// tokenParsed.kc_onboarding === "completed" &&
					// tokenParsed.kc_realm === properties.orgProperties.org
					tokenParsed.user_org &&
					tokenParsed.role
				) {
					sessionStorage.setItem("user_org", tokenParsed.user_org);
					if (userRole !== "unknown") {
						setUserRole(userRole);
						setCheckRealm("yes");
						console.log("dashboard login");
						const allowedRoles = Object.values(properties.showAllFieldToUserRoles);
						const hasAllowedRole = allowedRoles.includes(userRole);
						console.log(hasAllowedRole);
						if (sessionStorage.getItem("googleProfile") === "undefined") {
							GlobalProfile.SetProfile(userIcon);
						} else if (hasAllowedRole !== true) {
							setCheckRealm("unknown");
						}
					} else if (userRole === "unknown") {
						setCheckRealm("unknown");
					} else {
						setCheckRealm("no");
					}
				} else if (tokenParsed.user_org && tokenParsed.role) {
					sessionStorage.setItem("user_org", tokenParsed.user_org);
					setUserRole(userRole);
					setCheckRealm("yes");
					console.log("dashboard login");
				} else if (userRole === undefined || userRole === "unknown") {
					setCheckRealm("no");
					console.log("register user");
				}
			} else if (!oidcAuthentication.isAuthenticated) {
				oidcAuthentication.signinRedirect();
			} else {
				console.log("Another state");
			}
		}
	}, [oidcAuthentication, dispatch]);

	useEffect(() => {
		const refreshTokensWithTimeout = async () => {
			if (userRole !== undefined && userRole !== "unknown") {
				try {
					await oidcAuthentication.signinSilent();
					console.log("Token refreshed successfully");
				} catch (error) {
					console.error("Error refreshing token:", error);
				}
			}
		};

		refreshTokensWithTimeout();
		// Clean up function - No need to include userRole here
		return () => {};
	}, [userRole]); // Include userRole in the dependency array to track changes in it

	if (oidcAuthentication.isLoading) {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
				<div>
					<CircularProgress />
				</div>
			</div>
		);
	}

	return (
		<>
			<Routes
				userRole={userRole}
				checkRealm={checkRealm}
				isAuthenticated={oidcAuthentication.isAuthenticated}
				oidcAuthentication={oidcAuthentication}
			/>
		</>
	);
}

export default App;
