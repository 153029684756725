import { GlobalRole } from "Constants";
import properties from "components/properties/ApplicationProps";

function getSetupOrgJson(data) {
	console.log(data);
	let stringifyFetchResource = {
		email: sessionStorage.getItem("username"),

		event: properties.events.USERONBOARDED,
		action: properties.actions.SAVE,
		// orgKey: data.orgKey,
		// orgName: data.orgName,
		orgShortName: sessionStorage.getItem("user_org"),
		displayName: data.orgRootName,
		region: data.region,
		versioned: data.orgVersioning,
		shareOutsideOrg: properties.shareOutSideOrg.false,
		bucketType: properties.bucketType.STANDARD,
		encrypted: data.encrypted,
		endpointExpirationTime: data.endpointExpirationTime,
		autoOnBoardingEnabled: data.autoOnBoardingEnabled,
		gstNo: data.gstNo,
		// whitelistedIps: data.whitelistedIps,
		contactEmail: data.contactEmail,
		contactNumber: data.contactNumber,
		subscriptionKey: data.subscriptionKey,
		lastUpdateUser: sessionStorage.getItem("email"),
		lastUpdateTime: new Date().getTime(),
	};

	return stringifyFetchResource;
}

function getUpdateOrgJson(data) {
	console.log(data);
	let stringifyFetchResource = {
		email: sessionStorage.getItem("username"),
		accountId: data.accountid,
		event: properties.events.DOORGUPDATE,
		action: properties.actions.SAVE,
		// orgKey: data.orgKey,
		// orgName: data.orgName,
		orgShortName:
			GlobalRole.userrole === properties.userRoles.support
				? data.orgRootName
				: sessionStorage.getItem("user_org"),

		displayName: data.orgRootName,
		region: data.region,
		versioned: data.orgVersioning,
		shareOutsideOrg: properties.shareOutSideOrg.false,
		bucketType: properties.bucketType.STANDARD,
		encrypted: data.encrypted,
		endpointExpirationTime: data.endpointExpirationTime,
		autoOnBoardingEnabled: data.autoOnBoardingEnabled,
		gstNo: data.gstNo,
		whitelistedIps: data.whitelistedIps || null,
		// whitelistedIps: Array.isArray(data.whitelistedIps) ? data.whitelistedIps : [],
		contactEmail: data.contactEmail,
		contactNumber: data.contactNumber,
		subscriptionKey: data.subscriptionKey,
		lastUpdateUser: sessionStorage.getItem("email"),
		lastUpdateTime: new Date().getTime(),
	};

	return stringifyFetchResource;
}

export { getSetupOrgJson, getUpdateOrgJson };
