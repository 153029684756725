import { makeStyles } from "@material-ui/styles";
import { font, icon, neutral } from "./MainCss";

export default makeStyles((theme) => ({
	btnwithbackgroundcolor: {
		backgroundColor: neutral.buttoncolor,
	},
	btnwithoutbackgroundcolor: {
		minHeight: "100%",
		display: "flex",
		flexDirection: "column",
	},
	iconStyle: {
		width: icon.icon,
		height: icon.height,
		color: neutral.textcolor,
		marginRight: "5px",
		marginLeft: "-7px",
		textcolor: neutral.textcolor,
	},
	iconStyleGroup: {
		width: icon.icon,
		height: icon.height,
		color: neutral.textcolor,
		marginRight: "5px",
		marginLeft: "5px",
		textcolor: neutral.textcolor,
	},
	curlIconBgButtonStyle: {
		border: "0.2px solid #bcbec0",
		boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.2)",
		padding: "2px",
		borderRadius: "5px",
		backgroundColor: "white",
	},
	fontstyle: {
		fontFamily: font.fontFamily,
	},
}));
