export default function createUpdateRoleAttributeJSON({ initiatorDetails, formData }) {
	const { employeeDetails, orgName, userRole } = formData;
	const employeeEmails = employeeDetails.map(({ value }) => value);

	const requestJson = {
		header: {
			email: initiatorDetails.email,
			user: initiatorDetails.user,
			org: orgName,
		},

		authChangeIds: [...employeeEmails],
		org: orgName,
		roles: userRole.value,
	};

	return requestJson;
}
