// IPaymentAdapter.js

export default class IPaymentAdapter {
    initializePayment() {
      throw new Error("Method not implemented.");
    }
  
    makePayment() {
      throw new Error("Method not implemented.");
    }
  
    onPaymentSuccess() {
      throw new Error("Method not implemented.");
    }
  
    onPaymentFailure() {
      throw new Error("Method not implemented.");
    }
  }
  