import properties from "components/properties/ApplicationProps";

export function getUnassignCompositionDetailsJson(accountid, subscriptionName, subscriptionKey,) {
	let stringifyFetchResource = {
		action: properties.actions.SAVE,
		accountId: accountid,
		subscriptionName: subscriptionName,
		subscriptionKey: subscriptionKey,
	};

	return stringifyFetchResource;
}
