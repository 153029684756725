import { memo } from "react";
import {
	Bar,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	BarChart as ReBarChart,
} from "recharts";
import DataUsageChartsTooltip from "../DataUsageChartsTooltip";

const BarChart = ({ data }) => {
	return (
		<>
			<ResponsiveContainer
				width="100%"
				height="100%"
			>
				<ReBarChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="xAxisData" />
					<YAxis />
					<Tooltip
						content={<DataUsageChartsTooltip />}
						cursor={Boolean(data.length)}
					/>
					<Legend />
					<Bar
						dataKey="yAxisData"
						name="Storage Used (in GB)"
						fill="#82ca9d"
						type="monotone"
					/>
				</ReBarChart>
			</ResponsiveContainer>
		</>
	);
};

export default memo(BarChart);
