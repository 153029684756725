//import { AuthService } from "Service/AuthService";
import { dashboardApi } from "../misc/DashboardApi";

export default class RegistrationService {
	async registerUser(requestbody) {
		// TODO : Postman call get admin token
		// TODO : send request to keycloak registration

		let requestJsonObject = requestbody;
		console.log("REQUEST JSON OBJECT FROM REGISTRATION SERVICE :", requestJsonObject);
		try {
			let onBoardUserResponse = await dashboardApi.onBoardUser(requestJsonObject);

			console.log(
				"User onBoardResponse received",
				onBoardUserResponse,
				"Status Code :",
				onBoardUserResponse.status,
				"Footer Code :",
				onBoardUserResponse.data.footer.code,
				"Footer Message :",
				onBoardUserResponse.data.footer.msg,
			);
			// window.location.href = "/dashboard";
			if (onBoardUserResponse.data.footer.msg === "SUCCESS") {
				//	window.location.href = "/welcometodf";
				//	window.location.href = "/welcometodf";
			}
			return onBoardUserResponse;
		} catch (err) {
			console.log("Error Occurred while onboarding user", err);
		}
	}
}
