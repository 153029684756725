import axios from "axios";

export async function awsFileupload(config) {
	return await axios(config)
		.then((res) => res)
		.catch((error) => error);
}

export async function awsFileFetch(config) {
	return await axios(config);
}
