import { useState, useCallback, memo } from "react";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { defaultHeaders } from "../../DefaultHeaders";
import { ContextRequestAddResource } from "components/requestcontext/AddResource";
import { dashboardApi } from "components/misc/DashboardApi";
import { checkResourceExists } from "components/fileService/updateResource/CheckResource";
import eventEmitter from "components/misc/eventEmitter";
import Notification from "./notification/Notification";
import properties from "components/properties/ApplicationProps";
import { useContext } from "react";
import { FontContext } from "../UI/Theme";
import { useDispatch, useSelector } from "react-redux";
import { HIDE_NEW_FOLDER_DIALOG } from "store/uiSlice";

const NewFolderDialog = () => {
	const dispatch = useDispatch();
	const [foldername, setfoldername] = useState("");
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { fontFamily, largeTextSize } = useContext(FontContext);
	const [buttonLoading, setButtonLoading] = useState(false);

	const { showNewFolderDialog } = useSelector(({ ui }) => ui);

	const handleClose = useCallback(() => {
		setfoldername("");
		dispatch(HIDE_NEW_FOLDER_DIALOG());
	}, [dispatch]);

	const showSnackbarWithMessage = useCallback((message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	}, []);

	const stopPropagationForTab = useCallback((event) => {
		if (event.key === "Tab") {
			event.stopPropagation();
			return false;
		}
	}, []);

	const handleSubmitButton = useCallback(async () => {
		setButtonLoading(() => true);
		const enteredFolderName = foldername;
		const folderobj = await checkResourceExists.checkResourceExistsIndashboard(
			enteredFolderName,
			properties.parentFolderId,
		);

		if (folderobj === undefined) {
			if (enteredFolderName !== "") {
				const defaultHeader = defaultHeaders.GetDefaultUserHeader();
				const requestJsonObject = ContextRequestAddResource.generateUploadFolderResourceJson(
					enteredFolderName,
					properties.parentFolderId,
				);
				requestJsonObject.header = defaultHeader.header;
				const response = await dashboardApi.insertOrUpdateResource(requestJsonObject);

				if (response) {
					showSnackbarWithMessage("folder created successfully");
					handleClose();
					setfoldername("");
					setButtonLoading(() => false);
					eventEmitter.emit("RefreshDashboardEvent");
				}

				setfoldername("");
				handleClose();
			} else {
				console.log("null is not allowed");
				setButtonLoading(() => false);
			}
		} else {
			showSnackbarWithMessage("Folder name already exists");
			setButtonLoading(() => false);
		}

		handleClose();
	}, [foldername, handleClose, showSnackbarWithMessage]);

	return (
		<>
			<div>
				<Dialog
					open={showNewFolderDialog}
					onClose={handleClose}
					fullWidth
					maxWidth="xs"
					onKeyDown={stopPropagationForTab}
				>
					<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
						Create New Folder
					</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							margin="dense"
							id="foldername"
							label="Folder Name"
							fullWidth
							size="small"
							value={foldername}
							onChange={(e) => {
								setfoldername(e.target.value);
							}}
							variant="outlined"
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									handleSubmitButton(event);
								} else if (event.key === "Tab") {
									event.stopPropagation();
								}
							}}
							style={{ fontFamily: fontFamily }}
							InputProps={{ style: { fontFamily: fontFamily } }}
						/>

						<DialogActions
							style={{
								marginRight: "-8px",
								paddingBottom: "10px",
								marginTop: "0.5rem",
							}}
						>
							<Button
								onClick={handleClose}
								data-button-name="new-folder-cancel"
							>
								Cancel
							</Button>

							<Button
								onClick={handleSubmitButton}
								data-button-name="new-folder-submit"
								buttonType="main"
								isLoading={buttonLoading}
							>
								Submit
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</div>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</>
	);
};

export default memo(NewFolderDialog);
