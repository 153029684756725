import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboardApi } from "components/misc/DashboardApi";
import getFetchUserProfileJSON from "components/requestcontext/FetchUserProfile";

const initialState = {
	data: null,
	loading: null,
	error: null,
};

export const fetchUserProfileSummeryThunk = createAsyncThunk(
	"userDetails/fetchUserProfileSummery",
	async (_, { fulfillWithValue, rejectWithValue }) => {
		try {
			const fetchJSON = getFetchUserProfileJSON();
			const res = await dashboardApi.fetchUserProfile(fetchJSON);
			console.log(res);
			// console.log(res.data.resourceProperties.fields.accountid);
			const accountId = res.data.resourceProperties?.fields.accountid;
			sessionStorage.setItem("accountId", accountId);
			if (res.hasOwnProperty("data") && res.data !== "") {
				const { footer, resourceProperties } = res.data;

				if (footer.code !== 0) {
					return rejectWithValue("Invalid Details");
				}

				return fulfillWithValue(resourceProperties.fields);
			}
		} catch (error) {
			console.log(error);
			return rejectWithValue(error);
		}
	},
);

const userDetailsSlice = createSlice({
	name: "userDetails",
	initialState,
	reducers: {
		SAVE_USER_DETAILS: (state, { payload }) => {
			state.data = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserProfileSummeryThunk.pending, (state, { payload }) => {
			state.loading = true;
			state.data = initialState.data;
			state.error = initialState.error;
		});

		builder.addCase(fetchUserProfileSummeryThunk.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.error = false;
			state.data = payload;
		});

		builder.addCase(fetchUserProfileSummeryThunk.rejected, (state, { payload }) => {
			state.loading = false;
			state.error = payload;
			state.data = initialState.data;
		});
	},
});

export const { SAVE_USER_DETAILS } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
