// Following list is generated from file names which can be found inside
// dayjs/locale folder from node_modules

export const localeList = [
	"af",
	"am",
	"ar-dz",
	"ar-iq",
	"ar",
	"ar-kw",
	"ar-ly",
	"ar-ma",
	"ar-sa",
	"ar-tn",
	"az",
	"be",
	"bg",
	"bi",
	"bm",
	"bn-bd",
	"bn",
	"bo",
	"br",
	"bs",
	"ca",
	"cs",
	"cv",
	"cy",
	"da",
	"de-at",
	"de-ch",
	"de",
	"dv",
	"el",
	"en-au",
	"en-ca",
	"en-gb",
	"en-ie",
	"en-il",
	"en-in",
	"en",
	"en-nz",
	"en-sg",
	"en-tt",
	"eo",
	"es-do",
	"es",
	"es-mx",
	"es-pr",
	"es-us",
	"et",
	"eu",
	"fa",
	"fi",
	"fo",
	"fr-ca",
	"fr-ch",
	"fr",
	"fy",
	"ga",
	"gd",
	"gl",
	"gom-latn",
	"gu",
	"he",
	"hi",
	"hr",
	"ht",
	"hu",
	"hy-am",
	"id",
	"is",
	"it-ch",
	"it",
	"ja",
	"jv",
	"ka",
	"kk",
	"km",
	"kn",
	"ko",
	"ku",
	"ky",
	"lb",
	"lo",
	"lt",
	"lv",
	"me",
	"mi",
	"mk",
	"ml",
	"mn",
	"mr",
	"ms",
	"ms-my",
	"mt",
	"my",
	"nb",
	"ne",
	"nl-be",
	"nl",
	"nn",
	"oc-lnc",
	"pa-in",
	"pl",
	"pt-br",
	"pt",
	"rn",
	"ro",
	"ru",
	"rw",
	"sd",
	"se",
	"si",
	"sk",
	"sl",
	"sq",
	"sr-cyrl",
	"sr",
	"ss",
	"sv-fi",
	"sv",
	"sw",
	"ta",
	"te",
	"tet",
	"tg",
	"th",
	"tk",
	"tlh",
	"tl-ph",
	"tr",
	"tzl",
	"tzm",
	"tzm-latn",
	"ug-cn",
	"uk",
	"ur",
	"uz",
	"uz-latn",
	"vi",
	"x-pseudo",
	"yo",
	"zh-cn",
	"zh-hk",
	"zh",
	"zh-tw",
];
