import Utils from "./components/misc/Utils";
import properties from "components/properties/ApplicationProps";
import getUser from "Service/AuthManager";

function GetDefaultUserHeader() {
	return {
		header: {
			user: sessionStorage.getItem("email"),
			org: sessionStorage.getItem("user_org"),
			clientId: Utils.getUUID(),
			email: sessionStorage.getItem("email"),
		},
	};
}

function GetDefaultRequestHeader() {
	return {
		headers: {
			Authorization: "Bearer " + getUser()?.access_token,
			"Content-Type": properties.contentType,
			"Access-Control-Allow-Origin": "*",
		},
	};
}

function GetUserOnBoardReqHeader() {
	return {
		headers: {
			Authorization: "Bearer " + getUser()?.access_token,
			"Content-Type": properties.contentType,
			"Access-Control-Allow-Origin": properties.Access_Control_Allow_Origin,
			org: sessionStorage.getItem("kcRealm"),
		},
	};
}

export let defaultHeaders = {
	GetDefaultUserHeader,
	GetDefaultRequestHeader,
	GetUserOnBoardReqHeader,
};
