const { checkboxPosition } = require("Constants");

const checkIfUserClickOnCheckBox = (event) =>
	!!(
		event.offsetX >= checkboxPosition.leftBorder &&
		event.offsetX <= checkboxPosition.rightBorder &&
		event.offsetY >= checkboxPosition.topBorder &&
		event.offsetY <= checkboxPosition.bottomBorder
	);

export default checkIfUserClickOnCheckBox;
