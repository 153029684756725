import properties from "components/properties/ApplicationProps";

export function getSearchSubscriptionJson(item) {
	let stringifyFetchResource = {
		// action: properties.actions.SEARCH,
		subscriptionName: item,
	};

	return stringifyFetchResource;
}
