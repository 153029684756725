import axios from "axios";
import properties from "components/properties/ApplicationProps";
import getUser from "./AuthManager";

const getDefaultHeaders = () => ({
	Authorization: "Bearer " + getUser().access_token,
	"Content-Type": properties.contentType,
	"Access-Control-Allow-Origin": "*",
});

class RequestManager {
	async insertOrUpdateResource(requestJson) {
		return axios
			.post(properties.requestRoutes.insertOrUpdateResource, JSON.stringify(requestJson), {
				headers: getDefaultHeaders(),
			})
			.then((result) => result)
			.catch((error) => error);
	}

	async completeInsertOrUpdateResource(requestJson) {
		return axios
			.put(properties.requestRoutes.completeInsertOrUpdateResource, JSON.stringify(requestJson), {
				headers: getDefaultHeaders(),
			})
			.then((result) => result)
			.catch((error) => error);
	}

	async fetchResource(requestJson) {
		return axios
			.post(properties.requestRoutes.fetchResource, JSON.stringify(requestJson), {
				headers: getDefaultHeaders(),
			})
			.then((result) => result)
			.catch((error) => error);
	}
}

export const requestManager = new RequestManager();
