export default function fetchOrgNameFromUserEmail() {
	const userEmail = sessionStorage.getItem("username");
	let orgName = "";

	if (userEmail && userEmail.includes("@")) {
		const [, afterAt] = userEmail.split("@");
		const domainParts = afterAt.split(".");

		if (domainParts.length > 1) {
			orgName = domainParts.join("").replace(/\./g, "");
		} else {
			orgName = "Invalid domain structure";
		}
	} else {
		orgName = "Invalid email or no '@' found";
	}

	return orgName;
}
