import properties from "components/properties/ApplicationProps";

class AppConfig {
	config;

	async get() {
		if (!this.config) {
			const response = await fetch("/envconfig");
			console.log(response);
			this.config = await response.json();
		}
		return this.config;
	}
}

export const appConfig = new AppConfig();
export const maxAllowedFileSize = 4831838208; // 4.5 GB
export const maxLabels = 3;
export function getContentType(filename) {
	const ContentType = filename.split(".").pop().toUpperCase();
	return ContentType;
}

export const ResourceSharingUsers = [];
export const KcToken = [];
export const FileDownload = [];
export const FileDetails = [];
export const selectedResource = [];
export const uploadQueue = [];
export const firstname = sessionStorage.getItem("firstName");
export const MoveResources = [];
export const uploadingFiles = [];
export const updateAttributes = [];
export const gridSelectAllEventsList = [
	"apiSelectAll",
	"apiSelectAllFiltered",
	"apiSelectAllCurrentPage",
	"uiSelectAll",
	"uiSelectAllFiltered",
	"uiSelectAllCurrentPage",
];

export const checkboxPosition = {
	/**
	 * Checkbox's left border from left side of row div
	 */
	leftBorder: 16,
	/**
	 * Checkbox's right border from left side of row div
	 */
	rightBorder: 31,
	/**
	 * Checkbox's top border from top side of row div
	 */
	topBorder: 11,
	/**
	 * Checkbox's bottom border from top side of row div
	 */
	bottomBorder: 26,
};

//global state for username
class GlobalUsernameService {
	username = "";
	setUsername(username) {
		this.username = username;
	}
}
export const GlobalUsername = new GlobalUsernameService();

class GlobalFirstnameService {
	firstname = "";
	setFirstname(firstname) {
		this.firstname = firstname;
	}
}
export const GlobalFirstname = new GlobalFirstnameService();

class GlobalLastnameService {
	lastname = "";
	setLastname(lastname) {
		this.lastname = lastname;
	}
}
export const GlobalLastname = new GlobalLastnameService();

class GlobalGroupService {
	groupid = "";
	setGroupId(groupid) {
		this.groupid = groupid;
	}
}
export const GlobalGroup = new GlobalGroupService();

class TestController {
	resetabortController() {
		console.log("reset uploading state");
		this.abortController = new AbortController();
	}
	abortController = new AbortController();
}
export const testController = new TestController();

class GlobalUserRole {
	userrole = "";
	SetRole(userrole) {
		this.userrole = userrole;
	}
}
export const GlobalRole = new GlobalUserRole();

class GlobalProfilePic {
	profile = "";
	SetProfile(profile) {
		this.profile = profile;
	}
}
export const GlobalProfile = new GlobalProfilePic();

export const setPermissions = {
	disable_permissions: [
		"Setting",
		"dashboard",
		"search",
		"sharewithme",
		"sharedresources",
		"notifications",
		"datausage",
	],
	notification_permissions: ["notifications"],
	context_permissions: [
		"createFolder",
		"share",
		"rename",
		"cut",
		"paste",
		"delete",
		"Show Versions",
		"Details",
		"unshare",
		"refresh",
		"clone",
		"download",
	],
	ownership_permissions: ["changeownership", "linkaccount"],
	setting_permissions: ["settings", "account", "updateorg", "welcomemodal", "setupinprogress"],
	group_permissions: ["mygroups", "creategroup", "account", "updategroup"],
	payment_permissions: ["payment"],
	datamaintenance_permissions: ["datamaintenance"],
	attribute_permissions: ["updateatrribute", "generateinvoice"],
	org_permissions: ["orgonboard"],
	subscription_permissions: [
		"addsubscription",
		"updatesubscription",
		"assignsubscriptionplan",
		"unassigncomposition",
		"generatedatausage",
		"displaydatausagereport",
		"changesubscription",
		"accountbucketcreation",
	],
	dns_management: ["dnsmanagement"],
};

export const config = {
	url: process.env.REACT_APP_KEYCLOAK_BASE_URL,
	clientId: process.env.REACT_APP_CLIENT_ID,
	realm: properties.orgProperties.org,
};
// const keycloakBaseUrl = "https://demo-drive.datafrugal.com/";
// const clientId = process.env.REACT_APP_CLIENT_ID || "mm-app";

// export const oidcConfig = {
// 	authority: `${keycloakBaseUrl}/auth/realms/DATAFRUGAL`,
// 	client_id: clientId,
// 	redirect_uri: `${keycloakBaseUrl}`,
// 	response_type: "code",
// 	scope: "openid profile",
// 	post_logout_redirect_uri: `${keycloakBaseUrl}`,
// 	userStore: new WebStorageStateStore({ store: window.localStorage }),
// 	silent_redirect_uri: window.location.origin + "/silent-renew",
// 	automaticSilentRenew: true,
// };

// console.log("env variables::", config);
export const prod = {
	...config,
};

export const demo = {
	...config,
};

export const dev = {
	...config,
};

export const local = {
	...config,
};

export const responseMessage = {
	common: { success: "SUCCESS", failure: "FAILURE" },
};

export const HALF_SECOND = 500;
export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
