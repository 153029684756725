import properties from "components/properties/ApplicationProps";

// export function getSubscriptionDetails(
// 	subscriptionName,
// 	subscriptionCharge,
// 	subscriptionValidity,
// 	subscriptionAddons,
// 	subscriptionDiscount,
// ) {
// 	console.log(subscriptionName, subscriptionCharge, subscriptionValidity, subscriptionDiscount);
// 	let stringSubscriptionDetails = {
// 		action: properties.actions.SAVE,
// 		subscriptionKey: "",
// 		subscriptionName: subscriptionName,
// 		subscriptionCharge: subscriptionCharge,
// 		subscriptionChargeType: "",
// 		validity: subscriptionValidity,
// 		licenses: subscriptionAddons,
// 		discount: subscriptionDiscount,
// 		discounttype: "",
// 		description: "",
// 	};

// 	return stringSubscriptionDetails;
// }

export function getSubscriptionDetails(subscriptionDetails) {
	console.log("Subscription Details:", subscriptionDetails);

	const stringSubscriptionDetails = {
		action: properties.actions.SAVE,
		subscriptionKey: "",
		...subscriptionDetails,
	};

	return stringSubscriptionDetails;
}
