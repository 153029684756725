import properties from "components/properties/ApplicationProps";
import { defaultHeaders } from "DefaultHeaders";
import { registry } from "Service/AuthService";

// const requestHeaders = defaultHeaders.GetDefaultRequestHeader();

// -- Helper functions
async function onBoardUser(raw) {
	const data = JSON.stringify(raw);
	const onBoardUserReqHeaders = defaultHeaders.GetUserOnBoardReqHeader();
	try {
		const response = await registry
			.getInstance("transport")
			.post(properties.requestRoutes.onBoardUser, data, onBoardUserReqHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error("Error in onBoardUser:", error);
		return error; // Handle error appropriately
	}
}

async function captureOrgDetails(raw) {
	const data = JSON.stringify(raw);
	const onBoardOrgReqHeaders = defaultHeaders.GetDefaultRequestHeader();
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.captureOrgDetails, data, onBoardOrgReqHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function onBoardOrg(raw) {
	const data = JSON.stringify(raw);
	const onBoardOrgReqHeaders = defaultHeaders.GetDefaultRequestHeader();
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.onBoardOrg, data, onBoardOrgReqHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchOrgDetails(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchOrgDetails, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchDashboardResources(id = properties.parentFolderId) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const userHeader = defaultHeaders.GetDefaultUserHeader();
	try {
		const registryRes = await registry.getInstance("transport").post(
			properties.requestRoutes.fetchDashboardResources,
			{
				header: userHeader.header,
				action: properties.actions.SEARCH,
				parentFolderId: id,
				resourceId: properties.resourceId,
			},
			requestHeaders,
		);
		console.log("Registry Res:", registryRes);
		return registryRes;
	} catch (error) {}
}

async function fetchSharedDashBoardResource(id = properties.fetchSWM, resourceid = properties.defaultResourceId) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const userHeader = defaultHeaders.GetDefaultUserHeader();

	return registry
		.getInstance("transport")
		.post(
			properties.requestRoutes.fetchSharedDashBoardResource,
			{
				header: userHeader.header,
				action: properties.actions.SEARCH,
				parentFolderId: id,
				resourceId: resourceid,
			},
			requestHeaders,
		)
		.then((response) => {
			return response;
		})
		.catch((err) => err);
}

async function fetchResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchResource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function addResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.addResource, data, requestHeaders)
		.then((response) => {
			return response;
		});
}

async function uploadResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	const res = await registry
		.getInstance("transport")
		.post(properties.requestRoutes.uploadResource, data, requestHeaders)
		.then((response) => {
			return response;
		});

	return res;
}

async function completeUploadResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.completeUploadResource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

async function insertOrUpdateResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.insertOrUpdateResource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

async function completeInsertOrUpdateResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.completeInsertOrUpdateResource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

async function completeAddResource(raw, signal) {
	if (signal.aborted === false) {
		const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
		const data = JSON.stringify(raw);
		const registryRes = await registry
			.getInstance("transport")
			.put(properties.requestRoutes.completeAddResource, data, requestHeaders, { signal }, { data })
			.then((response) => {
				return response;
			});

		return registryRes;
	} else {
		// testController.resetabortController();
	}
}

async function fetchsharedresourcesbyuser(id = properties.fetchSharedByUserId) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const userHeader = defaultHeaders.GetDefaultUserHeader();

	return registry
		.getInstance("transport")
		.post(
			properties.requestRoutes.fetchsharedresourcesbyuser,
			{
				header: userHeader.header,
				action: properties.actions.SEARCH,
				parentFolderId: id,
				resourceId: properties.resourceId,
			},
			requestHeaders,
		)
		.then((response) => {
			return response;
		});
}

async function updateResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateResource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateResourceInfo(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateResourceInfo, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function completeUpdateResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.completeUpdateResource, data, requestHeaders, {
			data: data,
		})
		.then((response) => {
			return response;
		});
}

async function deleteResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.delete(properties.requestRoutes.deleteResource, {
			headers: requestHeaders.headers,
			data,
		})
		.then((response) => response)
		.catch((err) => err);
}

async function moveResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.moveResource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function addSharedResource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.addSharedResource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((err) => err);
}

async function searchUsers(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.searchUsers, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function searchUsersWithRoleUser(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.searchUsersWithRoleUser, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function searchAccountUsers(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.searchAccountUsers, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function searchUsersFromAccount(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchAccountUsers, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchGroups(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);

	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchGroups, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function addGroup(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.addGroup, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function addUsersInGroup(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.addUsersInGroup, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchUsersInGroup(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchUsersInGroup, data, requestHeaders)
		.then((response) => {
			return response;
		});
}

async function updateUsersInGroup(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateGroupUsers, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function getOrgProps(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.getOrgProps, data, requestHeaders)
		.then((response) => {
			return response;
		});
}

async function fetchresources(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchresources, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchResourceVersions(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchResourceVersions, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateGroupInfo(requestjson, token) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(requestjson);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateGroupInfo, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function deleteGroup(requestjson) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(requestjson);
	return registry
		.getInstance("transport")
		.delete(properties.requestRoutes.deleteGroup, {
			headers: requestHeaders.headers,
			data,
		})
		.then((response) => {
			return response;
		});
}

async function cancelRequest(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.cancelRequest, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function cancelRequests(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.cancelRequests, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function linkUserAccount(requestjson) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(requestjson);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.linkUserAccount, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function sendInvitations(requestjson) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(requestjson);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.sendInvitations, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function unsharedresource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.unsharedresource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function changeOwnership(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.changeOwnership, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function createaccount(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.createaccount, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function resourceLabels(requestjson) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(requestjson);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.resourceLabels, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateUsersRoleAttribute(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.updateUsersRoleAttribute, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}
async function cloneresource(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.cloneresource, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function acceptInvitationToLinkUser(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.acceptInvitationToLinkUser, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function generateNotification(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.generateNotification, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchNotifications(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchNotifications, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateAttributes(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.put(properties.requestRoutes.updateAttributes, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchAttributes(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchAttributes, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchUserProfile(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchuserprofile, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function rejectNotification(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.rejectNotification, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateGroupUsers(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateGroupUsers, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateGroup(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateGroup, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateAccount(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateAccount, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}
async function fetchAccountDetailsOfUser(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchAccountDetailsOfUser, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function getDataUsage(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.getDataUsage, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}
async function getAllPlans() {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	try {
		const response = await registry
			.getInstance("transport")
			.get(properties.requestRoutes.getAllPlans, requestHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error("Error in getallplans:", error);
		return error; // Handle error appropriately
	}
}

async function getPaymentOrder(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.getPaymentOrder, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}
async function validatePayment(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.validatePayment, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}
async function calculateTotalAmount(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.calculateTotalAmount, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}
async function fetchInvoice(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchInvoice, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}
async function fetchResourceContent(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchResourceContent, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function createSubscriptionPlan(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.createSubscription, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function getAllSubscriptions(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log("Request IN DASHBOARD API:", data, requestHeaders);
	try {
		const response = await registry
			.getInstance("transport")
			.post(properties.requestRoutes.getAllSubscriptions, data, requestHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error(error);
		return error; // Handle error appropriately
	}
}

async function fetchSubscriptions(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchSubscriptions, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function updateSubsctiption(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.updateSubsctiption, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function assignSubsctiptionToAccount(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.assignSubscriptionToAccount, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function unAssignSubsctiptionToAccount(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.unAssignSubscription, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function getAllAssociatedSubscriptionsWithAccount(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log("Request IN DASHBOARD API:", data, requestHeaders);
	try {
		const response = await registry
			.getInstance("transport")
			.post(properties.requestRoutes.getAllAssociatedSubscriptionsWithAccount, data, requestHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error(error);
		return error; // Handle error appropriately
	}
}

async function changeSubscription(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log("Request IN DASHBOARD API:", data, requestHeaders);
	try {
		const response = await registry
			.getInstance("transport")
			.post(properties.requestRoutes.changeSubscription, data, requestHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error(error);
		return error; // Handle error appropriately
	}
}

async function fetchAllAccountDetails(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log("Request IN DASHBOARD API:", data, requestHeaders);
	try {
		const response = await registry
			.getInstance("transport")
			.post(properties.requestRoutes.fetchAllAccountDetails, data, requestHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error(error);
		return error; // Handle error appropriately
	}
}

async function fetchSubscriptionFeatures(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log("Request IN DASHBOARD API:", data, requestHeaders);
	try {
		const response = await registry
			.getInstance("transport")
			.post(properties.requestRoutes.getSubscriptionFeatures, data, requestHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error(error);
		return error; // Handle error appropriately
	}
}

async function updateInvoice(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	try {
		const response = await registry
			.getInstance("transport")
			.post(properties.requestRoutes.updateInvoice, data, requestHeaders);
		console.log("RESPONSE IN DASHBOARD API:", response);
		return response;
	} catch (error) {
		console.error(error);
		return error; // Handle error appropriately
	}
}
async function fetchInvoiceForSupport(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchInvoiceForSupport, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchAllOrgDetails(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchAllOrgDetails, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchOrgAccountDetails(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchOrgAccountDetails, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function calculateDataUsage(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.calculateDataUsage, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchSharedUsers(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchSharedUsers, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function getAllRegions(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.getAllRegions, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function addCustomSubdomain(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.addCustomSubdomain, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchDNSDetails(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchDNSDetails, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function refreshCache(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.refreshCache, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function orgEvents(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	console.log(data);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.orgEvents, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function bucketCreationRequest(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.supportInTheMiddle, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchAllTransactions(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchAllTransactions, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function handleNotificationRequest(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.handleNotification, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

async function fetchOrgNAccountDetails(raw) {
	const requestHeaders = defaultHeaders.GetDefaultRequestHeader();
	const data = JSON.stringify(raw);
	return registry
		.getInstance("transport")
		.post(properties.requestRoutes.fetchOrgNAccountDetails, data, requestHeaders)
		.then((response) => {
			return response;
		})
		.catch((error) => error);
}

export const dashboardApi = {
	fetchDashboardResources,
	fetchSharedDashBoardResource,
	fetchResource,
	addResource,
	completeAddResource,
	onBoardUser,
	onBoardOrg,
	fetchOrgDetails,
	captureOrgDetails,
	deleteResource,
	updateResource,
	updateResourceInfo,
	completeUpdateResource,
	moveResource,
	addSharedResource,
	searchUsers,
	searchUsersWithRoleUser,
	searchAccountUsers,
	fetchGroups,
	addGroup,
	addUsersInGroup,
	getOrgProps,
	fetchUsersInGroup,
	fetchresources,
	fetchsharedresourcesbyuser,
	fetchResourceVersions,
	cancelRequest,
	cancelRequests,
	uploadResource,
	completeUploadResource,
	insertOrUpdateResource,
	completeInsertOrUpdateResource,
	updateGroupInfo,
	deleteGroup,
	resourceLabels,
	linkUserAccount,
	createaccount,
	unsharedresource,
	changeOwnership,
	updateUsersRoleAttribute,
	sendInvitations,
	cloneresource,
	acceptInvitationToLinkUser,
	generateNotification,
	fetchNotifications,
	updateAttributes,
	fetchAttributes,
	fetchUserProfile,
	rejectNotification,
	updateGroup,
	updateGroupUsers,
	updateAccount,
	fetchAccountDetailsOfUser,
	getDataUsage,
	getAllPlans,
	getPaymentOrder,
	validatePayment,
	calculateTotalAmount,
	fetchInvoice,
	fetchResourceContent,
	updateInvoice,
	fetchInvoiceForSupport,
	createSubscriptionPlan,
	getAllSubscriptions,
	fetchSubscriptions,
	updateSubsctiption,
	assignSubsctiptionToAccount,
	unAssignSubsctiptionToAccount,
	getAllAssociatedSubscriptionsWithAccount,
	fetchAllAccountDetails,
	fetchAllOrgDetails,
	fetchOrgAccountDetails,
	calculateDataUsage,
	searchUsersFromAccount,
	updateUsersInGroup,
	fetchSharedUsers,
	fetchSubscriptionFeatures,
	changeSubscription,
	getAllRegions,
	addCustomSubdomain,
	fetchDNSDetails,
	refreshCache,
	orgEvents,
	bucketCreationRequest,
	fetchAllTransactions,
	handleNotificationRequest,
	fetchOrgNAccountDetails,
};
