import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { dashboardApi } from "components/misc/DashboardApi";
import eventEmitter from "components/misc/eventEmitter";
import Notification from "./notification/Notification";
import { neutral, textsize } from "assets/css/MainCss";
import { getDeleteResourceJson } from "../requestcontext/DeleteResource";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import { useLocation } from "react-router-dom";
import { getDeleteGroupJson } from "components/requestcontext/DeleteGroup";

export default function DeleteDialog({ deleteDialogOpen, handleClose, selected }) {
	const currentPageRoute = useLocation().pathname;
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const { fontFamily, largeTextSize, smallTextSize } = useContext(FontContext);

	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};

	const handleDelete = async () => {
		console.log("Delete button clicked");
		console.log(selected);
		// let selected = selected;
		for (let i = 0; i < selected.length; i++) {
			console.log("inside for");
			try {
				let item = selected[i];
				console.log(item);
				let rid = item.fields.resourceid;

				let deleteResourceJson = getDeleteGroupJson(item);
				console.log("deleteResourceJson", deleteResourceJson);
				let resourceId = rid;
				deleteResourceJson.resourceId = resourceId;
				console.log("Delete Request json body:", deleteResourceJson);
				let deleteResponse = await dashboardApi.deleteGroup(deleteResourceJson);
				console.log(
					"group deleted successfully",
					deleteResponse,
					"Footer Message :",
					deleteResponse.data.footer.msg,
				);
				if (deleteResponse.data.footer.msg === "SUCCESS") {
					showSnackbarWithMessage("Group deleted successfully");
					eventEmitter.emit("GroupDashboardEvent");
				} else {
					showSnackbarWithMessage("Failed to delete group");
				}
			} catch (err) {
				console.log("....", err);
			}
		}
		handleClose();
	};

	const stopPropagationForTab = (event) => {
		if (event.key === "Tab") {
			event.stopPropagation();
			return false;
		}
	};

	return (
		<>
			<div>
				<Dialog
					open={deleteDialogOpen}
					onClose={handleClose}
					fullWidth
					maxWidth="xs"
					onKeyDown={stopPropagationForTab}
				>
					<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
						{"Delete Resource? "}
					</DialogTitle>

					<DialogContent style={{ fontFamily: fontFamily, fontSize: smallTextSize }}>
						Are you sure you want to delete this?
					</DialogContent>
					<DialogActions
						style={{
							marginRight: "18px",
							paddingBottom: "24px",
							marginTop: "-10px",
						}}
					>
						<Button
							id="deleteButton"
							type="submit"
							onClick={() => {
								if (currentPageRoute.includes("mygroups")) {
									handleDelete();
									return;
								}

								handleDelete();
							}}
							variant="contained"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
							}}
							data-button-name="Delete"
						>
							Delete
						</Button>
						<Button
							onClick={handleClose}
							variant="outlined"
							color="primary"
							style={{
								color: neutral.buttoncolor,
								borderColor: neutral.buttoncolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
							}}
						>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>

			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</>
	);
}
