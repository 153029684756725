import { dashboardApi } from "components/misc/DashboardApi";
import { getGenerateNotificationJSON } from "components/requestcontext/HandleNotification";

export async function joinOrganization(ownerEmail, actionType) {
	const formData = {
		email: ownerEmail,
		action: actionType,
	};

	const generateNotificationJSON = getGenerateNotificationJSON(formData);
	try {
		const response = await dashboardApi.handleNotificationRequest(generateNotificationJSON);
		return response;
	} catch (error) {
		throw error;
	}
}
