import React, { useContext } from "react";
import CustomButton from "components/CustomComponents/Button";
import CustomTextField from "components/CustomComponents/TextField";

import { FontContext } from "components/UI/Theme";

import { neutral } from "assets/css/MainCss";
import Notification from "components/dashboard/notification/Notification";
import { useJoinOrgViewModel } from "ViewModel/JoinOrgViewModel";
import { useHistory } from "react-router-dom";

export default function JoinOrgForm() {
	const { fontFamily } = useContext(FontContext);
	const history = useHistory();

	// ViewModel to handle form logic and state
	const { ownerEmail, setOwnerEmail, buttonLoading, showSnackbar, snackbarMessage, setShowSnackbar, handleJoinOrg } =
		useJoinOrgViewModel(history);

	function handleCloseOrgOnboard() {
		history.push("./settings");
	}

	// Form submit handler that prevents page refresh
	const handleSubmit = (e) => {
		e.preventDefault();
		handleJoinOrg(e);
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<h1
					style={{
						fontFamily: fontFamily,
						fontSize: 24,
						textAlign: "center",
					}}
				>
					Join Organisation
				</h1>
				<hr />

				<CustomTextField
					autoFocus
					className="input-field"
					label="Search for email"
					id="search-accountid"
					variant="outlined"
					size="small"
					value={ownerEmail}
					onChange={(e) => setOwnerEmail(e.target.value)}
					fullWidth
					margin="normal"
					InputLabelProps={{
						style: { fontFamily: fontFamily },
					}}
					InputProps={{ style: { fontFamily: fontFamily } }}
				/>

				<div className="form-buttons-container">
					<CustomButton
						text="Cancel"
						onClick={handleCloseOrgOnboard}
						variant="outlined"
						data-button-id="cancel-org-onboard"
					/>
					<CustomButton
						text="Save"
						type="submit"
						variant="outlined"
						buttonType="main"
						isLoading={buttonLoading}
						data-button-id="join-org"
						style={{
							backgroundColor: neutral.buttoncolor,
							color: neutral.buttontextcolor,
						}}
					/>
				</div>
			</form>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</>
	);
}
