import React from "react";
import { useEffect } from "react";
import eventEmitter from "components/misc/eventEmitter";
import "../../assets/css/Table.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchBucketCreationeRequestsThunk } from "store/bucketCreationSlice";
import "assets/css/Dropzone.css";

import AccountBucketManagement from "components/AccountBucketManagement";

const BucketCreation = () => {
	const dispatch = useDispatch();
	const currentDashboardResources = useSelector((state) => state.bucketCreation.data.resourcePropertiesList);
	const areDashboardResourcesLoading = useSelector((state) => state.bucketCreation.loading);

	useEffect(() => {
		dispatch(fetchBucketCreationeRequestsThunk()); //component mount
		eventEmitter.on("RefreshBucketTransactionGrid", () => {
			dispatch(fetchBucketCreationeRequestsThunk()); //on hitting trigger event for refreshing grid
		});
	}, [dispatch]);

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(RESET_DASHBOARD_SLICE());
	// 		dispatch(HIDE_RESOURCE_VERSION_NO());
	// 		dispatch(RESET_DASHBOARD_UP_LOCATION_ID_SLICE());
	// 	};
	// }, [dispatch]);

	return (
		<div
			className="resources-page"
			id="dashboard-page"
		>
			<AccountBucketManagement
				resourcesData={currentDashboardResources}
				showGridProgressbar={areDashboardResourcesLoading}
			/>
		</div>
	);
};

export default React.memo(BucketCreation);
