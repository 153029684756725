import delete_icon from "assets/proto_1/delete.png";

const columnDefinitions = (removeIpAddress) => [
	{
		headerName: "Whitelisted IPs",
		field: "ip",
		width: 300,
		sort: "asc",
	},
	{
		// headerName: "Actions",
		// field: "action",
		width: 100,
		minWidth: 100,
		maxWidth: 100,
		cellRenderer: (params) => {
			const { data } = params;
			const handleClick = (e) => {
				e.preventDefault();
				removeIpAddress(data.ip);
			};
			return (
				<button
					id="deleteipbtn"
					style={{ background: "none", border: "none", cursor: "pointer" }}
					onClick={handleClick}
				>
					<img
						src={delete_icon}
						alt="Delete"
						style={{ width: "20px", height: "20px" }}
						className="deleteSubscription"
					/>
				</button>
			);
		},
	},
];

export default columnDefinitions;
