/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useStyles from "assets/css/Common";
// components
import Dot from "../../../../routes/Dot";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserNotificationsThunk } from "store/notificationsSlice";

import { Typography } from "@mui/material";

const SidebarLink = ({ link, icon, label, isSidebarOpened, nestedRoutes, nested, testId }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const [openNestedRoute, setOpenNestedRoute] = useState(false);
	const isLinkActive = link === `/${pathname.split("/").filter((x) => x)[0]}`;
	const userNotifications = useSelector(({ notifications }) => notifications.data);

	const toggleNestedRouteCollapse = () => setOpenNestedRoute(!openNestedRoute);

	const NestedRouteCollapse = () => (
		<>
			<Collapse
				in={openNestedRoute}
				timeout="auto"
				unmountOnExit
				style={{
					backgroundColor: "#ebebeb",
				}}
			>
				{nestedRoutes?.length > 0
					? nestedRoutes.map((nestedRoute) => (
							<Link to={nestedRoute.link}>
								<List
									component="div"
									disablePadding
								>
									<ListItem button>
										<ListItemIcon>
											<img
												src={nestedRoute.icon}
												alt={"userIcon"}
												className={classes.iconStyleGroup}
											></img>
										</ListItemIcon>
										<ListItemText primary={nestedRoute.label} />
									</ListItem>
								</List>
							</Link>
					  ))
					: null}
			</Collapse>
		</>
	);

	useEffect(() => {
		if (link === "/notifications") dispatch(fetchUserNotificationsThunk());
	}, [link, dispatch]);

	return (
		<>
			<ListItem
				button
				disableRipple
				component={link && Link}
				to={link}
				className={classes.link}
				// classes={{
				// 	root: classnames(classes.linkRoot, {
				// 		[classes.linkActive]: isLinkActive && !nested,
				// 		[classes.linkNested]: nested,
				// 	}),
				// }}
				style={{
					marginLeft: 11,
					paddingLeft: 6,
					borderRadius: 10,
					backgroundColor: `${isLinkActive ? "#c8e6f0" : "unset"}`,
					color: `${isLinkActive ? "#1e70bf" : "unset"}`,
					width: `${!isSidebarOpened ? "45px" : "95%"}`,
				}}
				css={{
					":hover": {
						backgroundColor: `${isLinkActive ? "#c8e6f0" : "#d9d9d96b !important"}`,
					},
					":focus": {
						backgroundColor: "unset",
					},
				}}
				data-test-id={`${testId}`}
				onClick={toggleNestedRouteCollapse}
			>
				<Tooltip
					title={label}
					placement="bottom"
				>
					<ListItemIcon
						className={classnames(classes.linkIcon, {
							[classes.linkIconActive]: isLinkActive,
						})}
					>
						{nested ? <Dot color={isLinkActive && "primary"} /> : icon}
					</ListItemIcon>
				</Tooltip>
				<ListItemText
					classes={{
						primary: classnames(classes.linkText, {
							[classes.linkTextActive]: isLinkActive,
							[classes.linkTextHidden]: !isSidebarOpened,
						}),
					}}
					primary={label}
				/>
				{userNotifications.length && link === "/notifications" ? (
					<Typography
						style={{
							color: "#fff",
							backgroundColor: "#12a5c6",
							position: "absolute",
							top: "5px",
							left: "25px",
							fontSize: "12px",
							padding: "1px 5px",
							borderRadius: "10px",
							textAlign: "center",
							lineHeight: "1",
						}}
					>
						{userNotifications.length}
					</Typography>
				) : null}
				{nestedRoutes?.length > 0 ? openNestedRoute ? <ExpandLess /> : <ExpandMore /> : null}
			</ListItem>
			{nestedRoutes?.length > 0 ? <NestedRouteCollapse /> : null}
		</>
	);
};

export default SidebarLink;
