import { memo, useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import debounce from "lodash/debounce";
import { dashboardApi } from "components/misc/DashboardApi";
import { getSearchUserJson } from "components/requestcontext/SearchUser";
import { getFetchUsersInGroup } from "components/requestcontext/FetchUsersInGroup";
import { getFetchGroupJson } from "components/requestcontext/FetchGroup";
import { FontContext } from "components/UI/Theme";
import { useDispatch } from "react-redux";
import { RESET_USERS_TO_SHARE_RESOURCES, SAVE_USERS_TO_SHARE_RESOURCES } from "store/resourceGridSlice";

const UserSelector = ({ prevSharedUsers }) => {
	const dispatch = useDispatch();
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const { fontFamily } = useContext(FontContext);

	const searchUsers = useCallback(
		async (value) => {
			try {
				const userResourceJson = getSearchUserJson(value);
				const searchuserResponse = await dashboardApi.searchAccountUsers(userResourceJson);

				if (searchuserResponse?.data?.resourcePropertiesList) {
					const users = searchuserResponse.data.resourcePropertiesList.map((user) => {
						sessionStorage.setItem("useridd", user.fields.userid);
						return {
							value: user.fields.email,
							label: user.fields.email,
							avatar: user.fields.avatarUrl,
							userid: user.fields.userid,
							isGroup: user.fields.hasOwnProperty("groupid"),
						};
					});

					const fetchGroupJson = getFetchGroupJson(value);
					const fetchGroupResponse = await dashboardApi.fetchGroups(fetchGroupJson);

					if (fetchGroupResponse?.data?.resourcePropertiesList) {
						const groups = fetchGroupResponse.data.resourcePropertiesList.map((group) => ({
							value: group.fields.groupid,
							label: group.fields.name,
							avatar: group.fields.avatarUrl,
							groupid: group.fields.groupid,
							userid: group.fields.userid,
							isGroup: group.fields.hasOwnProperty("groupid"),
						}));
						console.log("Groups:", groups);
						const prevSharedUsersList = prevSharedUsers.map((email) => ({
							value: email,
							label: email,
							avatar: null,
							userid: null,
							isGroup: false,
						}));
						setOptions([...users, ...groups, ...prevSharedUsersList]);
						setError(null);
					} else {
						setError("No groups found");
						setOptions(users); // Set users as options even if no groups are found
					}
				} else {
					setError("No users found");
					setOptions([]); // Set no options when no users are found
				}
			} catch (error) {
				console.error("An error occurred while searching for users:", error);
				setOptions([]);
				setError("An error occurred while searching for users");
			} finally {
				setIsLoading(false);
			}
		},
		[prevSharedUsers],
	);

	const debouncedSearch = debounce((value) => {
		if (value.trim().length >= 3) {
			setIsLoading(true);
			searchUsers(value);
		}
	}, 500);

	function handleInputChange(value) {
		console.log(value);
		// setSearchTerm(value);
		Promise.resolve(debouncedSearch(value));
	}

	const handleChange = useCallback(
		async (selectedItems) => {
			try {
				let combinedUsersList = [];
				const usersList = selectedItems
					.filter(({ isGroup }) => !isGroup)
					.map(({ value, userid }) => ({ email: value, userid }));

				usersList.map(({ email }) => combinedUsersList.push(email));

				const groupsList = selectedItems
					.filter(({ isGroup }) => isGroup)
					.map(({ label, groupid }) => ({ name: label, groupid }));

				if (groupsList.length) {
					const usersInGroups = await Promise.allSettled(
						groupsList.map(async ({ name, groupid }) => {
							const res = await dashboardApi.fetchUsersInGroup(getFetchUsersInGroup(name));

							if (res?.data?.resourcePropertiesList) {
								return res.data.resourcePropertiesList.map(({ fields }) => fields.email);
							}
						}),
					);

					usersInGroups.map(({ value }) => combinedUsersList.push(value));
				}

				const flattenUsersList = combinedUsersList.flat(Infinity);
				combinedUsersList = [...new Set(flattenUsersList)].map((user) => ({ email: user }));

				setSelectedUsers(selectedItems);
				dispatch(SAVE_USERS_TO_SHARE_RESOURCES(combinedUsersList));
			} catch (error) {
				console.log(error);
			}
		},
		[dispatch],
	);

	useEffect(() => {
		const usersList = prevSharedUsers.map((email) => ({
			avatar: null,
			isGroup: false,
			label: email,
			userid: null,
			value: email,
		}));

		// save already shared users to select field
		setOptions(() => [...usersList]);
		setSelectedUsers(() => [...usersList]);

		// save already shared users to Redux for future sharing purpose
		const usersListEmailOnly = usersList.map(({ value }) => ({ email: value }));
		dispatch(SAVE_USERS_TO_SHARE_RESOURCES([...usersListEmailOnly]));

		return () => {
			setOptions(() => []);
			setSelectedUsers(() => []);
			dispatch(RESET_USERS_TO_SHARE_RESOURCES());
		};
	}, [prevSharedUsers, dispatch]);

	console.log(options);

	return (
		<div style={{ display: "flex", alignItems: "left", gap: "16px" }}>
			<div style={{ width: "100%", marginTop: "5px" }}>
				<Select
					isMulti
					autoFocus
					closeMenuOnScroll={false}
					options={options}
					value={selectedUsers}
					inputId="share-input"
					onChange={handleChange}
					onInputChange={handleInputChange}
					isLoading={isLoading}
					placeholder="Search for people or groups"
					noOptionsMessage={() => "No results found"}
					formatOptionLabel={(option) => (
						<div>
							<div>{option.label}</div>
						</div>
					)}
					styles={{
						menu: (provided) => ({
							...provided,
							maxHeight: "200px",
						}),
						menuList: (provided) => ({
							...provided,
							maxHeight: "100px",
							overflowY: "auto",
						}),
						control: (provided) => ({
							...provided,
							marginTop: "0px",
						}),
					}}
					InputProps={{ style: { fontFamily: fontFamily } }}
				/>
			</div>
			{error && <div style={{ color: "red" }}>{error}</div>}
		</div>
	);
};

export default memo(UserSelector);
