import properties from "components/properties/ApplicationProps";

export function getPaymentOrderJson(amount, planId, invoiceid) {
    let stringifyFetchResource = {
        header: {
            user: sessionStorage.getItem("username"),
            email: sessionStorage.getItem("email"),
            org: sessionStorage.getItem("user_org"),
        },
        action: properties.actions.SEARCH,
        amount: amount,
        subscriptionKey: planId,
        currency: "INR",
        invoiceId: invoiceid,
    };

    return stringifyFetchResource;
}