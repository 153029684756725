// import { useKeycloak } from "@react-keycloak/web";
import properties from "components/properties/ApplicationProps";
import { useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const LoginPage = () => {
	// // const { keycloak } = useKeycloak();

	const auth = useAuth();
	console.log(auth);
	const history = useHistory();

	if (!auth.isAuthenticated) {
		auth.signinRedirect();
	} else {
		const userRole = sessionStorage.getItem("role");
		console.log("User Role from sessionStorage:", userRole);

		const allowedRoles = Object.values(properties.showWelcomeModalBoxToUserRoles);
		const hasAllowedRole = allowedRoles.includes(userRole);

		if (hasAllowedRole) {
			history.push("/welcometodf");
		} else {
			history.push("/dashboard");
		}
	}

	return <></>;
};
export default LoginPage;
