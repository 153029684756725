import { createSlice, nanoid } from "@reduxjs/toolkit";

const DEFAULT_DASHBOARD_LOCATION_DETAILS = {
	id: crypto.randomUUID(),
	name: "Dashboard",
	isFolder: true,
	resourceId: 0,
	parentFolderId: -1,
	locationId: 0,
};

const DEFAULT_SEARCH_LOCATION_DETAILS = {
	id: crypto.randomUUID(),
	name: "Search",
	isFolder: true,
	resourceId: 0,
	parentFolderId: -1,
	locationId: 0,
};

const DEFAULT_SHARED_WITH_ME_LOCATION_DETAILS = {
	id: crypto.randomUUID(),
	name: "Shared With Me",
	isFolder: true,
	resourceId: 0,
	parentFolderId: -1,
	locationId: 0,
};

const initialState = {
	refreshDashboardUsingBreadCrumb: true,
	refreshSearchUsingBreadCrumb: false,
	refreshSharedWithMeUsingBreadCrumb: false,
	dashboardUpLocationId: [DEFAULT_DASHBOARD_LOCATION_DETAILS],
	searchUpLocationId: [DEFAULT_SEARCH_LOCATION_DETAILS],
	sharedWithMeUpLocationId: [DEFAULT_SHARED_WITH_ME_LOCATION_DETAILS],
};

const gridNavigationSlice = createSlice({
	name: "gridNavigation",
	initialState,
	reducers: {
		SAVE_DASHBOARD_UP_LOCATION_ID: {
			prepare: (resource) => ({
				payload: {
					id: crypto.randomUUID(),
					name: resource.filename,
					isFolder: resource.isfolder,
					resourceId: resource.resourceid,
					parentFolderId: resource.parentfolderid,
					locationId: resource.locationid,
				},
			}),

			reducer: (state, { payload }) => {
				const isCurrentFolderPresent = state.dashboardUpLocationId.filter(
					(folder) => folder.resourceId === payload.resourceId,
				).length;

				if (!isCurrentFolderPresent) state.dashboardUpLocationId.push(payload);
			},
		},

		SAVE_SHARED_WITH_ME_UP_LOCATION_ID: {
			prepare: (resource) => ({
				payload: {
					id: crypto.randomUUID(),
					name: resource.filename,
					isFolder: resource.isfolder,
					resourceId: resource.resourceid,
					parentFolderId: resource.parentfolderid,
					locationId: resource.locationid,
				},
			}),

			reducer: (state, { payload }) => {
				const isCurrentFolderPresent = state.sharedWithMeUpLocationId.filter(
					(folder) => folder.resourceId === payload.resourceId,
				).length;

				if (!isCurrentFolderPresent) state.sharedWithMeUpLocationId.push(payload);
			},
		},

		SAVE_SEARCH_UP_LOCATION_ID: {
			prepare: (resource) => ({
				payload: {
					id: crypto.randomUUID(),
					name: resource.filename,
					isFolder: resource.isfolder,
					resourceId: resource.resourceid,
					parentFolderId: resource.parentfolderid || resource.parentfolderid,
					locationId: resource.locationid || resource.lid,
				},
			}),

			reducer: (state, { payload }) => {
				const isCurrentFolderPresent = state.searchUpLocationId.filter(
					(folder) => folder.resourceId === payload.resourceId,
				).length;

				if (!isCurrentFolderPresent) state.searchUpLocationId.push(payload);
			},
		},

		SAVE_GO_TO_LOCATION_BREADCRUMB_CHIP: {
			prepare: (resource) => ({
				payload: {
					id: crypto.randomUUID(),
					name: resource.parentfoldername,
					isFolder: resource.isfolder,
					resourceId: resource.resourceid,
					parentFolderId: resource.isfolder
						? resource.locationid || resource.lid
						: resource.parentfolderid || resource.pid,
					locationId: resource.isfolder
						? resource.parentfolderid || resource.pid
						: resource.locationid || resource.lid,
				},
			}),
			reducer: (state, { payload }) => {
				state.searchUpLocationId.length = 1;
				state.searchUpLocationId.push(payload);
				state.refreshSearchUsingBreadCrumb = true;
			},
		},

		REFRESH_DASHBOARD_GRID: (state) => {
			state.refreshDashboardUsingBreadCrumb = true;
		},

		REFRESH_SEARCH_GRID: (state) => {
			state.refreshSearchUsingBreadCrumb = true;
		},

		REFRESH_SHARED_WITH_ME_GRID: (state) => {
			state.refreshSharedWithMeUsingBreadCrumb = true;
		},

		REMOVE_LAST_DASHBOARD_UP_LOCATION_ID: (state, _) => {
			state.dashboardUpLocationId.pop();
		},

		REMOVE_LAST_SHARED_WITH_ME_UP_LOCATION_ID: (state, _) => {
			state.sharedWithMeUpLocationId.pop();
		},

		REMOVE_LAST_SEARCH_UP_LOCATION_ID: (state, _) => {
			state.searchUpLocationId.pop();
		},

		RESET_DASHBOARD_UP_LOCATION_ID_SLICE: (state, _) => {
			state.dashboardUpLocationId = initialState.dashboardUpLocationId;
			state.refreshDashboardUsingBreadCrumb = true;
		},

		RESET_SHARED_WITH_ME_UP_LOCATION_ID_SLICE: (state, _) => {
			state.sharedWithMeUpLocationId = initialState.sharedWithMeUpLocationId;
			state.refreshSharedWithMeUsingBreadCrumb = true;
		},

		RESET_SEARCH_UP_LOCATION_ID_SLICE: (state, _) => {
			state.searchUpLocationId = initialState.searchUpLocationId;
			state.refreshSearchUsingBreadCrumb = true;
		},

		HANDLE_DASHBOARD_BREADCRUMB_CLICK: (state, { payload }) => {
			state.dashboardUpLocationId.splice(payload + 1);
			state.refreshDashboardUsingBreadCrumb = true;
		},

		HANDLE_SHARED_WITH_ME_BREADCRUMB_CLICK: (state, { payload }) => {
			state.sharedWithMeUpLocationId.splice(payload + 1);
			state.refreshSharedWithMeUsingBreadCrumb = true;
		},

		HANDLE_SEARCH_BREADCRUMB_CLICK: (state, { payload }) => {
			state.searchUpLocationId.splice(payload + 1);
			state.refreshSearchUsingBreadCrumb = true;
		},

		STOP_REFRESHING_USING_BREADCRUMB: (state, _) => {
			state.refreshDashboardUsingBreadCrumb = false;
			state.refreshSearchUsingBreadCrumb = false;
			state.refreshSharedWithMeUsingBreadCrumb = false;
		},

		UPDATE_SEARCH_BREADCRUMB_NAME: (state, { payload }) => {
			state.searchUpLocationId[0].name = `Search: ${payload}`;
		},

		UPDATE_DEFAULT_DASHBOARD_UP_LOCATION_ID: (state, { payload }) => {
			state.dashboardUpLocationId[0].locationId = payload;
		},
	},
});

export const {
	SAVE_DASHBOARD_UP_LOCATION_ID,
	SAVE_SEARCH_UP_LOCATION_ID,
	SAVE_SHARED_WITH_ME_UP_LOCATION_ID,

	REFRESH_DASHBOARD_GRID,
	REFRESH_SEARCH_GRID,
	REFRESH_SHARED_WITH_ME_GRID,

	REMOVE_LAST_DASHBOARD_UP_LOCATION_ID,
	REMOVE_LAST_SEARCH_UP_LOCATION_ID,
	REMOVE_LAST_SHARED_WITH_ME_UP_LOCATION_ID,

	RESET_DASHBOARD_UP_LOCATION_ID_SLICE,
	RESET_SEARCH_UP_LOCATION_ID_SLICE,
	RESET_SHARED_WITH_ME_UP_LOCATION_ID_SLICE,

	HANDLE_DASHBOARD_BREADCRUMB_CLICK,
	HANDLE_SEARCH_BREADCRUMB_CLICK,
	HANDLE_SHARED_WITH_ME_BREADCRUMB_CLICK,

	STOP_REFRESHING_USING_BREADCRUMB,
	SAVE_GO_TO_LOCATION_BREADCRUMB_CHIP,
	UPDATE_SEARCH_BREADCRUMB_NAME,
	UPDATE_DEFAULT_DASHBOARD_UP_LOCATION_ID,
} = gridNavigationSlice.actions;

export default gridNavigationSlice.reducer;
