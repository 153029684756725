import { createSlice } from "@reduxjs/toolkit";

const userAuthDetailsSlice = createSlice({
	name: "userAuthDetails",
	initialState: {
		data: null,
	},
	reducers: {
		SAVE_USER_AUTH_DETAILS: (state, { payload }) => {
			state.data = payload;
		},
	},
});

export const { SAVE_USER_AUTH_DETAILS } = userAuthDetailsSlice.actions;

export default userAuthDetailsSlice.reducer;
