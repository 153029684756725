import React from "react";
import RegistrationService from "../registrationform/RegistrationService";
import properties from "components/properties/ApplicationProps";
import "./style.css";
import { CircularProgress } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import EventEmitter from "components/misc/eventEmitter";
import WelcomeModal from "pages/Login/WelcomeModal";
// import Notification from 'components/dashboard/notification/Notification';
const styles = (theme) => ({
	root: {
		display: "flex",
		justifyContent: "center", // Center horizontally
		alignItems: "center", // Center vertically
		height: "100vh", // Adjust the height to center vertically in the viewport
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
});
class RegisterForm extends React.Component {
	registrationService = new RegistrationService();
	constructor() {
		super();
		this.state = {
			fields: {
				firstName: "",
				lastName: "",
				open: false,
				userName: "",
				email: "",
				mobileno: "",
				password: "",
				confirmpassword: "",
				selectedOption: null,
				orgName: "",
				orgRootName: "",
				org: "",
			},
			errors: {},
			msg: {},
			showSnackbar: false,
			snackbarMessage: "",
			checkStatus: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
	}

	handleChange(e) {
		e.preventDefault();
		console.log(e);
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}

	showSnackbarWithMessage(message) {
		this.setState({
			showSnackbar: true,
			snackbarMessage: message,
		});
	}

	async componentDidMount() {
		try {
			const getonBoardJson = async () => {
				console.log("getOnBoard method called..");

				const data = {
					header: {
						email: sessionStorage.getItem("email"),
						org: properties.orgProperties.org,
						idpUserId: sessionStorage.getItem("idpUserId"),
					},
					role: properties.role.EDITOR,
					userName: sessionStorage.getItem("username"),
					firstName: sessionStorage.getItem("firstName"),
					lastName: sessionStorage.getItem("lastName"),
					email: sessionStorage.getItem("email"),
					active: properties.activeStatus.true,
					org: sessionStorage.getItem("kcRealm"),
					idpUserId: sessionStorage.getItem("idpUserId"),
					lastUpdateTime: Date.now(),
				};
				//  await this.login(data);  //calling this for checking user roles
				if (data) {
					console.log("this.getonBoardJson() called..", data);
					this.showSnackbarWithMessage("Signup Processing...");
					this.submituserRegistrationForm(data);
				}
			};

			await getonBoardJson(); // Call the getonBoardJson function using async/await
		} catch (error) {
			console.log(error);
			// this.setState({ error, loading: false });
		}
	}

	async submituserRegistrationForm(data) {
		try {
			if (this.validateForm()) {
				let requestbody = data;
				console.log(requestbody);
				let submitResponse = await this.registrationService.registerUser(requestbody);
				console.log("User Info Received ..", requestbody);
				console.log("Submitted User Info Successfully:", submitResponse);
				// If the registration was successful, redirect the user to the dashboard page
				if (submitResponse.data.footer.msg === "SUCCESS") {
					// Use the "push" method to navigate to the dashboard page
					this.showSnackbarWithMessage("Signup Processing...");

					this.props.history.push("/welcometodf");
					this.setState({ checkStatus: true });
					//	EventEmitter.emit("User Onboarded");
				}
				return submitResponse;
			} else {
				throw new Error("Form validation failed..");
			}
		} catch (error) {
			console.log(error.message);
			this.showSnackbarWithMessage("User signup failed...");
		}
	}
	//Form Validations

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let msg = {};
		let formIsValid = true;

		if (!fields["firstName"]) {
			formIsValid = false;
			errors["firstName"] = "*Please specify your firstName.";
		}

		if (!fields["lastName"]) {
			formIsValid = false;
			errors["lastName"] = "*Please specify your lastName.";
		}

		if (!fields["userName"]) {
			formIsValid = false;
			errors["userName"] = "*Please enter your userName.";
		}

		if (typeof fields["firstName"] !== "undefined") {
			if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
				formIsValid = false;
				errors["firstName"] = "*Please enter alphabet characters only.";
			}
		}

		if (typeof fields["lastName"] !== "undefined") {
			if (!fields["lastName"].match(/^[a-zA-Z ]*$/)) {
				formIsValid = false;
				errors["lastName"] = "*Please enter alphabet characters only.";
			}
		}

		if (typeof fields["userName"] !== "undefined") {
			if (!fields["userName"].match(/^[a-zA-Z ]*$/)) {
				formIsValid = false;
				errors["userName"] = "*Please enter alphabet characters only.";
			}
		}

		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your email-ID.";
		}

		if (typeof fields["email"] !== "undefined") {
			//regular expression for email validation
			var pattern = new RegExp(
				/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
			);
			if (!pattern.test(fields["email"])) {
				formIsValid = false;
				errors["email"] = "*Please enter valid email-ID.";
			}
		}

		if (!fields["mobileno"]) {
			formIsValid = false;
			errors["mobileno"] = "*Please enter your mobile no.";
		}

		if (typeof fields["mobileno"] !== "undefined") {
			if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
				formIsValid = false;
				errors["mobileno"] = "*Please enter valid mobile no.";
			}
		}

		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Please enter your password.";
		}

		if (!fields["confirmpassword"]) {
			formIsValid = false;
			errors["confirmpassword"] = "*Please confirm your password.";
		}

		//TODO : Password validations to be done
		if (typeof fields["password"] !== "undefined") {
			if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
				formIsValid = false;
				errors["password"] = "*Please enter secure and strong password.";
			}
		}

		if (typeof fields["password"] !== typeof fields["confirmpassword"]) {
			formIsValid = false;
			errors["confirmpassword"] = "Password did not matched";
			console.log("Password did not matched");
		}

		if (typeof fields["password"] == typeof fields["confirmpassword"]) {
			formIsValid = true;
			msg["confirmpassword"] = "Password created successfully!";

			console.log("Password matched");
		}

		this.setState({
			fields: {
				firstName: "",
				lastName: "",
				userName: "",
				email: "",
				mobileno: "",
				password: "",
				confirmpassword: "",
			},
			errors: errors,
		});
		return formIsValid;
	}
	handleOptionChange = (event, newValue) => {
		this.setState({ selectedOption: newValue });
	};

	handlePayment = () => {
		switch (this.state.selectedOption.value) {
			case "Plan1":
				// Handle credit card payment
				console.log("plan1");
				break;
			case "Plan2":
				// Handle PayPal payment
				console.log("plan2");
				break;
			case "Plan3":
				// Handle bank transfer payment
				console.log("plan3");
				break;
			default:
				// Handle error or no payment method selected
				break;
		}
	};

	handleClickOpen() {
		this.open(true);
	}

	render() {
		const { classes } = this.props;

		return (
			<>
				<div className={classes.root}>
					<div style={{ height: "100vh", display: "grid", placeItems: "center" }}>
						<CircularProgress size={25} />
						{this.state.checkStatus === true && <WelcomeModal />}
						{/* {location.pathname === "/welcometodf" && <MainDashboard />} */}
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(withStyles(styles)(RegisterForm)); // Wrap with withRouter
