import NodeRSA from "node-rsa";

export const handleGenerateKey = (setOrgKey) => {
	try {
		const key = new NodeRSA({ b: 2048 });
		const publicKey = key.exportKey("public");
		const privateKey = key.exportKey("private");

		// Set the public key to your state
		setOrgKey(publicKey);
		console.log("Public Key:", publicKey);
		console.log("Private Key:", privateKey);
	} catch (error) {
		console.error("Error generating key:", error);
	}
};
