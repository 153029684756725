// UnloadWarning.js
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

const UnloadWarning = () => {
	const isUploading = useSelector((state) => state.fileUpload.isUploading);
	const isDownloading = useSelector((state) => state.fileDownload.isDownloading);
	const isPaymentInProgress = useSelector((state) => state.payment.isPaymentInProgress);

	const handleWindowBeforeUnload = useCallback(
		(event) => {
			if (isUploading || isDownloading || isPaymentInProgress) {
				event.preventDefault();
				const message = `You have ongoing ${
					(isUploading && "uploads") ||
					(isDownloading && "downloads") ||
					isPaymentInProgress ||
					"payment process"
				}. Are you sure you want to leave?`;
				return (event.result = message);
			}
		},
		[isUploading, isDownloading, isPaymentInProgress],
	);

	useEffect(() => {
		window.addEventListener("beforeunload", handleWindowBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleWindowBeforeUnload);
		};
	}, [isUploading, isDownloading, isPaymentInProgress]);

	return null;
};

export default UnloadWarning;
