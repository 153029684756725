import properties from "components/properties/ApplicationProps";

export function getSearchUserFromAccountJson(orgName, accountId) {
	let stringifyFetchResource = {
		action: properties.actions.SEARCH,
		orgName: orgName,
		accountId: accountId,
	};

	return stringifyFetchResource;
}
