import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	notifications: {},
	showNotificationPanelForceFully: false,
	makeButtonVisible: false,
	totalUploadsETC: 0,
	currentlyUploading: 0,
	currentlyDownloading: 0,
};

const uploadStatsSlice = createSlice({
	name: "uploadStats",
	initialState,
	reducers: {
		DEFAULT_STATS_FOR_NOTIFICATIONS: (state, { payload }) => {
			/**
			 * It is important to assign a blank object here with some important properties.
			 * This will help Immer to track updates.
			 * Go here to check more.
			 * https://redux-toolkit.js.org/usage/immer-reducers#updating-nested-data
			 */
			state.notifications[payload.fileId] = {};
			/* ------------------------------------ X ----------------------------------- */

			state.notifications[payload.fileId].fileId = payload.fileId;
			state.notifications[payload.fileId].name = payload.name;
			state.notifications[payload.fileId].type = payload.type;
			state.notifications[payload.fileId].size = payload.size;
			state.notifications[payload.fileId].operationType = payload.operationType;

			state.notifications[payload.fileId].etc = payload.etc;
			state.notifications[payload.fileId].completionTime = payload.completionTime;
			state.notifications[payload.fileId].error = payload.error;

			state.notifications[payload.fileId].isUploading =
				payload.operationType === "upload" ? payload.isUploading : payload.isDownloading;
			state.notifications[payload.fileId].isUploaded =
				payload.operationType === "upload" ? payload.isUploaded : payload.isDownloaded;
			state.notifications[payload.fileId].uploadSpeed =
				payload.operationType === "upload" ? payload.uploadSpeed : payload.downloadSpeed;
			state.notifications[payload.fileId].stopUpload =
				payload.operationType === "upload" ? payload.stopUpload : payload.stopDownload;
			state.notifications[payload.fileId].isUploadStopped =
				payload.operationType === "upload" ? payload.isUploadStopped : payload.isDownloadStopped;
			state.notifications[payload.fileId].didItTryToUpload =
				payload.operationType === "upload" ? payload.didItTryToUpload : payload.didItTryToDownload;
			state.notifications[payload.fileId].uploadCompletePercentageNumber =
				payload.operationType === "upload"
					? payload.uploadCompletePercentageNumber
					: payload.downloadCompletePercentageNumber;
		},

		ADD_TO_NOTIFICATIONS: (state, { payload }) => {
			/**
			 * It is no needed or recommended to create new object here.
			 * Because it is already got created by above reducer
			 * and object are by nature reference type. So this reducer will
			 * point to same object.
			 */
			state.notifications[payload.fileId].etc = payload.etc;
			state.notifications[payload.fileId].completionTime = payload.completionTime;
			state.notifications[payload.fileId].error = payload.error;
			state.notifications[payload.fileId].errorMssg = payload.errorMssg;

			state.notifications[payload.fileId].isUploading =
				payload.operationType === "upload" ? payload.isUploading : payload.isDownloading;
			state.notifications[payload.fileId].isUploaded =
				payload.operationType === "upload" ? payload.isUploaded : payload.isDownloaded;
			state.notifications[payload.fileId].uploadSpeed =
				payload.operationType === "upload" ? payload.uploadSpeed : payload.downloadSpeed;
			state.notifications[payload.fileId].stopUpload =
				payload.operationType === "upload" ? payload.stopUpload : payload.stopDownload;
			state.notifications[payload.fileId].isUploadStopped =
				payload.operationType === "upload" ? payload.isUploadStopped : payload.isDownloadStopped;
			state.notifications[payload.fileId].didItTryToUpload =
				payload.operationType === "upload" ? payload.didItTryToUpload : payload.didItTryToDownload;
			state.notifications[payload.fileId].uploadCompletePercentageNumber =
				payload.operationType === "upload"
					? payload.uploadCompletePercentageNumber
					: payload.downloadCompletePercentageNumber;
		},

		UPDATE_TOTAL_UPLOADS_ETC: (state, _) => {
			state.totalUploadsETC = Object.keys(state.notifications)
				.map((fileId) => {
					const notification = state.notifications[fileId];
					return notification.etc || 0;
				})
				.filter((x) => x)
				.reduce((a, b) => a + b, 0);
		},

		RESET_TOTAL_UPLOADS_ETC: (state, _) => {
			state.totalUploadsETC = initialState.totalUploadsETC;
		},

		MAKE_CLEAR_NOTIFICATIONS_BUTTON_VISIBLE: (state, { payload }) => {
			state.makeButtonVisible = payload;
		},

		SHOW_NOTIFICATION_PANEL_FORCEFULLY: (state, _) => {
			const notificationsPanelEl = document.querySelector(".notifications-panel-container");
			state.showNotificationPanelForceFully = true;

			notificationsPanelEl.style.display = "block";
			notificationsPanelEl.classList.add("animate__slideInUp");
			notificationsPanelEl.classList.remove("animate__slideOutDown");
		},

		HIDE_NOTIFICATION_PANEL_FORCEFULLY: (state, _) => {
			const notificationsPanelEl = document.querySelector(".notifications-panel-container");
			state.showNotificationPanelForceFully = false;

			notificationsPanelEl.classList.add("animate__slideOutDown");
			notificationsPanelEl.classList.remove("animate__slideInUp");
			setTimeout(() => {
				notificationsPanelEl.style.display = "none";
			}, 500);
		},

		TOGGLE_NOTIFICATION_PANEL: (state) => {
			const notificationsPanelEl = document.querySelector(".notifications-panel-container");
			state.showNotificationPanelForceFully = !state.showNotificationPanelForceFully;

			if (state.showNotificationPanelForceFully) {
				notificationsPanelEl.style.display = "block";
				notificationsPanelEl.classList.add("animate__slideInUp");
				notificationsPanelEl.classList.remove("animate__slideOutDown");
			} else {
				notificationsPanelEl.classList.add("animate__slideOutDown");
				notificationsPanelEl.classList.remove("animate__slideInUp");
				setTimeout(() => {
					notificationsPanelEl.style.display = "none";
				}, 500);
			}
		},

		GET_TOTAL_NUMBER_OF_UPLOADING_FILES: (state, _) => {
			state.currentlyUploading = Object.keys(state.notifications)
				.map((key) => state.notifications[key])
				.filter(({ operationType, isUploading }) => operationType === "upload" && isUploading === true).length;
		},
		GET_TOTAL_NUMBER_OF_DOWNLOADING_FILES: (state, _) => {
			state.currentlyUploading = Object.keys(state.notifications)
				.map((key) => state.notifications[key])
				.filter(
					({ operationType, isUploading }) => operationType === "download" && isUploading === true,
				).length;
		},

		// STOP_ALL_UPLOADS: (state, _) => {
		// 	Object.keys(state.notifications)
		// 		.map((key) => current(state.notifications[key]))
		// 		.filter(
		// 			({ isUploaded, isUploadStopped, stopUpload }) =>
		// 				isUploaded === false && isUploadStopped === false && typeof stopUpload === "function",
		// 		)
		// 		.forEach(({ stopUpload }) => {
		// 			stopUpload();
		// 		});
		// },

		CLEAR_ALL_NOTIFICATIONS: (state, _) => {
			// TODO: Look for alternative ways to impletement removing uploaded notification
			//! using `delete` is not recommended for deleting object keys.

			Object.keys(state.notifications)
				.map((key) => state.notifications[key])
				.filter(
					({ isUploaded, isUploading, didItTryToUpload, isUploadStopped }) =>
						(isUploaded || isUploadStopped || didItTryToUpload) && isUploading === false,
				)
				.forEach(({ fileId }) => {
					delete state.notifications[fileId];
				});
		},
	},
});

export const {
	DEFAULT_STATS_FOR_NOTIFICATIONS,
	ADD_TO_NOTIFICATIONS,
	UPDATE_TOTAL_UPLOADS_ETC,
	RESET_TOTAL_UPLOADS_ETC,
	CLEAR_ALL_NOTIFICATIONS,
	// STOP_ALL_UPLOADS,
	GET_TOTAL_NUMBER_OF_UPLOADING_FILES,
	GET_TOTAL_NUMBER_OF_DOWNLOADING_FILES,
	MAKE_CLEAR_NOTIFICATIONS_BUTTON_VISIBLE,
	SHOW_NOTIFICATION_PANEL_FORCEFULLY,
	HIDE_NOTIFICATION_PANEL_FORCEFULLY,
	TOGGLE_NOTIFICATION_PANEL,
} = uploadStatsSlice.actions;

export default uploadStatsSlice.reducer;
