import { memo, useCallback, useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import UserSelector from "./UserSelector";
import { neutral, textsize } from "assets/css/MainCss";
import { FontContext } from "../UI/Theme";
import { useDispatch, useSelector } from "react-redux";
import { getShareResourceJson } from "components/requestcontext/ShareResource";
import { dashboardApi } from "components/misc/DashboardApi";
import { REFRESH_DASHBOARD_GRID } from "store/gridNavigationSlice";
import { SET_GRID_MESSAGE } from "store/uiSlice";
import getFetchSharedUsersJson from "components/requestcontext/FetchSharedUsers";

const ShareDialog = ({ openShareDialog, handleCloseDialog }) => {
	const dispatch = useDispatch();

	const { shareResources } = useSelector(({ resourceGrid }) => resourceGrid);
	const { fontFamily, largeTextSize } = useContext(FontContext);
	const { usersToShareResources } = useSelector(({ resourceGrid }) => resourceGrid);
	const [preSharedUsers, setPrevSharedUsers] = useState([]);

	const getPrevSharedUsersList = useCallback(async () => {
		const resourceIds = shareResources.map(({ resourceid }) => resourceid);
		const reqJson = getFetchSharedUsersJson(resourceIds);
		const res = await dashboardApi.fetchSharedUsers(reqJson);

		if (res?.data?.resourcePropertiesList) {
			const users = res.data.resourcePropertiesList.map(({ fields }) => fields.email);
			setPrevSharedUsers(() => users);
		}
	}, [shareResources]);

	const handleShareButton = useCallback(async () => {
		try {
			const shareActionRes = await Promise.allSettled(
				shareResources.map(async (resource) => {
					try {
						const userResourceJson = getShareResourceJson({
							resource,
							usersWhomToShare: usersToShareResources,
						});
						const addsharedResponse = await dashboardApi.addSharedResource(userResourceJson);
						const isResourceSharedSuccessfully = addsharedResponse?.data?.footer.code === 0;

						if (!isResourceSharedSuccessfully) {
							return Promise.reject({
								status: "failed",
								filename: resource.filename,
								resourceId: resource.resourceid,
							});
						}

						return Promise.resolve({
							status: "success",
							filename: resource.filename,
							resourceId: resource.resourceid,
						});
					} catch (error) {
						throw new Error(error);
					}
				}),
			);

			const success = shareActionRes
				.filter((res) => {
					if (res.status === "fulfilled") return res.value;
					return null;
				})
				.filter((x) => x);

			dispatch(REFRESH_DASHBOARD_GRID());
			handleCloseDialog();

			if (success.length === shareResources.length) {
				dispatch(SET_GRID_MESSAGE("Files Shared successfully"));
				return;
			}

			if (success.length < shareResources.length && success.length !== 0) {
				dispatch(SET_GRID_MESSAGE("Some files failed to Share"));
				return;
			}

			dispatch(SET_GRID_MESSAGE("Failed to Share files!"));
		} catch (error) {
			console.log(error);
		}
	}, [shareResources, usersToShareResources, handleCloseDialog, dispatch]);

	useEffect(() => {
		if (!shareResources.length) {
			return;
		}

		getPrevSharedUsersList();
	}, [shareResources, getPrevSharedUsersList]);

	return (
		<>
			<div>
				<Dialog
					open={openShareDialog}
					onClose={handleCloseDialog}
					maxWidth="false" // Set maxWidth to false to disable the default maxWidth
					PaperProps={{
						style: {
							minWidth: "500px",
							minHeight: "280px",
							maxWidth: "80%",
							maxHeight: "80%",
							width: "768px",
							height: "500px",
							overflowY: "hidden",
							fontFamily: fontFamily,
						},
					}}
				>
					<DialogTitle style={{ fontFamily: fontFamily, fontSize: largeTextSize }}>
						Share Resource
					</DialogTitle>

					<DialogContent>
						<UserSelector prevSharedUsers={preSharedUsers} />
					</DialogContent>

					<DialogActions
						style={{
							marginRight: "18px",
							paddingBottom: "10px",
							marginBottom: "10px",
						}}
					>
						{/* TODO: Add loading support */}
						<Button
							onClick={handleCloseDialog}
							variant="outlined"
							color="primary"
							style={{
								color: neutral.buttoncolor,
								borderColor: neutral.buttoncolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
								// width: "100px",
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={handleShareButton}
							variant="contained"
							color="primary"
							style={{
								backgroundColor: neutral.buttoncolor,
								color: neutral.buttontextcolor,
								fontSize: textsize.buttontextsize,
								fontFamily: fontFamily,
								// width: "100px",
							}}
							data-cy-button="resource-share-button"
						>
							Share
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</>
	);
};

export default memo(ShareDialog);
