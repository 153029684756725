import React from "react";
import { TextField, IconButton, InputAdornment, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import useStyles from "components/dashboard/header/styles";
import TuneIcon from "@mui/icons-material/Tune";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
export default function Search(props) {
	const { searchTerm, handleSearchTermChange, handleClearSearch, handleKeyPress, handleOpenDialog } = props;
	const classes = useStyles();

	// Define the fixed width for the TextField
	const { fontFamily } = useContext(FontContext);
	return (
		<div className={classes.searchContainer}>
			<TextField
				value={searchTerm}
				onChange={handleSearchTermChange}
				onKeyPress={handleKeyPress}
				size="small"
				fullWidth
				data-cy-share="search"
				id="search-input"
				variant="outlined"
				placeholder="Search"
				style={{ maxWidth: "100%" }} // Set the fixed width here
				InputProps={{
					placeholder: "Search",
					style: { fontFamily: fontFamily },
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							{searchTerm && (
								<IconButton onClick={handleClearSearch}>
									<ClearIcon />
								</IconButton>
							)}
							<IconButton onClick={handleOpenDialog}>
								<Tooltip title="Advanced Search">
									<TuneIcon aria-controls="advance-search" />
								</Tooltip>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<div className="results" />
		</div>
	);
}
