// ManualPaymentAdapter.js
import IPaymentAdapter from './IPaymentAdapter';

export default class ManualPaymentAdapter extends IPaymentAdapter {
  makePayment(amount) {
    // Process manual payment
    console.log(`Received manual payment of ${amount}`);
    this.onPaymentSuccess({ amount });
  }

  onPaymentSuccess(data) {
    console.log("Manual payment successful", data);
  }

  onPaymentFailure(error) {
    console.log("Manual payment failed", error);
  }
}
