import { makeStyles } from "@material-ui/styles";
import { neutral } from "assets/css/MainCss";
import { icon } from "assets/css/MainCss";

export default makeStyles((theme) => ({
	root: {
		width: "100%",
		border: "none",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
		border: "none",
	},
	table: {
		minWidth: "100%",
		border: "none",
		// makes datagrid header to stick
		overflowY: "scroll",
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	tableRowSelected: {
		"&:hover": {
			backgroundColor: neutral.hovereffect,
			color: "white",
		},
		"&$checked": {
			backgroundColor: neutral.hovereffect,
		},
	},
	tableRowRoot: {
		"&:hover": {
			backgroundColor: neutral.hovereffect,
			color: "white",
		},
	},
	// dragOverRow: {
	//   backgroundColor: 'red'
	// },
	startDraggedOverRow: {
		backgroundColor: "#12a5c6" /* Or any other highlight color you prefer */,
		color: "#ffffff",
	},
	endDraggedOverRow: {
		backgroundColor: "#fff" /* Or any other highlight color you prefer */,
		color: "#ffffff",
	},
	textfield: {
		fontSize: "14px",
	},
	dragdropfile: {
		align: "center",
		height: "80vh",
		fontSize: "18px",
		fontWeight: "bold",
		color: "#555",
	},
	breadcrumb: {
		verticalAlign: "middle",
		padding: "0px 0px 5px 15px",
		alignItems: "center",
		justify: "center",
	},
	gridview: {
		alignItems: "center",
		justify: "center",
	},
	draganddrop: {
		width: 0,
		height: 0,
		overflow: "hidden",
	},
	//tablecontainer in dashboard
	tablecontainer: {
		border: "none",
		borderBottom: "none",
		maxHeight: "100%",
		height: "86vh",
		backgroundRepeat: "no-repeat",
		position: "relative",
		zIndex: 1,
	},
	//outercontainer of dashboard
	outercontainer: {
		// backgroundColor: 'rgb(238, 242, 246);',
		//border: "1px solid #c7c7c7",
		//borderRadius: "5px",
		overflowY: "auto",
		height: "calc(100vh - 125px)",
		marginRight: "10px",
		marginLeft: "10px",
		// backgroundImage: `url(${backgroundImage})`,
	},
	//innercontainer of dashboard
	innercontainer: {
		// border: "1px solid #c7c7c7",
		maxHeight: "100%",
		height: "100%",
	},
	tablecell: {
		overflowY: "auto",
		minHeight: "70vh",
		border: "none",
		height: "76vh",
		fontSize: "18px",
		fontweight: "bold",
		textcolor: "#ffffff",
		color: "#555",
	},
	tablerow: {
		border: "none", // Remove the border
	},
	checkbox: {
		color: neutral.buttoncolor,
	},
	iconStyleGroup: {
		width: icon.icon,
		height: icon.height,
		color: neutral.textcolor,
		marginRight: "5px",
		marginLeft: "5px",
		textcolor: neutral.textcolor,
	},
	listItemWithBorder: {
		borderBottom: "rgba(108, 173, 209, 0.5)",
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
		width: "50%",
	},
	iconStyle: {
		width: "13px",
		height: "13px",
	},
	buttonstyles: {
		width: "300px",
		height: "45px",
		borderRadius: "10px",
	},
	settingCardStyles: {
		width: "100%",
		minWidth: 290,
		maxWidth: 290,
		textAlign: "center",
		margin: "auto",
		marginTop: "2.5%",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		height: "25%",
		maxHeight: "25%",
		justifyContent: "space-between",
		marginLeft: "2.5%",
		marginRight: "2.5%",
		padding: 0,
		borderRadius: "1%",
		position: "relative",
	},
	settingCardStyles1: {
		width: "100%",
		minWidth: 250,
		maxWidth: 290,
		textAlign: "center",
		margin: "auto",
		marginTop: "2.5%",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		height: "25%",
		maxHeight: "25%",
		justifyContent: "space-between",
		marginLeft: "2.5%",
		marginRight: "2.5%",
		padding: 0,
		borderRadius: "1%",
		position: "relative",
	},
	settingCardButton: {
		position: "absolute",
		bottom: "10px",
		left: "50%",
		transform: "translateX(-50%)",
	},
	settingCardIcon: {
		width: "70px",
		height: "70px",
		marginLeft: "auto",
		marginRight: "auto",
		borderRadius: "50%",
		backgroundColor: "#bcbec0",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: "0 auto",
		marginBottom: "20px",
	},
	advancedSettingButtonContainer: {
		// display: "flex",
		justifyContent: " left",
	},
	advancedSettingButton: {
		transition: "background-color 0.3s",
		display: "flex",
		alignItems: "center",
		padding: "8px 16px",
	},
	advancedSettingicon: {
		transform: "rotate(0)",
		transition: "transform 0.3s ease",
		fontSize: "1.5rem",
	},
}));
