import React, { useState, useEffect, useRef, memo } from "react";
import CreatableSelect from "react-select/creatable";
import { dashboardApi } from "components/misc/DashboardApi";
import { getResourceLabelJson } from "components/requestcontext/ResourceLabel";
import { maxLabels } from "Constants";
import eventEmitter from "components/misc/eventEmitter";
import "./ResourceLabel.css";
import { useDispatch } from "react-redux";
import { SET_GRID_MESSAGE } from "store/uiSlice";
import { useLocation } from "react-router-dom";

const ResourceLabels = ({ resourceLabels, resourceid, resourceName }) => {
	const dispatch = useDispatch();
	const currentPageRoute = useLocation().pathname;

	const createOption = (label, resourceid) => ({
		label,
		value: label.toUpperCase().replace(/\W/g, ""),
		resourceid: resourceid,
	});

	const labelsOptions = JSON.parse(resourceLabels.value);

	const hasLabels = labelsOptions.filter((x) => x).map((label) => createOption(label, resourceid));

	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState(
		labelsOptions.filter((x) => x).map((label) => createOption(label, resourceid)),
	);
	const [selectorOpen, setSelectorOpen] = useState(false); // Track selector open state
	const containerRef = useRef(null);

	const customStyles = {
		control: (provided) => ({
			...provided,
			minHeight: "10px", // Adjust the height as needed
			border: "none", // Remove border
			boxShadow: "none", // Remove box shadow
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			display: "none", // Hide the down arrow indicator
		}),
		option: (provided) => ({
			...provided,
			maxWidth: "350px !important", // Use !important to ensure priority
			fontSize: "12px !important", // Adjust the font size for options
			padding: "1px 3px !important", // Adjust the padding for options
			whiteSpace: "nowrap", // Display options in one line
			maxHeight: "250px",
		}),
	};
	const containerStyle = {
		width: "350px",
		maxHeight: "250px",
		marginLeft: "-10px",
	};

	useEffect(() => {
		// Close the selector when clicking outside of it
		function handleClickOutside(event) {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setSelectorOpen(false);
			}
		}
		// Attach the event listener when the selector is open
		if (selectorOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			// Remove the event listener when the selector is closed
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			// Clean up the event listener when the component unmounts
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [selectorOpen]);

	function handleChange(selected) {
		if (selected.length <= maxLabels) {
			console.log(selected);
			console.log("selected", selected);
			handleresourcelabels(resourceid, selected);
		} else {
			console.log("Maximum number of labels reached.");
			dispatch(SET_GRID_MESSAGE("You can not add more than 3 labels"));
		}
	}

	const handleresourcelabels = (resourceid, selected) => {
		console.log(selected.label, options);
		const resourceLabel = [];

		for (let i = 0; i < selected.length; i++) {
			if (selected[i].label.length > 15) {
				dispatch(SET_GRID_MESSAGE("Label can not be more than 15 characters"));
				return;
			}

			resourceLabel.push(selected[i].label);
			console.log(resourceLabel);
		}

		setIsLoading(true);
		const userResourceLabelJson = getResourceLabelJson(resourceid, resourceLabel);

		console.log(userResourceLabelJson);

		dashboardApi
			.resourceLabels(userResourceLabelJson)
			.then((res) => {
				if (res.data.footer.msg === "SUCCESS") {
					if (currentPageRoute.includes("dashboard")) {
						eventEmitter.emit("RefreshDashboardEvent");
					}

					if (currentPageRoute.includes("search")) {
						eventEmitter.emit("refreshSearchGrid");
					}
				}
			})
			.catch((error) => {
				console.error("An error occurred while searching for users:", error);
				setOptions([]);
			});

		setIsLoading(false);
	};

	return (
		<div
			ref={containerRef}
			style={containerStyle}
			className="resource-labels"
			data-test-id={`${resourceName}-label-container`}
		>
			<CreatableSelect
				isMulti
				isClearable
				isDisabled={isLoading}
				isLoading={isLoading}
				options={options}
				value={hasLabels}
				onChange={handleChange}
				placeholder={!hasLabels ? "" : "None"}
				styles={customStyles}
				data-test-id={`${resourceName}-label-input`}
				components={{ IndicatorSeparator: () => null }}
			/>
		</div>
	);
};

export default memo(ResourceLabels);
