import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import Button from "components/inputs/Button";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { dashboardApi } from "components/misc/DashboardApi";
import useStyles from "routes/TableStyles";
import { CssBaseline, Grid } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Notification from "components/dashboard/notification/Notification";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { FontContext } from "components/UI/Theme";
import { getSearchUsersToLinkJson } from "components/requestcontext/SearchUsersToLink";
import properties from "components/properties/ApplicationProps";
import { GlobalUsername } from "Constants";
import { useSelector } from "react-redux";
import createUpdateRoleAttributeJSON from "components/requestcontext/UpdateUserRoleAttribute";
import { useDebouncedState } from "@react-hookz/web/esm/useDebouncedState";
import FormContainer from "layout/FormContainer";
import "./DMScreen.styles.css";

const DMScreen = () => {
	const classes = useStyles();
	const history = useHistory();
	const assignrole = useRef();
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [searchTerm, setSearchTerm] = useDebouncedState("", 300);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [selectedRole, setSelectedRole] = useState(null);
	const authDetails = useSelector(({ authDetails }) => authDetails.data);
	const { themeColor, fontFamily } = useContext(FontContext);
	const showSnackbarWithMessage = (message) => {
		setShowSnackbar(true);
		setSnackbarMessage(message);
	};
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		async function searchUsers() {
			const min_search_chars = 3;

			if (searchTerm.length >= min_search_chars) {
				try {
					setIsLoading(true);
					let userResourceJson = getSearchUsersToLinkJson(searchTerm);
					let searchuserResponse = await dashboardApi.searchAccountUsers(userResourceJson);
					console.log(searchuserResponse.data.resourcePropertiesList);
					const fileItems = searchuserResponse.data.resourcePropertiesList.map((item) => {
						for (var i = 0; i < item.length; i++) {
							var dataa = item[i];
							console.log(dataa);
							//  resourceIds push(item[i].fields.resourceid); // add resourceid to resourceIds array
						}
						return {
							value: item.fields.email,
							label: `${item.fields.email} (${item.fields.rolename})`,
							avatar: item.fields.avatarUrl,
							userid: item.fields.userid,
						};
					});
					const usersWithoutGlobalUsername = fileItems.filter(
						(element) => element.value !== GlobalUsername.username,
					);

					console.log(usersWithoutGlobalUsername);
					setOptions(usersWithoutGlobalUsername);
				} catch (error) {
					console.error("An error occurred while searching for users:", error);
					setOptions([]);
				} finally {
					setIsLoading(false);
				}
			}
		}
		if (searchTerm.trim() !== "") {
			searchUsers();
		} else {
			setOptions([]);
		}
	}, [searchTerm]);

	function handleInputChange(newValue) {
		setSearchTerm(newValue);
	}

	function handleChange(selected) {
		const names = selected.map((item) => item.userid);
		console.log(names);
		setSelectedUsers(selected);
		console.log("selected users :", options);
	}

	const formSubmitHandler = useCallback(
		async (formData) => {
			setButtonLoading(() => true);

			try {
				const initiatorDetails = { email: authDetails.email, user: authDetails.preferred_username };
				const requestJson = createUpdateRoleAttributeJSON({ initiatorDetails, formData });
				const res = await dashboardApi.updateUsersRoleAttribute(requestJson);

				if (res.hasOwnProperty("data")) {
					if (res.data?.footer?.code === 0) {
						setSnackbarMessage("User's Role has been successfully changed");
						setShowSnackbar(() => true);
						setButtonLoading(() => false);
					} else {
						setSnackbarMessage("Failed to change user's role. Setting it to 'Unknown'");
						setShowSnackbar(() => true);
						setButtonLoading(() => false);
					}
				} else {
					setSnackbarMessage("Something went wrong");
					setShowSnackbar(() => true);
					setButtonLoading(() => false);
				}
			} catch (error) {
				console.log(error);
				setButtonLoading(() => false);
			}
		},
		[authDetails],
	);

	const handleUserRoleChange = useCallback(async () => {
		if (selectedUsers.length === 0) {
			return;
		}

		const formData = {
			employeeDetails: selectedUsers,
			orgName: authDetails.user_org,
			userRole: selectedRole,
		};

		try {
			formSubmitHandler(formData);
		} catch (error) {
			console.error("An error occurred while linking user accounts:", error);
			showSnackbarWithMessage("Error linking user accounts");
		}
		setSelectedRole([]);
		setOptions([]);
		setSelectedUsers([]);
	}, [selectedRole, authDetails, selectedUsers, formSubmitHandler]);

	function handleClose() {
		history.push("/settings");
	}

	const USER_ROLES = [
		{ id: "c23e3711-9845-4071-a9d4-649189f76b18", label: "Member", value: properties.userRoles.member },
		{ id: "0dea2cfc-26e4-454c-a628-0e96b84dd769", label: "Billing", value: properties.userRoles.billing },
		{ id: "ed0b4232-bf68-430f-a7ec-3b7ce8724186", label: "System Admin", value: properties.userRoles.systemadmin },
	];

	function handleChangeRole(selectedRole) {
		console.log("Selected role:", selectedRole);
		setSelectedRole(selectedRole);
	}

	return (
		<div
			style={{
				position: "relative",
				backgroundColor: themeColor,
				height: "calc(100% - 55px)",
			}}
		>
			<CssBaseline />
			<Grid className={classes.breadcrumb}>
				<Grid
					item
					md={9}
					sm={12}
					xs={12}
					container
					alignItems="center"
					justify="center"
					style={{
						maxWidth: "100%",
						height: "32px",
					}}
				></Grid>
			</Grid>
			<div className={classes.outercontainer}>
				<div className={classes.innercontainer}>
					<TableContainer className={classes.tablecontainer}>
						<FormContainer>
							<form>
								<h1
									style={{
										fontFamily: fontFamily,
										fontSize: 24,
										textAlign: "center",
									}}
								>
									Change User Role
								</h1>

								<hr />

								<TextField
									className="input-field"
									label="Organization Name"
									variant="outlined"
									size="small"
									fullWidth
									margin="normal"
									value={authDetails.user_org}
									InputProps={{
										readOnly: true,
										style: { fontFamily: fontFamily },
									}}
									InputLabelProps={{
										style: { fontFamily: fontFamily },
									}}
								/>

								<Select
									autoFocus
									fullWidth
									isMulti
									className="input-field"
									margin="normal"
									options={options}
									value={selectedUsers}
									onChange={handleChange}
									onInputChange={handleInputChange}
									isLoading={isLoading}
									placeholder="Search User"
									inputId="share-role"
									noOptionsMessage={() => "No results found"}
									styles={{
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										control: (provided) => ({
											...provided,
											marginTop: "5px",
											minHeight: "36px",
											width: "100%",
											fontFamily: fontFamily,
										}),
									}}
									formatOptionLabel={(option) => (
										<div style={{ display: "flex", alignItems: "center" }}>
											<div>{option.label}</div>
										</div>
									)}
								/>

								<Select
									className="input-field"
									ref={assignrole}
									margin="normal"
									options={USER_ROLES}
									value={selectedRole}
									onChange={handleChangeRole}
									isLoading={isLoading}
									placeholder="Assign role"
									inputId="assign-role"
									noOptionsMessage={() => "No results found"}
									//	isSearchable={false}
									styles={{
										width: "100%",
										control: (provided) => ({
											...provided,
											width: "100%",
											marginTop: "10px",
											minHeight: "36px",
											fontFamily: fontFamily,
										}),
										menu: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
										menuList: (provided) => ({
											...provided,
											minHeight: "30px",
											maxHeight: "150px",
											fontFamily: fontFamily,
										}),
									}}
								/>

								<div className="form-buttons-container">
									<Button
										onClick={handleClose}
										variant="outlined"
										data-button-name="cancel-user-role"
									>
										Cancel
									</Button>

									<Button
										onClick={handleUserRoleChange}
										variant="outlined"
										buttonType="main"
										isLoading={buttonLoading}
										data-button-name="check-user-role"
									>
										Change
									</Button>
								</div>
							</form>
						</FormContainer>
					</TableContainer>
				</div>
			</div>
			<Notification
				showSnackbar={showSnackbar}
				snackbarMessage={snackbarMessage}
				setShowSnackbar={setShowSnackbar}
			/>
		</div>
	);
};

export default memo(DMScreen);
