import React, { memo, useCallback, useRef } from "react";
import eventEmitter from "components/misc/eventEmitter";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PUT_FILES_IN_QUEUE } from "store/fileUploadSlice";
import { GlobalRole } from "Constants";
import properties from "components/properties/ApplicationProps";

const FolderSelector = () => {
	const inputFileRef = useRef();
	const dispatch = useDispatch();
	const droppedLocationId = useSelector(({ droppedLocationId }) => droppedLocationId.locationId);

	const handleFolderSelection = useCallback(() => {
		/*Collecting node-element and performing click*/
		try {
			inputFileRef.current.click();
		} catch (error) {}
	}, []);

	const uploadFolder = useCallback(
		(e) => {
			const files = e.target.files;
			const droppedFiles = [].concat([...files]);
			dispatch(PUT_FILES_IN_QUEUE({ droppedFiles, droppedLocationId }));

			// dump selected files so onChange event can detect new file selection
			e.target.value = "";
		},
		[droppedLocationId, dispatch],
	);

	useEffect(() => {
		eventEmitter.on("selectUploadFolder", () => {
			handleFolderSelection();
		});
	}, [handleFolderSelection]);

	return (
		<>
			<div>
				<label className="custom-file-upload">
					<input
						ref={inputFileRef}
						type="file"
						accept="*"
						path="true"
						webkitdirectory="true"
						disabled={GlobalRole.userrole === properties.userRoles.user}
						style={{ display: "none" }}
						onChange={uploadFolder}
					/>
				</label>
			</div>
		</>
	);
};
export default memo(FolderSelector);
